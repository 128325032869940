/* eslint-disable no-underscore-dangle */

import {toast} from 'react-toastify';

import {authorChanged, authorsChanged} from '.';
import {db} from '../../../helpers/helper-firebase';
import {logErrorMessage} from '../../../helpers/helper-logs';

const displayError = err => {
  // Handle Errors here.
  logErrorMessage(err.message);
  toast.error(err.message, {
    kind: 'error',
    autoClose: 5000,
  });
  return false;
};

const parseAuthor = (id, data) => {
  const {
    created,
    email,
    image,
    lastEdited,
    lastName,
    level,
    name,
    title,
    hidden,
    internal = false,
    ignoredAuthorIds = [],
  } = data;
  return {
    id,
    name,
    lastName,
    initials: name.trim().slice(0, 1) + lastName.trim().slice(0, 1),
    fullName: `${name.trim()} ${lastName.trim()}`,
    email,
    title,
    level,
    hidden,
    internal,
    image: image?.id ?? null,
    created: created.toDate().toISOString(),
    lastEdited:
      lastEdited?.toDate?.()?.toISOString?.() ?? new Date().toISOString(),
    ignoredAuthorIds,
  };
};

export const listenAuhtors = () => dispatch =>
  db.collection('developmentAuthor').onSnapshot(querySnapshot => {
    const authors = {};
    querySnapshot.forEach(doc => {
      authors[doc.id] = parseAuthor(doc.id, doc.data());
      // if (!data.hidden) {
      //   authors[doc.id] = parseFirebaseFields(data);
      // }
    });
    dispatch(authorsChanged(authors));
  });

export const getAuthor = uid => dispatch =>
  db
    .ref('developmentAuthor')
    .doc(uid)
    .get()
    .then(doc => {
      const author = parseAuthor(doc.id, doc.data());
      dispatch(authorChanged(author));
    })
    .catch(displayError);
