import {
  WIP_CHANGED,
  WIPS_CHANGED,
  PINS_CHANGED,
  LISTENS_CHANGED,
  WIP_JOINS_CHANGED,
  WIP_PLAYING,
  WIP_UPLOADING,
  WIP_RESET,
  WIP_UPLOADING_PROGRESS,
  WIPS_NOTIFICATION,
  NOTES_CHANGED,
} from './constants';

export const wipChanged = (payload: any) => ({
  type: WIP_CHANGED,
  payload,
});

export const wipsChanged = (payload: any) => ({
  type: WIPS_CHANGED,
  payload,
});

export const pinsChanged = (payload: any) => ({
  type: PINS_CHANGED,
  payload,
});

export const listensChanged = (payload: any) => ({
  type: LISTENS_CHANGED,
  payload,
});

export const wipJoinsChanged = (payload: any) => ({
  type: WIP_JOINS_CHANGED,
  payload,
});

export const notesChanged = (payload: any) => ({
  type: NOTES_CHANGED,
  payload,
});

export const wipsPlaying = (payload: any) => ({
  type: WIP_PLAYING,
  payload,
});

export const wipsUploading = (payload: any) => ({
  type: WIP_UPLOADING,
  payload,
});

export const wipsUploadingProgress = (payload: any) => ({
  type: WIP_UPLOADING_PROGRESS,
  payload,
});

export const wipsNotifications = (payload: any) => ({
  type: WIPS_NOTIFICATION,
  payload,
});

export const wipsReset = (payload: any) => ({
  type: WIP_RESET,
  payload,
});
