// const crypto = require('crypto');

const CryptoJS = require('crypto-js');

const keySize = 256;
const iterations = 100;

class Encrypter {
  constructor(encryptionKey, userId) {
    this.chars = userId.split('').map(e => e.charCodeAt(0));
    this.iv = CryptoJS.lib.WordArray.create(this.chars);

    this.key = CryptoJS.PBKDF2(encryptionKey, 'salt', {
      keySize: keySize / 32,
      iterations,
    });
  }

  encrypt(clearText) {
    // console.log(clearText);
    const encrypted = CryptoJS.AES.encrypt(clearText, this.key, {
      iv: this.iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    return encodeURIComponent(encrypted.toString());
  }

  decrypt(encryptedText) {
    const decrypted = CryptoJS.AES.decrypt(
      decodeURIComponent(encryptedText),
      this.key,
      {
        iv: this.iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      },
    );
    // console.log('decrypted', decrypted.toString(CryptoJS.enc.Utf8));
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

export default Encrypter;
