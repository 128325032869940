import React from 'react';
import styled from 'styled-components';

interface IPopoverMenuItemProps {
  onClick?: () => void;
  divider?: boolean;
}

const StyledPopoverMenuItem = styled.div`
  font-weight: 500;
  font-size: 1.3rem;
  padding: 1rem;
  cursor: ${props => (props.onClick ? 'pointer' : '')};
  background-color: transparent;
  &:hover {
    background-color: rgba(29, 33, 36, 0.04);
  }
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const StyledDivider = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #1d2124;
`;

export const PopoverMenuItem: React.FC<IPopoverMenuItemProps> = ({
  children,
  onClick,
  divider,
}) => (
  <>
    <StyledPopoverMenuItem onClick={onClick}>
      {/** @ts-expect-error */}
      {children}
    </StyledPopoverMenuItem>
    {!!divider && <StyledDivider />}
  </>
);
