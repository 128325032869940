import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const preventDefaults = e => {
  e.preventDefault();
  e.stopPropagation();
};

export const supportedAudio = {
  'audio/mpeg': true,
  'audio/mp4': true,
  'audio/x-m4a': true,
  'audio/flac': true,
  'audio/wav': true,
  'audio/aiff': true,
};

export const isDraggedItemFile = f => {
  console.log(f.webkitGetAsEntry());
  return f.webkitGetAsEntry().isFile;
};

const Dropzone = ({
  children,
  // supportedFormats,
  supportedFiletype,
  onFiles,
  multiple,
  onFolders,
}) => {
  const [active, setActive] = useState(false);
  const activeRef = useRef(false);
  const highlight = e => {
    if (activeRef.current !== true) {
      activeRef.current = true;
      setActive(true);
    }
    preventDefaults(e);
  };
  const unhighlight = e => {
    if (activeRef.current !== false) {
      activeRef.current = false;
      setActive(false);
    }
    preventDefaults(e);
  };
  const drop = e => {
    preventDefaults(e);
    const dt = e.dataTransfer;
    if (!dt.items) {
      return;
    }
    // const filesArr = dt.items ? [...dt.items] : [...dt.files];
    const files = [...dt.files].filter((_, i) =>
      isDraggedItemFile(dt.items[i]),
    );
    const folders = [...dt.files].filter(
      (_, i) => !isDraggedItemFile(dt.items[i]),
    );
    setActive(false);
    console.log(files);
    console.log(folders);
    const supported = files.filter(
      f => f.type.split('/')[0] === supportedFiletype,
    );
    if (supported.length > 0) {
      onFiles(multiple ? supported : [supported[0]]);
    }
    if (folders.length > 0) {
      onFolders(multiple ? folders : [folders[0]]);
    }
  };
  useEffect(() => {
    window.addEventListener('dragenter', highlight, false);
    window.addEventListener('dragover', highlight, false);
    window.addEventListener('mouseout', unhighlight, false);
    window.addEventListener('drop', drop, false);
    return () => {
      window.removeEventListener('dragenter', highlight, false);
      window.removeEventListener('dragover', highlight, false);
      window.removeEventListener('mouseout', unhighlight, false);
      window.removeEventListener('drop', drop, false);
    };
  }, []);
  return (
    <div className={`app__dropzone ${active ? 'active' : ''}`}>
      <div className="app__dropzone--overlay" />
      <div className="app__dropzone--content">{children}</div>
    </div>
  );
};

Dropzone.defaultProps = {
  // supportedFormats: supportedAudio,
  supportedFiletype: 'audio',
  onFiles: () => {},
  onFolders: () => {},
  multiple: false,
};

Dropzone.propTypes = {
  children: PropTypes.node.isRequired,
  // supportedFormats: PropTypes.objectOf(PropTypes.any),
  supportedFiletype: PropTypes.string,
  onFiles: PropTypes.func,
  onFolders: PropTypes.func,
  multiple: PropTypes.bool,
};

export default Dropzone;
