import React from 'react';

import '@untitled/app/core/components/atoms/progress-circular/style.scss';

interface ProgressCircularProps {
  className: string;
  color?: string;
  bg?: string;
  radius: number;
  stroke: number;
  progress: number;
}

interface ProgressCircularGridItemProps
  extends Omit<ProgressCircularProps, 'radius'> {}

export const ProgressCircularGridItem = ({
  className = '',
  color,
  bg,
  stroke,
  progress,
}: ProgressCircularGridItemProps) => {
  const circumference = 27 * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <div className={`app__progress--circular ${className}`}>
      <svg version="1.1" height={100} width={100}>
        <circle
          className="circle-bg"
          cx={57}
          cy={44}
          r={27}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{strokeDashoffset: 0, stroke: bg}}
        />
        <circle
          className="circle"
          cx={57}
          cy={44}
          r={27}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{strokeDashoffset, stroke: color}}
        />
      </svg>
    </div>
  );
};

const ProgressCircular = ({
  className = '',
  progress = 0,
  radius = 50,
  stroke = 2,
  color = undefined,
  bg = undefined,
}: ProgressCircularProps) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <div className={`app__progress--circular ${className}`}>
      <svg
        version="1.1"
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
        preserveAspectRatio="xMinYMin meet">
        <circle
          className="circle-bg"
          cx={radius}
          cy={radius}
          r={normalizedRadius}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{strokeDashoffset: 0, stroke: bg}}
        />
        <circle
          className="circle"
          cx={radius}
          cy={radius}
          r={normalizedRadius}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{strokeDashoffset, stroke: color}}
        />
      </svg>
    </div>
  );
};

export default ProgressCircular;
