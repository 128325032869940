import {
  AUTHOR_CHANGED,
  AUTHORS_CHANGED,
  AUTHOR_PRESENCE,
  AUTHOR_SELECT,
  AUTHOR_RESET,
} from './constants';

export const authorChanged = payload => ({
  type: AUTHOR_CHANGED,
  payload,
});

export const authorsChanged = payload => ({
  type: AUTHORS_CHANGED,
  payload,
});

export const authorsPresence = payload => ({
  type: AUTHOR_PRESENCE,
  payload,
});

export const authorsSelect = payload => ({
  type: AUTHOR_SELECT,
  payload,
});

export const authorsReset = payload => ({
  type: AUTHOR_RESET,
  payload,
});
