module.exports = {
  APP_MOUNTED: 'APP_MOUNTED',
  APP_UNMOUNTED: 'APP_UNMOUNTED',
  WORKER_MOUNTED: 'WORKER_MOUNTED',
  WORKER_STARTED: 'WORKER_STARTED',
  SAVE_QUEUE_CONFIG: 'SAVE_QUEUE_CONFIG',
  ADD_QUEUE_FOLDER: 'ADD_QUEUE_FOLDER',
  ADD_QUEUE_FOLDER_UNTITLED: 'ADD_QUEUE_FOLDER_UNTITLED',
  REMOVE_QUEUE_FOLDER: 'REMOVE_QUEUE_FOLDER',

  APP_INIT: 'APP_INIT',

  WATCHER_CHANGED: 'WATCHER_CHANGED',
  QUEUE_CHANGED: 'QUEUE_CHANGED',

  LOG_WORKER_MESSAGE: 'LOG_WORKER_MESSAGE',
  LOG_WORKER_EXCEPTION: 'LOG_WORKER_EXCEPTION',
  LOG_WORKER_EVENT: 'LOG_WORKER_EVENT',
};
