import React, {useState} from 'react';
import {PopoverMenuItem} from '@untitled/app/core/components/atoms/popover-menu-item';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
// @ts-expect-error
import IconMoreHorizontal from 'react-feather/dist/icons/more-horizontal';
// @ts-expect-error
import IconTrash from 'react-feather/dist/icons/trash-2';
// @ts-expect-error
import IconDownload from 'react-feather/dist/icons/download';
// @ts-expect-error
import IconLink from 'react-feather/dist/icons/link';
import {PopoverMenu} from '../../atoms/popover-menu';

interface IDetailsPopoverMenuTrackActionsProps {
  audioId: string;
  wipId: string;
  onDelete: () => void;
  onDownload: () => void;
  onClickShareTrackLink: (arg0: string) => void;
  isOwner: boolean;
  isUploader: boolean;
  uploading: boolean;
  canDownload: boolean;
  isProducerOfWip: boolean;
  isAuthorOfTrack: boolean;
  isAuthorOfWip: boolean;
}

const StyledPopoverMenuItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextContainer = styled.div`
  margin-left: 0.5rem;
  white-space: nowrap;
`;

export const DetailsPopoverMenuTrackActions: React.FC<
  IDetailsPopoverMenuTrackActionsProps
> = ({
  audioId,
  wipId,
  onDelete,
  onDownload,
  onClickShareTrackLink,
  isOwner,
  isUploader,
  uploading,
  canDownload,
  isProducerOfWip,
  isAuthorOfTrack,
  isAuthorOfWip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const {audio} = useSelector(state => ({
    // @ts-expect-error
    audio: !!audioId ? state.audios.all[audioId] : {},
  }));
  const canEnableShareLink = isAuthorOfTrack || isAuthorOfWip;
  const canShareLink = canEnableShareLink || isProducerOfWip;
  /**
   * While producers can share track links, we do not want to show the
   * 'Share Link' button if the track has not been made public yet
   */
  const showShareLink = canEnableShareLink || (canShareLink && audio.isPublic);
  const showDelete = isOwner || (isUploader && !uploading);
  const showDownload = canDownload && !uploading;

  const handleClickDelete = () => {
    setOpen(false);
    onDelete();
  };

  const handleClickDownload = () => {
    setOpen(false);
    onDownload();
  };

  const handleClickShareTrackLink = () => {
    setOpen(false);
    onClickShareTrackLink(audioId);
  };

  const labelId = 'details-popover-menu-track-actions-label';
  const descriptionId = 'details-popover-menu-track-actions-description';

  return (
    <>
      <div style={{display: 'block'}}>
        <PopoverMenu
          setOpen={setOpen}
          open={open}
          labelId={labelId}
          descriptionId={descriptionId}
          render={() => (
            <>
              <div style={{display: 'none'}} id={labelId}>
                track actions menu
              </div>
              <div style={{display: 'none'}} id={descriptionId}>
                the track actions menu allows you to delete, download, or share
                a link to this track
              </div>
              {showDelete && (
                <PopoverMenuItem onClick={handleClickDelete} divider>
                  <StyledPopoverMenuItemContainer>
                    <IconTrash size={15} />
                    <StyledTextContainer id={descriptionId}>
                      Delete
                    </StyledTextContainer>
                  </StyledPopoverMenuItemContainer>
                </PopoverMenuItem>
              )}
              {showDownload && (
                <PopoverMenuItem
                  onClick={handleClickDownload}
                  divider={showShareLink}>
                  <StyledPopoverMenuItemContainer>
                    <IconDownload size={15} />
                    <StyledTextContainer>Download</StyledTextContainer>
                  </StyledPopoverMenuItemContainer>
                </PopoverMenuItem>
              )}
              {showShareLink && (
                <PopoverMenuItem onClick={handleClickShareTrackLink}>
                  <StyledPopoverMenuItemContainer>
                    <IconLink size={15} />
                    <StyledTextContainer>Share Link</StyledTextContainer>
                  </StyledPopoverMenuItemContainer>
                </PopoverMenuItem>
              )}
            </>
          )}>
          <button type="button">
            <IconMoreHorizontal size={24} />
          </button>
        </PopoverMenu>
      </div>
    </>
  );
};
