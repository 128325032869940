import React, {useState} from 'react';
import {useDispatch, useStore} from 'react-redux';

import Input from '@untitled/app/core/components/atoms/input';
import {verifyMFACode} from '@untitled/app/core/redux/actions/common/async';
import {invokeCloudFunction} from '@untitled/app/core/helpers/helper-firebase';

type Props = {
  onBack: () => void;
};

const StepsMFA: React.FC<Props> = ({onBack}) => {
  const [mfaCode, setMfaCode] = useState<string>('');
  const [resending, setResending] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {getState} = useStore();
  const {user} = getState().common;

  const getMaskedNumber = React.useMemo(() => {
    if (!user) {
      return '';
    }
    const endDigits = user.phoneNumber.slice(-4) as string;
    return endDigits.padStart(user.phoneNumber.length, '*');
  }, [user]);

  const onChange = (e: any) => {
    setMfaCode(e.value);
  };

  const onSubmit = () => {
    if (mfaCode) {
      verifyMFACode(mfaCode)(dispatch, getState);
      setMfaCode('');
    }
  };

  const onResend = async () => {
    try {
      setResending(true);
      await invokeCloudFunction('generateMFACode')({uid: user.uid});
      setResending(false);
    } catch (error) {
      console.log(error);
      setResending(false);
    }
  };

  return (
    <div className="app__slide app__steps--card steps-center">
      <div className="mb-3">We sent a code to {getMaskedNumber}</div>
      <div className="app__steps--form">
        <Input
          className="app__input mb-3"
          name="mfa"
          placeholder="Verification code"
          value={mfaCode}
          onChange={onChange}
          onEnter={onSubmit}
        />
        <button
          type="button"
          className="app__btn"
          onClick={onSubmit}
          disabled={mfaCode.length === 0}>
          Verify
        </button>
        <div className="mt-2">
          <button
            type="button"
            onClick={onResend}
            className="app__link"
            disabled={resending}>
            Resend code
          </button>
        </div>
        <button type="button" className="app__link" onClick={onBack}>
          Back
        </button>
        <div className="mt-3">
          Can't enter code? Reach out to{' '}
          <a href="mailto:help@untitledinbrackets.com">
            help@untitledinbrackets.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default StepsMFA;
