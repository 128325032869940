import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import FileExplorerSlide from './slide';
import Button from '../../atoms/button';
import './style.scss';
import {generateId} from '../../../helpers/helper-util';
import {compareDates} from '../../../helpers/helper-untitled';

// TODO: make animation duration variable
// TODO: improve nestedSum

const nestedSum = items =>
  (items || []).reduce((acc, o) => {
    if (o.items.length > 0) {
      return acc + nestedSum(o.items);
    }
    return acc + (o.progress || 0);
  }, 0);

const FileExplorer = ({
  name,
  menu,
  actions,
  onFileClick,
  onAdd,
  search,
  onHeightChanged,
  setRef,
  changed,
}) => {
  const explorerId = useRef(name || generateId()).current;
  const routesRef = useRef({});
  const [menuHeight, setMenuHeight] = useState(null);
  const [selected, setSelected] = useState('<root>');
  const [levels, setLevels] = useState({});

  setRef({
    selected,
    setSelected,
  });

  const renderMenu = (paths, node = '<root>', parent = '', level = 0) => {
    const it = paths.map(
      ({name: n, url, items, key, type, uri, title: t, ...rest}) => {
        const itemc = items.length > 0 ? 1 : 0;
        const order = type === 'action' ? -1 : itemc;
        const hasItems = items.length > 0;
        const pushing = {
          name: n || t,
          url: url || uri,
          hasItems,
          order,
          itemsCount: items.length,
          key,
          type,
          ...rest,
        };
        if (hasItems) {
          pushing.total = nestedSum(items) / items.length;
          pushing.created = Math.max(
            ...items.map(item => new Date(item.created)),
          );
        }
        return pushing;
      },
    );
    routesRef.current[node] = {
      items: it,
      parent,
      level,
    };
    paths.forEach(path => {
      if (path.items.length > 0) {
        renderMenu(path.items, path.url, node, level + 1);
      }
    });
  };

  useEffect(() => {
    if (menu.length === 0) {
      setLevels({});
    } else {
      routesRef.current = {};
      renderMenu(menu);
      if (!routesRef.current[selected]) {
        setSelected('<root>');
      }
      setLevels({...routesRef.current});
    }
  }, [menu, selected, changed]);

  useEffect(() => {
    onHeightChanged(menuHeight);
  }, [menuHeight]);

  return (
    <div className="app__fileexplorer" name={explorerId}>
      <div
        className="app__fileexplorer--inner"
        style={{
          height: Math.max(menuHeight, 90),
          transition: '300ms height ease-in-out',
        }}>
        {Object.keys(levels).map(node => {
          const {items, parent, level} = levels[node];
          const key = `menu-${explorerId}-${node}`;

          // TODO: make sorting dynamic
          // const sorted = items.length
          //   ? items.sort((a, b) => (b.order - a.order || a.name.localeCompare(b.name)))
          //   : [];
          const sorted = items.length
            ? items
                .sort((a, b) => b.order - a.order || compareDates(a, b))
                .reverse()
            : [];
          return (
            <FileExplorerSlide
              key={key}
              items={sorted}
              node={node}
              search={search}
              parent={parent}
              level={level}
              setSelected={setSelected}
              selectedNode={selected}
              selectedLevel={levels[selected].level}
              onEnter={setMenuHeight}
              onFileClick={onFileClick}
              onAdd={onAdd}
              renderActions={
                actions.length === 0
                  ? undefined
                  : (item, onClose) =>
                      actions.map(action => (
                        <Button
                          className="app__fileexplorer--tooltip-action"
                          onTap={(_, e) => {
                            action.onClick(item, e);
                            onClose();
                          }}
                          key={action.key}>
                          {action.label}
                        </Button>
                      ))
              }
            />
          );
        })}
      </div>
    </div>
  );
};

FileExplorer.defaultProps = {
  name: undefined,
  menu: [],
  actions: [],
  onFileClick: () => {},
  onAdd: () => {},
  search: {
    query: '',
  },
  onHeightChanged: () => {},
  setRef: () => {},
  changed: -1,
};

FileExplorer.propTypes = {
  name: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.any),
  actions: PropTypes.arrayOf(PropTypes.any),
  onFileClick: PropTypes.func,
  onAdd: PropTypes.func,
  search: PropTypes.objectOf(PropTypes.any),
  onHeightChanged: PropTypes.func,
  setRef: PropTypes.func,
  changed: PropTypes.number,
};

export default FileExplorer;
