import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

import {isInStandaloneMode} from '../../../helpers/helper-useragent';

import './style.scss';

const Scroller = ({children, id, className, style}) => {
  const scrollerRef = useRef(null);
  useEffect(() => {
    const curRef = scrollerRef.current;
    if (isInStandaloneMode()) {
      disableBodyScroll(curRef);
    }
    return () => {
      if (isInStandaloneMode()) {
        enableBodyScroll(curRef);
      }
    };
  }, []);
  return (
    <div
      id={id}
      ref={scrollerRef}
      className={`app__scroller ${className}`}
      style={style}>
      {children}
    </div>
  );
};

Scroller.defaultProps = {
  id: '',
  className: '',
  style: {},
};

Scroller.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};

export default Scroller;
