import React, {useState} from 'react';
import Reveal from '@untitled/app/core/components/atoms/reveal';
import styled from 'styled-components';
import type {PublicTrack} from '@untitled/app/core/components/templates/share-track';

const StyledRoot = styled.div`
  position: relative;
  overflow: hidden;
  animation-name: fadeIn;
  animation-duration: 2s;
`;

const StyledBlur = styled.div`
  position: absolute;
  filter: blur(15px);
  height: 110%;
  width: 110%;
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  z-index: 0;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 80px 96px;
  position: relative;
  border-bottom: 1px solid var(--border);
  @media screen and (max-width: 667px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 64px;
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

const StyledContainerCover = styled.div`
  flex: 0 0 140px;
  width: 140px;
  height: 140px;
  position: relative;
  border: 1px solid #000000;
  .block-reveal {
    width: 100%;
    height: 100%;
  }
  img {
    width: 138px;
    height: 138px;
    object-fit: cover;
  }
  @media screen and (max-width: 667px) {
    margin: 0 auto;
  }
`;

const StyledContainerTitle = styled.div`
  flex: 1;
  width: calc(100% - 140px);
  padding-left: 60px;
  @media screen and (max-width: 667px) {
    width: 100%;
    padding: 8px 0;
    text-align: center;
  }
`;

const StyledContainerAuthor = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  @media screen and (max-width: 667px) {
    justify-content: center;
  }
`;
type IShareTrackInfoHeaderProps = {
  track: PublicTrack;
};

export const ShareTrackInfoHeader: React.FC<IShareTrackInfoHeaderProps> = ({
  track,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleLoadCover = () => {
    setLoaded(true);
  };

  return (
    <StyledRoot>
      <StyledBlur style={{backgroundImage: `url(${track.wipCover})`}} />
      <StyledContainer>
        <StyledContainerCover>
          <Reveal
            className={loaded ? 'animated' : ''}
            color="#DBDBDB"
            delay={0}
            duration={0.9}>
            <img
              alt={track.title}
              onLoad={handleLoadCover}
              src={track.wipCover}
              width="100%"
            />
          </Reveal>
        </StyledContainerCover>
        <StyledContainerTitle>
          <h1 className="truncate">{track.title || 'No title'}</h1>
          <StyledContainerAuthor>
            <div style={{marginBottom: 8}}>{track.authorName}</div>
          </StyledContainerAuthor>
        </StyledContainerTitle>
      </StyledContainer>
    </StyledRoot>
  );
};
