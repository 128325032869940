import {
  AUTHOR_CHANGED,
  AUTHORS_CHANGED,
  AUTHOR_PRESENCE,
  AUTHOR_SELECT,
  AUTHOR_RESET,
} from '../actions/authors/constants';

const defaultState = {
  users: {},
  presence: {},
  selected: {},
  usersUpdate: Date.now(),
  presenceUpdate: Date.now(),
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHOR_CHANGED: {
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload,
        },
        usersUpdate: Date.now(),
      };
    }

    case AUTHORS_CHANGED: {
      const users = action.payload || {};
      return {
        ...state,
        users,
        usersUpdate: Date.now(),
      };
    }

    case AUTHOR_PRESENCE: {
      const presence = action.payload || {};
      return {
        ...state,
        presence,
        presenceUpdate: Date.now(),
      };
    }

    case AUTHOR_SELECT: {
      const selected = action.payload || {};
      return {
        ...state,
        selected,
      };
    }

    case AUTHOR_RESET: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
