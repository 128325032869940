/**
 * Constants authors
 */

/** @constant {string} AUTHOR_CHANGED */
export const AUTHOR_CHANGED = 'core/redux/authors/AUTHOR_CHANGED';

/** @constant {string} AUTHORS_CHANGED */
export const AUTHORS_CHANGED = 'core/redux/authors/AUTHORS_CHANGED';

/** @constant {string} AUTHOR_PRESENCE */
export const AUTHOR_PRESENCE = 'core/redux/authors/AUTHOR_PRESENCE';

/** @constant {string} AUTHOR_SELECT */
export const AUTHOR_SELECT = 'core/redux/authors/AUTHOR_SELECT';

/** @constant {string} AUTHOR_RESET */
export const AUTHOR_RESET = 'core/redux/authors/AUTHOR_RESET';
