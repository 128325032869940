import {DEFAULT_ARTWORKS} from '../../constants';
import {
  ARTWORK_CHANGED,
  ARTWORKS_CHANGED,
  ARTWORK_RESET,
} from '../actions/artworks/constants';

const defaultState = {
  all: DEFAULT_ARTWORKS,
  artworksUpdate: Date.now(),
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ARTWORK_CHANGED: {
      return {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
        },
        artworksUpdate: Date.now(),
      };
    }

    case ARTWORKS_CHANGED: {
      const all = action.payload || {};
      return {
        ...state,
        // all,
        all: {...DEFAULT_ARTWORKS, ...all},
        artworksUpdate: Date.now(),
      };
    }

    case ARTWORK_RESET: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
