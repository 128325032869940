import {generateId} from '../helpers/helper-util';

class EventEmmiter {
  constructor() {
    this.listeners = {
      status: {},
      load: {},
      progress: {},
      state_changed: {},
    };
    this.addListener = this.addListener.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.emitListeners = this.emitListeners.bind(this);
  }

  addListener(key, fn) {
    if (!this.listeners[key]) return null;
    const id = generateId();
    this.listeners[key][id] = fn;
    return () => {
      this.removeListener(key, id);
    };
  }

  removeListener(key, id) {
    if (!this.listeners[key]) return;
    delete this.listeners[key][id];
  }

  emitListeners(key, value) {
    Object.keys(this.listeners[key]).forEach(k => {
      this.listeners[key][k](value);
    });
  }
}

export default EventEmmiter;
