/**
 * Constants artwork
 */

/** @constant {string} ARTWORK_CHANGED */
export const ARTWORK_CHANGED = 'core/redux/artwork/ARTWORK_CHANGED';

/** @constant {string} ARTWORKS_CHANGED */
export const ARTWORKS_CHANGED = 'core/redux/artwork/ARTWORKS_CHANGED';

/** @constant {string} ARTWORK_PRESENCE */
export const ARTWORK_PRESENCE = 'core/redux/artwork/ARTWORK_PRESENCE';

/** @constant {string} ARTWORK_RESET */
export const ARTWORK_RESET = 'core/redux/artwork/ARTWORK_RESET';
