import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import IconDelete from 'react-feather/dist/icons/x';
import {CopyWipLinkButton} from '@untitled/app/core/components/organisms/invite/copy-wip-link-button';
import '@untitled/app/core/components/organisms/invite/style.scss';
import Tabs from '@untitled/app/core/components/molecules/tabs';
import useThrottledSearch from '@untitled/app/core/components/hooks/useSearch';
import {MakeLinkPublic} from '@untitled/app/core/components/organisms/invite/make-link-public';
import {CopyPublicLink} from '@untitled/app/core/components/organisms/invite/copy-public-link';

const QUERY_LENGTH = 2;

const Invite = ({
  selectedWip,
  permissions,
  onProducer,
  onConsumer,
  onRemove,
}) => {
  const {searchQuery, setSearchQuery} = useThrottledSearch();
  const [search, setSearch] = useState('');

  const user = useSelector(state => state.common.user);
  const audios = useSelector(state => state.audios.all);
  const authors = useSelector(state => state.authors.users);

  const {
    confirmed = [],
    readers = [],
    writers = [],
    author: wipAuthor,
    denied = [],
  } = selectedWip;

  const nonHiddenAuthors = Object.fromEntries(
    Object.entries(authors).filter(
      ([, v]) =>
        (v.hidden === undefined || v.hidden === false) && v.internal === false,
    ),
  );
  const authorsKeys = Object.keys(nonHiddenAuthors).filter(
    k => k !== selectedWip.author,
  );
  const keysFiltered = authorsKeys;

  const invitedUsers = [...writers, ...readers];
  const consfirmedUsers = invitedUsers.filter(k => confirmed.indexOf(k) !== -1);

  const invitedAuthors = invitedUsers
    .sort()
    .sort(
      (a, b) =>
        (consfirmedUsers.indexOf(b) !== -1) -
        (consfirmedUsers.indexOf(a) !== -1),
    )
    .filter(k => authors[k])
    .map(k => ({...authors[k], id: k}));

  const allAuthors = keysFiltered
    .sort()
    .filter(k => authors[k])
    .map(k => ({...authors[k], id: k}));

  const author = authors[wipAuthor || user.uid];

  useEffect(() => {
    if (search.length > QUERY_LENGTH) {
      setSearchQuery(search);
    } else {
      setSearchQuery('');
    }
  }, [search]);

  const isOwner = wipAuthor === user.uid;
  const canEdit =
    isOwner ||
    (confirmed.indexOf(user.uid) !== -1 && writers.indexOf(user.uid) !== -1);

  const visibleUsers =
    searchQuery.length > QUERY_LENGTH ? allAuthors : invitedAuthors;

  const mainAudio = selectedWip.audio ? [selectedWip.audio] : [];

  const wipAudios = selectedWip.audios
    ? [...selectedWip.audios, ...mainAudio]
    : mainAudio;

  const audioAuthors = wipAudios
    .map(audioId => audios[audioId] && audios[audioId].author)
    .filter(Boolean);

  return (
    <div className="app__invite">
      {selectedWip?.id !== undefined && (
        <CopyWipLinkButton
          wipId={selectedWip.id}
          disabled={!selectedWip.isPublic}
        />
      )}
      {canEdit && (
        <div className="app__invite--form">
          <div className="app__invite--inputs">
            {selectedWip !== undefined && selectedWip.id !== undefined && (
              <>
                <MakeLinkPublic
                  wipId={selectedWip.id}
                  isPublic={selectedWip.isPublic}
                  isOwner={isOwner}
                />
                {selectedWip.isPublic && canEdit && (
                  <CopyPublicLink wipId={selectedWip.id} />
                )}
              </>
            )}
            <input
              name="search"
              className="app__input"
              type="text"
              placeholder="Search by Name, Number, Email"
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      )}
      <div className="app__invite--authors">
        {author && (
          <div className="app__invite--authors-row author-owner">
            <div className="app__miniauthor">
              <div className="app__miniauthor--initials">{author.initials}</div>
              <div className="app__miniauthor--name">{`${author.name} ${author.lastName}`}</div>
            </div>
          </div>
        )}
        {visibleUsers.map(({id, initials, name, lastName}) => {
          const showQuery =
            searchQuery.length <= QUERY_LENGTH ||
            new RegExp(searchQuery, 'gi').test(name) ||
            new RegExp(searchQuery, 'gi').test(lastName);
          const isInvited = permissions[id] ? 1 : -1;
          const isActive = permissions[id] === 'writer' ? 0 : isInvited;
          const hasAddedAudio = audioAuthors.indexOf(id) !== -1;
          const isConfirmed = confirmed.indexOf(id) !== -1;
          //#region Invited/Confirmed logic
          // In this region we can have two sets of buttons to
          // display, one with the producer or consumer buttons
          // (if invited user has confirmed), and they should have
          // and event which can toggle them; other with just
          // the invited button and this button does nothing just
          // as in the mobile app.
          const isDenied = denied.indexOf(id) !== -1;
          const prodConsTabs = [
            {label: 'Producer', event: onProducer},
            {label: 'Listener', event: onConsumer},
          ];
          const invitedTab = [{label: 'Invited', event: () => {}}];

          // Below is the most complex part of the code
          // if it's invited BUT the user hasn't confirmed
          // or has denied, then we should show the invited
          // button. Otherwise, we display the Listener/Producer
          // buttons.
          const tabs =
            isInvited !== -1 && (!isConfirmed || isDenied)
              ? invitedTab
              : prodConsTabs;
          const activeTab = isConfirmed ? isActive : -1;
          //#endregion
          return (
            showQuery && (
              <div key={id} className="app__invite--authors-row">
                <div className="app__miniauthor truncate">
                  <div className="app__miniauthor--initials">{initials}</div>
                  <div className="app__miniauthor--name truncate">{`${name} ${lastName}`}</div>
                </div>
                <div className="app__invite--authors-actions">
                  {canEdit && (
                    <Tabs active={activeTab}>
                      {tabs.map(({label, event}) => (
                        <button
                          key={label}
                          type="button"
                          className="app__tabs--text"
                          onClick={() => {
                            event(id);
                          }}>
                          {label}
                        </button>
                      ))}
                    </Tabs>
                  )}
                  {!hasAddedAudio &&
                  invitedUsers.indexOf(id) !== -1 &&
                  (canEdit || id === user.uid) ? (
                    <button
                      type="button"
                      className="app__tabs--button"
                      onClick={() => {
                        onRemove(id);
                      }}>
                      <IconDelete size={14} strokeWidth="2" />
                    </button>
                  ) : (
                    <div className="app__tabs--button invisible">
                      <IconDelete size={14} stroke="transparent" />
                    </div>
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

Invite.defaultProps = {
  selectedWip: {},
  permissions: {},
  onProducer: () => {},
  onConsumer: () => {},
  onRemove: () => {},
};

Invite.propTypes = {
  selectedWip: PropTypes.objectOf(PropTypes.any),
  permissions: PropTypes.objectOf(PropTypes.any),
  onProducer: PropTypes.func,
  onConsumer: PropTypes.func,
  onRemove: PropTypes.func,
};

export default Invite;
