import React, {
  useCallback,
  useContext,
  // useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import UploadIcon from 'react-feather/dist/icons/upload';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import qs from 'query-string';

import Dropzone from '../../atoms/dropzone';
import {setLoader} from '../../../redux/actions/common';
import {deleteWipAudio} from '../../../redux/actions/wips/async';
import {postCommentOnAudio} from '../../../redux/actions/audios/async';
// import UploadWip from '../../organisms/upload/upload-wip';
// import { UploadContext } from '../../context/upload-context';

import './style.scss';
import Scroller from '../../atoms/scroller';
import Slides from '../../molecules/slides';
import {bulkUploadAudios} from '../../../redux/actions/wips/async-bulk';
import InfoHeader from '../../organisms/info/header';
import InfoBody from '../../organisms/info/body';
import {UPlayerContext} from '../../context/player-context';

// TODO: add window on close alert when uploading
// TODO: check why is necesary slides for playbar to show properly

const Preview = ({user, featured, setMainLoader, authors}) => {
  const history = useHistory();
  // const { uploads, audiosProgress, audiosNames } = useContext(UploadContext);
  const {search} = history.location;
  const query = qs.parse(search, {ignoreQueryPrefix: true});
  const queryWip = query.wip || '';
  const selectedWip = useMemo(() => featured[queryWip] || {}, [queryWip]);

  const {player, playerState, playSong} = useContext(UPlayerContext);

  const {id: playingId} = playerState;

  const {id, songs} = selectedWip;

  const isPlayingFeat = playingId === id;

  const onPlay = useCallback(
    ({track}) => {
      const song = songs?.[track];
      playSong(id, songs, track, song?.coverURL);
    },
    [id, songs, playSong],
  );

  const onBack = () => {
    const {state} = history.location;
    if (state && state.prevPath) {
      history.push(state.prevPath);
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    if (isPlayingFeat) {
      player.update(songs);
    }
  }, [songs, isPlayingFeat]);

  // const uploadsProgress = {};
  // if (selectedWip.id && audiosProgress[selectedWip.id]) {
  //   Object.keys(audiosProgress[selectedWip.id]).forEach((k) => {
  //     uploadsProgress[k] = {
  //       progress: audiosProgress[selectedWip.id][k],
  //       name: audiosNames[selectedWip.id][k],
  //     };
  //   });
  // }

  const [soundFiles, setSoundFiles] = useState();

  if (soundFiles) {
    console.log(soundFiles);
  }

  const {confirmed = [], writers = [], author: wipAuthor} = selectedWip || {};

  const userUid = user ? user.uid : '';

  const isOwner = wipAuthor === userUid;
  const canEdit =
    isOwner ||
    (confirmed.indexOf(userUid) !== -1 && writers.indexOf(userUid) !== -1);

  useEffect(() => {
    setMainLoader(false);
  }, []);
  return (
    <div id="PreviewPage" className="app__page app__content">
      <div className="app__home">
        {/* <Header
          slide={2}
          onTab={(t) => {
            history.push({
              pathname: '/home',
              state: { tab: t },
            });
          }}
          search={audioSearch}
          onSearch={onSearch}
        /> */}

        <Slides className="app__homebody" autoHeight={false}>
          <Scroller className="app__homebody--tab scrollable">
            <InfoHeader
              coverURL={selectedWip.coverURL}
              id={selectedWip.id}
              title={selectedWip.albumName}
              authorName={selectedWip.authorName}
              authorInitials={
                selectedWip.authorName && selectedWip.authorName[0]
              }
              onBack={onBack}
              disabled
            />
            <InfoBody
              userId={userUid}
              selectedWip={selectedWip}
              authors={authors}
              wipAudios={selectedWip.songs}
              canComment={false}
              onPlay={onPlay}
              // onDownload={onDownload}
              // onDelete={onDeleteMessage}
              playerState={playerState}
              // currentUploads={uploadsProgress}
            />
          </Scroller>
        </Slides>

        {canEdit && (
          <Dropzone onFiles={setSoundFiles} multiple>
            <div className="app__dropzone--container">
              <div className="app__dropzone--row m-1">
                <UploadIcon />
              </div>
              <div className="app__dropzone--row">
                <h3>Drop audio here</h3>
              </div>
            </div>
          </Dropzone>
        )}
      </div>
    </div>
  );
};

Preview.defaultProps = {
  user: {},
  featured: {},
  setMainLoader: () => {},
  authors: {},
};

Preview.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  featured: PropTypes.objectOf(PropTypes.any),
  setMainLoader: PropTypes.func,
  authors: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = ({common, authors, queue, landing}) => ({
  user: common.user, // Needed for hocQueue
  userData: queue.userData, // Needed for hocQueue
  authors: authors.users,
  featured: landing.featured,
});

const mapDispatchToProps = {
  setMainLoader: setLoader,
  onDeleteAudio: deleteWipAudio,
  commentOnAudio: postCommentOnAudio,
  onBulkAudios: bulkUploadAudios,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
