import {parseFirebaseFields} from '../helpers/helper-untitled';
import EventEmmiter from './events';

class UploadsManager extends EventEmmiter {
  static getInstance() {
    if (!UploadsManager.instance) {
      UploadsManager.instance = new UploadsManager();
    }
    return UploadsManager.instance;
  }

  constructor() {
    super();
    this.uploads = {};
    this.audiosProgress = {};
    this.imagesProgress = {};
    this.audiosNames = {};

    this.emitState = this.emitState.bind(this);

    this.addUpload = this.addUpload.bind(this);
    this.updateUpload = this.updateUpload.bind(this);
    this.deleteUpload = this.deleteUpload.bind(this);
    this.removeUpload = this.removeUpload.bind(this);
    this.removeUploadedAudio = this.removeUploadedAudio.bind(this);
  }

  addUpload(author = {}, wipData, uploadData, isNew = false) {
    const {
      wipRef,
      audio: audioRef,
      coverArt: coverArtRef,
      // author: authorRef,
      created,
      title,
    } = wipData;
    const {
      // soundFile,
      // audioBlob,
      audioTitle = '',
      coverArtBlob,
    } = uploadData;
    const parsedData = parseFirebaseFields({
      ...wipData,
      id: wipRef,
      tracks: [audioRef],
    });
    delete parsedData.wipRef;
    if (isNew) {
      // const audioURL = URL.createObjectURL(soundFile || audioBlob);
      const coverURL = URL.createObjectURL(coverArtBlob);
      const authorName = author.fullName || 'Loading...';
      this.uploads = {
        ...this.uploads,
        [parsedData.id]: {
          ...parsedData,
          // audioURL,
          album: {
            cover: coverURL,
          },
          authorName,
          confirmed: [],
          coverURL,
          denied: [],
          title: {created: created.toDate().toISOString(), text: title.text},
          uploading: true,
          uploadingProgress: 0,
          ready: false,
        },
      };
      this.audiosProgress[parsedData.id] =
        this.audiosProgress[parsedData.id] || {};
      this.audiosProgress[parsedData.id][audioRef.id] = 0;
      this.imagesProgress[parsedData.id] =
        this.imagesProgress[parsedData.id] || {};
      this.imagesProgress[parsedData.id][coverArtRef.id] = 0;
      this.audiosNames[parsedData.id] = this.audiosNames[parsedData.id] || {};
      this.audiosNames[parsedData.id][audioRef.id] = audioTitle;
    } else {
      this.audiosProgress[parsedData.id] =
        this.audiosProgress[parsedData.id] || {};
      this.audiosProgress[parsedData.id][audioRef.id] = 0;
      this.audiosNames[parsedData.id] = this.audiosNames[parsedData.id] || {};
      this.audiosNames[parsedData.id][audioRef.id] = audioTitle;
    }
    this.emitState();
  }

  updateUpload({wipId, fileId, uploadProgress, type}) {
    if (type === 'image') {
      this.imagesProgress[wipId] = this.imagesProgress[wipId] || {};
      this.imagesProgress[wipId][fileId] = uploadProgress * 100;
    }
    if (type === 'audio') {
      this.audiosProgress[wipId] = this.audiosProgress[wipId] || {};
      this.audiosProgress[wipId][fileId] = uploadProgress * 100;
    }
    this.emitState();
  }

  deleteUpload({wipId, fileId}) {
    this.audiosProgress[wipId] = this.audiosProgress[wipId] || {};
    this.imagesProgress[wipId] = this.imagesProgress[wipId] || {};
    delete this.imagesProgress[wipId][fileId];
    delete this.audiosProgress[wipId][fileId];
  }

  removeUpload(wipId) {
    delete this.uploads[wipId];
    delete this.imagesProgress[wipId];
    delete this.audiosProgress[wipId];
    this.emitState();
  }

  emitState() {
    this.emitListeners('state_changed', this.status);
  }

  /**
   * @typedef RemoveAudioParam
   * @property {string} audioId
   * @property {string} wipId
   *
   * @param {RemoveAudioParam}
   */
  removeUploadedAudio({audioId, wipId}) {
    let calledOnAudio = false;

    if (this.audiosNames?.[wipId]?.[audioId]) {
      delete this.audiosNames?.[wipId]?.[audioId];
      calledOnAudio = true;
    }

    if (this.audiosProgress?.[wipId]?.[audioId]) {
      delete this.audiosProgress?.[wipId]?.[audioId];
      calledOnAudio = true;
    }

    if (calledOnAudio) {
      this.emitState();
    }
  }

  get status() {
    return {
      uploads: {...this.uploads},
      audiosProgress: {...this.audiosProgress},
      imagesProgress: {...this.imagesProgress},
      audiosNames: {...this.audiosNames},
      removeUploadedAudio: this.removeUploadedAudio,
    };
  }
}

export default UploadsManager;
