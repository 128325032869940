import React, {useCallback, useRef} from 'react';
import SendIcon from 'react-feather/dist/icons/send';
import {MentionsInput, Mention, SuggestionDataItem} from 'react-mentions';

import './mention-style.scss';
import './style.scss';
import Button from '../button';

interface Props {
  name: string;
  thoughts: string;
  onChange: (chng: {name: string; value: string}) => void;
  onSubmit: () => void;
  suggestions: any[];
  renderUserSuggestion?: (
    entry: SuggestionDataItem,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: Boolean,
  ) => JSX.Element;
}

const CommentInput = ({
  name,
  thoughts,
  onChange,
  onSubmit,
  suggestions,
  renderUserSuggestion,
}: Props) => {
  const containerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const handleSubmit = useCallback(() => {
    onSubmit();
    onChange({
      name,
      value: '',
    });
  }, [onSubmit, onChange, name]);
  const isThoughtsValid = useCallback(() => {
    return thoughts.replace(/\s+/gi, '').length > 0;
  }, [thoughts]);

  return (
    <div className="app__commentinput">
      <div className="left" ref={containerRef}>
        <MentionsInput
          value={thoughts}
          className="mentions"
          placeholder="Add comments..."
          onKeyDown={evt => {
            if (evt.key === 'Enter' && !evt.shiftKey) {
              evt.preventDefault();
              if (isThoughtsValid()) {
                handleSubmit();
              }
            }
            return true;
          }}
          onChange={e => {
            onChange({
              name,
              value: e.target.value,
            });
          }}
          allowSuggestionsAboveCursor
          forceSuggestionsAboveCursor>
          <Mention
            trigger="@"
            data={suggestions}
            renderSuggestion={renderUserSuggestion}
            appendSpaceOnAdd
          />
        </MentionsInput>
      </div>
      <div className="right">
        <Button
          className="app__commentinput--send"
          onTap={handleSubmit}
          tabIndex="0"
          value=""
          name="comment-submit"
          ripple
          disabled={!isThoughtsValid()}>
          <SendIcon strokeWidth={1.5} />
        </Button>
      </div>
    </div>
  );
};

CommentInput.defaultProps = {
  name: '',
  thoughts: '',
  onChange: () => {},
  onSubmit: () => {},
  suggestions: [],
  renderUserSuggestion: () => {},
};

export default CommentInput;
