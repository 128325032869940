import {
  ACTIVITYS_CHANGED,
  ACTIVITY_OPEN,
  ACTIVITY_RESET,
  READS_CHANGED,
} from '../actions/activity/constants';

const defaultState = {
  all: [],
  reads: {},
  activityUpdate: Date.now(),
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIVITYS_CHANGED: {
      // const all = action.payload || [];
      return {
        ...state,
        // all,
        activityUpdate: Date.now(),
        ...action.payload,
      };
    }

    case READS_CHANGED: {
      const reads = action.payload || {};
      return {
        ...state,
        reads,
        activityUpdate: Date.now(),
      };
    }

    case ACTIVITY_RESET: {
      return defaultState;
    }

    case ACTIVITY_OPEN: {
      return {
        ...defaultState,
        openActivitiesUI: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
