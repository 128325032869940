/* eslint-disable no-underscore-dangle */

import {activitysChanged, readsChanged} from '.';
import {db} from '../../../helpers/helper-firebase';
import {
  ActivityType,
  stageString,
  UntitledInstanceObjects,
} from '../../../helpers/helper-types';
import {
  mergeWIPData,
  parseFirebaseFields,
} from '../../../helpers/helper-untitled';

const parseActivity = (activities, wipsList, lastRead, ignoredAuthorIds) => {
  const newActivity = [];
  const oldActivity = [];

  let lastFeed = new Date(0);
  activities.forEach(e => {
    const wip = wipsList[e.wipId];
    if (!wip) {
      return;
    }

    if (
      e.kind === ActivityType.newInvite &&
      ignoredAuthorIds.includes(e.author)
    ) {
      console.debug(`Ignoring invite id=${e.id} for wipId=${e.wipId}`);
      return;
    }

    const created = new Date(e.created);
    if (created > lastRead) {
      if (e.kind !== ActivityType.newInvite) {
        newActivity.push({
          ...e,
          coverUrl: wip.coverUrl,
          authorName: wip.authorName,
          title: wip.title,
        });
      }
    } else if (e.kind !== ActivityType.newInvite) {
      oldActivity.push({
        ...e,
        coverUrl: wip.coverUrl,
        authorName: wip.authorName,
        title: wip.title,
      });
    }
    if (lastFeed < created) {
      lastFeed = created;
    }
  });
  return {
    all: activities,
    oldActivity,
    newActivity,
    lastActivity: lastFeed,
  };
};

const compareDates = (a, b) => {
  const ad = new Date(a.created);
  const bd = new Date(b.created);
  if (ad < bd) {
    return -1;
  }
  if (ad > bd) {
    return 1;
  }
  return 0;
};

export const listenActivitys = authorId => (dispatch, getState) =>
  db
    .collectionGroup(stageString(UntitledInstanceObjects.Activity))
    .where('subscribers', 'array-contains', authorId)
    .orderBy('created', 'desc')
    .limit(200)
    .onSnapshot(querySnapshot => {
      const state = getState();
      const {wips, audios, authors, activity} = state;
      const wipsList = mergeWIPData(wips.all, audios.all, authors.users);
      const lastRead = new Date(activity.reads._last || 0);
      const activitys = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();

        if (data.type === 'deniedWip') {
          return;
        }

        const created = new Date(
          data.created.seconds * 1000 + data.created.nanoseconds / 1000000,
        );
        activitys.push({
          ...parseFirebaseFields(data),
          id: doc.id,
          created,
        });
      });
      const sortedActivities = activitys.sort(compareDates).reverse();
      const {ignoredAuthorIds = []} =
        authors.users?.[state.common.user.uid] ?? {};

      const parsedActivity = parseActivity(
        sortedActivities,
        wipsList,
        lastRead,
        ignoredAuthorIds,
      );
      dispatch(activitysChanged(parsedActivity));
    });

export const listenReads = authorId => dispatch =>
  db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(authorId)
    .collection(stageString(UntitledInstanceObjects.AuthorMeta))
    .doc('reads')
    .onSnapshot(doc => {
      const data = doc.data() || {};
      const reads = Object.keys(data).reduce((acc, key) => {
        acc[key] = data[key].toDate().toISOString();
        return acc;
      }, {});
      dispatch(readsChanged(reads));
    });
