import React from 'react';
import PropTypes from 'prop-types';
import {isElectron} from '../../../helpers/helper-useragent';
import PlayPause from '../../atoms/playpause';

const toHHMMSS = secs => {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map(v => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

const ExplorerFile = ({onAdd, onClick, item, playerState}) => {
  const {queueState = 'waiting', progress = 0, name, fileSize, duration} = item;
  const isFinished = queueState === 'uploaded';
  const labelA = isFinished ? fileSize : queueState;
  const labelB = isFinished ? toHHMMSS(duration) : `${progress.toFixed(2)}%`;
  const canPlay = isFinished || isElectron;
  const id = item.url || item.uri;
  return (
    <div className="app__fileexplorer--file">
      <PlayPause
        onClick={() => {
          onClick(item);
        }}
        className="app__fileexplorer--file-icon"
        size={32}
        playing={playerState.currentId === id && playerState.playing}
        disabled={!canPlay}
      />
      {/* <button
        className="app__fileexplorer--file-icon"
        type="button"
        onClick={() => { onClick(item); }}
        disabled={!canPlay}
      >
        <Music size={18} />
      </button> */}
      <button
        className="app__fileexplorer--file-info"
        type="button"
        onClick={() => {
          onClick(item);
        }}
        disabled={!canPlay}>
        <div className="app__fileexplorer--file-name">{name}</div>
        <div className={`app__fileexplorer--file-stats flex ${queueState}`}>
          <small className="app__fileexplorer--file-count capitalize">
            {labelA}
          </small>
          <small className="app__fileexplorer--file-url truncate">
            {labelB}
          </small>
        </div>
      </button>
      <div className="app__fileexplorer--file-actions">
        <button
          className="app__fileexplorer--file-action"
          type="button"
          onClick={() => {
            onAdd(item);
          }}
          disabled={!isFinished}>
          ADD
        </button>
      </div>
    </div>
  );
};

ExplorerFile.defaultProps = {
  onAdd: () => {},
  onClick: () => {},
  item: {},
  playerState: {},
};

ExplorerFile.propTypes = {
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  playerState: PropTypes.objectOf(PropTypes.any),
};

export default ExplorerFile;
