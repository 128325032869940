/* eslint-disable no-param-reassign */
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {getSortedTracks} from '../../helpers/helper-untitled';
import {UPlayerContext} from '../context/player-context';

const usePlayer = () => {
  const {playerState, playSong} = useContext(UPlayerContext);
  const audios = useSelector(state => state.audios.all);
  const authors = useSelector(state => state.authors.users);

  const onPlay = wip => {
    if (wip.uploading) {
      console.log('Wip is in upload state');
      return;
    }
    const audiosSorted = getSortedTracks(wip, authors, audios);
    const index = playerState.id === wip.id ? playerState.index : 0;
    playSong(wip.id, audiosSorted, index, wip.album.cover);
  };

  const onPlayTrack = ({wipUid, wipAudios, track, audioUid, coverUrl}) => {
    if (wipAudios && wipAudios.length > 0) {
      const cover = coverUrl || wipAudios[track].coverURL;
      playSong(wipUid, wipAudios, track, cover, audioUid);
    }
  };

  return {
    onPlay,
    onPlayTrack,
  };
};

export default usePlayer;
