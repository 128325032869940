import React from 'react';
import PropTypes from 'prop-types';

import ControlsMain from './controls-main';
import ControlsSeek from './controls-seek';

import './style.scss';

const Controls = ({
  player,
  playing,
  looped,
  loading,
  onPlay,
  onPrev,
  onNext,
  onLoop,
}) => (
  <div className="app__controls">
    <ControlsMain
      playing={playing}
      looped={looped}
      onPlay={onPlay}
      onPrev={onPrev}
      onNext={onNext}
      onLoop={onLoop}
      disabled={loading}
    />
    <ControlsSeek player={player} playing={playing} disabled={loading} />
  </div>
);

Controls.defaultProps = {
  player: {},
  playing: false,
  looped: false,
  loading: false,
  onPlay: () => {},
  onPrev: () => {},
  onNext: () => {},
  onLoop: () => {},
};

Controls.propTypes = {
  player: PropTypes.objectOf(PropTypes.any),
  playing: PropTypes.bool,
  looped: PropTypes.bool,
  loading: PropTypes.bool,
  onPlay: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onLoop: PropTypes.func,
};

export default Controls;
