import React from 'react';
import PropTypes from 'prop-types';

import {
  IconNext,
  IconPause,
  IconPlay,
  IconPrev,
  IconRepeat,
} from '../../atoms/icons';

const ControlsMain = ({
  playing,
  looped,
  onPlay,
  onPrev,
  onNext,
  onLoop,
  disabled,
}) => (
  <div className="app__controls--main">
    <button
      type="button"
      className="app__controls--main-btn"
      onClick={onPrev}
      disabled={disabled}>
      <IconPrev />
    </button>
    <button
      type="button"
      className={`app__controls--main-btn control-outline ${
        looped ? 'selected' : ''
      }`}
      onClick={onLoop}
      disabled={disabled}>
      <IconRepeat />
    </button>
    <button
      type="button"
      className="app__controls--main-btn control-main"
      onClick={onPlay}
      disabled={disabled}>
      {playing ? <IconPause size={36} /> : <IconPlay size={36} />}
    </button>
    <button
      type="button"
      className="app__controls--main-btn"
      onClick={onNext}
      disabled={disabled}>
      <IconNext />
    </button>
  </div>
);

ControlsMain.defaultProps = {
  playing: false,
  looped: false,
  disabled: false,
  onPlay: () => {},
  onPrev: () => {},
  onNext: () => {},
  onLoop: () => {},
};

ControlsMain.propTypes = {
  playing: PropTypes.bool,
  looped: PropTypes.bool,
  disabled: PropTypes.bool,
  onPlay: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onLoop: PropTypes.func,
};

export default ControlsMain;
