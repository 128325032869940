import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reducers from '@untitled/app/core/redux/reducers';
import {persistStore, persistReducer} from 'redux-persist';
import createCompressor from 'redux-persist-transform-compress';
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage';
import {ENABLE_REDUX_TOOLS} from '@untitled/config';

type ComposerFunctionType = <R>(a: R) => R;
interface WindowWithReduxDevTools extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: ComposerFunctionType;
}

const win = window as WindowWithReduxDevTools;
let composeBuilder: ComposerFunctionType = compose;

if (
  ENABLE_REDUX_TOOLS &&
  typeof win === 'object' &&
  win.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  composeBuilder = win.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const enhancer = composeBuilder(applyMiddleware(thunk));
const compressor = createCompressor();
const persistConfig = {
  key: 'root',
  storage: createIdbStorage({name: '[untitled]', storeName: 'root'}),
  transforms: [compressor],
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
