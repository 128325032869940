import * as Sentry from '@sentry/react';
import {SENTRY_ENABLED} from '../../../config';
import {isElectron} from './helper-useragent';

export const logErrorMessage = message => {
  console.error(message);
  if (SENTRY_ENABLED) {
    if (isElectron) {
      window.electronApi.logWorkerMessage(message);
    } else {
      Sentry.captureMessage(message);
    }
  }
};

export const logErrorException = error => {
  console.error(error);
  if (SENTRY_ENABLED) {
    if (isElectron) {
      window.electronApi.captureException(error);
    } else {
      Sentry.captureException(error);
    }
  }
};

export const logEvent = (message, extra) => {
  console.log({message, extra});
  if (SENTRY_ENABLED) {
    if (isElectron) {
      window.electronApi.captureEvent({message, extra});
    } else {
      Sentry.captureEvent({message, extra});
    }
  }
};
