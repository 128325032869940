import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from '../../atoms/timeago';
import {onWipJoin} from '@untitled/app/core/redux/actions/wips/async';

const ActivityInvites = ({news, onAccept, onDeny}) => {
  const keys = Object.keys(news).reverse();
  return (
    <div className="app__activity--invites">
      {keys.map(k => {
        const {title, coverURL, authorName, lastEdited} = news[k];
        return (
          <div
            className="app__activity--invites-item"
            key={`notification-${k}`}
            data-key={k}>
            <div className="app__invites">
              <div
                className="app__invites--thumb"
                style={{backgroundImage: `url(${coverURL})`}}
              />
              <div className="app__invites--info">
                <div className="app__invites--info-title">{title.text}</div>
                <div className="app__invites--info-author">
                  <div className="app__invites--author-name">{authorName}</div>
                  <div className="app__invites--hyphen"> - </div>
                  <div className="app__invites--author-date">
                    <TimeAgo datetime={lastEdited} />
                  </div>
                </div>
              </div>
              <div className="app__invites--actions">
                <button
                  className="app__btn"
                  type="button"
                  onClick={() => {
                    onAccept(k);
                    onWipJoin(k, true);
                  }}>
                  Accept
                </button>
                <button
                  className="app__btn"
                  type="button"
                  onClick={() => {
                    onDeny(k);
                  }}>
                  Decline
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

ActivityInvites.defaultProps = {
  news: {},
  onAccept: () => {},
  onDeny: () => {},
};

ActivityInvites.propTypes = {
  news: PropTypes.objectOf(PropTypes.any),
  onAccept: PropTypes.func,
  onDeny: PropTypes.func,
};

export default ActivityInvites;
