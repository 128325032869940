import {toast} from 'react-toastify';

import firebase, {db} from '@untitled/app/core/helpers/helper-firebase';
import {
  ActivityType,
  stageString,
  UntitledInstanceObjects,
} from '@untitled/app/core/helpers/helper-types';
import {
  generateNotifications,
  processActivities,
  sendPushNotification,
} from '@untitled/app/core/helpers/helper-notifications';
import {allSettled} from '@untitled/app/core/helpers/helper-upload';
import Analytics from '@untitled/app/core/helpers/helper-analytics';
import UploadsManager from '@untitled/app/core/classes/uploads-manager';
import {logErrorMessage} from '@untitled/app/core/helpers/helper-logs';
import {
  preprocessCover,
  preprocessAudio,
} from '@untitled/app/core/helpers/helper-bulk';
import {defaultDisplayDate} from '@untitled/app/core/helpers/helper-untitled';

const uploadManager = UploadsManager.getInstance();

const {
  // arrayRemove,
  arrayUnion,
} = firebase.firestore.FieldValue;

const displayError = err => {
  // Handle Errors here.
  logErrorMessage(err.message);
  toast.error(err.message, {
    kind: 'error',
    autoClose: 5000,
  });
  return false;
};

const uploadAudio = async (wipId, audioRef, audioStorageRef, audioBlob) =>
  new Promise(resolve => {
    audioStorageRef.put(audioBlob).on(
      'state_changed',
      a => {
        uploadManager.updateUpload({
          wipId,
          uploadProgress: a.bytesTransferred / a.totalBytes || 0,
          fileId: audioRef.id,
          type: 'audio',
        });
      },
      e => {
        displayError(e);
      },
      () => audioStorageRef.getDownloadURL().then(resolve),
    );
  });

const uploadCover = async (
  wipId,
  coverArtRef,
  coverArtStorageRef,
  coverArtBlob,
) =>
  new Promise(resolve => {
    coverArtStorageRef.put(coverArtBlob).on(
      'state_changed',
      a => {
        uploadManager.updateUpload({
          wipId,
          uploadProgress: a.bytesTransferred / a.totalBytes || 0,
          fileId: coverArtRef.id,
          type: 'image',
        });
      },
      e => {
        console.log('e', e);
      },
      () => coverArtStorageRef.getDownloadURL().then(resolve),
    );
  });

/**
 * @typedef Data
 * @property {string} uriSound
 * @property {firebase.firestore.DocumentReference} audioRef
 * @property {firebase.storage.Reference} audioStorageRef
 *
 * @typedef Track
 * @property {firebase.firestore.DocumentReference} author
 * @property {string} title
 *
 * @param {Data} data
 * @param {Track} track
 * @param {firebase.firestore.DocumentReference} wipRef
 * @returns {() => Promise<string>}
 */
const createAudioTask = (data, track, wipRef, wip, wipAuthor) => async () => {
  const {uriSound, audioRef, audioStorageRef} = data;
  const {author: authorRef} = track;
  const author = (await authorRef.get()).data();
  const {name = '', lastName = ''} = author;
  const authorName = `${name} ${lastName}`;
  const wipAuthorName = `${wipAuthor.name} ${wipAuthor.lastName}`;

  const file = await fetch(uriSound);
  const blob = await file.blob();
  const uri = await uploadAudio(wipRef.id, audioRef, audioStorageRef, blob);

  // Why am I doing this?
  // Well, the `Preview` component expects a string
  // specifically for the `TimeAgo` inside `Preview`,
  // using the timestamp would mess up that component.
  const timestamp = new Date();
  await allSettled([
    audioRef.set({
      ...track,
      uri,
      created: timestamp,
      lastEdited: timestamp,
    }),
    wipRef.set(
      {
        lastEdited: timestamp,
        audios: arrayUnion(audioRef),
        album: {
          albumTitle: wip?.title?.text ?? defaultDisplayDate(),
          authorName: wipAuthorName,
          cover: wip.cover,
          id: wip.id,
          uploading: false,
          wipId: wip.id,
          hash: 'pending',
          tracks: arrayUnion({
            audioId: audioRef.id,
            author: authorName,
            created: timestamp,
            id: audioRef.id,
            title: track.title,
            url: uri,
            wipId: wipRef.id,
            fromRecording: false,
          }),
        },
      },
      {merge: true},
    ),
  ]);

  return track.title;
};

export const bulkUploadAudios = (user, uploadData) => async (_, getState) => {
  const {id: authorId} = user;
  const {wipId, audioTitles = [], soundFiles} = uploadData;
  const {wips, authors} = getState();
  const wip = wips.all[wipId];
  const wipAuthor = authors.users[wip.author];
  if (!wip || !soundFiles) {
    toast.dark('Error uploading to WIP', {
      autoClose: 3000,
      hideProgressBar: true,
    });
    return false;
  }

  const wipRef = db
    .collection(stageString(UntitledInstanceObjects.WIP))
    .doc(wip.id);
  const authorRef = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(authorId);

  const audioIds = [];

  const tasks = soundFiles.map(file => {
    const {track: t, data: d} = preprocessAudio(
      file,
      audioTitles,
      authorRef,
      wipId,
    );
    const wData = {...wip, audio: d.audioRef, wipRef};
    const aData = {audioTitle: t.title};
    uploadManager.addUpload(user, wData, aData, false);

    audioIds.push(d.audioRef.id);

    return createAudioTask(d, t, wipRef, wip, wipAuthor);
  });

  const confirmed = wip.confirmed || [];

  const subscribers = [wip.author, ...confirmed].filter(
    a => a !== authorRef.id,
  );
  const users = subscribers.map(id => ({id, ...authors.users[id]}));
  const author = authors.users[authorRef.id];

  audioIds.forEach(async audioId => {
    const meta = {
      kind: ActivityType.newAudio,
      audioId,
    };

    await processActivities(
      wipRef,
      authorRef,
      getState(),
      [wip.author, ...confirmed],
      meta,
    );
  });

  const index = audioTitles.map(f => f.id).indexOf(soundFiles[0].name);
  const mainTitle =
    index !== -1
      ? audioTitles[index].name
      : soundFiles[0].name.split('.').slice(0, -1).join('.');

  const audioTitle =
    soundFiles.length > 1 ? `${soundFiles.length} audios` : mainTitle;

  const notifications = generateNotifications({
    type: ActivityType.newAudio,
    users,
    // audioId: audioRef.id,
    author,
    wip,
    audioTitle,
    wipTitle: uploadData.wipTitle,
  });

  const starterPromise = Promise.resolve(null);
  const onEnd = at => {
    toast.dark(`${at} upload has finished!`, {
      autoClose: 3000,
      hideProgressBar: true,
    });
  };
  await tasks.reduce(
    (p, spec) => p.then(() => spec().then(onEnd)),
    starterPromise,
  );

  toast.dark('Audios added successfully', {
    autoClose: 3000,
    hideProgressBar: true,
  });

  return sendPushNotification(notifications);
};

// TODO: add progress callback for dynamic toasts and links
// TODO: add file size for better progress display
export const bulkToNewWip = (user, uploadData) => async (_, getState) => {
  console.log('bulkToNewWip', user, uploadData);
  const {id: authorId} = user;
  const {
    wipTitle,
    audioTitles = [],
    soundFiles,
    coverFile,
    croppedAreaPixels,
    writers = [],
    readers = [],
  } = uploadData;
  const wipAuthor = getState().authors.users[authorId];

  const wipRef = db.collection(stageString(UntitledInstanceObjects.WIP)).doc();
  const authorRef = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(authorId);
  const now = firebase.firestore.Timestamp.now();

  const wipId = wipRef.id;

  const readersArray = readers.map(authId =>
    db.collection(stageString(UntitledInstanceObjects.Author)).doc(authId),
  );

  const writersArray = writers.map(authId =>
    db.collection(stageString(UntitledInstanceObjects.Author)).doc(authId),
  );

  const {
    coverArtRef,
    coverArtBlob,
    coverArtStorageRef,
    coverArtData,
    uriImage,
  } = await preprocessCover(coverFile, croppedAreaPixels, authorRef, wipId);

  const [mainFile, ...audioFiles] = soundFiles;

  const {track, data: audioData} = preprocessAudio(
    mainFile,
    audioTitles,
    authorRef,
    wipId,
  );

  audioData.audioBlob = await fetch(audioData.uriSound).then(f => f.blob());

  const {audioRef, audioStorageRef, audioBlob, uriSound} = audioData;
  const coverArtDownloadURL = await uploadCover(
    wipId,
    coverArtRef,
    coverArtStorageRef,
    coverArtBlob,
  );

  const wipData = {
    id: wipRef.id,
    wipRef,
    author: authorRef,
    created: now,
    lastEdited: now,
    title: {
      text: wipTitle,
      created: now,
    },
    peaks: [1, 1, 1],
    readers: readersArray,
    writers: writersArray,
    coverArt: coverArtRef,
    audio: audioRef,
    audios: [],
    cover: coverArtDownloadURL,
  };
  uploadManager.addUpload(
    user,
    wipData,
    {coverArtBlob, audioTitle: track.title},
    true,
  );

  const tasks = audioFiles.map(file => {
    const {track: t, data: d} = preprocessAudio(
      file,
      audioTitles,
      authorRef,
      wipId,
    );
    const wData = {...wipData, audio: d.audioRef};
    const aData = {audioTitle: t.title};
    uploadManager.addUpload(user, wData, aData, false);

    return createAudioTask(d, t, wipRef, wipData, wipAuthor);
  });

  const audioDownloadURL = await uploadAudio(
    wipId,
    audioRef,
    audioStorageRef,
    audioBlob,
  );

  const invited = [...readers, ...writers].map(id =>
    db.collection(stageString(UntitledInstanceObjects.Author)).doc(id),
  );

  const wipUpdates = {...wipData, invited};

  delete wipUpdates.wipRef;

  await allSettled([
    wipRef.set({
      ...wipUpdates,
      album: {
        cover: coverArtDownloadURL,
      },
      ready: true,
      uploading: null,
    }),
    audioRef.set({...track, uri: audioDownloadURL}),
    coverArtRef.set({...coverArtData, uri: coverArtDownloadURL}),
  ]).then(() => {
    URL.revokeObjectURL(uriImage);
    URL.revokeObjectURL(uriSound);
    toast.dark(`${track.title} upload has finished!`, {
      autoClose: 3000,
      hideProgressBar: true,
    });

    // TODO: properly determine the length of the uploaded audio
    const audioDuration = 24;
    Analytics.logEventCreateWIP('device_upload');

    for (let i = 0; i < soundFiles.length; i += 1) {
      Analytics.logEventCreateAudio(audioDuration, 'device_upload');
    }
    for (let i = 0; i < readers.length; i += 1) {
      Analytics.logEventShareWip(readers[i], wipRef.id, false);
    }
    for (let i = 0; i < writers.length; i += 1) {
      Analytics.logEventShareWip(writers[i], wipRef.id, true);
    }

    return true;
  });

  const meta = {
    kind: ActivityType.newInvite,
  };

  const {author, users} = await processActivities(
    wipRef,
    authorRef,
    getState(),
    [...readers, ...writers],
    meta,
  );

  const notifications = generateNotifications({
    type: ActivityType.newInvite,
    users,
    audioId: audioRef.id,
    author,
    wip: {
      id: wipRef.id,
      author: authorRef.id,
      readers,
      writers,
      title: wipData.title,
    },
    audioTitle: track.title,
    wipTitle,
    targetAll: true,
  });

  const starterPromise = Promise.resolve(null);
  const onEnd = at => {
    toast.dark(`${at} upload has finished!`, {
      autoClose: 3000,
      hideProgressBar: true,
    });
  };
  await tasks.reduce(
    (p, spec) => p.then(() => spec().then(onEnd)),
    starterPromise,
  );

  toast.dark(`${wipTitle} saved successfully!`, {
    autoClose: 3000,
    hideProgressBar: true,
  });

  return sendPushNotification(notifications);
};
