import React from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import Folder from 'react-feather/dist/icons/folder';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import MoreVertical from 'react-feather/dist/icons/more-vertical';

import Button from '../../atoms/button';

import styles from './style.scss';
import Analytics from '../../../helpers/helper-analytics';

const TRANS_SEC = parseFloat(styles.explorerMillis || 0.5) * 1000;

const ExplorerFolder = ({
  setSelected,
  item,
  renderActions,
  tooltip,
  setTooltip,
  level,
  total,
}) => {
  const {name, key, url, itemsCount} = item;
  const countLabel = itemsCount > 1 ? 'items' : 'item';
  const countNumber = itemsCount > 99 ? '+99' : itemsCount;

  return (
    <div className="app__fileexplorer--folder" type="button">
      <Button
        className="app__fileexplorer--folder-icon"
        onTap={() => {
          Analytics.logEventViewFolder();
          setSelected(url);
        }}>
        <Folder size={18} />
      </Button>
      <Button
        className="app__fileexplorer--folder-info"
        onTap={() => {
          Analytics.logEventViewFolder();
          setSelected(url);
        }}>
        <div className="app__fileexplorer--folder-name">{name}</div>
        <div className="app__fileexplorer--folder-stats flex">
          <small className="app__fileexplorer--folder-count">{`${countNumber} ${countLabel}`}</small>
          <small className="app__fileexplorer--folder-url truncate">
            {key}
          </small>
        </div>
      </Button>
      {total < 100 && (
        <div className="app__fileexplorer--folder-actions mr-3">
          <div className="app__fileexplorer--total">
            <div
              className="app__fileexplorer--total-progress"
              style={{width: `${total}%`}}
            />
          </div>
        </div>
      )}
      {/* <div className="app__fileexplorer--folder-actions mr-3">
        <div className="app__fileexplorer--total">
          <div className="app__fileexplorer--total-progress" style={{ width: `${total}%` }} />
        </div>
      </div> */}
      <div className="app__fileexplorer--folder-actions">
        {!!renderActions && level === 0 && (
          <>
            <Button
              className="app__fileexplorer--folder-action"
              onTap={() => {
                if (tooltip === key) {
                  setTooltip('');
                  return;
                }
                setTooltip(key);
              }}>
              <MoreVertical size={16} />
            </Button>
            <CSSTransition
              in={tooltip === key}
              timeout={TRANS_SEC}
              classNames="app__dropanim"
              unmountOnExit>
              <div className="app__fileexplorer--tooltip">
                {renderActions(item, () => setTooltip(''))}
              </div>
            </CSSTransition>
          </>
        )}
        <Button
          className="app__fileexplorer--folder-action"
          onTap={() => {
            setSelected(url);
          }}>
          <ChevronRight size={16} />
        </Button>
      </div>
    </div>
  );
};

ExplorerFolder.defaultProps = {
  setSelected: () => {},
  renderActions: undefined,
  setTooltip: () => {},
  item: {},
  tooltip: '',
  level: 0,
  total: 0,
};

ExplorerFolder.propTypes = {
  setSelected: PropTypes.func,
  renderActions: PropTypes.func,
  setTooltip: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  tooltip: PropTypes.string,
  level: PropTypes.number,
  total: PropTypes.number,
};

export default ExplorerFolder;
