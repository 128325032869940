import React from 'react';
import PropTypes from 'prop-types';

const Clickable = ({
  id,
  className,
  ariaLabel,
  children,
  onClick,
  setRef,
  tabIndex,
  style,
}) => (
  <div
    id={id}
    className={className}
    aria-label={ariaLabel}
    onClick={onClick}
    role="button"
    ref={setRef}
    tabIndex={tabIndex}
    onKeyUp={e => {
      if (e.keyCode === 13) {
        e.preventDefault();
        onClick();
      }
    }}
    style={style}>
    {children}
  </div>
);

Clickable.defaultProps = {
  children: '',
  className: '',
  ariaLabel: '',
  id: '',
  setRef: () => {},
  tabIndex: 0,
  style: {},
};

Clickable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  setRef: PropTypes.func,
  tabIndex: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
};
export default Clickable;
