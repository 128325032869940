import React, {useContext, useEffect} from 'react';
import {ShareTrackPreviewTrack} from '@untitled/app/core/components/molecules/share-track-preview-track';
import {UPlayerContext} from '@untitled/app/core/components/context/player-context';
import {PublicTrack} from '@untitled/app/core/components/templates/share-track';
import styled from 'styled-components';

const StyledRoot = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 96px;
  animation-name: fadeUp;
  animation-duration: 1s;
  @media screen and (max-width: 667px) {
    padding: 16px 16px;
  }
`;

interface IShareTrackInfoBodyProps {
  track: PublicTrack;
}

export const ShareTrackInfoBody: React.FC<IShareTrackInfoBodyProps> = ({
  track,
}) => {
  const {player, playerState, setPlayerState} = useContext(UPlayerContext);

  useEffect(() => {
    const unsubscribe = player.addListener('state_changed', setPlayerState);

    return unsubscribe;
  }, []);

  /** Note that our audio player, player.js, requires an array of tracks. */
  const playlist = [
    {
      id: track.id,
      title: track.title,
      cover: track.wipCover,
      authorName: track.authorName,
      uri: track.url,
    },
  ];

  const handlePlay = () => {
    if (!player.created) {
      player.create(track.id, playlist, 0, track.wipCover, true);
    }

    if (player.playing) {
      player.pause();
    } else {
      player.play(0);
    }
  };

  return (
    <StyledRoot>
      <ShareTrackPreviewTrack
        title={track.title}
        author={track.authorName}
        created={track.created.toString()}
        playing={playerState.playing}
        onPlay={handlePlay}
        fromRecording={track.fromRecording}
      />
    </StyledRoot>
  );
};
