import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import Checkmark from 'react-feather/dist/icons/check-square';
import Sqaure from 'react-feather/dist/icons/square';

const Check = ({
  id,
  className,
  title,
  setRef,
  type,
  tabIndex,
  disabled,
  value,
  name,
  required,
  onChange,
  onKeyPress,
  onEnter,
  error,
  style,
}) => {
  const keyPress = e => {
    if (e.key === 'Enter') {
      onEnter();
    } else {
      onKeyPress(e);
    }
  };
  return (
    <label className={`${className} ${value ? 'checked' : ''}`}>
      <input
        id={id}
        className={`${error ? 'has-error' : ''}`}
        type={type}
        ref={setRef}
        checked={value}
        onChange={e => {
          onChange({name: name === '' ? id : name, value: e.target.checked});
        }}
        onKeyPress={keyPress}
        tabIndex={tabIndex}
        disabled={disabled}
        required={required}
        style={style}
        // onFocus={(e) => {
        //   e.target.click();
        // }}
      />
      {value ? <Checkmark size={14} color="#141414" /> : <Sqaure size={14} />}
      {title}
    </label>
  );
};

Check.defaultProps = {
  id: '',
  onChange: v => {
    console.log(v);
  },
  setRef: () => {},
  onEnter: () => {},
  onKeyPress: () => {},
  className: '',
  title: '',
  type: 'checkbox',
  name: '',
  disabled: false,
  error: false,
  required: false,
  value: false,
  tabIndex: '0',
  style: {},
};

Check.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onEnter: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  tabIndex: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};

export default Check;
