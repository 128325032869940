import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {CSSTransition} from 'react-transition-group';
import {useHistory} from 'react-router-dom';

import TrackItem from '../../molecules/track-item';
import Controls from '../../molecules/controls';
import {IconVolumeMax} from '../../atoms/icons';

import './style.scss';
import {UPlayerContext} from '../../context/player-context';
import RangeSlider from '../../atoms/range-slider';
import {TRANS_SLOW} from '../../../constants';
import {MenuContext} from '../../context/menu-context';

// TODO: fix page transition playbar issue

const Playbar = ({wips, user}) => {
  const {player, playerState} = useContext(UPlayerContext);
  const {setSelectedMenu} = useContext(MenuContext);
  const history = useHistory();

  const onSelect = wipId => {
    setSelectedMenu('');
    if (user) {
      history.push(`/details?wip=${wipId}`);
    } else {
      history.push(`/preview?wip=${wipId}`);
    }
  };

  const onPlay = () => {
    if (playerState.playing) {
      player.pause();
    } else {
      player.play(playerState.index);
    }
  };

  const onPrev = () => {
    if (player.elapsed > 10) {
      player.seek(0);
    } else {
      player.skip('prev');
    }
  };

  const onNext = () => {
    if (player.playlist.length > 1) {
      player.skip('next');
    } else {
      player.seek(0);
    }
  };

  const onLoop = () => {
    player.loop();
  };

  const onVolume = e => {
    const volume = parseFloat(e.target.value);
    player.volume(volume);
  };

  const {
    id,
    playing,
    looped,
    loading,
    playlist,
    currentAuthor,
    currentTitle,
    currentCover,
    globalVolume,
  } = playerState;

  /**
   * We disable the 'select' functionality of the playbar for public track/wip
   * pages
   */
  const disablePlaybarNavigation =
    !wips[id] || window.location.href.includes('/go/share');

  return (
    <CSSTransition
      in={playlist.length > 0}
      timeout={TRANS_SLOW}
      classNames="transition-slow"
      unmountOnExit>
      <div className="app__playbar">
        <div className="app__playbar--audio">
          <div className="app__playbar--audio-info">
            <TrackItem
              id={id}
              author={currentAuthor}
              title={currentTitle}
              cover={currentCover}
              album={wips[id] ? wips[id].title.text : ''}
              playing={playing}
              loading={loading}
              selectDisabled={disablePlaybarNavigation}
              onSelect={onSelect}
              onPlay={onPlay}
            />
          </div>
          <div className="app__playbar--audio-controls">
            <Controls
              playing={playing}
              looped={looped}
              player={player}
              onPlay={onPlay}
              onPrev={onPrev}
              onNext={onNext}
              onLoop={onLoop}
              loading={loading}
            />
          </div>
          <div className="app__playbar--audio-volume">
            <IconVolumeMax />

            <RangeSlider
              min={0}
              max={1}
              step={0.01}
              onChange={onVolume}
              value={globalVolume}
            />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

Playbar.defaultProps = {
  wips: {},
  user: {},
};

Playbar.propTypes = {
  wips: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = ({common, wips}) => ({
  wips: wips.all,
  user: common.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Playbar);
