/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-underscore-dangle */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {
  ActivityType,
  stageString,
  UntitledInstanceObjects,
} from '../../../helpers/helper-types';
import firebase, {db} from '../../../helpers/helper-firebase';
import TimeAgo from '../../atoms/timeago';
import Clickable from '../../atoms/clickable';

const FAKESTRING = '';

const mentionRegEx = /((.)\[([^[]*)]\(([^(^)]*)\))/gi;
const replaceMentionValues = (value, replacer) =>
  value.replace(mentionRegEx, (fullMatch, original, trigger, name, id) =>
    replacer({
      original,
      trigger,
      name,
      id,
    }),
  );

const ActivityNotifications = ({
  user,
  authors,
  wips,
  audios,
  activity,
  reads,
  onAuthor,
  onSelect,
}) => {
  const lastActivityRef = activity.lastActivity || new Date(0);

  const lastRead = new Date(reads._last || 0);

  const onSetLastRead = () => {
    console.log('SetLastRead');
    const thisAuthorReadsRef = db
      .collection(stageString(UntitledInstanceObjects.Author))
      .doc(user.uid)
      .collection(stageString(UntitledInstanceObjects.AuthorMeta))
      .doc('reads');

    thisAuthorReadsRef.set(
      {
        _last: firebase.firestore.Timestamp.now(),
      },
      {merge: true},
    );
  };

  const getAuthorComponent = ({id, name}) => (
    <b
      role="button"
      onClick={() => {
        onAuthor(id);
      }}>
      {name}
    </b>
  );

  const getInfoComponent = item => {
    const {
      kind,
      wipId,
      removedAuthorId,
      audioId,
      created,
      taggedAuthors,
      comment,
      author,
      guestName,
    } = item;
    const wip = wips[wipId];
    // const wipAuthor = authors[wip.author];
    const itemAuthor = authors[author];

    switch (kind) {
      case ActivityType.updatedTitle: {
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} updated the title for ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.newInvite: {
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} invited you to ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.acceptedInvite: {
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} accepted an invite to ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.deniedWip: {
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} denied your invite to ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.removedPermissionsFromWip: {
        const removedAuthor = removedAuthorId && authors[removedAuthorId];
        if (removedAuthor) {
          return (
            <span>
              {removedAuthor.name}
              <span>{`${FAKESTRING} was removed from ${FAKESTRING}`}</span>
              <Clickable
                className="app__notification--item-title inline"
                onClick={() => onSelect(wip)}>
                <b>{`${FAKESTRING} ${wip.title.text}`}</b>
              </Clickable>
              <span className="app__invite--hyphen"> - </span>
              <TimeAgo datetime={created} />
            </span>
          );
        }
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} removed a user from ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.newAudio: {
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} added ${
              audios[audioId]?.title || 'a new audio file'
            } on ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.newImage: {
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} changed the cover of ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.newComment: {
        const tagged = Array.isArray(taggedAuthors) ? taggedAuthors : [];
        const msg =
          tagged.indexOf(user.uid) !== -1 ? 'mentioned you on' : 'commented on';
        const parsed = replaceMentionValues(comment, ({name}) => `@${name}`);
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} ${msg} ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip, audioId)}>
              <b>{`${FAKESTRING} ${wip.title.text}: ${FAKESTRING}`}</b>
            </Clickable>
            <span>{`${FAKESTRING} ${parsed}`}</span>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.newListen: {
        return (
          <span className="app__notification--item-content">
            {guestName}
            <span>{`${FAKESTRING} listened ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.updatedAudioTitle: {
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} updated the title for ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${audios[audioId]?.title}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      case ActivityType.arrangedWip: {
        return (
          <span className="app__notification--item-content">
            {getAuthorComponent(itemAuthor)}
            <span>{`${FAKESTRING} changed the order of ${FAKESTRING}`}</span>
            <Clickable
              className="app__notification--item-title inline"
              onClick={() => onSelect(wip)}>
              <b>{`${FAKESTRING} ${wip.title.text}`}</b>
            </Clickable>
            <span className="app__invite--hyphen"> - </span>
            <TimeAgo datetime={created} />
          </span>
        );
      }
      default:
        return null;
    }
  };

  const renderElements = item => {
    const {wipId, id, author, guestName} = item;
    const itemAuthor = authors[author];
    if (!itemAuthor) {
      console.debug(`Filtering activityId=${id} from deleted userId=${author}`);
      return null;
    }

    const initials = itemAuthor
      ? itemAuthor.initials
      : guestName[0].toUpperCase();
    return (
      <div className="app__activity--notifications-item" key={`${wipId}-${id}`}>
        <div className="app__miniauthor--initials light">
          <span>{initials}</span>
        </div>
        <div className="app__activity--notifications-info">
          {getInfoComponent(item)}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (lastRead < lastActivityRef) {
      onSetLastRead();
    }
  }, []);
  return (
    <>
      {activity?.newActivity?.length > 0 && (
        <div className="app__activity--notifications">
          <h5>New</h5>
          {activity.newActivity.map(renderElements)}
        </div>
      )}
      {activity?.oldActivity?.length > 0 && (
        <div className="app__activity--notifications">
          <h5>Seen</h5>
          {activity.oldActivity.map(renderElements)}
        </div>
      )}
    </>
  );
};

ActivityNotifications.defaultProps = {
  user: {},
  authors: {},
  activity: {},
  reads: {},
  wips: {},
  audios: {},
  onAuthor: () => {},
  onSelect: () => {},
};

ActivityNotifications.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  authors: PropTypes.objectOf(PropTypes.any),
  activity: PropTypes.objectOf(PropTypes.any),
  reads: PropTypes.objectOf(PropTypes.any),
  wips: PropTypes.objectOf(PropTypes.any),
  audios: PropTypes.objectOf(PropTypes.any),
  onAuthor: PropTypes.func,
  onSelect: PropTypes.func,
};

export default ActivityNotifications;
