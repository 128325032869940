import {combineReducers} from 'redux';
import common from './common';
import wips from './wips';
import authors from './authors';
import audios from './audios';
import artworks from './artworks';
import activity from './activity';
import queue from './queue';
import landing from './landing';

const reducers = combineReducers({
  common,
  wips,
  authors,
  audios,
  artworks,
  activity,
  queue,
  landing,
});

export default reducers;
