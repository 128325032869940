import {useRef, useState} from 'react';
import Compressor from 'compressorjs';
import {getRandomGradientImg} from '../../../../helpers/helper-untitled';
import {logErrorException} from '../../../../helpers/helper-logs';

const getRandomImage = () => ({
  uri: getRandomGradientImg(),
  type: 'jpeg',
  extension: 'jpg',
});

const useUploadCover = () => {
  const [coverFile, setCoverArt] = useState(getRandomImage());
  const compressorRef = useRef(null);

  const onArtworkChanged = files => {
    if (files.length) {
      const type = files[0].type.split('/').pop();
      const extension = files[0].name.split('.').pop();
      compressorRef.current = new Compressor(files[0], {
        width: 520,
        minHeight: 520,
        convertSize: 1000000,
        strict: false,
        quality: 0.8,
        success(result) {
          const uri = URL.createObjectURL(result);
          setCoverArt({
            uri,
            type,
            extension,
          });
          compressorRef.current = null;
        },
        error(e) {
          logErrorException(e);
        },
      });
    }
  };

  return {
    coverFile,
    onArtworkChanged,
  };
};

export default useUploadCover;
