/* eslint-disable react/prop-types */

import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {isElectron} from '../../helpers/helper-useragent';

import {StepsAuth} from '../organisms/steps/steps-auth';
import StepsStarted from '../organisms/steps/steps-started';

// TODO: remove setMainLoader

const HocQueue = Component => props => {
  const {user, userData = {}, setMainLoader} = props;
  const hasUser = user && user.uid;
  const loggedIn =
    hasUser && (!user.mfaEnabled || (user.mfaEnabled && user.mfaVerified));
  const history = useHistory();
  const location = useLocation();
  const isSharedWip =
    location.pathname.startsWith('/go/share') ||
    location.pathname.startsWith('/share');
  const isWaitlist = location.pathname.startsWith('/waitlist');
  const {hasOnboarding} = userData;

  // This check needs to run first, because sometimes
  // `history` from the `useHistory` is null, and that's
  // why sometimes, the previous version worked, but once
  // history is filled, you'll be hitting `/home`.
  if (isSharedWip || isWaitlist) {
    return <Component {...props} />;
  }

  if (hasUser && hasOnboarding) {
    history.push({
      pathname: '/home',
      state: {tab: 0},
    });
  }

  if (!loggedIn) {
    return (
      <div id="AuthPage" className="app__content">
        <StepsAuth />
      </div>
    );
  }

  if (!hasOnboarding && isElectron) {
    return (
      <div id="AuthPage" className="app__content">
        <StepsStarted author={user.uid} setMainLoader={setMainLoader} />
      </div>
    );
  }

  return <Component {...props} />;
};

export default HocQueue;
