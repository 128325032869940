import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Switch = ({id, value, onChange}) => (
  <label htmlFor={id} className="app__switch sw-primary">
    <input
      id={id}
      type="checkbox"
      checked={value}
      onChange={e => {
        onChange({
          name: id,
          value: e.target.checked,
        });
      }}
    />
    <div className="sw-pan" />
    <div className="sw-btn" />
  </label>
);

Switch.defaultProps = {
  value: false,
  onChange: () => {},
};

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
