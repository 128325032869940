import React from 'react';
import {useHistory} from 'react-router-dom';
import {AsYouType, isValidPhoneNumber} from 'libphonenumber-js';

import Input from '../../atoms/input';
import {invokeCloudFunction} from '../../../helpers/helper-firebase';
import HocQueue from '../../hoc/hoc-queue';

const Waitlist = () => {
  const history = useHistory();

  const [data, setData] = React.useState({
    email: '',
    name: '',
    message: '',
    phone: '',
  });

  const [isSent, setIsSent] = React.useState(false);

  const isDisabled = React.useMemo(
    () =>
      data.email === '' ||
      data.name === '' ||
      data.message === '' ||
      !isValidPhoneNumber(data.phone, 'US'),
    [data],
  );

  const handleBack = React.useCallback(() => {
    history.goBack();
  }, []);

  const handleJoin = React.useCallback(async () => {
    await invokeCloudFunction('addToWaitlist')(data);
    setIsSent(true);
  }, [data]);

  const handleChangeEmail = React.useCallback(
    event => {
      setData({
        ...data,
        email: event.value,
      });
    },
    [data, setData],
  );

  const handleChangeMessage = React.useCallback(
    event => {
      setData({
        ...data,
        message: event.value,
      });
    },
    [data, setData],
  );

  const handleChangeName = React.useCallback(
    event => {
      setData({
        ...data,
        name: event.value,
      });
    },
    [data, setData],
  );

  const handleChangePhone = React.useCallback(
    event => {
      setData({
        ...data,
        phone: new AsYouType().input(event.value),
      });
    },
    [data, setData],
  );

  const renderThanks = React.useMemo(
    () => <>Thanks, you’ve been added to the waitlist.</>,
    [],
  );

  const renderForm = React.useMemo(
    () => (
      <>
        <Input
          className="app__input mb-3"
          name="name"
          onChange={handleChangeName}
          placeholder="Full name"
          autoComplete="name"
          type="text"
          value={data.name}
        />
        <Input
          className="app__input mb-3"
          name="phone"
          onChange={handleChangePhone}
          placeholder="Phone number"
          autoComplete="tel"
          type="phone"
          value={data.phone}
        />
        <Input
          className="app__input mb-3"
          name="email"
          onChange={handleChangeEmail}
          placeholder="Email"
          autoComplete="email"
          type="email"
          value={data.email}
        />
        <Input
          className="app__input mb-3"
          name="message"
          onChange={handleChangeMessage}
          placeholder="How did you hear about us?"
          type="text"
          value={data.message}
        />
        <button
          className="app__btn"
          disabled={isDisabled}
          onClick={handleJoin}
          type="button">
          Join Waitlist
        </button>
        <br />
        <button className="app__link" onClick={handleBack} type="button">
          Back
        </button>
      </>
    ),
    [
      data,
      handleChangeName,
      handleChangePhone,
      handleChangeEmail,
      handleChangeMessage,
      handleBack,
      isDisabled,
      handleJoin,
    ],
  );

  return (
    <div className="app__slide app__steps--card steps-center">
      <div className="app__steps--form">
        {isSent ? renderThanks : renderForm}
      </div>
    </div>
  );
};

export default HocQueue(Waitlist);
