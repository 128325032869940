/* eslint-disable no-param-reassign */
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {mergeWIPData} from '@untitled/app/core/helpers/helper-untitled';
import {db} from '@untitled/app/core/helpers/helper-firebase';
import {wipsNotifications} from '@untitled/app/core/redux/actions/wips';

const useBubbles = () => {
  const activity = useSelector((state: any) => state.activity || {});
  const notifications = useSelector(({wips}: any) => wips?.notifications ?? []);
  const currentUser = useSelector(({common}: any) => common?.user?.uid);
  const news = useSelector(({audios, authors}: any) =>
    mergeWIPData(notifications, audios.all, authors.users),
  );
  const dispatch = useDispatch();
  const [bubbles, setBubbles] = useState(0);
  const lastRead = new Date(activity.reads._last || 0);
  const newActivity = activity.newActivity || [];
  const lastActivityRef = activity.lastActivity || new Date(0);
  const addBubble = lastRead < lastActivityRef ? newActivity.length : 0;
  const bubbleCount = Object.keys(news).length + addBubble;

  useEffect(() => {
    setBubbles(bubbleCount);
  }, [bubbleCount]);

  useEffect(() => {
    /** This effect prevents the user to see
     * cached invites when the user logs in, gets
     * invited to some wips but doesn't accept them or
     * deny them, then closes the browser, and after that
     * comes back.
     */
    const keys = Object.keys(notifications || {});
    const wipRefs = keys.map(key =>
      db.collection('developmentWIP').doc(key).get(),
    );
    Promise.all(wipRefs)
      .then(docs => {
        const docHash = docs.reduce(
          (acc, doc) => ({
            ...acc,
            [doc.id]: doc.data(),
          }),
          {},
        );
        const removed = Object.entries(docHash)
          .filter(([, value]) => !value)
          .map(([id]) => id);
        const confirmedDeniedOrNotInvited = Object.entries(docHash)
          .filter(([_, data]: [string, any]) => {
            if (!data) {
              return false;
            }

            const denied = (data.denied || []).map((den: any) => den.id);
            const confirmed = (data.confirmed || []).map(
              (conf: any) => conf.id,
            );
            const invited = (data.invited || []).map((inv: any) => inv.id);
            return (
              denied.indexOf(currentUser) !== -1 ||
              confirmed.indexOf(currentUser) !== -1 ||
              invited.indexOf(currentUser) === -1
            );
          })
          .map(([id]) => id);
        const toUpdateSet = new Set([
          ...removed,
          ...confirmedDeniedOrNotInvited,
        ]);
        const toUpdate = [...toUpdateSet].reduce(
          (acc, notificationId) => ({...acc, [notificationId]: undefined}),
          {},
        );
        dispatch(wipsNotifications(toUpdate));
      })
      .catch(err => console.error('Error when fetching docs.', err));
  }, []);

  return bubbles;
};

export default useBubbles;
