import {WIPstatus} from '../../helpers/helper-types';
import {
  WIP_CHANGED,
  WIPS_CHANGED,
  PINS_CHANGED,
  WIP_PLAYING,
  WIP_RESET,
  WIP_UPLOADING,
  WIP_UPLOADING_PROGRESS,
  WIPS_NOTIFICATION,
  NOTES_CHANGED,
  LISTENS_CHANGED,
  WIP_JOINS_CHANGED,
} from '../actions/wips/constants';

interface WipUploadingProgressPayload {
  wipId: string;
  fileId: string;
  progress: number;
}

interface Uploading {
  uploading: Record<string, number>;
}

interface State {
  all: object;
  loading: object;
  notifications: object;
  pins: object[];
  notes: object;
  uploading?: Record<string, Uploading>;
}

const defaultState: State = {
  all: {},
  loading: {},
  notifications: {},
  pins: [],
  notes: {},
  uploading: {},
};

interface Action {
  type: string;
  payload: any;
}

const reducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case WIP_CHANGED: {
      return {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
        },
      };
    }

    case WIPS_CHANGED: {
      return {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
        },
      };
    }

    case PINS_CHANGED: {
      const pins = action.payload || [];
      return {
        ...state,
        pins,
      };
    }

    case LISTENS_CHANGED: {
      const listens = action.payload || {};
      return {
        ...state,
        listens,
      };
    }

    case WIP_JOINS_CHANGED: {
      const wipJoins = action.payload || {};
      return {
        ...state,
        wipJoins,
      };
    }

    case NOTES_CHANGED: {
      const notes = action.payload || [];
      return {
        ...state,
        notes,
      };
    }

    case WIP_PLAYING: {
      const playing = action.payload || {};
      return {
        ...state,
        playing,
      };
    }

    case WIPS_NOTIFICATION: {
      const notifications = action.payload || {};
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...notifications,
        },
      };
    }

    case WIP_UPLOADING: {
      const {wipId, data} = action.payload;
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [wipId]: data,
        },
      };
    }

    case WIP_UPLOADING_PROGRESS: {
      const {wipId, fileId, progress} =
        action.payload as WipUploadingProgressPayload;
      const {uploading = {}} = state;
      if (!uploading[wipId]) {
        uploading[wipId] = {uploading: {}};
      }
      if (progress === 1) {
        delete uploading[wipId].uploading[fileId];
      } else {
        uploading[wipId].uploading[fileId] = progress;
      }
      if (Object.keys(uploading[wipId].uploading).length === 0) {
        delete uploading[wipId];
      }
      return {
        ...state,
        uploading,
      };
    }

    case WIP_RESET: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
