/**
 * Constants audios
 */

/** @constant {string} AUDIO_CHANGED */
export const AUDIO_CHANGED = 'core/redux/audios/AUDIO_CHANGED';

/** @constant {string} AUDIOS_CHANGED */
export const AUDIOS_CHANGED = 'core/redux/audios/AUDIOS_CHANGED';

/** @constant {string} AUDIO_PRESENCE */
export const AUDIO_PRESENCE = 'core/redux/audios/AUDIO_PRESENCE';

/** @constant {string} AUDIO_REMOVE */
export const AUDIO_REMOVE = 'core/redux/audios/AUDIO_REMOVE';

/** @constant {string} AUDIO_RESET */
export const AUDIO_RESET = 'core/redux/audios/AUDIO_RESET';

/** @constant {string} AUDIO_SELECT */
export const AUDIO_SELECT = 'core/redux/audios/AUDIO_SELECT';

/** @constant {string} COMMENTS_CHANGED */
export const COMMENTS_CHANGED = 'core/redux/audios/COMMENTS_CHANGED';
