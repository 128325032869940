import React, {useLayoutEffect} from 'react';
import {connect} from 'react-redux';

import {authLogout} from '@untitled/app/core/redux/actions/common/async';

import {setLoader} from '@untitled/app/core/redux/actions/common';

interface Props {
  setMainLoader: (loading: boolean) => void;
}

const NotFound = ({setMainLoader}: Props) => {
  useLayoutEffect(() => {
    setMainLoader(false);
  }, []);

  return (
    <div id="NotFoundPage" className="app__page app__content">
      <h1>404</h1>
      <h5>Page not Found</h5>
      <h4>Oops, something went wrong...</h4>
      <a href="/" className="btn btn-primary mt-4">
        Go Back Home
      </a>
    </div>
  );
};

export const ErrorFallback = ({
  status = 500,
  header = '',
  message = 'Oops, something went wrong',
}) => (
  <div id="NotFoundPage" className="app__page app__content">
    <h1>{status}</h1>
    <h5>{header}</h5>
    <h4>{message}</h4>
    <a href="/home" className="btn btn-primary mt-4">
      Go Back Home
    </a>
  </div>
);

interface MapStateToProps {
  common: any;
  queue: any;
}

const mapStateToProps = ({common, queue}: MapStateToProps) => ({
  user: common.user, // Needed for hocQueue
  userData: queue.userData, // Needed for hocQueue
});

const mapDispatchToProps = {
  logoutAuth: authLogout,
  setMainLoader: setLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
