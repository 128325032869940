import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {secondsToMinutes} from '../../../helpers/helper-util';
import RangeSlider from '../../atoms/range-slider';

const ControlsSeek = ({player, playing, disabled}) => {
  const [playback, setPlayback] = useState({
    currentTime: player.elapsed || 0,
    duration: player.duration || 0,
  });
  const [pressed, setPressed] = useState(false);
  const [seeked, setSeeked] = useState(0);

  const onSeek = ({target}) => {
    setSeeked(parseFloat(target.value));
  };

  const onDown = () => {
    setPressed(true);
  };
  const onUp = () => {
    setPressed(false);
    setPlayback({
      duration: player.duration,
      currentTime: seeked,
    });
    player.seek(seeked / player.duration);
  };

  const onTick = () => {
    setPlayback({
      currentTime: player.elapsed || 0,
      duration: player.duration || 0,
    });
  };

  useEffect(() => {
    let interval;
    if (playing) {
      interval = setInterval(onTick, 100);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [playing]);

  return (
    <>
      <div className="app__controls--time">
        <div className="app__controls--time-elapsed">
          {secondsToMinutes(playback.currentTime)}
        </div>
        <div className="app__controls--time-duration">
          {secondsToMinutes(playback.currentTime - playback.duration)}
        </div>
      </div>
      <div className="app__controls--time-seek">
        <RangeSlider
          min={0}
          max={playback.duration}
          step={0.01}
          value={pressed ? seeked : playback.currentTime}
          onChange={onSeek}
          onMouseDown={onDown}
          onTouchStart={onDown}
          onTouchEnd={onUp}
          onMouseUp={onUp}
          disabled={disabled}
        />
      </div>
    </>
  );
};

ControlsSeek.defaultProps = {
  player: {},
  playing: false,
  disabled: false,
};

ControlsSeek.propTypes = {
  player: PropTypes.objectOf(PropTypes.any),
  playing: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ControlsSeek;
