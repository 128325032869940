import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Input from '../../../atoms/input';

const StepsPassword = ({onBack, rememberMeEmail, onPasswordReset}) => {
  const [user, setUser] = useState({resetEmail: rememberMeEmail});

  const onChange = e => {
    setUser({
      ...user,
      [e.name]: e.value,
    });
  };

  const onClick = () => {
    onPasswordReset(user.resetEmail);
  };

  const onEnter = () => {
    onPasswordReset(user.resetEmail);
  };

  const {resetEmail} = user;

  return (
    <div className="app__slide app__steps--card steps-center">
      <div className="app__steps--form">
        <Input
          className="app__input"
          name="resetEmail"
          type="email"
          placeholder="Enter email..."
          value={resetEmail}
          onChange={onChange}
          onEnter={onEnter}
        />
        <button type="button" className="app__btn" onClick={onClick}>
          Reset Password
        </button>
        <button type="button" className="app__link" onClick={onBack}>
          Back
        </button>
      </div>
    </div>
  );
};

StepsPassword.defaultProps = {
  rememberMeEmail: '',
  onPasswordReset: () => {},
  onBack: () => {},
};

StepsPassword.propTypes = {
  rememberMeEmail: PropTypes.string,
  onPasswordReset: PropTypes.func,
  onBack: PropTypes.func,
};

export default StepsPassword;
