import {
  ACTIVITY_CHANGED,
  ACTIVITYS_CHANGED,
  READS_CHANGED,
  ACTIVITY_RESET,
  ACTIVITY_OPEN,
} from './constants';

export const activityChanged = payload => ({
  type: ACTIVITY_CHANGED,
  payload,
});

export const activitysChanged = payload => ({
  type: ACTIVITYS_CHANGED,
  payload,
});

export const readsChanged = payload => ({
  type: READS_CHANGED,
  payload,
});

export const activitysReset = payload => ({
  type: ACTIVITY_RESET,
  payload,
});

export const activityOpen = payload => ({
  type: ACTIVITY_OPEN,
  payload,
});
