/**
 * Constants untitled
 */

/** @constant {string} WIP_CHANGED */
export const WIP_CHANGED = 'core/redux/untitled/WIP_CHANGED';

/** @constant {string} WIPS_CHANGED */
export const WIPS_CHANGED = 'core/redux/untitled/WIPS_CHANGED';

/** @constant {string} PINS_CHANGED */
export const PINS_CHANGED = 'core/redux/untitled/PINS_CHANGED';

/** @constant {string} LISTENS_CHANGED */
export const LISTENS_CHANGED = 'core/redux/untitled/LISTENS_CHANGED';

/** @constant {string} WIP_JOINS_CHANGED */
export const WIP_JOINS_CHANGED = 'core/redux/untitled/WIP_JOINS_CHANGED';

/** @constant {string} NOTES_CHANGED */
export const NOTES_CHANGED = 'core/redux/untitled/NOTES_CHANGED';

/** @constant {string} WIP_PLAYING */
export const WIP_PLAYING = 'core/redux/untitled/WIP_PLAYING';

/** @constant {string} WIP_UPLOADING */
export const WIP_UPLOADING = 'core/redux/untitled/WIP_UPLOADING';

/** @constant {string} WIP_UPLOADING_PROGRESS */
export const WIP_UPLOADING_PROGRESS =
  'core/redux/untitled/WIP_UPLOADING_PROGRESS';

/** @constant {string} WIPS_NOTIFICATION */
export const WIPS_NOTIFICATION = 'core/redux/untitled/WIPS_NOTIFICATION';

/** @constant {string} WIP_RESET */
export const WIP_RESET = 'core/redux/untitled/WIP_RESET';
