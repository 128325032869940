import {
  ARTWORK_CHANGED,
  ARTWORKS_CHANGED,
  ARTWORK_PRESENCE,
  ARTWORK_RESET,
} from './constants';

export const artworkChanged = payload => ({
  type: ARTWORK_CHANGED,
  payload,
});

export const artworksChanged = payload => ({
  type: ARTWORKS_CHANGED,
  payload,
});

export const artworksPresence = payload => ({
  type: ARTWORK_PRESENCE,
  payload,
});

export const artworksReset = payload => ({
  type: ARTWORK_RESET,
  payload,
});
