import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import * as Sentry from '@sentry/react';

import {store, persistor} from '@untitled/app/core/redux/store';
import Layout from '@untitled/app/core/layout';
import {UPlayerProvider} from '@untitled/app/core/components/context/player-context';
import {MenuProvider} from '@untitled/app/core/components/context/menu-context';
import {UploadProvider} from '@untitled/app/core/components/context/upload-context';
import Routes from '@untitled/app/core/routers/router-web.tsx';
import {logErrorException} from '@untitled/app/core/helpers/helper-logs';
import {ErrorFallback} from '@untitled/app/core/components/templates/notfound';
import {PersistGate} from 'redux-persist/integration/react';

const client = new ApolloClient({
  uri: '/graphql-service/',
  cache: new InMemoryCache(),
});

const Root = () => (
  <Sentry.ErrorBoundary fallback={ErrorFallback} onError={logErrorException}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter basename="/">
            <UPlayerProvider>
              <MenuProvider>
                <UploadProvider>
                  <Layout>
                    <Routes />
                    <div className="app__toastify" id="ToastHolder" />
                    <ToastContainer />
                  </Layout>
                </UploadProvider>
              </MenuProvider>
            </UPlayerProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </Sentry.ErrorBoundary>
);

export default Root;
