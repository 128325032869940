import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Slides from '../../../molecules/slides';

import Button from '../../../atoms/button';

import '../style.scss';
import {authLogout} from '../../../../redux/actions/common/async';
import {addUntitledFolder} from '../../../../helpers/helper-electron-api';

const StepsStarted = ({logoutAuth, setMainLoader}) => {
  const goToSlide = useRef();

  const [slide, setSlide] = useState(0);
  const [createFolder, setCreateFolder] = useState(false);

  const onBack = () => {
    if (goToSlide.current) {
      goToSlide.current(slide - 1);
    }
  };

  const onContinue = () => {
    if (goToSlide.current) {
      goToSlide.current(slide + 1);
    }
  };

  const addQueueFolderUntitled = () => {
    addUntitledFolder(createFolder);
  };

  useEffect(() => {
    setMainLoader(false);
  }, []);

  return (
    <div className="app__onboarding">
      <div className="app__steps--indicators">
        <div
          className={`app__steps--indicators-circle ${
            slide >= 0 ? 'active' : ''
          }`}
        />
        <div
          className={`app__steps--indicators-circle ${
            slide >= 1 ? 'active' : ''
          }`}
        />
      </div>
      <Slides
        className="app__steps"
        setRef={({goTo}) => {
          goToSlide.current = goTo;
        }}
        startAt={slide}
        onChange={setSlide}
        autoHeight={false}>
        <div className="app__slide app__steps--tab">
          <div className="app__steps--form text-center">
            <h1 className="mb-4">Create [untitled] folder on desktop?</h1>
            <Button
              className="btn btn-dark mb-3"
              onTap={() => {
                setCreateFolder(true);
                onContinue();
              }}>
              Yes
            </Button>
            <Button
              className="btn btn-outline"
              onTap={() => {
                setCreateFolder(false);
                onContinue();
              }}>
              No
            </Button>
          </div>
        </div>
        <div className="app__slide app__steps--tab">
          <div className="app__steps--form text-center">
            <h1 className="mb-4">Welcome to [untitled]...</h1>
            <Button
              className="btn btn-dark mb-3"
              onTap={() => {
                addQueueFolderUntitled(false);
              }}>
              Finish
            </Button>
            <Button className="btn btn-outline" onTap={onBack}>
              Back
            </Button>
          </div>
        </div>
      </Slides>
      <Button className="app__onboarding--logout" onTap={logoutAuth}>
        Logout
      </Button>
    </div>
  );
};

StepsStarted.defaultProps = {
  logoutAuth: () => {},
  setMainLoader: () => {},
};

StepsStarted.propTypes = {
  logoutAuth: PropTypes.func,
  setMainLoader: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  logoutAuth: authLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepsStarted);
