import React from 'react';

import Reveal from '@untitled/app/core/components/atoms/reveal';
import type {WIP} from '@untitled/app/core/components/templates/share';

import './style.scss';

type Props = {
  wip: WIP;
};

export const InfoHeader: React.FC<Props> = ({wip}) => {
  const [loaded, setLoaded] = React.useState<boolean>(false);

  return (
    <div className="app__info--header">
      <div
        className="app__info--blur"
        style={{backgroundImage: `url(${wip.cover})`}}
      />
      <div className="app__info--header-container">
        <div className="app__info--header-thumb">
          <Reveal
            className={loaded ? 'animated' : ''}
            color="#DBDBDB"
            delay={0}
            duration={0.9}>
            <img
              alt={wip.title}
              onLoad={() => {
                setLoaded(true);
              }}
              src={wip.cover}
              width="100%"
            />
          </Reveal>
        </div>
        <div className="app__info--header-info">
          <h1 className="truncate">{wip.title || 'No title'}</h1>
          <div className="app__info--header-authors">
            <div className="app__miniauthor--name">{wip.authorName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
