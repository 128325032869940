import {
  TOGGLE_THEME,
  AUTH_CHANGED,
  CLAIMS_CHANGED,
  SET_LOADER,
  SET_MODAL,
  HIDE_INSTALL_MESSAGE,
  REMEMBER_ME,
  REMEMBER_ME_EMAIL,
  SELECT_MENU,
  RESET,
  MFA_VERIFIED,
  SET_SHORTCUTS_ENABLED,
} from './constants';

export const toggleTheme = payload => ({
  type: TOGGLE_THEME,
  payload,
});

export const userLogged = payload => ({
  type: AUTH_CHANGED,
  payload,
});

export const setMfaVerified = payload => ({
  type: MFA_VERIFIED,
  payload,
});

export const claimsChanged = payload => ({
  type: CLAIMS_CHANGED,
  payload,
});

export const setLoader = payload => ({
  type: SET_LOADER,
  payload,
});

export const setModal = payload => ({
  type: SET_MODAL,
  payload,
});

export const hideInstallMessage = payload => ({
  type: HIDE_INSTALL_MESSAGE,
  payload,
});

export const setRememberMe = payload => ({
  type: REMEMBER_ME,
  payload,
});

export const setRememberMeEmail = payload => ({
  type: REMEMBER_ME_EMAIL,
  payload,
});

export const setSelectedMenu = payload => ({
  type: SELECT_MENU,
  payload,
});

export const resetCommon = () => ({
  type: RESET,
});

export const setShortcutsEnabled = payload => ({
  type: SET_SHORTCUTS_ENABLED,
  payload,
});
