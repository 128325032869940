import {isElectron} from './helper-useragent';

const defaultFn = () => {};

export const appMounted = isElectron
  ? window.electronApi.appMounted
  : defaultFn;
export const subscribeEvents = isElectron
  ? window.electronApi.subscribeEvents
  : defaultFn;
export const unbscribeEvents = isElectron
  ? window.electronApi.unbscribeEvents
  : defaultFn;
export const saveQueueConfig = isElectron
  ? window.electronApi.saveQueueConfig
  : defaultFn;
export const addQueueFolder = isElectron
  ? window.electronApi.addQueueFolder
  : defaultFn;
export const removeQueueFolder = isElectron
  ? window.electronApi.removeQueueFolder
  : defaultFn;
export const addUntitledFolder = isElectron
  ? window.electronApi.addQueueFolderUntitled
  : defaultFn;

export const handleDialog = isElectron
  ? window.electronApi.handleDialog
  : defaultFn;
