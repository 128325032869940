import React from 'react';
import {toast} from 'react-toastify';
import type {ToastOptions} from 'react-toastify';

const TOAST_DURATION = 3000;
const TOAST_CONF: ToastOptions = {
  autoClose: TOAST_DURATION,
  hideProgressBar: true,
};

interface Props {
  wipId: string;
}

export const CopyPublicLink: React.FC<Props> = ({wipId}) => {
  const linkUrl = `${window.location.origin}/share/wip/${wipId}`;

  return (
    <div className="app__publicwip-container">
      <input className="app__publicwip-linkbox" defaultValue={linkUrl} />
      <button
        className="app__publicwip-copybtn"
        type="button"
        onClick={() => {
          navigator.clipboard
            .writeText(linkUrl)
            .then(() => toast.success('Link copied to clipboard', TOAST_CONF));
        }}>
        Copy
      </button>
    </div>
  );
};
