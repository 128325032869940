import styles from './assets/styles/foundation/_variables.scss';

import assets from './assets';

export const TRANS_SEC = parseFloat(styles.animationMillis);
export const TRANS_MILLI = TRANS_SEC * 1000;
export const TRANS_SLOW = parseFloat(styles.animationSlow) * 1000;
export const TRANS_FAST = parseFloat(styles.animationFast) * 1000;

export const COLORS = {
  primary: styles.primaryColor,
  secondary: styles.secondaryColor,

  light: styles.lightColor,
  dark: styles.darkColor,

  main: styles.mainColor,
  success: styles.successColor,
  danger: styles.dangerColor,
  info: styles.infoColor,
  warning: styles.warningColor,
};

export const SIZES = {
  thumbSize: styles.thumbSize,
  thumbSizeSm: styles.thumbSizeSm,
};

export const IDS = {
  main: {
    scroll: 'MainScroll',
    home: 'HomePage',
    generic: 'GenericPage',
    menu: 'MenuToggle',
  },
};

export const DEFAULT_ARTWORKS = {
  artwork01: {
    author: 'author01',
    created: '2021-01-22T08:00:00.000Z',
    lastEdited: '2021-01-22T08:00:00.000Z',
    uri: assets.featured.artwork01,
  },
};

export const DEFAULT_AUTHORS = {
  author01: {
    created: '2021-01-22T08:00:00.000Z',
    email: 'alanlili@untitledinbrackets.com',
    initials: 'AL',
    lastEdited: '2021-01-22T08:00:00.000Z',
    lastName: '',
    name: 'Alan Lili',
  },
};

export const DEFAULT_AUDIOS = {
  audio01: {
    author: 'author01',
    title: 'Soft Plans',
    created: '2021-01-22T08:00:00.000Z',
    kind: 'audio',
    lastEdited: '2021-01-22T08:00:00.000Z',
    peaks: [1, 1, 1],
    // storageRef: '',
    uri: assets.featured.audio01,
    ext: '.mp3',
  },
  audio01b: {
    author: 'author01',
    title: 'soft plans (teaser) V3',
    created: '2021-01-22T08:00:00.000Z',
    kind: 'audio',
    lastEdited: '2021-01-22T08:00:00.000Z',
    peaks: [1, 1, 1],
    // storageRef: '',
    uri: assets.featured.audios01[0],
    ext: '.mp3',
  },
  audio01c: {
    author: 'author01',
    title: 'soft plans (teaser) V5',
    created: '2021-01-22T08:00:00.000Z',
    kind: 'audio',
    lastEdited: '2021-01-22T08:00:00.000Z',
    peaks: [1, 1, 1],
    // storageRef: '',
    uri: assets.featured.audios01[1],
    ext: '.mp3',
  },
  audio01d: {
    author: 'author01',
    title: 'soft plans (teaser)V9 w master',
    created: '2021-01-22T08:00:00.000Z',
    kind: 'audio',
    lastEdited: '2021-01-22T08:00:00.000Z',
    peaks: [1, 1, 1],
    // storageRef: '',
    uri: assets.featured.audios01[2],
    ext: '.mp3',
  },
};

export const DEFAULT_WIPS = {
  track01: {
    audio: 'audio01',
    audios: ['audio01b', 'audio01c', 'audio01d'],
    author: 'author01',
    coverArt: 'artwork01',
    created: '2021-01-22T08:00:00.000Z',
    denied: [],
    id: 'track01',
    lastEdited: new Date(2021, 0, 22, 0, 0, 0, 0).toISOString(),
    readers: [],
    ready: true,
    title: {created: '2021-01-22T08:00:00.000Z', text: 'Soft Plans (01.22.21)'},
    writers: [],
    disabled: true,
  },
};

export const WIPstatus = {
  archived: 'archived',
};
