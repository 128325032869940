import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import {getNotes, saveNote} from '../../../redux/actions/wips/async';

import './style.scss';
import {debounce} from '../../../helpers/helper-util';

const Notes = ({className, user, wip, disabled}) => {
  const notes = useSelector(state => state.wips.notes || {});
  const currentNotes = useRef(notes[wip.id] || {text: ''});
  const [newNotes, setNewNotes] = useState(currentNotes.current.text);

  const lastSaveRef = useRef(newNotes);
  const submittingRef = useRef(false);
  const unchanged = useRef(true);
  const dispatch = useDispatch();

  const onNotes = (n, showToast = true) => {
    if (submittingRef.current) {
      return;
    }
    submittingRef.current = true;
    saveNote({
      wipId: wip.id,
      authorId: user.uid,
      text: n,
      title: wip.title.text,
    })
      .then(() => {
        console.log('Notes saved!');
        if (showToast) {
          toast.dark('Notes saved!');
        }
      })
      .finally(() => {
        submittingRef.current = false;
        lastSaveRef.current = n;
      });
  };

  const onSave = n => {
    onNotes(n, false);
  };

  const onSaveDebounced = useRef(debounce(onSave, 3000)).current;

  const onBeforeUnload = e => {
    if (lastSaveRef.current !== currentNotes.current.text) {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to close?';
    }
    return e.returnValue;
  };

  useEffect(() => {
    const hasNotes = !!notes[wip.id];
    if (hasNotes && unchanged.current) {
      if (currentNotes.current.lastUpdated) {
        const ad = new Date(notes[wip.id].lastUpdated);
        const bd = new Date(currentNotes.current.lastUpdated);
        const isRecent = hasNotes && ad > bd;
        if (isRecent) {
          setNewNotes(notes[wip.id].text || '');
        }
      } else {
        setNewNotes(notes[wip.id].text || '');
      }
    }
  }, [notes]);

  useEffect(() => {
    if (!unchanged.current) {
      onSaveDebounced(newNotes);
    }
  }, [newNotes]);

  useEffect(() => {
    const unsub = dispatch(getNotes(user.uid));
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      unsub();
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return (
    <div className={`app__notes ${className}`}>
      <textarea
        className="app__notes--input"
        onChange={e => {
          unchanged.current = newNotes.text === e.target.value;
          setNewNotes(e.target.value);
          currentNotes.current.text = e.target.value;
          currentNotes.current.lastUpdated = Date.now();
        }}
        value={newNotes}
        disabled={disabled}
      />
    </div>
  );
};

Notes.defaultProps = {
  className: '',
  user: {},
  wip: {},
  disabled: undefined,
};

Notes.propTypes = {
  className: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any),
  wip: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default Notes;
