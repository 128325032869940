import React, {useEffect, useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';

import Player from '@untitled/app/core/classes/player';
import {throttle} from '@untitled/app/core/helpers/helper-util';
import {isIOS} from '@untitled/app/core/helpers/helper-useragent';
import Analytics from '@untitled/app/core/helpers/helper-analytics';
import {useSelector} from 'react-redux';
import assets from '@untitled/app/core/assets';

const defaultPlayer = {
  player: Player.getInstance(),
  playerState: {playing: false, id: '', index: 0},
  playSong: (id, songs, track, cover) => {},
  setPlayerState: _ => {},
};

export const UPlayerContext = React.createContext(defaultPlayer);

export const UPlayerProvider = ({children}) => {
  const player = useRef(Player.getInstance()).current;
  const [playerState, setPlayerState] = useState(player.status);
  const featuredSelector = useSelector(state => ({
    wips: state.landing.featured,
    shortcutsEnabled: state.common.shortcutsEnabled,
  }));

  const playSong = useCallback(
    (id, songs, track = 0, cover = '', audioId = '') => {
      if (!player.created || id !== player.id) {
        const coverUrl =
          cover || (featuredSelector?.wips?.[id]?.album?.cover ?? assets.thumb);
        player.create(id, songs, track, coverUrl);
        player.play(track, false, audioId);
      } else if (track === player.index) {
        if (player.playing) {
          player.pause();
        } else {
          player.play(track, false, audioId);
        }
      } else {
        player.skipTo(track, audioId);
      }
    },
    [player, featuredSelector],
  );

  const stopPlaying = useCallback(() => {
    player.destroy();
  }, [player]);

  const trackPlayPause = () => {
    if (!player.created) {
      return;
    }
    playSong(player.id, player.playlist, player.index);
  };

  const onPrev = () => {
    if (player.elapsed > 10) {
      player.seek(0);
    } else {
      player.skip('prev');
    }
  };

  const onNext = () => {
    if (player.playlist.length > 1) {
      player.skip('next');
    } else {
      player.seek(0);
    }
  };

  const throttlePlaySong = throttle(trackPlayPause, 100);

  const keyDown = event => {
    if (!player.created) {
      return;
    }
    switch (event.code) {
      case 'Space': {
        const elemType = document.activeElement.nodeName.toLowerCase();
        if (elemType !== 'input' && elemType !== 'textarea') {
          // prevent default spacebar event (scrolling to bottom)
          event.preventDefault();
          throttlePlaySong();
        }
        break;
      }
      case 'ArrowRight': {
        onNext();
        break;
      }
      case 'ArrowLeft': {
        onPrev();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const playerSubscriber = player.addListener(
      'state_changed',
      setPlayerState,
    );
    if (featuredSelector.shortcutsEnabled) {
      document.addEventListener('keydown', keyDown);
    } else {
      document.removeEventListener('keydown', keyDown);
    }

    if (!isIOS && navigator.mediaSession) {
      navigator.mediaSession.setActionHandler('play', () => {
        throttlePlaySong();
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        throttlePlaySong();
      });
      navigator.mediaSession.setActionHandler('stop', () => {
        console.log('stop');
        stopPlaying();
      });
      navigator.mediaSession.setActionHandler('previoustrack', () => {
        onPrev();
      });
      navigator.mediaSession.setActionHandler('nexttrack', () => {
        onNext();
      });
    }

    // unsubscribe on unmount
    return () => {
      playerSubscriber();
      document.removeEventListener('keydown', keyDown);
    };
  }, [featuredSelector.shortcutsEnabled]);

  useEffect(() => {
    const {isPublic} = player.status;
    if (player.playing) {
      Analytics.startListeningAudio(isPublic);
    } else {
      Analytics.stopListeningAudio(isPublic);
    }
  }, [player.playing]);

  const value = React.useMemo(
    () => ({
      player,
      playerState,
      playSong,
      stopPlaying,
      setPlayerState,
    }),
    [playerState],
  );

  return (
    <UPlayerContext.Provider value={value}>{children}</UPlayerContext.Provider>
  );
};

UPlayerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
