/* eslint-disable no-underscore-dangle */

import {toast} from 'react-toastify';

import {audioChanged, audiosChanged, commentsChanged} from '.';
import Analytics from '../../../helpers/helper-analytics';
import firebase, {auth, db} from '../../../helpers/helper-firebase';
import {
  generateActivityFeed,
  generateNotifications,
  sendPushNotification,
} from '../../../helpers/helper-notifications';
import {logErrorMessage} from '../../../helpers/helper-logs';
import {
  ActivityType,
  stageString,
  UntitledInstanceObjects,
  WIPMediaKinds,
} from '../../../helpers/helper-types';
import {parseFirebaseFields} from '../../../helpers/helper-untitled';
import {replaceMentionValues} from '../../../helpers/helper-util';

const displayError = err => {
  // Handle Errors here.
  logErrorMessage(err.message);
  toast.error(err.message, {
    autoClose: 5000,
  });
  return false;
};

export const listenAudios = uid => dispatch =>
  db
    .collection(stageString(WIPMediaKinds.audio))
    .where(
      'readers',
      'array-contains',
      db.collection(stageString(UntitledInstanceObjects.Author)).doc(uid),
    )
    .onSnapshot(querySnapshot => {
      const audios = {};
      querySnapshot.forEach(doc => {
        const data = doc.data();
        const {
          storageRef,
          uri,
          wip,
          created,
          lastEdited,
          wipRef,
          author,
          title,
          fromRecording,
          streamUri,
          isPublic,
        } = data;
        audios[doc.id] = parseFirebaseFields({
          storageRef,
          uri,
          wip,
          created,
          lastEdited,
          wipRef,
          author,
          title,
          fromRecording,
          streamUri,
          isPublic: isPublic || false,
        });
      });
      dispatch(audiosChanged(audios));
    });

export const getAudio = uid => dispatch =>
  db
    .ref(stageString(WIPMediaKinds.audio))
    .doc(uid)
    .get()
    .then(doc => {
      const data = doc.data();
      const audio = parseFirebaseFields(data);
      dispatch(audioChanged(audio));
    })
    .catch(displayError);

export const getComments = audioId => dispatch =>
  db
    .collection(stageString(WIPMediaKinds.audio))
    .doc(audioId)
    .collection(stageString('comments'))
    .orderBy('created', 'asc')
    .onSnapshot(
      sn => {
        console.log('onSnapshot');
        if (!sn.empty || sn.size !== 0) {
          const comments = sn.docs.map(doc => ({
            id: doc.id,
            ...parseFirebaseFields(doc.data()),
          }));
          // .sort((a, b) => a.created.localeCompare(b.created));
          // this.comments[audioId] = comments;
          console.log(comments);

          dispatch(
            commentsChanged({
              audioId,
              comments,
            }),
          );
        } else {
          dispatch(
            commentsChanged({
              audioId,
              comments: [],
            }),
          );
        }
      },
      error => {
        console.warn('Comments onSnapshot error', error);
      },
    );

const mentionRegEx = /((.)\[([^[]*)]\(([^(^)]*)\))/gi;

export const postCommentOnAudio = data => async (_, getState) => {
  const authorId = auth.currentUser && auth.currentUser.uid;
  if (authorId) {
    const {audioId, text, wipId} = data;
    const {wips, authors} = getState();
    const wipList = {...wips.notifications, ...wips.all};
    const wip = wipList[wipId];
    const authorList = authors.users;
    const author = authorList[authorId];
    const isOwner = wip.author === authorId;

    const authorRef = db
      .collection(stageString(UntitledInstanceObjects.Author))
      .doc(authorId);

    const commentRef = db
      .collection(stageString(WIPMediaKinds.audio))
      .doc(audioId)
      .collection(stageString('comments'))
      .doc();

    await commentRef.set({
      author: authorRef, // TODO: replace with authorId
      created: firebase.firestore.Timestamp.now(),
      text: text.trim(),
    });

    const matches = text.matchAll(mentionRegEx);
    const tagged = [...matches].map(m => m[m.length - 1]);

    const subs = [...wip.confirmed, wip.author];

    const subscribers = (isOwner ? wip.confirmed : subs).filter(
      a => a !== authorId,
    );

    const users = subscribers.map(k => authorList[k]);

    const wipRef = db
      .collection(stageString(UntitledInstanceObjects.WIP))
      .doc(wipId);
    const parsed = replaceMentionValues(text, ({name}) => `@${name}`);

    const activityUpdateData = generateActivityFeed({
      wipId,
      audioId,
      authorId,
      kind: ActivityType.newComment,
      subscribers,
      comment: text,
      taggedAuthors: tagged,
    });

    await wipRef
      .collection(stageString(UntitledInstanceObjects.Activity))
      .doc()
      .set(activityUpdateData)
      .then(() => {
        Analytics.logEventComment();
      });

    wipRef.update({lastEdited: firebase.firestore.Timestamp.now()});

    const notifications = generateNotifications({
      type: ActivityType.newComment,
      users,
      author,
      wip,
      audioId,
      comment: parsed,
      taggedAuthors: tagged,
    });

    sendPushNotification(notifications);
    return true;
  }
  return false;
};
