import React from 'react';
import PropTypes from 'prop-types';

export const IconBack = ({size, color}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3L5 12L19 21V3V3Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
IconBack.defaultProps = {
  size: 32,
  color: undefined,
};
IconBack.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
export const IconPlay = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5408 14.1605L33.5408 23.1605C34.1531 23.5541 34.1531 24.4492 33.5408 24.8428L19.5408 33.8428C18.8752 34.2706 18 33.7928 18 33.0016V15.0016C18 14.2105 18.8752 13.7326 19.5408 14.1605Z"
      fill={color}
    />
  </svg>
);
IconPlay.defaultProps = {
  size: 32,
  color: undefined,
};
IconPlay.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconPlayCircle = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11ZM20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11Z"
      fill={color}
    />
    <path d="M8 6V16L16 11L8 6Z" fill={color} />
  </svg>
);
IconPlayCircle.defaultProps = {
  size: 32,
  color: '#ffffff',
};
IconPlayCircle.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconPause = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="M18 15H22C22.5523 15 23 15.4477 23 16V32C23 32.5523 22.5523 33 22 33H18C17.4477 33 17 32.5523 17 32V16C17 15.4477 17.4477 15 18 15Z"
    />
    <path
      fill={color}
      d="M26 15H30C30.5523 15 31 15.4477 31 16V32C31 32.5523 30.5523 33 30 33H26C25.4477 33 25 32.5523 25 32V16C25 15.4477 25.4477 15 26 15Z"
    />
  </svg>
);
IconPause.defaultProps = {
  size: 32,
  color: undefined,
};
IconPause.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconRepeat = ({size}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M28.2929 20.2929C27.9024 20.6834 27.9024 21.3166 28.2929 21.7071C28.6834 22.0976 29.3166 22.0976 29.7071 21.7071L33.7071 17.7071C34.0976 17.3166 34.0976 16.6834 33.7071 16.2929L29.7071 12.2929C29.3166 11.9024 28.6834 11.9024 28.2929 12.2929C27.9024 12.6834 27.9024 13.3166 28.2929 13.7071L31.5858 17L28.2929 20.2929Z" />
    <path d="M16 23V21C16 19.3431 17.3431 18 19 18H33C33.5523 18 34 17.5523 34 17C34 16.4477 33.5523 16 33 16H19C16.2386 16 14 18.2386 14 21V23C14 23.5523 14.4477 24 15 24C15.5523 24 16 23.5523 16 23Z" />
    <path d="M19.7071 27.7071C20.0976 27.3166 20.0976 26.6834 19.7071 26.2929C19.3166 25.9024 18.6834 25.9024 18.2929 26.2929L14.2929 30.2929C13.9024 30.6834 13.9024 31.3166 14.2929 31.7071L18.2929 35.7071C18.6834 36.0976 19.3166 36.0976 19.7071 35.7071C20.0976 35.3166 20.0976 34.6834 19.7071 34.2929L16.4142 31L19.7071 27.7071Z" />
    <path d="M32 25V27C32 28.6569 30.6569 30 29 30H15C14.4477 30 14 30.4477 14 31C14 31.5523 14.4477 32 15 32H29C31.7614 32 34 29.7614 34 27V25C34 24.4477 33.5523 24 33 24C32.4477 24 32 24.4477 32 25Z" />
  </svg>
);
IconRepeat.defaultProps = {
  size: 32,
};
IconRepeat.propTypes = {
  size: PropTypes.number,
};

export const IconShuffle = ({size}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M32 20C32 20.5523 32.4477 21 33 21C33.5523 21 34 20.5523 34 20V15C34 14.4477 33.5523 14 33 14H28C27.4477 14 27 14.4477 27 15C27 15.5523 27.4477 16 28 16H32V20Z" />
    <path d="M16.7071 32.7071L33.7071 15.7071C34.0976 15.3166 34.0976 14.6834 33.7071 14.2929C33.3166 13.9024 32.6834 13.9024 32.2929 14.2929L15.2929 31.2929C14.9024 31.6834 14.9024 32.3166 15.2929 32.7071C15.6834 33.0976 16.3166 33.0976 16.7071 32.7071Z" />
    <path d="M28 32C27.4477 32 27 32.4477 27 33C27 33.5523 27.4477 34 28 34H33C33.5523 34 34 33.5523 34 33V28C34 27.4477 33.5523 27 33 27C32.4477 27 32 27.4477 32 28V32H28Z" />
    <path d="M26.2929 27.7071L32.2929 33.7071C32.6834 34.0976 33.3166 34.0976 33.7071 33.7071C34.0976 33.3166 34.0976 32.6834 33.7071 32.2929L27.7071 26.2929C27.3166 25.9024 26.6834 25.9024 26.2929 26.2929C25.9024 26.6834 25.9024 27.3166 26.2929 27.7071Z" />
    <path d="M15.2929 16.7071L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.7071 15.2929C16.3166 14.9024 15.6834 14.9024 15.2929 15.2929C14.9024 15.6834 14.9024 16.3166 15.2929 16.7071Z" />
  </svg>
);
IconShuffle.defaultProps = {
  size: 32,
};
IconShuffle.propTypes = {
  size: PropTypes.number,
};

export const IconNext = ({size}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6247 15.2191L27.6247 23.2191C28.1251 23.6194 28.1251 24.3805 27.6247 24.7808L17.6247 32.7808C16.9699 33.3046 16 32.8384 16 31.9999V15.9999C16 15.1614 16.9699 14.6952 17.6247 15.2191Z" />
    <path d="M30 17V31C30 31.5523 30.4477 32 31 32C31.5523 32 32 31.5523 32 31V17C32 16.4477 31.5523 16 31 16C30.4477 16 30 16.4477 30 17Z" />
  </svg>
);
IconNext.defaultProps = {
  size: 32,
};
IconNext.propTypes = {
  size: PropTypes.number,
};

export const IconPrev = ({size}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M30.3753 32.7808L20.3753 24.7808C19.8749 24.3805 19.8749 23.6194 20.3753 23.2191L30.3753 15.2191C31.0301 14.6952 32 15.1614 32 15.9999V31.9999C32 32.8384 31.0301 33.3046 30.3753 32.7808Z" />
    <path d="M18 31V17C18 16.4477 17.5523 16 17 16C16.4477 16 16 16.4477 16 17V31C16 31.5523 16.4477 32 17 32C17.5523 32 18 31.5523 18 31Z" />
  </svg>
);
IconPrev.defaultProps = {
  size: 32,
};
IconPrev.propTypes = {
  size: PropTypes.number,
};

export const IconMute = ({size}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6492 19.9999L21.3753 16.2191C22.0301 15.6952 23 16.1614 23 16.9999V30.9999C23 31.8384 22.0301 32.3046 21.3753 31.7808L16.6492 27.9999H13C12.4477 27.9999 12 27.5522 12 26.9999V20.9999C12 20.4476 12.4477 19.9999 13 19.9999H16.6492Z" />
    <path d="M33.2929 20.2929L27.2929 26.2929C26.9024 26.6834 26.9024 27.3166 27.2929 27.7071C27.6834 28.0976 28.3166 28.0976 28.7071 27.7071L34.7071 21.7071C35.0976 21.3166 35.0976 20.6834 34.7071 20.2929C34.3166 19.9024 33.6834 19.9024 33.2929 20.2929Z" />
    <path d="M27.2929 21.7071L33.2929 27.7071C33.6834 28.0976 34.3166 28.0976 34.7071 27.7071C35.0976 27.3166 35.0976 26.6834 34.7071 26.2929L28.7071 20.2929C28.3166 19.9024 27.6834 19.9024 27.2929 20.2929C26.9024 20.6834 26.9024 21.3166 27.2929 21.7071Z" />
  </svg>
);
IconMute.defaultProps = {
  size: 32,
};
IconMute.propTypes = {
  size: PropTypes.number,
};

export const IconVolumeMax = ({size}) => (
  <svg
    width={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M6.20647 7.17331H0.340271V17.1609H6.20647L14.3715 23.6672V0.666992L6.20647 7.17331Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8179 6.82227L19.2734 7.71304C19.9603 9.05638 20.3851 10.5168 20.3851 12.1675C20.3851 13.802 19.9421 15.2925 19.2764 16.6161L18.8269 17.5099L17.0354 16.6129L17.4849 15.7191C18.0394 14.6163 18.3806 13.4319 18.3806 12.1675C18.3806 10.8795 18.0538 9.72866 17.4879 8.62198L17.0324 7.73121L18.8179 6.82227Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7479 3.7998L22.2527 4.66368C23.5672 6.91302 24.3936 8.9427 24.3936 12.1675C24.3936 15.3972 23.5312 17.4449 22.2566 19.6647L21.7583 20.5324L20.0191 19.5381L20.5173 18.6704C21.6831 16.6402 22.3892 14.9378 22.3892 12.1675C22.3892 9.39218 21.7122 7.70932 20.5211 5.67112L20.0163 4.80724L21.7479 3.7998Z"
    />
  </svg>
);
IconVolumeMax.defaultProps = {
  size: 24,
};
IconVolumeMax.propTypes = {
  size: PropTypes.number,
};

export const IconPlus = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M23 16V32H24H25V16H24H23Z" fill={color} />
    <path d="M16 25H32V24V23H16V24V25Z" fill={color} />
  </svg>
);
IconPlus.defaultProps = {
  size: 32,
  color: 'black',
};
IconPlus.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconUser = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33 33V31C33 28.2386 30.7614 26 28 26H20C17.2386 26 15 28.2386 15 31V33C15 33.5523 15.4477 34 16 34C16.5523 34 17 33.5523 17 33V31C17 29.3431 18.3431 28 20 28H28C29.6569 28 31 29.3431 31 31V33C31 33.5523 31.4477 34 32 34C32.5523 34 33 33.5523 33 33Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 19C19 21.7614 21.2386 24 24 24C26.7614 24 29 21.7614 29 19C29 16.2386 26.7614 14 24 14C21.2386 14 19 16.2386 19 19ZM27 19C27 20.6569 25.6569 22 24 22C22.3431 22 21 20.6569 21 19C21 17.3431 22.3431 16 24 16C25.6569 16 27 17.3431 27 19Z"
      fill={color}
    />
  </svg>
);
IconUser.defaultProps = {
  size: 32,
  color: 'black',
};
IconUser.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconBell = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 30C12.6667 30 12.6667 28 14 28C15.1046 28 16 27.1046 16 26V21C16 16.5817 19.5817 13 24 13C28.4183 13 32 16.5817 32 21V26C32 27.1046 32.8954 28 34 28C35.3333 28 35.3333 30 34 30H14ZM30 26V21C30 17.6863 27.3137 15 24 15C20.6863 15 18 17.6863 18 21V26C18 26.7286 17.8052 27.4117 17.4649 28H30.5351C30.1948 27.4117 30 26.7286 30 26ZM24 34.9965C25.0696 34.9965 26.0583 34.427 26.595 33.5018C26.9817 32.8351 26.5007 32 25.73 32H22.27C21.4993 32 21.0183 32.8351 21.405 33.5018C21.9417 34.427 22.9304 34.9965 24 34.9965Z"
      fill={color}
    />
  </svg>
);
IconBell.defaultProps = {
  size: 32,
  color: 'black',
};
IconBell.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconTrash = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 5H17V4C17 2.3 15.7 1 14 1H10C8.3 1 7 2.3 7 4V5H3C2.4 5 2 5.4 2 6C2 6.6 2.4 7 3 7H4V20C4 21.7 5.3 23 7 23H17C18.7 23 20 21.7 20 20V7H21C21.6 7 22 6.6 22 6C22 5.4 21.6 5 21 5ZM9 4C9 3.4 9.4 3 10 3H14C14.6 3 15 3.4 15 4V5H9V4ZM17 21C17.6 21 18 20.6 18 20V7H6V20C6 20.6 6.4 21 7 21H17ZM11 11V17C11 17.6 10.6 18 10 18C9.4 18 9 17.6 9 17V11C9 10.4 9.4 10 10 10C10.6 10 11 10.4 11 11ZM15 17V11C15 10.4 14.6 10 14 10C13.4 10 13 10.4 13 11V17C13 17.6 13.4 18 14 18C14.6 18 15 17.6 15 17Z"
      fill={color}
    />
  </svg>
);
IconTrash.defaultProps = {
  size: 32,
  color: 'black',
};
IconTrash.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconDownload = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6 16.9C11.5 16.9 11.4 16.8 11.3 16.7L7.3 12.7C6.9 12.3 6.9 11.7 7.3 11.3C7.7 10.9 8.3 10.9 8.7 11.3L11 13.6V2C11 1.4 11.4 1 12 1C12.6 1 13 1.4 13 2V13.6L15.3 11.3C15.7 10.9 16.3 10.9 16.7 11.3C17.1 11.7 17.1 12.3 16.7 12.7L12.7 16.7C12.65 16.75 12.6 16.775 12.55 16.8C12.5 16.825 12.45 16.85 12.4 16.9C12.3 17 12.1 17 12 17C11.9 17 11.7 17 11.6 16.9ZM22 20V17C22 16.4 21.6 16 21 16C20.4 16 20 16.4 20 17V20C20 20.6 19.6 21 19 21H5C4.4 21 4 20.6 4 20V17C4 16.4 3.6 16 3 16C2.4 16 2 16.4 2 17V20C2 21.7 3.3 23 5 23H19C20.7 23 22 21.7 22 20Z"
      fill={color}
    />
  </svg>
);
IconDownload.defaultProps = {
  size: 32,
  color: 'black',
};
IconDownload.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconUsers = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.01594 7C4.01594 4.2 6.22471 2 9.03588 2C11.847 2 14.0558 4.2 14.0558 7C14.0558 9.8 11.847 12 9.03588 12C6.22471 12 4.01594 9.8 4.01594 7ZM18.0718 19V21C18.0718 21.6 17.6702 22 17.0678 22C16.4654 22 16.0638 21.6 16.0638 21V19C16.0638 17.3 14.7586 16 13.0518 16H5.01993C3.31315 16 2.00797 17.3 2.00797 19V21C2.00797 21.6 1.60638 22 1.00399 22C0.401594 22 0 21.6 0 21V19C0 16.2 2.20877 14 5.01993 14H13.0518C15.863 14 18.0718 16.2 18.0718 19ZM9.03588 10C7.3291 10 6.02392 8.7 6.02392 7C6.02392 5.3 7.3291 4 9.03588 4C10.7427 4 12.0478 5.3 12.0478 7C12.0478 8.7 10.7427 10 9.03588 10ZM20.2805 14.2C19.7785 14.1 19.1761 14.4 19.0757 14.9C18.9753 15.4 19.2765 16 19.7785 16.1C21.0837 16.4 21.9873 17.6 21.9873 19V21C21.9873 21.6 22.3889 22 22.9913 22C23.5937 22 23.9953 21.6 23.9953 21V19C24.0957 16.7 22.5897 14.7 20.2805 14.2ZM15.0598 2.9C15.2606 2.3 15.7626 2 16.2646 2.2C18.9753 2.8 20.5817 5.6 19.8789 8.4C19.4773 10.2 18.0718 11.5 16.2646 12H16.0638C15.5618 12 15.1602 11.7 15.0598 11.2C14.859 10.7 15.2606 10.1 15.7626 10C16.867 9.7 17.6702 8.9 17.9714 7.8C18.3729 6.1 17.369 4.5 15.7626 4.1C15.2606 4 14.9594 3.4 15.0598 2.9Z"
      fill={color}
    />
  </svg>
);
IconUsers.defaultProps = {
  size: 32,
  color: 'black',
};
IconUsers.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconX = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7 17.3C19.1 17.7 19.1 18.3 18.7 18.7C18.5 18.9 18.3 19 18 19C17.7 19 17.5 18.9 17.3 18.7L12 13.4L6.7 18.7C6.5 18.9 6.3 19 6 19C5.7 19 5.5 18.9 5.3 18.7C4.9 18.3 4.9 17.7 5.3 17.3L10.6 12L5.3 6.7C4.9 6.3 4.9 5.7 5.3 5.3C5.7 4.9 6.3 4.9 6.7 5.3L12 10.6L17.3 5.3C17.7 4.9 18.3 4.9 18.7 5.3C19.1 5.7 19.1 6.3 18.7 6.7L13.4 12L18.7 17.3Z"
      fill={color}
    />
  </svg>
);
IconX.defaultProps = {
  size: 32,
  color: 'black',
};
IconX.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconFolder = ({size, color}) => (
  <svg
    width={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 5H20C21.7 5 23 6.3 23 8V19C23 20.7 21.7 22 20 22H4C2.3 22 1 20.7 1 19V5C1 3.3 2.3 2 4 2H9C9.3 2 9.6 2.2 9.8 2.4L11.5 5ZM20 20C20.6 20 21 19.6 21 19V8C21 7.4 20.6 7 20 7H11C10.7 7 10.4 6.8 10.2 6.6L8.5 4H4C3.4 4 3 4.4 3 5V19C3 19.6 3.4 20 4 20H20Z"
      fill={color}
    />
  </svg>
);
IconFolder.defaultProps = {
  size: 32,
  color: 'black',
};
IconFolder.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconPin = ({size, color}) => (
  <svg height={size} width={size} viewBox="0 0 36 36">
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M35.6912366,8.6432639 C36.5918829,7.74444267 35.4557465,5.14819239 33.1473239,2.84431729 C30.875051,0.571436029 28.3177112,-0.565004602 27.3891779,0.278027721 L27.3716194,0.260464547 L14.6128074,11.7188855 C12.2437539,9.64223552 8.67155617,9.75556149 6.43882224,11.9782006 C5.65137759,12.7671104 5.65137759,14.0448561 6.43882224,14.833766 L12.2279537,20.6192819 L0.332605388,34.0571758 C-0.111472797,34.5022288 -0.110779149,35.2231084 0.334154694,35.6673055 C0.779088537,36.1115026 1.49977519,36.1108088 1.94385341,35.6657559 L15.3533618,23.7537918 L15.9286599,24.329244 L18.9105016,27.3118841 L21.1393947,29.5403409 C21.5175526,29.918999 22.0306842,30.1317606 22.5657624,30.1317606 C23.1008405,30.1317606 23.6139721,29.918999 23.99213,29.5403409 C26.2135361,27.3071417 26.3268224,23.7347318 24.2513757,21.3652002 L35.6509554,8.66702584 C35.6623168,8.65669456 35.6809081,8.6546283 35.6912366,8.6432639 Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
IconPin.defaultProps = {
  size: 32,
  color: 'black',
};
IconPin.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export const IconLink = ({size, color}) => (
  <svg
    height={size}
    width={size}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.01306 0C2.24076 0 1.46635 0.294108 0.880249 0.880208C-0.291952 2.05241 -0.291952 3.97363 0.880249 5.14583L2.86072 7.1263C3.07019 7.34212 3.41508 7.34424 3.6309 7.13477C3.84672 6.92529 3.84884 6.5804 3.63936 6.36458L1.64197 4.36719C0.884481 3.6097 0.884481 2.39941 1.64197 1.64193C2.39946 0.88444 3.60974 0.88444 4.36723 1.64193L7.02478 4.29948C7.78227 5.05697 7.78227 6.26725 7.02478 7.02474C6.93591 7.11361 6.83647 7.17708 6.73702 7.24479C6.48946 7.41406 6.42387 7.75049 6.59314 7.99805C6.76241 8.24561 7.09884 8.3112 7.34639 8.14193C7.50085 8.03613 7.65108 7.92188 7.7865 7.78646C8.9587 6.61426 8.9587 4.69303 7.7865 3.52083L5.14587 0.880208C4.55977 0.294108 3.78536 0 3.01306 0ZM5.83988 4.80729C5.81661 4.81152 5.79333 4.81787 5.77218 4.82422C5.69177 4.84115 5.61772 4.875 5.55212 4.92578C5.43787 5.01253 5.31938 5.10775 5.21358 5.21354C4.04138 6.38574 4.04138 8.30697 5.21358 9.47917L7.85421 12.1198C9.02641 13.292 10.9476 13.292 12.1198 12.1198C13.292 10.9476 13.292 9.02637 12.1198 7.85417L10.1394 5.89063C10.0082 5.72982 9.79871 5.65576 9.59558 5.70231C9.39457 5.74886 9.23588 5.90755 9.18933 6.10856C9.14278 6.31169 9.21684 6.52116 9.37764 6.65234L11.3581 8.63281C12.1156 9.3903 12.1156 10.6006 11.3581 11.3581C10.6006 12.1156 9.39034 12.1156 8.63285 11.3581L5.9753 8.70052C5.21781 7.94304 5.21781 6.73275 5.9753 5.97526C6.04089 5.90755 6.1213 5.861 6.19535 5.80599C6.41329 5.67269 6.5085 5.40397 6.42175 5.16276C6.33712 4.92155 6.09379 4.77344 5.83988 4.80729Z"
      fill={color}
    />
  </svg>
);
IconLink.defaultProps = {
  size: 32,
  color: 'black',
};
IconLink.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
