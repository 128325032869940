import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import UploadIcon from 'react-feather/dist/icons/upload';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import qs from 'query-string';

import InfoWip from '../../organisms/info/info-wip';
import Dropzone from '../../atoms/dropzone';
import HocQueue from '../../hoc/hoc-queue';
import {setLoader} from '../../../redux/actions/common';
import usePlayer from '../../hooks/usePlayer';
import useMenu from '../../hooks/useMenu';
import {deleteWipAudio} from '../../../redux/actions/wips/async';
import {postCommentOnAudio} from '../../../redux/actions/audios/async';
import Menu from '../../organisms/menu';
import MenuHeader from '../../organisms/menu/menu-header';
import UserProfile from '../../organisms/user/user-profile-sm';
// import UploadWip from '../../organisms/upload/upload-wip';
import {UploadContext} from '../../context/upload-context';

import {mergeWIPData} from '../../../helpers/helper-untitled';
import Header from '../../organisms/header';
import './style.scss';
import Scroller from '../../atoms/scroller';
import Slides from '../../molecules/slides';
import {bulkUploadAudios} from '../../../redux/actions/wips/async-bulk';
import {activityOpen} from '@untitled/app/core/redux/actions/activity';

// TODO: add window on close alert when uploading
// TODO: check why is necesary slides for playbar to show properly

const Details = ({
  user,
  wips,
  audios,
  setMainLoader,
  authors,
  pins,
  onDeleteAudio,
  commentOnAudio,
  onBulkAudios,
  wipInvites,
  activityOpen,
}) => {
  const history = useHistory();
  const {uploads, audiosProgress, audiosNames, removeUploadedAudio} =
    useContext(UploadContext);
  const {search} = history.location;
  const query = qs.parse(search, {ignoreQueryPrefix: true});
  const queryWip = query.wip || '';

  const selectedWip = wips[queryWip] || uploads[queryWip] || {};
  const currentAuthor = authors[user.uid];

  const uploadsProgress = {};
  if (selectedWip.id && audiosProgress[selectedWip.id]) {
    Object.keys(audiosProgress[selectedWip.id]).forEach(k => {
      uploadsProgress[k] = {
        progress: audiosProgress[selectedWip.id][k],
        name: audiosNames[selectedWip.id][k],
      };
    });
  }

  const [selectedAudio, setSelectedAudio] = useState({});
  const [audioSearch, setAudioSearch] = useState({query: ''});
  const [soundFiles, setSoundFiles] = useState();
  const {onPlayTrack} = usePlayer();

  const {selectedMenu, setSelectedMenu, onAuthor} = useMenu();

  const onSearch = e => {
    setAudioSearch({...audioSearch, query: e.target.value});
  };

  const {confirmed = [], writers = [], author: wipAuthor} = selectedWip || {};

  const isOwner = wipAuthor === user.uid;
  const canEdit =
    isOwner ||
    (confirmed.indexOf(user.uid) !== -1 && writers.indexOf(user.uid) !== -1);

  const onCloseMenu = () => {
    setSoundFiles();
    setSelectedMenu('');
  };

  const uploadToWip = async wipId => {
    const newWip = {
      wipId,
      soundFiles,
    };
    console.log(newWip, currentAuthor);
    // TODO: disable drag and drop while uploading
    onBulkAudios(currentAuthor, newWip);
  };

  useEffect(() => {
    if (soundFiles) {
      if (selectedWip) {
        uploadToWip(selectedWip.id);
      } else {
        setSelectedMenu('upload');
      }
    }
  }, [soundFiles]);

  useEffect(() => {
    const q = qs.parse(search, {ignoreQueryPrefix: true});
    if (q.audio && audios[q.audio]) {
      setSelectedAudio({
        ...audios[q.audio],
        id: q.audio,
      });
      history.replace(`/details?wip=${q.wip}`);
    }
  }, [search]);

  useEffect(() => {
    if (wipInvites[queryWip]) {
      activityOpen(true);
      history.replace('/home');
    } else if (!selectedWip || Object.keys(selectedWip || {}).length === 0) {
      history.replace('/home');
    }
  }, [wipInvites, queryWip, selectedWip]);

  useEffect(() => {
    setMainLoader(false);
  }, []);

  return (
    <div id="DetailsPage" className="app__page app__content">
      <div className="app__home">
        <Header
          slide={2}
          onTab={t => {
            history.push({
              pathname: '/home',
              state: {tab: t},
            });
          }}
          search={audioSearch}
          onSearch={onSearch}
        />

        <Slides className="app__homebody" autoHeight={false}>
          <Scroller className="app__homebody--tab scrollable">
            <InfoWip
              user={user}
              selectedWip={selectedWip}
              onAuthor={onAuthor}
              authors={authors}
              onBack={() => {
                history.push('home');
              }}
              onPlay={onPlayTrack}
              onDeleteAudio={onDeleteAudio}
              commentOnAudio={commentOnAudio}
              selectedAudio={selectedAudio}
              setSelectedAudio={setSelectedAudio}
              audiosProgress={audiosProgress[selectedWip.id] || {}}
              pins={pins}
              // onDeleted={() => { uploadManager.removeUpload(selectedWip.id); }}
              audioSearch={audioSearch}
              uploadsProgress={uploadsProgress}
              removeUploadedAudio={removeUploadedAudio}
            />
          </Scroller>
        </Slides>

        {canEdit && (
          <Dropzone onFiles={setSoundFiles} multiple>
            <div className="app__dropzone--container">
              <div className="app__dropzone--row m-1">
                <UploadIcon />
              </div>
              <div className="app__dropzone--row">
                <h3>Drop audio here</h3>
              </div>
            </div>
          </Dropzone>
        )}

        {/* <Menu menuOpened={selectedMenu === 'upload' && !!soundFiles} onClose={onCloseMenu}>
          <MenuHeader title="Save To" onClose={onCloseMenu} />
          <UploadWip soundFiles={soundFiles} onDone={onCloseMenu} selectedWip={selectedWip} />
        </Menu> */}
        <Menu menuOpened={selectedMenu === 'profile'} onClose={onCloseMenu}>
          <MenuHeader title="Profile" onClose={onCloseMenu} />
          <UserProfile />
        </Menu>
      </div>
    </div>
  );
};

Details.defaultProps = {
  user: {},
  wips: {},
  audios: {},
  setMainLoader: () => {},
  authors: {},
  onDeleteAudio: () => {},
  commentOnAudio: () => {},
  onBulkAudios: () => {},
  pins: [],
  wipInvites: {},
};

Details.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  wips: PropTypes.objectOf(PropTypes.any),
  audios: PropTypes.objectOf(PropTypes.any),
  setMainLoader: PropTypes.func,
  authors: PropTypes.objectOf(PropTypes.any),
  onDeleteAudio: PropTypes.func,
  commentOnAudio: PropTypes.func,
  onBulkAudios: PropTypes.func,
  pins: PropTypes.arrayOf(PropTypes.any),
  wipInvites: PropTypes.any,
  activityOpen: PropTypes.func,
};

const mapStateToProps = ({common, audios, authors, queue, wips}) => ({
  user: common.user, // Needed for hocQueue
  userData: queue.userData, // Needed for hocQueue
  wips: mergeWIPData(wips.all, audios.all, authors.users),
  authors: authors.users,
  pins: wips.pins,
  audios: audios.all,
  wipInvites: wips.notifications || [],
});

const mapDispatchToProps = {
  setMainLoader: setLoader,
  onDeleteAudio: deleteWipAudio,
  commentOnAudio: postCommentOnAudio,
  onBulkAudios: bulkUploadAudios,
  activityOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(HocQueue(Details));
