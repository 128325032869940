import {
  AUDIO_CHANGED,
  AUDIOS_CHANGED,
  AUDIO_RESET,
  AUDIO_SELECT,
  AUDIO_REMOVE,
  COMMENTS_CHANGED,
} from '../actions/audios/constants';

const defaultState = {
  all: {},
  comments: {},
  audiosUpdate: Date.now(),
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUDIO_CHANGED: {
      return {
        ...state,
        all: {
          ...state.all,
          ...action.payload,
        },
        audiosUpdate: Date.now(),
      };
    }

    case AUDIOS_CHANGED: {
      const all = action.payload || {};
      return {
        ...state,
        all,
        audiosUpdate: Date.now(),
      };
    }

    case AUDIO_REMOVE: {
      const {all} = state;
      delete all[action.payload];
      return {
        ...state,
        all,
      };
    }

    case AUDIO_SELECT: {
      const selectedAudio = action.payload || {};
      return {
        ...state,
        selectedAudio,
      };
    }

    case COMMENTS_CHANGED: {
      const {audioId, comments} = action.payload || {};
      return {
        ...state,
        comments: {
          ...state.comments,
          [audioId]: comments,
        },
      };
    }

    case AUDIO_RESET: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
