import '@untitled/app/core/components/templates/home/style.scss';

import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import UploadIcon from 'react-feather/dist/icons/upload';

import {UPlayerContext} from '@untitled/app/core/components/context/player-context';
import Scroller from '@untitled/app/core/components/atoms/scroller';
import Slides from '@untitled/app/core/components/molecules/slides';
import Grid from '@untitled/app/core/components/molecules/grid';
import assets from '@untitled/app/core/assets';
import FileExplorer from '@untitled/app/core/components/organisms/fileexplorer';
import {MenuContext} from '@untitled/app/core/components/context/menu-context';
import {
  setSelectedQueue,
  setSelectedQueueWip,
} from '@untitled/app/core/redux/actions/queue';
import Dropzone from '@untitled/app/core/components/atoms/dropzone';
import {deleteWipAudio} from '@untitled/app/core/redux/actions/wips/async';
import {
  addQueueFolder,
  handleDialog,
  removeQueueFolder,
} from '@untitled/app/core/helpers/helper-electron-api';
import {isElectron} from '@untitled/app/core/helpers/helper-useragent';
import Analytics from '@untitled/app/core/helpers/helper-analytics';
import {getSafeRegexString} from '@untitled/app/core/helpers/helper-util';
import {postCommentOnAudio} from '@untitled/app/core/redux/actions/audios/async';
import {activityOpen} from '@untitled/app/core/redux/actions/activity';
import {UploadContext} from '@untitled/app/core/components/context/upload-context';
import usePlayer from '@untitled/app/core/components/hooks/usePlayer';
import useMenu from '@untitled/app/core/components/hooks/useMenu';
import Header from '@untitled/app/core/components/organisms/header';
import {logErrorMessage} from '@untitled/app/core/helpers/helper-logs';
import {db} from '@untitled/app/core/helpers/helper-firebase';
import {wipsChanged} from '@untitled/app/core/redux/actions/wips';

const defaultArr = [];

const HomeBody = ({
  wips,
  pins,
  listens,
  wipJoins,
  authors,
  userData,
  machineData,
  setSoundFiles,
  setQueue,
  setQueueWip,
  goToSlide,
  setGotToRef,
  setSelectedAudio,
  openActivitiesUI,
  activityOpen,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {tab = 0} = history.location.state || {};
  const {setSelectedMenu} = useContext(MenuContext);
  const {playerState, playSong} = useContext(UPlayerContext);
  const {uploads, audiosProgress} = useContext(UploadContext);
  const [slide, setSlide] = useState(tab);
  const [search, setSearch] = useState({query: ''});
  const [menu, setMenu] = useState([]);
  const [results, setResults] = useState([]);
  const goToPanel = useRef();
  const preSelectedHeaderOption = openActivitiesUI ? 'activity' : '';

  const {onAccount, onAuthor, onBubble} = useMenu();

  const {filesTree = defaultArr, filesMap = defaultArr} = machineData;
  const {author} = userData;

  const {onPlay} = usePlayer();

  const onTab = t => {
    if (goToSlide) {
      setSelectedAudio();
      goToSlide(t);
    }
    if (t === 1 && goToPanel.current) {
      goToPanel.current('<root>');
    }
    if (t === 1) {
      Analytics.logEventViewDesktopTab();
    }
  };

  const onSelect = wipUid => {
    if (goToSlide) {
      Analytics.logEventViewWIP();
      // TODO: make types for internal URLS
      history.push(`/details?wip=${wipUid}`);
    }
  };

  const onFileClick = useCallback(
    item => {
      console.log(item);
      const uri = item.url || item.uri;
      const route = isElectron ? `file://${uri}` : uri;
      const name = isElectron ? uri.split('/').pop().split('.')[0] : item.name;
      const authorName = authors[author] ? authors[author].name : '';
      const username = isElectron ? authorName : authors[item.author].name;

      playSong(
        route,
        [
          {
            id: uri,
            title: name,
            coverURL: assets.thumb,
            authorName: username,
            uri: route,
            ext: item.ext,
            noCache: isElectron,
          },
        ],
        0,
      );
    },
    [authors],
  );

  const onAdd = useCallback(file => {
    console.log(file);
    setQueueWip();
    setQueue(file);
    setSelectedMenu('saveQueue');
  }, []);

  const setRef = useCallback(({setSelected}) => {
    goToPanel.current = setSelected;
  }, []);

  const onSearch = e => {
    setSearch({...search, query: e.target.value});
  };

  const setSoundFolder = folders => {
    addQueueFolder(folders[0].path);
  };

  const onError = (msg = '') => {
    logErrorMessage(msg);
  };

  const fileExplorerActions = useRef(
    !isElectron
      ? []
      : [
          {
            key: 'DeleteQueue',
            label: 'Delete From Queue',
            icon: 'Delete',
            onClick: item => {
              removeQueueFolder(item.url);
            },
          },
        ],
  ).current;

  const electronAction = useRef({
    type: 'action',
    action: () => {
      handleDialog(({value}) => {
        console.log('handleDialog', value);
        if (value) {
          addQueueFolder(value);
        }
      });
    },

    items: [],
    key: 'addNewFolder',
    name: 'Add New Folder',
    route: '#',
    url: '#',
  }).current;

  useEffect(() => {
    if (isElectron) {
      filesTree.push(electronAction);
    }
    setMenu(filesTree);
  }, [filesTree]);

  useEffect(() => {
    if (search.query.length > 0) {
      const term = getSafeRegexString(search.query);
      const newMenu = filesMap
        .map(m => ({...m, items: []}))
        .filter(ar => new RegExp(term, 'gi').test(ar.name || ar.title));

      setResults([
        ...newMenu,
        {
          type: 'action',
          action: () => {},

          items: [],
          key: 'searchResults',
          name: 'Search Results',
          route: '#',
          url: '#',
          renderLabel: () => <span>{newMenu.length}</span>,
        },
      ]);
    }
  }, [filesMap, search]);

  useEffect(() => {
    if (openActivitiesUI) {
      activityOpen(false);
    }

    const keys = Object.keys(wips || {});
    const wipRefs = keys.map(key =>
      db.collection('developmentWIP').doc(key).get(),
    );
    Promise.all(wipRefs)
      .then(docs => {
        const toKeep = docs.filter(doc => doc.exists).map(doc => doc.id);
        const toRemove = keys
          .filter(key => toKeep.indexOf(key) === -1)
          .reduce((acc, id) => ({...acc, [id]: undefined}), {});
        if (Object.keys(toRemove).length > 0) {
          dispatch(wipsChanged(toRemove));
        }
      })
      .catch(err => console.error('Error when fetching docs.', err));
  }, []);

  return (
    <div className="app__home">
      <Header
        slide={slide}
        onTab={onTab}
        search={search}
        onSearch={onSearch}
        onBubble={onBubble}
        onAccount={onAccount}
        preSelectedOption={preSelectedHeaderOption}
      />
      <Slides
        className="app__homebody"
        setRef={setGotToRef}
        onChange={setSlide}
        startAt={slide}
        autoHeight={false}>
        <Scroller className="app__slide app__homebody--tab scrollable">
          <Grid
            wips={{...uploads, ...wips}}
            authors={authors}
            onSelect={onSelect}
            onPlay={onPlay}
            onAuthor={onAuthor}
            onAdd={setSoundFiles}
            playerState={playerState}
            exclude={w => w.disabled}
            pins={pins}
            listens={listens}
            wipJoins={wipJoins}
            search={search}
            uploadsProgress={audiosProgress}
            onError={onError}
          />

          <Dropzone onFiles={setSoundFiles} multiple>
            <div className="app__dropzone--container">
              <div className="app__dropzone--row m-1">
                <UploadIcon />
              </div>
              <div className="app__dropzone--row">
                <h3>Drop audio here</h3>
              </div>
            </div>
          </Dropzone>
        </Scroller>
        <Scroller className="app__slide app__steps--tab scrollable">
          <FileExplorer
            name="explorerTree"
            onFileClick={onFileClick}
            onAdd={onAdd}
            menu={search.query.length > 0 ? results : menu}
            actions={fileExplorerActions}
            setRef={setRef}
            search={search}
            changed={slide}
          />

          {isElectron && (
            <Dropzone onFolders={setSoundFolder}>
              <div className="app__dropzone--container">
                <div className="app__dropzone--row m-1">
                  <UploadIcon />
                </div>
                <div className="app__dropzone--row">
                  <h3>Drop folder here</h3>
                </div>
              </div>
            </Dropzone>
          )}
        </Scroller>
      </Slides>
    </div>
  );
};

HomeBody.defaultProps = {
  wips: {},
  machineData: {},
  userData: {},
  pins: [],
  listens: {},
  wipJoins: {},
  authors: {},
  setSoundFiles: () => {},
  setQueue: () => {},
  setQueueWip: () => {},
  setGotToRef: () => {},
  goToSlide: () => {},
  setSelectedAudio: () => {},
  openActivitiesUI: false,
};

HomeBody.propTypes = {
  wips: PropTypes.objectOf(PropTypes.any),
  machineData: PropTypes.objectOf(PropTypes.any),
  userData: PropTypes.objectOf(PropTypes.any),
  pins: PropTypes.arrayOf(PropTypes.any),
  listens: PropTypes.objectOf(PropTypes.any),
  wipJoins: PropTypes.objectOf(PropTypes.any),
  authors: PropTypes.objectOf(PropTypes.any),
  setSoundFiles: PropTypes.func,
  setQueue: PropTypes.func,
  setQueueWip: PropTypes.func,
  setGotToRef: PropTypes.func,
  goToSlide: PropTypes.func,
  setSelectedAudio: PropTypes.func,
  openActivitiesUI: PropTypes.bool,
  activityOpen: PropTypes.func,
};

const mapStateToProps = ({authors, wips, audios, queue, activity}) => ({
  machineData: queue.machineData,
  userData: queue.userData,
  wips: wips.all,
  authors: authors.users,
  audios: audios.all,
  pins: wips.pins,
  listens: wips.listens,
  wipJoins: wips.wipJoins,
  openActivitiesUI: activity.openActivitiesUI,
});

const mapDispatchToProps = {
  setQueue: setSelectedQueue,
  setQueueWip: setSelectedQueueWip,
  onDeleteAudio: deleteWipAudio,
  commentOnAudio: postCommentOnAudio,
  activityOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeBody);
