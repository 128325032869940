import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import Preview from '@untitled/app/core/components/molecules/preview';

const InfoBody = ({
  userId,
  playerState,
  authors,
  selectedWip,
  wipAudios,
  onPlay,
  onDownload,
  onDelete,
  isOwner,
  canComment,
  canEdit,
  onComments,
  currentUploads,
  removeUploadedAudio,
  handleClickShareTrackLink,
}) => {
  const currentAuthor = authors[userId] || {};
  const availableAudios = useMemo(
    () => wipAudios?.filter?.(w => !!w) ?? [],
    [wipAudios],
  );
  const availableAudiosIds = useMemo(
    () => availableAudios.map(a => a.id),
    [availableAudios],
  );

  const fakeAudios = Object.keys(currentUploads)
    .filter(k => availableAudiosIds.indexOf(k) === -1)
    .map(k => ({
      author: currentAuthor.id,
      authorName: currentAuthor.fullName || 'Please wait...',
      id: k,
      created: new Date().toLocaleString(),
      playlistId: selectedWip.id,
      wipRef: selectedWip.id,
      uploading: true,
      progress: currentUploads[k].progress,
      title: currentUploads[k].name || 'Uploading',
    }))
    .reverse();

  const previews = useMemo(
    () => [...fakeAudios, ...availableAudios],
    [fakeAudios, availableAudios],
  );

  useEffect(() => {
    wipAudios.forEach(a =>
      removeUploadedAudio({wipId: selectedWip.id, audioId: a?.id}),
    );
  }, [wipAudios, selectedWip]);

  return (
    <div className="app__info--body">
      {previews.map((audio, i) => {
        if (!audio) {
          return null;
        }
        const {authorName} = audio;
        const {fullName = ''} = authors[audio.author] || {};
        const title = audio.title || selectedWip.title?.text;
        const playingSelected = selectedWip.id === playerState.id;
        const isPlayingByAudioId = !!playerState.audioId;
        const playing =
          playingSelected &&
          playerState.playing &&
          (isPlayingByAudioId
            ? audio.id === playerState.audioId
            : playerState.index === i);

        return (
          <Preview
            key={audio.id}
            id={audio.id}
            wipId={selectedWip.id}
            title={title}
            author={authorName || fullName}
            datetime={audio.created || audio.lastEdited}
            onPlay={() => {
              const index = availableAudiosIds.indexOf(audio.id);
              if (index !== -1) {
                onPlay({
                  wipUid: selectedWip.id,
                  audioUid: audio.id,
                  wipAudios: availableAudios,
                  track: index,
                });
              }
            }}
            playing={playing}
            downloadURL={audio.uri}
            onDownload={() => {
              onDownload(audio, title);
            }}
            onDelete={() => {
              onDelete(selectedWip, audio);
            }}
            onComments={() => {
              onComments({
                ...audio,
                track: i,
              });
            }}
            onClickShareTrackLink={handleClickShareTrackLink}
            canDownload={canEdit}
            canComment={canComment}
            isOwner={isOwner}
            isUploader={userId === audio.author}
            fromRecording={audio.fromRecording}
            uploading={audio.progress + 1}
          />
        );
      })}
    </div>
  );
};

InfoBody.defaultProps = {
  selectedWip: {},
  authors: {},
  playerState: {},
  currentUploads: {},
  wipAudios: [],
  onPlay: () => {},
  onDelete: () => {},
  onDownload: () => {},
  onComments: () => {},
  canEdit: false,
  canComment: true,
  isOwner: false,
  removeUploadedAudio: () => {},
  handleClickShareTrackLink: () => {},
};

InfoBody.propTypes = {
  userId: PropTypes.string.isRequired,
  selectedWip: PropTypes.objectOf(PropTypes.any),
  authors: PropTypes.objectOf(PropTypes.any),
  playerState: PropTypes.objectOf(PropTypes.any),
  currentUploads: PropTypes.objectOf(PropTypes.any),
  wipAudios: PropTypes.arrayOf(PropTypes.any),
  onPlay: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onComments: PropTypes.func,
  canEdit: PropTypes.bool,
  canComment: PropTypes.bool,
  isOwner: PropTypes.bool,
  removeUploadedAudio: PropTypes.func,
  handleClickShareTrackLink: PropTypes.func,
};

export default InfoBody;
