import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {setLoader} from '../../../redux/actions/common';

import HocQueue from '../../hoc/hoc-queue';
import HomeBody from './home-body';
import UserProfile from '../../organisms/user/user-profile-sm';
import Menu from '../../organisms/menu';
import MenuHeader from '../../organisms/menu/menu-header';
import UploadQueue from '../../organisms/upload/upload-queue';
import UploadWip from '../../organisms/upload/upload-wip';
import {MenuContext} from '../../context/menu-context';

import './style.scss';

const Home = () => {
  const [soundFiles, setSoundFiles] = useState();
  const [selectedAudio, setSelectedAudio] = useState();
  const {selectedMenu, setSelectedMenu} = useContext(MenuContext);
  const goToSlide = useRef();

  const onCloseMenu = () => {
    setSoundFiles();
    setSelectedMenu('');
  };

  useEffect(() => {
    if (soundFiles) {
      setSelectedMenu('saveWip');
    }
  }, [soundFiles]);

  return (
    <div id="MainPage" className="app__page app__content">
      <HomeBody
        setSoundFiles={setSoundFiles}
        selectMenu={setSelectedMenu}
        setGotToRef={({goTo}) => {
          goToSlide.current = goTo;
        }}
        goToSlide={goToSlide.current}
        selectedAudio={selectedAudio}
        setSelectedAudio={setSelectedAudio}
      />
      <Menu menuOpened={selectedMenu === 'profile'} onClose={onCloseMenu}>
        <MenuHeader title="Profile" onClose={onCloseMenu} />
        <UserProfile />
      </Menu>
      <Menu menuOpened={selectedMenu === 'saveQueue'} onClose={onCloseMenu}>
        <MenuHeader title="Save To" onClose={onCloseMenu} />
        <UploadQueue
          onDone={() => {
            onCloseMenu();
            if (goToSlide.current) {
              goToSlide.current(0);
            }
          }}
        />
      </Menu>
      <Menu
        menuOpened={selectedMenu === 'saveWip' && !!soundFiles}
        onClose={onCloseMenu}>
        <MenuHeader title="Save To" onClose={onCloseMenu} />
        <UploadWip soundFiles={soundFiles} onDone={onCloseMenu} />
      </Menu>
    </div>
  );
};

const mapStateToProps = ({common, queue}) => ({
  user: common.user, // Needed for hocQueue
  userData: queue.userData, // Needed for hocQueue
});

export default connect(mapStateToProps)(HocQueue(Home));
