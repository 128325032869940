import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';

import {mergeWIPData} from '../../../helpers/helper-untitled';
import ActivityNotifications from './activity-notifications';
import ActivityInvites from './activity-invites';

import './style.scss';
import {acceptInvite, denyInvite} from '../../../redux/actions/wips/async';
import {throttle} from '../../../helpers/helper-util';
import {
  generateNotifications,
  sendPushNotification,
} from '../../../helpers/helper-notifications';
import {authorsSelect} from '../../../redux/actions/authors';
import {MenuContext} from '../../context/menu-context';
import Analytics from '../../../helpers/helper-analytics';

const Activity = ({
  news,
  user,
  authors,
  wips,
  audios,
  activity,
  reads,
  onAcceptInvite,
  onDenyInvite,
  selectAuthor,
  onCloseMenu,
}) => {
  const {setSelectedMenu} = useContext(MenuContext);

  const history = useHistory();

  const onAuthor = authorId => {
    selectAuthor({...authors[authorId], id: authorId});
    setSelectedMenu('profile');
  };

  const onSelect = (wip, audioId) => {
    Analytics.logEventTapActivity();
    if (audioId) {
      // TODO: change audio to comment?
      history.push(`/details?wip=${wip.id}&audio=${audioId}`);
    } else {
      history.push(`/details?wip=${wip.id}`);
    }
    onCloseMenu();
  };

  const onAccept = wipId => {
    onAcceptInvite({wipId, authorId: user.uid}).then(
      ({users, author, wip, kind}) => {
        toast('Invitation accepted!', {hideProgressBar: true});

        const notifications = generateNotifications({
          type: kind,
          users,
          author,
          wip,
        });

        sendPushNotification(notifications);

        onSelect(wip);
      },
    );
  };

  const onDeny = wipId => {
    onDenyInvite({wipId, authorId: user.uid});
  };

  const throttledAccept = throttle(onAccept, 1000);
  const throttledDeny = throttle(onDeny, 1000);

  return (
    <div className="app__activity">
      {Object.keys(news).length > 0 && (
        <>
          <h3 className="mb-3 mt-2">Invites</h3>
          <ActivityInvites
            news={news}
            onAccept={throttledAccept}
            onDeny={throttledDeny}
          />
        </>
      )}

      <h3 className="mb-3 mt-2">Notifications</h3>
      <ActivityNotifications
        user={user}
        authors={authors}
        wips={wips}
        audios={audios}
        activity={activity}
        reads={reads}
        onAuthor={onAuthor}
        onSelect={onSelect}
      />
      <button
        className="app__menu--header-close"
        type="button"
        onClick={onCloseMenu}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.405544 0.405544C0.94627 -0.135182 1.82296 -0.135182 2.36369 0.405544L13.4406 11.4825C13.9813 12.0232 13.9813 12.8999 13.4406 13.4406C12.8999 13.9813 12.0232 13.9813 11.4825 13.4406L0.405544 2.36369C-0.135182 1.82296 -0.135182 0.94627 0.405544 0.405544Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4406 0.405544C13.9813 0.94627 13.9813 1.82296 13.4406 2.36369L2.36369 13.4406C1.82296 13.9813 0.94627 13.9813 0.405544 13.4406C-0.135182 12.8999 -0.135182 12.0232 0.405544 11.4825L11.4825 0.405544C12.0232 -0.135182 12.8999 -0.135182 13.4406 0.405544Z"
          />
        </svg>
      </button>
    </div>
  );
};

Activity.defaultProps = {
  user: {},
  authors: {},
  audios: {},
  news: {},
  activity: {},
  reads: {},
  wips: {},
  onAcceptInvite: () => {},
  onDenyInvite: () => {},
  selectAuthor: () => {},
  onCloseMenu: () => {},
};

Activity.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  authors: PropTypes.objectOf(PropTypes.any),
  audios: PropTypes.objectOf(PropTypes.any),
  news: PropTypes.objectOf(PropTypes.any),
  activity: PropTypes.objectOf(PropTypes.any),
  reads: PropTypes.objectOf(PropTypes.any),
  wips: PropTypes.objectOf(PropTypes.any),
  onAcceptInvite: PropTypes.func,
  onDenyInvite: PropTypes.func,
  selectAuthor: PropTypes.func,
  onCloseMenu: PropTypes.func,
};

const mapStateToProps = ({common, wips, audios, authors, activity}) => ({
  user: common.user,
  notifications: wips.notifications,
  news: mergeWIPData(wips.notifications, audios.all, authors.users),
  wips: mergeWIPData(wips.all, audios.all, authors.users),
  activity,
  audios: audios.all,
  reads: activity.reads,
  authors: authors.users,
});

const mapDispatchToProps = {
  onAcceptInvite: acceptInvite,
  onDenyInvite: denyInvite,
  selectAuthor: authorsSelect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
