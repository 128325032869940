import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Compressor from 'compressorjs';

import Slides from '../../molecules/slides';
import Invite from '../invite';

import styles from './style.scss';
import Edit from './edit';
import {getCroppedImg} from '../../../helpers/helper-upload';
import {updateWIP, onSetPin} from '../../../redux/actions/wips/async';
import {logErrorException} from '../../../helpers/helper-logs';

const thumbSize = parseFloat(styles.thumbSize);

const EditWip = ({user, selectedWip, wipUpdate, onDone, pinned}) => {
  const {album, title} = selectedWip;
  const [curSlide, setCurSlide] = useState(0);
  const [wipTitle, setWipTitle] = useState(title.text);
  const [coverFile, setCoverArt] = useState({uri: album.cover});
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const goToSlide = useRef(null);
  const compressorRef = useRef(null);

  const hasChanged = coverFile.type || wipTitle !== title.text;
  const isValid = hasChanged && coverFile.uri && wipTitle !== '';

  const onPrev = () => {
    if (goToSlide.current) {
      goToSlide.current(0);
      setCurSlide(0);
    }
  };

  const onArtworkChanged = files => {
    if (files.length) {
      const type = files[0].type.split('/').pop();
      const extension = files[0].name.split('.').pop();
      compressorRef.current = new Compressor(files[0], {
        width: 520,
        minHeight: 520,
        convertSize: 1000000,
        strict: false,
        quality: 0.8,
        success(result) {
          const uri = URL.createObjectURL(result);
          setCoverArt({
            uri,
            type,
            extension,
          });
          compressorRef.current = null;
        },
        error(e) {
          logErrorException(e);
        },
      });
    }
  };

  const onUpdateWip = async () => {
    let artwork;
    if (coverFile.type) {
      const uriImage = await getCroppedImg(coverFile.uri, croppedAreaPixels, 0);
      artwork = {
        ...coverFile,
        uri: uriImage,
      };
    }
    const newEdit = {
      wip: selectedWip,
      coverArt: artwork,
      wipTitle,
    };
    onDone();
    await wipUpdate(user, newEdit);
  };

  return (
    <div className="app__upload pt-3">
      <Slides
        className="app__upload--steps center"
        startAt={curSlide ? 1 : 0}
        setRef={({goTo}) => {
          goToSlide.current = goTo;
        }}
        autoHeight={false}
        unmountOnExit>
        <div className="app__slide">
          <Edit
            coverArt={coverFile}
            setCroppedAreaPixels={setCroppedAreaPixels}
            onArtworkChanged={onArtworkChanged}
            wipTitle={wipTitle}
            setWipTitle={setWipTitle}
            onUpdateWip={() => {
              onUpdateWip();
            }}
            thumbSize={thumbSize}
            disabled={!isValid}
            pinned={pinned}
            onPin={() => {
              onSetPin(selectedWip.id, pinned);
            }}
          />
        </div>

        <div className="app__slide">
          <Invite
            selectedWip={{
              ...selectedWip,
              author: user.uid,
            }}
          />
          <div className="app__upload--footer p-3">
            <button
              type="button"
              onClick={onPrev}
              className="btn btn-outline app__upload--footer-action">
              <span>Done</span>
            </button>
          </div>
        </div>
      </Slides>
    </div>
  );
};

EditWip.defaultProps = {
  user: {},
  selectedWip: undefined,
  wipUpdate: () => {},
  onDone: () => {},
  pinned: false,
};

EditWip.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  selectedWip: PropTypes.objectOf(PropTypes.any),
  wipUpdate: PropTypes.func,
  onDone: PropTypes.func,
  pinned: PropTypes.bool,
};

const mapStateToProps = ({common}) => ({
  user: common.user,
});

const mapDispatchToProps = {
  wipUpdate: updateWIP,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWip);
