/**
 * Constants common
 */

/** @constant {string} TOGGLE_THEME */
export const TOGGLE_THEME = 'core/redux/common/TOGGLE_THEME';

/** @constant {string} AUTH_CHANGED */
export const AUTH_CHANGED = 'core/redux/common/AUTH_CHANGED';

/** @constant {string} MFA_VERIFIED */
export const MFA_VERIFIED = 'core/redux/common/MFA_VERIFIED';

/** @constant {string} CLAIMS_CHANGED */
export const CLAIMS_CHANGED = 'core/redux/common/CLAIMS_CHANGED';

/** @constant {string} SET_LOADER */
export const SET_LOADER = 'core/redux/common/SET_LOADER';

/** @constant {string} SET_MODAL */
export const SET_MODAL = 'core/redux/common/SET_MODAL';

/** @constant {string} HIDE_INSTALL_MESSAGE */
export const HIDE_INSTALL_MESSAGE = 'core/redux/common/HIDE_INSTALL_MESSAGE';

/** @constant {string} REMEMBER_ME */
export const REMEMBER_ME = 'core/redux/common/REMEMBER_ME';

/** @constant {string} REMEMBER_ME_EMAIL */
export const REMEMBER_ME_EMAIL = 'core/redux/common/REMEMBER_ME_EMAIL';

/** @constant {string} SELECT_MENU */
export const SELECT_MENU = 'core/redux/common/SELECT_MENU';

/** @constant {string} RESET */
export const RESET = 'core/redux/common/RESET';

/** @constant {string} SET_SHORTCUTS_ENABLED */
export const SET_SHORTCUTS_ENABLED = 'core/redux/common/SET_SHORTCUTS_ENABLED';
