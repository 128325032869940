import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Slide from './slide';
import styles from './style.scss';

const timeout = parseFloat(styles.slideTime);

const Slides = ({
  children,
  className,
  setRef,
  autoHeight,
  onChange,
  startAt,
  unmountOnExit,
  forceUpdate,
}) => {
  const [menuHeight, setMenuHeight] = useState(autoHeight ? 0 : '100%');
  const [selected, setSelected] = useState(startAt);
  const next = () => {
    setSelected(selected + 1);
  };
  const prev = () => {
    setSelected(selected - 1);
  };
  const goTo = go => {
    setSelected(go);
  };
  const slides = React.Children.map(children, (_, i) => i);
  setRef({
    next,
    prev,
    goTo,
    slides,
  });

  useEffect(() => {
    onChange(selected);
  }, [selected]);
  return (
    <div className={`app__sliding ${className}`} id="menu">
      <div
        className="app__sliding--inner"
        style={{
          height: menuHeight,
          // transition: `${timeout}ms height ease-in-out`,
        }}>
        {React.Children.map(children, (child, i) => (
          <Slide
            level={i}
            setSelected={setSelected}
            selectedLevel={selected}
            onEnter={autoHeight ? setMenuHeight : undefined}
            timeout={timeout * 1000}
            unmountOnExit={
              (child.props && child.props.unmountOnExit) || unmountOnExit
            }
            forceUpdate={forceUpdate}>
            {child}
          </Slide>
        ))}
      </div>
    </div>
  );
};

Slides.defaultProps = {
  className: '',
  setRef: () => {},
  onChange: () => {},
  autoHeight: true,
  unmountOnExit: false,
  startAt: 0,
  forceUpdate: 0,
};

Slides.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  setRef: PropTypes.func,
  onChange: PropTypes.func,
  autoHeight: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  startAt: PropTypes.number,
  forceUpdate: PropTypes.number,
};

export default Slides;
