import {logErrorException} from './helper-logs';

// const dev = process.env.NODE_ENV === 'development';
// const host = dev ? '/untitled-app-55769/us-central1' : 'https://us-central1-untitled-app-55769.cloudfunctions.net';
const host = 'https://us-central1-untitled-app-55769.cloudfunctions.net';

export const defaultConfig = {
  method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json',
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const copyQueueToNewWip = async ({
  audioStoragePath,
  queueStoragePath,
}) => {
  const url = `${host}/copyQueueToNewWip`;
  const reqdata = {
    audioStoragePath,
    queueStoragePath,
  };
  return fetch(url, {
    ...defaultConfig,
    body: JSON.stringify(reqdata), // body data type must match "Content-Type" header
  })
    .then(r => r.json())
    .catch(error => {
      logErrorException(error);
      return {error: true, message: 'Failed to move file'};
    });
};

export const addClaims = async uid => {
  const url = `${host}/addClaims`;
  const reqdata = {
    user: {uid},
  };
  return fetch(url, {
    ...defaultConfig,
    body: JSON.stringify(reqdata), // body data type must match "Content-Type" header
  })
    .then(r => r.json())
    .catch(error => {
      logErrorException(error);
      return {error: true, message: 'Failed to move file'};
    });
};

export const getLandingData = async () => {
  const url = `${host}/getLandingData`;
  return fetch(url, {
    ...defaultConfig,
  })
    .then(r => r.json())
    .catch(error => {
      logErrorException(error);
      return {error: true, message: 'Failed to move file'};
    });
};
