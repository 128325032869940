/* eslint-disable react/no-array-index-key */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import {getComments} from '../../../redux/actions/audios/async';
import TimeAgo from '../../atoms/timeago';

import './style.scss';
import {replaceMentionValues} from '../../../helpers/helper-util';

const DELIMITER = Math.random().toString();

const CONTROL_REGEX = /(.\[[^[]*]\([^(^)]*\))/;
// const CONTROL_REGEX = /(@\[[a-zA-Z0-9\u00C0-\u017F]+\]\([a-zA-Z0-9]+\))/;

const CONTROL_REGEX_GLOBAL = new RegExp(CONTROL_REGEX, 'gi');

const Comments = ({audio, onAuthor}) => {
  const user = useSelector(state => state.common.user || {});
  const comments = useSelector(state => state.audios.comments || {});
  const authors = useSelector(state => state.authors.users || {});
  const dispatch = useDispatch();

  const hasComments = !!comments[audio.id];
  const audioComments = comments[audio.id] || {};

  const getInfoComponent = ({id, text, created, authorName}) => {
    const replaced = text.replace(
      CONTROL_REGEX_GLOBAL,
      `${DELIMITER}$1${DELIMITER}`,
    );
    const splited = replaced.split(DELIMITER);
    return (
      <span className="app__comments--info-content">
        <div>
          <b>{`${authorName} `}</b>
          <TimeAgo datetime={created} />
        </div>
        {splited.map((s, i) => {
          const parsed = replaceMentionValues(s, ({name}) => `@${name}`)
            .split(/(?:\r\n|\r|\n)/)
            .map(st => <span key={st}>{st}</span>);
          return s.match(CONTROL_REGEX) ? (
            <span
              key={`${audio.id}-${id}-${i}`}
              className="app__user--link"
              onClick={() => {
                const userid = replaceMentionValues(
                  s,
                  ({id: authorId}) => authorId,
                );
                console.log(userid);
                onAuthor(userid);
              }}
              tabIndex={-1}
              role="button"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onAuthor();
                }
              }}>
              {parsed}
            </span>
          ) : (
            <span key={`${audio.id}-${id}-${i}`}>{parsed}</span>
          );
        })}
      </span>
    );
  };

  const renderElements = comment => {
    const {id, author, created, text} = comment;
    const itemAuthor = authors[author];
    if (!itemAuthor) {
      return null;
    }

    return (
      <div className="app__comments--item" key={id}>
        <div
          className={`app__miniauthor--initials light ${
            user.uid === itemAuthor.id ? 'active' : ''
          }`}>
          <span>{itemAuthor.initials}</span>
        </div>
        <div className="app__comments--info">
          {getInfoComponent({
            id,
            created,
            text,
            authorName: itemAuthor.fullName,
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const unsub = dispatch(getComments(audio.id));
    return unsub;
  }, []);

  const keys = Object.keys(audioComments);

  return (
    <div className="app__comments">
      {keys.length === 0 && (
        <span>{`${hasComments ? 'No comments yet.' : 'Loading...'}`}</span>
      )}
      {keys.map(k => renderElements(audioComments[k]))}
    </div>
  );
};

Comments.defaultProps = {
  audio: {},
  onAuthor: () => {},
};

Comments.propTypes = {
  audio: PropTypes.objectOf(PropTypes.any),
  onAuthor: PropTypes.func,
};

export default Comments;
