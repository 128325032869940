import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import path from 'path';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {DetailsMenuShareTrack} from '@untitled/app/core/components/molecules/details-menu-share-track';
import './style.scss';
import {logErrorException} from '@untitled/app/core/helpers/helper-logs';
import InfoHeader from './header';
import InfoBody from './body';
import {downloadUrls, downloadFromURL} from '../../../helpers/helper-download';
import Menu from '../menu';
import MenuHeader from '../menu/menu-header';
import EditWip from '../upload/edit-wip.js';
import PlayPause from '../../atoms/playpause';
import Notes from '../notes';
import Analytics from '../../../helpers/helper-analytics';
import ModalPicker from '../../molecules/modal-picker';
import UploadsManager from '../../../classes/uploads-manager';
import {UPlayerContext} from '../../context/player-context';
import useWipPlaylist from '../../hooks/useWipPlaylist';
import MenuInvite from '../menu/menu-invite';
import MenuComments from '../menu/menu-comments';
import {MenuNotes} from '../menu/menu-notes';

const InfoWip = ({
  user,
  selectedWip,
  onAuthor,
  authors,
  onUnmount,
  onBack,
  onPlay,
  onDeleteAudio,
  commentOnAudio,
  selectedAudio,
  setSelectedAudio,
  // audiosProgress,
  uploadsProgress,
  pins,
  onDeleted,
  audioSearch,
  removeUploadedAudio,
}) => {
  const {playerState} = useContext(UPlayerContext);
  const [menuMode, setMenuMode] = useState(selectedAudio.id && 'comments');
  const [thoughts, setThoughts] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [delmsg, setDelMsg] = useState({});
  const submittingRef = useRef();
  const uploadsManager = useRef(UploadsManager.getInstance()).current;

  const history = useHistory();

  const wipAudios = useWipPlaylist({selectedWip});

  const {confirmed = [], writers = [], author: wipAuthorId} = selectedWip || {};

  const isOwner = wipAuthorId === user.uid;
  const canEdit =
    isOwner ||
    (confirmed.indexOf(user.uid) !== -1 && writers.indexOf(user.uid) !== -1);

  // const currentUploads = audiosProgress[selectedWip.id] || {};

  const onCloseMenu = () => {
    setMenuMode();
  };

  const onDownload = async audio => {
    const ext = path.extname(audio.storageRef);
    const blob = await fetch(audio.uri).then(f => f.blob());
    const blobURL = window.URL.createObjectURL(blob);
    downloadFromURL(blobURL, `${audio.title}${ext}`);
    toast(`Downloading ${audio.title}`, {
      hideProgressBar: true,
      autoClose: 1000,
    });
  };

  const onDownloadAll = () => {
    toast(`Downloading all from ${selectedWip.title.text}`, {
      hideProgressBar: true,
    });
    downloadUrls(wipAudios, selectedWip.title.text);
  };

  const renderHeaderActions = () => {
    const playing = playerState.playing && playerState.id === selectedWip.id;
    const trackPlaying =
      playing &&
      playerState.playlist[playerState.index].id === selectedAudio.id;
    return (
      <PlayPause
        className="app__miniplaypause"
        playing={selectedAudio.id ? trackPlaying : playing}
        onClick={() => {
          const track = selectedAudio.id
            ? wipAudios.map(e => e.id).indexOf(selectedAudio.id)
            : 0;
          onPlay({
            wipUid: selectedWip.id,
            wipAudios,
            track,
          });
        }}
      />
    );
  };

  const onComment = () => {
    commentOnAudio({
      audioId: selectedAudio.id,
      text: thoughts,
      wipId: selectedWip.id,
    }).then(success => {
      if (success) {
        setThoughts('');
      }
    });
  };

  const onDeleteMessage = (w, a) => {
    if (w && a) {
      setDelMsg({wip: w, audio: a});
    }
  };

  const onDelete = (w, a) => {
    if (submittingRef.current) {
      return;
    }
    submittingRef.current = true;
    setDelMsg({});
    uploadsManager.deleteUpload({
      wipId: w.id,
      fileId: a.id,
    });
    onDeleteAudio(w, a)
      .then(res => {
        submittingRef.current = false;
        if (res) {
          history.push({
            pathname: '/home',
            state: {tab: 0},
          });
          onDeleted();
        }
      })
      .catch(reason => {
        logErrorException(reason);
      });
  };

  useEffect(() => {
    if (selectedAudio.id) {
      setMenuMode('comments');
    }
  }, [selectedAudio]);

  useEffect(() => {
    if (selectedWip.id && selectedAudio && selectedAudio.id) {
      const confirmedAuthors = selectedWip.confirmed || [];
      const users = [selectedWip.author, ...confirmedAuthors]
        .filter(k => authors[k] && authors[k].id !== user.uid)
        .map(k => ({...authors[k], display: authors[k].name, value: k}));

      setSuggestions(users);
    }
  }, [selectedWip, authors, selectedAudio]);

  useEffect(
    () => () => {
      onUnmount();
    },
    [],
  );

  const hasQuery = audioSearch.query && audioSearch.query.length > 0;
  const audiosList = hasQuery
    ? wipAudios.filter(a => new RegExp(audioSearch.query, 'gi').test(a.title))
    : wipAudios;

  const wipAuthor = authors[selectedWip.author] || {};

  const wipTitle = selectedWip.title ? selectedWip.title.text : '';

  /**
   * For performance reasons, we only ever render one <DetailsMenuShareTrack>
   * component. As such, 'menuShareTrackAudioId' refers to the id of the track
   * that is in focus whenever the user clicks 'Share Link'
   */
  const [menuShareTrackAudioId, setMenuShareTrackAudioId] =
    React.useState(undefined);
  const [openMenuShareTrack, setOpenMenuShareTrack] = React.useState(false);

  const handleClickShareTrackLink = audioId => {
    setMenuShareTrackAudioId(audioId);
    setOpenMenuShareTrack(true);
  };

  const handleCloseMenuShareTrack = () => {
    setOpenMenuShareTrack(false);
  };

  return (
    <>
      <div className="app__info">
        <InfoHeader
          coverURL={selectedWip.coverURL}
          id={selectedWip.id}
          title={wipTitle}
          authorName={wipAuthor.name}
          authorId={wipAuthor.id}
          authorInitials={wipAuthor.initials}
          onAddAuthor={() => {
            setMenuMode('collaborators');
          }}
          onAuthor={onAuthor}
          onDownloadAll={onDownloadAll}
          canEdit={canEdit}
          onBack={onBack}
          onEdit={() => {
            setMenuMode('edit');
          }}
          onNotes={() => {
            Analytics.logEventViewNotes(selectedWip.id);
            setMenuMode('notes');
          }}
          confirmed={confirmed.map(k => authors[k])}
          disabled={selectedWip.disabled}
        />
        <InfoBody
          userId={user.uid}
          selectedWip={selectedWip}
          authors={authors}
          wipAudios={audiosList}
          onPlay={onPlay}
          onDownload={onDownload}
          onDelete={onDeleteMessage}
          playerState={playerState}
          isOwner={isOwner}
          canEdit={canEdit}
          onComments={aud => {
            setMenuMode('comments');
            setSelectedAudio(aud);
          }}
          currentUploads={uploadsProgress}
          removeUploadedAudio={removeUploadedAudio}
          handleClickShareTrackLink={handleClickShareTrackLink}
        />
      </div>

      <Menu menuOpened={menuMode === 'edit'} onClose={onCloseMenu}>
        <MenuHeader
          title="Edit WIP"
          onClose={onCloseMenu}
          titleActions={renderHeaderActions}
        />
        <EditWip
          onDone={onCloseMenu}
          selectedWip={selectedWip}
          pinned={pins.indexOf(selectedWip.id) !== -1}
        />
      </Menu>

      <MenuNotes
        menuOpened={menuMode === 'notes'}
        onCloseMenu={onCloseMenu}
        renderHeaderActions={renderHeaderActions}
        user={user}
        selectedWip={selectedWip}
      />

      <MenuComments
        menuOpened={menuMode === 'comments'}
        selectedAudio={selectedAudio}
        onCloseMenu={onCloseMenu}
        thoughts={thoughts}
        setThoughts={setThoughts}
        onComment={onComment}
        suggestions={suggestions}
        title={selectedAudio ? selectedAudio.title : ''}
        renderHeaderActions={renderHeaderActions}
        onAuthor={onAuthor}
      />

      <MenuInvite
        selectedWip={selectedWip}
        menuOpened={menuMode === 'collaborators'}
        onCloseMenu={onCloseMenu}
        isOwner={isOwner}
      />

      <DetailsMenuShareTrack
        onClose={handleCloseMenuShareTrack}
        open={openMenuShareTrack}
        audioId={menuShareTrackAudioId}
        wipId={selectedWip.id}
      />

      <ModalPicker
        isVisible={!!delmsg.audio && !!delmsg.wip}
        toggleModal={() => {
          setDelMsg({});
        }}>
        <div className="modalpicker__panel">
          {delmsg.audio && (
            <div className="modalpicker__confirm">
              <h3>{`Are you sure you want to delete ${delmsg.audio.title}?`}</h3>
              <button
                type="button"
                onClick={() => {
                  onDelete(delmsg.wip, delmsg.audio);
                }}>
                I&apos;m Sure
              </button>
              <button
                type="button"
                onClick={() => {
                  setDelMsg({});
                }}>
                Go Back
              </button>
            </div>
          )}
        </div>
      </ModalPicker>
    </>
  );
};

InfoWip.defaultProps = {
  user: {},
  selectedWip: {},
  onAuthor: () => {},
  authors: {},
  onUnmount: () => {},
  onBack: () => {},
  onPlay: () => {},
  onDeleteAudio: () => {},
  commentOnAudio: () => {},
  selectedAudio: {},
  setSelectedAudio: () => {},
  // audiosProgress: {},
  uploadsProgress: {},
  audioSearch: {},
  pins: [],
  onDeleted: () => {},
};

InfoWip.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  selectedWip: PropTypes.objectOf(PropTypes.any),
  onAuthor: PropTypes.func,
  authors: PropTypes.objectOf(PropTypes.any),
  onUnmount: PropTypes.func,
  onBack: PropTypes.func,
  onPlay: PropTypes.func,
  onDeleteAudio: PropTypes.func,
  commentOnAudio: PropTypes.func,
  selectedAudio: PropTypes.objectOf(PropTypes.any),
  setSelectedAudio: PropTypes.func,
  // audiosProgress: PropTypes.objectOf(PropTypes.any),
  uploadsProgress: PropTypes.objectOf(PropTypes.any),
  audioSearch: PropTypes.objectOf(PropTypes.any),
  pins: PropTypes.arrayOf(PropTypes.any),
  onDeleted: PropTypes.func,
  removeUploadedAudio: PropTypes.func,
};

export default InfoWip;
