import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import {generateId} from '../../../helpers/helper-util';

const Files = ({
  children,
  className,
  id,
  onChange,
  accept,
  directory,
  multiple,
}) => {
  const idRef = useRef(id || generateId()).current;
  const onFilesChange = e => {
    const {files} = e.target;
    onChange(multiple ? [...files] : [files[0]]);
  };
  return (
    <label htmlFor={idRef} className={`app__files ${className}`}>
      {children}
      <input
        type="file"
        id={idRef}
        onChange={onFilesChange}
        accept={accept}
        webkitdirectory={directory}
        multiple={multiple}
      />
    </label>
  );
};

Files.defaultProps = {
  className: '',
  id: undefined,
  onChange: () => {},
  accept: undefined,
  directory: undefined,
  multiple: undefined,
};

Files.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  accept: PropTypes.string,
  directory: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default Files;
