import React, {Suspense, lazy} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Home from '@untitled/app/core/components/templates/home';
import Details from '@untitled/app/core/components/templates/details';
import Share from '@untitled/app/core/components/templates/share';
import Preview from '@untitled/app/core/components/templates/preview';
import NotFound from '@untitled/app/core/components/templates/notfound';
import PageLoader from '@untitled/app/core/components/atoms/loader';
import Waitlist from '@untitled/app/core/components/templates/waitlist/';
import {WipMetaData} from '@untitled/app/core/components/templates/wip/wip';
import ShareTrack from '@untitled/app/core/components/templates/share-track';

const Admin = lazy(
  () => import('@untitled/app/core/components/templates/admin'),
);
interface Props {
  history: JSX.Element[] | any | any[];
}

const Routes = ({history}: Props) => {
  const {location} = history;
  return (
    <Suspense fallback={<PageLoader />}>
      <div className="app__group">
        <Switch location={location}>
          <Route
            path="/"
            exact
            render={p => (
              <Home history={p.history} location={p.location} match={p.match} />
            )}
          />
          <Route path="/home" exact component={Home} />
          <Route path="/details" exact component={Details} />
          <Route path="/go/share/wip/:wipId" exact component={Share} />
          <Route path="/go/share/track/:trackId" exact component={ShareTrack} />
          <Route path="/preview" exact component={Preview} />
          <Route path="/admin" exact component={Admin} />
          <Route path="/go/wip/:wipId" exact component={WipMetaData} />
          <Route path="/waitlist" exact component={Waitlist} />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </div>
    </Suspense>
  );
};

const mapStateToProps = (state: any) => ({
  location: state.location,
  common: state.common,
  members: state.members,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
