import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {IconPlus} from '../../atoms/icons';
import {generateId, isMusic} from '../../../helpers/helper-util';
import {isIOS} from '../../../helpers/helper-useragent';

const defaultAccept = isIOS
  ? '.mp3,.wav,.flac,.aac,.m4a,.aiff,.mp4'
  : 'audio/*';

const GridItemAdd = ({accept, onChange, multiple}) => {
  const id = useRef(generateId()).current;

  return (
    <div className="app__grid--item">
      <label htmlFor={id} className="app__grid--item-add">
        <div className="app__grid--item-icon">
          <IconPlus size={120} />
        </div>
        <input
          type="file"
          id={id}
          onChange={e => {
            const files = [];
            for (let i = 0; i < e.target.files.length; i += 1) {
              const file = e.target.files[i];
              if (isMusic(file.name)) {
                files.push(file);
              }
            }
            onChange(multiple ? files : [files[0]]);
          }}
          onClick={e => {
            e.target.value = null;
          }}
          accept={accept}
          multiple={multiple}
        />
      </label>
      <div className="app__grid--item-info">
        <div className="app__grid--item-title truncate">
          <label htmlFor={id}>
            <p className="truncate">Upload</p>
          </label>
        </div>
      </div>
    </div>
  );
};

GridItemAdd.defaultProps = {
  onChange: () => {},
  accept: defaultAccept,
  multiple: undefined,
};

GridItemAdd.propTypes = {
  onChange: PropTypes.func,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
};

export default GridItemAdd;
