import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';

interface Props {}

export const WipMetaData = (_props: Props) => {
  const {wipId} = useParams<{wipId: string}>();

  useEffect(() => {
    const pathname = wipId ? `/details?wip=${wipId}` : '/home';
    window.location.hash = '';
    window.location.replace(pathname);
  }, []);

  return (
    <Helmet>
      <meta property="og:title" content="Check out this WIP on [untitled]" />
      <meta
        property="og:image"
        content="https://untitledinbrackets.com/images/favicon-96x96.png"
      />
      <meta
        property="og:description"
        content="Check out this WIP on [untitled]"
      />
      <meta property="og:site_name" content="Untitled In Brackets" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.untitledinbrackets.com/" />
    </Helmet>
  );
};
