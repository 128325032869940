import {
  SET_QUEUE_INIT,
  SET_TEMPURL,
  SET_MUSIC_URL,
  SET_MACHINE_DATA,
  TOGGLE_FOLDER,
  SET_USER_DATA,
  SET_SELECTED_WIP,
  SET_SELECTED_QUEUE,
  QUEUE_RESET,
} from './constants';

export const setQueueInit = payload => ({
  type: SET_QUEUE_INIT,
  payload,
});

export const setQueueTempUrl = payload => ({
  type: SET_TEMPURL,
  payload,
});

export const setQueueDownloadsUrl = payload => ({
  type: SET_MUSIC_URL,
  payload,
});

export const setQueueUserData = payload => ({
  type: SET_USER_DATA,
  payload,
});

export const setMachineData = payload => ({
  type: SET_MACHINE_DATA,
  payload,
});

export const toggleQueueFolder = payload => ({
  type: TOGGLE_FOLDER,
  payload,
});

export const setSelectedQueueWip = payload => ({
  type: SET_SELECTED_WIP,
  payload,
});

export const setSelectedQueue = payload => ({
  type: SET_SELECTED_QUEUE,
  payload,
});

export const queueReset = payload => ({
  type: QUEUE_RESET,
  payload,
});
