import React, {useState} from 'react';
import PropTypes from 'prop-types';

const defaultMenu = '';

export const MenuContext = React.createContext(defaultMenu);

export const MenuProvider = ({children}) => {
  const [selectedMenu, setSelectedMenu] = useState(defaultMenu);

  return (
    <MenuContext.Provider
      value={{
        selectedMenu,
        setSelectedMenu,
      }}>
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
