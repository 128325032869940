import React from 'react';
import PropTypes from 'prop-types';

import Files from '../../atoms/files';
import ThumbCrop from '../../molecules/thumb-crop';
import Input from '../../atoms/input';

const Edit = ({
  coverArt,
  setCroppedAreaPixels,
  onArtworkChanged,
  wipTitle,
  setWipTitle,
  onUpdateWip,
  onPin,
  thumbSize,
  disabled,
  pinned,
}) => (
  <div className="app__slide app__upload">
    <div className="app__upload--thumb mb-3">
      <img src={coverArt.uri} alt="artwork" />
      {coverArt.uri && (
        <ThumbCrop
          path={coverArt.uri}
          setCroppedAreaPixels={setCroppedAreaPixels}
          thumbSize={thumbSize}
        />
      )}
      <Files onChange={onArtworkChanged}>Edit Cover</Files>
    </div>
    <div className="app__upload--form">
      <div className="app__upload--inputs">
        <Input
          name="name"
          className="app__input"
          placeholder="Add WIP Title"
          value={wipTitle}
          onChange={e => {
            setWipTitle(e.value);
          }}
        />
      </div>
    </div>

    <div className="app__upload--footer p-3">
      <button
        className={`app__upload--footer-action btn ${
          pinned ? 'btn-primary' : 'btn-outline'
        } mb-2`}
        type="button"
        onClick={onPin}>
        {pinned ? 'Pinned' : 'Pin'}
      </button>
      <button
        type="button"
        onClick={onUpdateWip}
        disabled={disabled}
        className="app__upload--footer-action btn btn-outline">
        <span>Done</span>
      </button>
    </div>
  </div>
);

Edit.defaultProps = {
  coverArt: {},
  setCroppedAreaPixels: () => {},
  onArtworkChanged: () => {},
  wipTitle: '',
  setWipTitle: () => {},
  onUpdateWip: () => {},
  onPin: () => {},
  thumbSize: 322,
  disabled: false,
  pinned: false,
};

Edit.propTypes = {
  coverArt: PropTypes.objectOf(PropTypes.any),
  setCroppedAreaPixels: PropTypes.func,
  onArtworkChanged: PropTypes.func,
  wipTitle: PropTypes.string,
  setWipTitle: PropTypes.func,
  onUpdateWip: PropTypes.func,
  onPin: PropTypes.func,
  thumbSize: PropTypes.number,
  disabled: PropTypes.bool,
  pinned: PropTypes.bool,
};

export default Edit;
