import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';

import {FIREBASE_CONFIG} from '../../../config';

// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);
const settings = {timestampsInSnapshots: true};

export const db = firebase.firestore();
db.settings({...settings, merge: true});

export const auth = firebase.auth();
export const storage = firebase.storage();

export const provider = new firebase.auth.GoogleAuthProvider();

const cloudFunctions = firebase.functions();
export const invokeCloudFunction = (functionName: string, options: any = {}) =>
  cloudFunctions.httpsCallable(functionName, options);

export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3_600_000;
remoteConfig.defaultConfig = {
  use_stream_uris: true,
};

export default firebase;
