import '@untitled/app/core/components/molecules/grid/style.scss';

import React, {useMemo} from 'react';
import {compareDesc} from 'date-fns';
import PropTypes from 'prop-types';
import GridItem from '@untitled/app/core/components/molecules/grid/grid-item';
import GridItemAdd from '@untitled/app/core/components/molecules/grid/grid-add';
import {getSafeRegexString} from '@untitled/app/core/helpers/helper-util';

const Grid = ({
  className,
  wips,
  pins,
  listens,
  wipJoins,
  authors,
  playerState,
  onSelect,
  onPlay,
  onAuthor,
  onAdd,
  exclude,
  search,
  uploadsProgress,
  onError,
}) => {
  const wipsArr = useMemo(
    () =>
      Object.values(wips ?? {}).reduce((acc, wip) => {
        if (!wip) {
          return acc;
        }

        return [...acc, wip];
      }, []),
    [wips],
  );
  const sortedWipsArr = useMemo(
    () =>
      wipsArr.sort((a, b) => {
        const aLastListened =
          a.id in listens ? new Date(listens[a.id]).getTime() : -1;
        const aJoined =
          a.id in wipJoins ? new Date(wipJoins[a.id]).getTime() : -1;
        const aTime = Math.max(
          ...[new Date(a.lastEdited).getTime(), aLastListened, aJoined],
        );

        const bLastListened =
          b.id in listens ? new Date(listens[b.id]).getTime() : -1;
        const bJoined =
          b.id in wipJoins ? new Date(wipJoins[b.id]).getTime() : -1;
        const bTime = Math.max(
          ...[new Date(b.lastEdited).getTime(), bLastListened, bJoined],
        );

        return compareDesc(aTime, bTime);
      }),
    [wipsArr, listens, wipJoins],
  );
  const {pinned, sorted} = useMemo(
    () =>
      sortedWipsArr.reduce(
        (acc, wip) => {
          if (pins.indexOf(wip.id) !== -1) {
            acc.pinned.push({...wip, pinned: true});
          } else {
            acc.sorted.push({...wip, pinned: false});
          }

          return acc;
        },
        {pinned: [], sorted: []},
      ),
    [sortedWipsArr, pins],
  );

  const {query} = search;

  const isVisible = (q = '', arr = []) => {
    const term = getSafeRegexString(q);
    const showQuery =
      term.length === 0 || arr.some(ar => new RegExp(term, 'gi').test(ar));
    return showQuery;
  };

  const iterate = useMemo(() => [...pinned, ...sorted], [pinned, sorted]);

  return (
    <div className={`app__grid ${className}`}>
      {onAdd && <GridItemAdd onChange={onAdd} multiple />}
      {iterate.map(wip => {
        const author = authors[wip.author] || {};
        const title = wip.title || {};
        const isPlaying = playerState.playing && wip.id === playerState.id;
        const showWip = exclude ? !exclude(wip) : true;
        const {cover} = wip.album;

        const showQuery = isVisible(query, [
          author.name,
          author.lastName,
          wip.title.text,
        ]);

        const currentProgress = uploadsProgress[wip.id];

        return (
          showWip &&
          showQuery && (
            <GridItem
              key={wip.id}
              title={title.text || new Date(wip.created).toLocaleDateString()}
              author={author.name}
              cover={cover}
              audio={wip.audioURL}
              id={wip.id}
              onSelect={onSelect}
              onPlay={() => {
                onPlay(wip);
              }}
              onAuthor={() => {
                onAuthor(wip.author);
              }}
              playing={isPlaying}
              time={wip.lastEdited}
              uploading={!!wip.uploading}
              uploadProgress={currentProgress}
              color={wip.hex}
              onError={onError}
              pinned={wip.pinned}
            />
          )
        );
      })}
    </div>
  );
};

Grid.defaultProps = {
  className: '',
  wips: {},
  pins: [],
  listens: {},
  wipJoins: {},
  search: {},
  authors: {},
  playerState: {},
  uploadsProgress: {},
  onSelect: () => {},
  onPlay: () => {},
  onAuthor: () => {},
  onError: () => {},
  onAdd: undefined,
  exclude: undefined,
  maxSize: 0,
};

Grid.propTypes = {
  className: PropTypes.string,
  wips: PropTypes.objectOf(PropTypes.any),
  pins: PropTypes.arrayOf(PropTypes.any),
  listens: PropTypes.objectOf(PropTypes.any),
  wipJoins: PropTypes.objectOf(PropTypes.any),
  search: PropTypes.objectOf(PropTypes.any),
  authors: PropTypes.objectOf(PropTypes.any),
  playerState: PropTypes.objectOf(PropTypes.any),
  uploadsProgress: PropTypes.objectOf(PropTypes.any),
  onSelect: PropTypes.func,
  onPlay: PropTypes.func,
  onAuthor: PropTypes.func,
  onError: PropTypes.func,
  onAdd: PropTypes.func,
  exclude: PropTypes.func,
  maxSize: PropTypes.number,
};

export default Grid;
