import React, {useEffect} from 'react';
import {useQuery, gql} from '@apollo/client';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {setLoader} from '@untitled/app/core/redux/actions/common';
import HocQueue from '@untitled/app/core/components/hoc/hoc-queue';
import Slides from '@untitled/app/core/components/molecules/slides';
import Scroller from '@untitled/app/core/components/atoms/scroller';
import {logErrorMessage} from '@untitled/app/core/helpers/helper-logs';
import {ShareTrackInfoBody} from '@untitled/app/core/components/organisms/share-track-info-body';
import {ShareTrackInfoHeader} from '@untitled/app/core/components/organisms/share-track-info-header';
import {WAITLIST_URL} from '@untitled/config';

const getPublicTrack = gql`
  query GetPublicTrack($id: ID!) {
    getPublicTrack(id: $id) {
      id
      created
      authorName
      wipCover
      title
      url
      fromRecording
      isPublic
    }
  }
`;

export interface PublicTrack {
  id: string;
  created: Date;
  authorName: string;
  wipCover: string;
  title: string;
  url: string;
  fromRecording: boolean;
  isPublic: boolean;
}

const ShareTrackPage: React.FC<{}> = () => {
  const {location} = useHistory();
  const dispatch = useDispatch();

  const trackId = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
  );

  const {data, loading, error} = useQuery(getPublicTrack, {
    variables: {
      id: trackId,
    },
  });

  /** @todo Add Amplitude analytics event for viewing public track. */
  // useEffect(() => {
  //   Analytics.logEventVisitPublicTrack(trackId);
  // }, [track]);

  useEffect(() => {
    if (!loading) {
      dispatch(setLoader(false));
    }
  }, [loading]);

  if (error) {
    logErrorMessage(error);
    window.location.hash = '';
    window.location.replace('/');
  }

  if (loading || data === undefined) {
    return null;
  }

  return (
    <div id="DetailsPage" className="app__page app__content">
      <div className="app__home">
        <Slides className="app__homebody" autoHeight={false}>
          <Scroller className="app__homebody--tab scrollable">
            <div className="app__info">
              <div className="app__info--join">
                <a
                  href={WAITLIST_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="app__info--join-btn">
                  <div className="">
                    join waitlist for{' '}
                    <span className="app__info--join-btn-untitled">
                      [untitled]
                    </span>
                  </div>
                </a>
              </div>
              <ShareTrackInfoHeader track={data.getPublicTrack} />
              <ShareTrackInfoBody track={data.getPublicTrack} />
            </div>
          </Scroller>
        </Slides>
      </div>
    </div>
  );
};

export default HocQueue(ShareTrackPage);
