import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import PlusIcon from 'react-feather/dist/icons/plus';
import {toast} from 'react-toastify';

import Reveal from '../../atoms/reveal';
import {IconBack, IconLink} from '../../atoms/icons';
import assets from '../../../assets';

const InfoHeader = ({
  coverURL,
  id,
  title,
  authorName,
  authorId,
  authorInitials,
  onAuthor,
  onAddAuthor,
  onDownloadAll,
  canEdit,
  onUnmount,
  onBack,
  onEdit,
  onNotes,
  confirmed = [],
  disabled,
}) => {
  const [loaded, setLoaded] = useState(false);
  const style = coverURL ? {backgroundImage: `url(${coverURL})`} : {};

  useEffect(
    () => () => {
      onUnmount();
    },
    [],
  );

  return (
    <div className="app__info--header">
      <div className="app__info--blur" style={style} />
      {id ? (
        <div className="app__info--header-container">
          <div className="app__info--header-thumb">
            <Reveal
              className={loaded ? 'animated' : ''}
              color="#DBDBDB"
              delay={0}
              duration={0.9}>
              <img
                width="100%"
                src={coverURL}
                alt={title}
                onLoad={() => {
                  setLoaded(true);
                }}
              />
            </Reveal>
          </div>
          <div className="app__info--header-info">
            <h1 className="truncate">{title || 'No title'}</h1>
            <div className="app__info--header-authors">
              <button
                className="app__miniauthor"
                onClick={() => {
                  onAuthor(authorId);
                }}
                type="button">
                <div className="app__miniauthor--initials light">
                  <span>{authorInitials}</span>
                </div>
                <div className="app__miniauthor--name">{authorName}</div>
              </button>
              {confirmed.map(
                cauthor =>
                  cauthor && (
                    <button
                      key={cauthor.id}
                      className="app__miniauthor"
                      onClick={() => {
                        onAuthor(cauthor.id);
                      }}
                      type="button">
                      <div className="app__miniauthor--initials">
                        <span>{cauthor.initials}</span>
                      </div>
                      <div className="app__miniauthor--name">
                        {cauthor.name}
                      </div>
                    </button>
                  ),
              )}

              {!disabled && (
                <button
                  type="button"
                  className="app__miniauthor"
                  onClick={onAddAuthor}>
                  <div className="app__miniauthor--add outline">
                    <PlusIcon size={14} strokeWidth="2" />
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="app__info--header-container">
          <div className="app__info--header-thumb">
            <img width="100%" src={assets.thumb} alt="thumb" />
          </div>
          <div className="app__info--header-info">
            <h1 className="truncate">Nothing to show here</h1>
          </div>
        </div>
      )}
      {id && onNotes && (
        <div className="app__info--actions-top">
          <button
            className="app__btn btn-outline"
            type="button"
            onClick={onNotes}>
            Notes
          </button>
        </div>
      )}
      <div className="app__info--actions-bottom">
        <div className="right_btns">
          {canEdit && (
            <button
              className="app__btn btn-outline"
              type="button"
              onClick={onEdit}>
              Edit WIP
            </button>
          )}
          {canEdit && onDownloadAll && (
            <button className="app__btn" type="button" onClick={onDownloadAll}>
              Download All
            </button>
          )}
        </div>
      </div>
      {onBack && (
        <button type="button" className="app__info--back" onClick={onBack}>
          <IconBack />
        </button>
      )}
    </div>
  );
};

InfoHeader.defaultProps = {
  coverURL: '',
  authorName: '',
  authorId: '',
  authorInitials: '',
  id: '',
  title: '',
  onAuthor: () => {},
  onAddAuthor: () => {},
  onDownloadAll: undefined,
  onUnmount: () => {},
  onBack: undefined,
  onEdit: () => {},
  onNotes: undefined,
  canEdit: false,
  confirmed: [],
  disabled: false,
};

InfoHeader.propTypes = {
  coverURL: PropTypes.string,
  authorName: PropTypes.string,
  authorId: PropTypes.string,
  authorInitials: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  onAuthor: PropTypes.func,
  onAddAuthor: PropTypes.func,
  onDownloadAll: PropTypes.func,
  onUnmount: PropTypes.func,
  onBack: PropTypes.func,
  onEdit: PropTypes.func,
  onNotes: PropTypes.func,
  canEdit: PropTypes.bool,
  confirmed: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default InfoHeader;
