import {toast} from 'react-toastify';
import {sep} from 'path';

import {setMachineData, setQueueInit} from '.';
import {APP_INIT, WATCHER_CHANGED} from '../../../../../../main/common';
import Encrypter from '../../../../../bridge/worker-encrypter';
import {
  appMounted,
  subscribeEvents,
  unbscribeEvents,
} from '../../../helpers/helper-electron-api';

import firebase, {db} from '../../../helpers/helper-firebase';
import {getQueueTree} from '../../../helpers/helper-queue';
import {
  ActivityType,
  AuthorMetaKinds,
  stageString,
  UntitledInstanceObjects,
  WIPMediaKinds,
} from '../../../helpers/helper-types';
import {parseFirebaseFields} from '../../../helpers/helper-untitled';
import {isElectron} from '../../../helpers/helper-useragent';
import Analytics from '../../../helpers/helper-analytics';
import {windowsPathToUnix} from '../../../helpers/helper-util';
import {allSettled} from '../../../helpers/helper-upload';
import UploadsManager from '../../../classes/uploads-manager';
import {
  generateNotifications,
  processActivities,
  sendPushNotification,
} from '../../../helpers/helper-notifications';
import {copyQueueToNewWip} from '../../../helpers/helper-cloud';
import {logErrorException} from '../../../helpers/helper-logs';

const uploadManager = UploadsManager.getInstance();

const {arrayUnion} = firebase.firestore.FieldValue;

export const listenQueue = userId => async dispatch => {
  const authorRef = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(userId);
  const metaRef = authorRef
    .collection(stageString(UntitledInstanceObjects.AuthorMeta))
    .doc(AuthorMetaKinds.machines);
  return db
    .collection(stageString(WIPMediaKinds.queue))
    .where('author', '==', authorRef)
    .onSnapshot(async querySnapshot => {
      const filesMap = [];
      const machinesDoc = await metaRef.get();
      const machinesDocData = parseFirebaseFields(machinesDoc.data());
      querySnapshot.forEach(doc => {
        if (machinesDocData[doc.id]) {
          const encrypter = new Encrypter(machinesDocData[doc.id], userId);
          const data = doc.data();
          try {
            const key = windowsPathToUnix(encrypter.decrypt(doc.id));
            const watchPath = windowsPathToUnix(
              encrypter.decrypt(data.watchPath),
            );
            const parent = watchPath.split(sep).slice(0, -1).join(sep);
            const filePath = key.split(parent).join('').split(sep).slice(1);
            filesMap.push({
              ...parseFirebaseFields(data),
              id: doc.id,
              key,
              watchPath,
              path: filePath,
            });
          } catch (error) {
            logErrorException(error);
          }
        }
      });
      const filesTree = getQueueTree(filesMap).map(f => ({
        ...f,
        route: f.key,
        url: f.key,
      }));
      dispatch(setMachineData({filesMap, filesTree}));
    });
};

// TODO: check https://github.com/jballant/webpack-strip-block

export const initQueueIpcAsync = userId => dispatch => {
  console.log('initQueueIpcAsync', userId);
  if (!isElectron) {
    listenQueue(userId)(dispatch);
    return () => {};
  }
  const dispatchSetQueue = (_, arg) => {
    console.log('dispatchSetQueue', arg);
    dispatch(setQueueInit(arg));
  };
  const dispatchSetMachineData = (_, arg) => {
    console.log(arg);
    dispatch(setMachineData(arg));
  };
  subscribeEvents(APP_INIT, dispatchSetQueue);
  subscribeEvents(WATCHER_CHANGED, dispatchSetMachineData);
  appMounted(userId);
  return () => {
    unbscribeEvents(APP_INIT, dispatchSetQueue);
    unbscribeEvents(WATCHER_CHANGED, dispatchSetMachineData);
  };
};

export const saveQueueConfig = queueConfig => {
  saveQueueConfig(queueConfig);
};

export const toastId = 'upload-queue-toast';
export const toastToWipId = 'toast-queue-to-wip';

export const addQueueToNewWIP =
  (wipData, uploadData) => async (_, getState) => {
    console.log('addQueueToNewWIP', wipData, uploadData);
    const {
      wipRef,
      author: authorRef,
      audio: audioRef,
      coverArt: coverArtRef,
    } = wipData;

    const {
      audioStorageRef,
      queueStorageRef,
      // audioBlob,
      // uriSound,
      coverArtStorageRef,
      coverArtBlob,
      uriImage,
      audioData,
      coverArtData,
      readers = [],
      writers = [],
    } = uploadData;

    const invited = [...readers, ...writers].map(id =>
      db.collection(stageString(UntitledInstanceObjects.Author)).doc(id),
    );

    toast.update(toastId, {
      progress: 0,
      render: 'Uploading files',
    });

    const copyFile = await copyQueueToNewWip({
      audioStoragePath: audioStorageRef.fullPath,
      queueStoragePath: queueStorageRef.fullPath,
    });

    if (copyFile.error) {
      toast.error(copyFile.message);

      toast.dismiss(toastId);
      return false;
    }

    const audioDownloadURL = copyFile.data;

    audioData.uri = audioDownloadURL;

    uploadManager.updateUpload({
      wipId: wipRef.id,
      uploadProgress: 1,
      fileId: audioRef.id,
      type: 'audio',
    });

    await audioRef.set(audioData);

    const [coverArtDownloadURL] = await Promise.all([
      new Promise(resolve => {
        coverArtStorageRef
          .put(coverArtBlob, {
            customMetadata: {
              author: authorRef.path,
              wip: wipRef.path,
              dbRef: coverArtRef.path,
              kind: WIPMediaKinds.image,
              storageRef: coverArtStorageRef.fullPath,
              imageRef: coverArtRef.id,
            },
          })
          .on(
            'state_changed',
            a => {
              uploadManager.updateUpload({
                wipId: wipRef.id,
                uploadProgress: a.bytesTransferred / a.totalBytes || 0,
                fileId: coverArtRef.id,
                type: 'image',
              });
            },
            e => {
              console.log('e', e);
            },
            () => coverArtStorageRef.getDownloadURL().then(resolve),
          );
      }),
    ]);
    coverArtData.uri = coverArtDownloadURL;

    await allSettled([
      coverArtRef.set(coverArtData),
      wipRef.set({
        ...wipData,
        album: {
          coverArtDownloadURL,
        },
        ready: true,
        uploading: null,
        invited,
      }),
    ]).then(() => {
      URL.revokeObjectURL(uriImage);
      // URL.revokeObjectURL(uriSound);

      toast.update(toastId, {
        autoClose: 3000,
        hideProgressBar: true,
        render: 'WIP created',
      });

      // TODO: properly determine the length of the uploaded audio
      const audioDuration = 26;
      Analytics.logEventCreateWIP('queue');
      Analytics.logEventCreateAudio(audioDuration, 'queue');

      for (let i = 0; i < readers.length; i += 1) {
        Analytics.logEventShareWip(readers[i], wipRef.id, false);
      }
      for (let i = 0; i < writers.length; i += 1) {
        Analytics.logEventShareWip(writers[i], wipRef.id, true);
      }
      return true;
    });

    const meta = {
      kind: ActivityType.newInvite,
    };

    const {author, users} = await processActivities(
      wipRef,
      authorRef,
      getState(),
      [...readers, ...writers],
      meta,
    );

    const notifications = generateNotifications({
      type: ActivityType.newInvite,
      users,
      audioId: audioRef.id,
      author,
      wip: {
        id: wipRef.id,
        ...uploadData,
        readers,
        writers,
        title: wipData.title,
      },
      audioTitle: audioData.title,
      wipTitle: uploadData.wipTitle,
      targetAll: true,
    });

    sendPushNotification(notifications);

    return {
      wipId: wipRef.id,
      audioId: audioRef.id,
    };
  };

export const addQueueToWIP = (wipData, uploadData) => async (_, getState) => {
  console.log('addQueueToWIP', wipData, uploadData);
  const {wips} = getState();
  const {wipRef, author: authorRef, audio: audioRef} = wipData;

  const {
    audioStorageRef,
    queueStorageRef,
    // audioBlob,
    audioData,
  } = uploadData;

  const wipList = {...wips.all, ...wips.notifications};
  const wip = wipList[wipRef.id];

  const now = firebase.firestore.Timestamp.now();

  toast.update(toastToWipId, {
    progress: 0,
    render: 'Uploading files',
  });

  const copyFile = await copyQueueToNewWip({
    audioStoragePath: audioStorageRef.fullPath,
    queueStoragePath: queueStorageRef.fullPath,
  });

  if (copyFile.error) {
    toast.error(copyFile.message);

    // toast.dismiss(toastToWipId);
    toast.update(toastToWipId, {
      progress: 0,
      render: 'Uploading files',
      autoClose: 1000,
    });
    return false;
  }

  const audioDownloadURL = copyFile.data;

  audioData.uri = audioDownloadURL;

  uploadManager.updateUpload({
    wipId: wipRef.id,
    uploadProgress: 1,
    fileId: audioRef.id,
    type: 'audio',
  });

  const updates = {
    lastEdited: now,
    audios: arrayUnion(audioRef),
  };

  allSettled([audioRef.set(audioData), wipRef.update(updates)]).then(() => {
    toast.update(toastToWipId, {
      autoClose: 5000,
      hideProgressBar: true,
      render: 'Audio added to WIP',
    });

    // TODO: properly determine the length of the uploaded audio
    const audioDuration = 49;
    Analytics.logEventCreateAudio(audioDuration, 'queue');
  });

  const confirmed = wip.confirmed || [];

  const meta = {
    kind: ActivityType.newAudio,
    audioId: audioRef.id,
  };

  const {author, users} = await processActivities(
    wipRef,
    authorRef,
    getState(),
    [wip.author, ...confirmed],
    meta,
  );

  const notifications = generateNotifications({
    type: ActivityType.newAudio,
    users,
    audioId: audioRef.id,
    author,
    wip,
    audioTitle: audioData.title,
  });

  sendPushNotification(notifications);

  return {
    wipId: wipRef.id,
    audioId: audioRef.id,
  };
};
