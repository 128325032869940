import {
  AUTH_CHANGED,
  CLAIMS_CHANGED,
  SET_LOADER,
  SET_MODAL,
  HIDE_INSTALL_MESSAGE,
  RESET,
  REMEMBER_ME,
  REMEMBER_ME_EMAIL,
  SELECT_MENU,
  TOGGLE_THEME,
  MFA_VERIFIED,
  SET_SHORTCUTS_ENABLED,
} from '../actions/common/constants';

const defaultState = {
  user: null,
  claims: {},
  loading: true,
  showInstallMessage: true,
  isAdmin: false,
  darkTheme: false,
  modal: '',
  menu: '',
  rememberMe: false,
  rememberMeEmail: '',
  shortcutsEnabled: true,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_THEME: {
      const darkTheme = !state.darkTheme;
      return {
        ...state,
        darkTheme,
      };
    }

    case AUTH_CHANGED: {
      return {
        ...state,
        user: action.payload || null,
      };
    }

    case MFA_VERIFIED: {
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            mfaVerified: action.payload,
          },
        };
      }

      console.error('MFA_VERIFIED with user unset');
      return state;
    }

    case CLAIMS_CHANGED: {
      return {
        ...state,
        claims: action.payload || {},
      };
    }

    case SET_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case SET_MODAL: {
      return {
        ...state,
        modal: action.payload,
      };
    }

    case HIDE_INSTALL_MESSAGE: {
      return {
        ...state,
        showInstallMessage: false,
      };
    }

    case REMEMBER_ME: {
      return {
        ...state,
        rememberMe: action.payload,
      };
    }

    case REMEMBER_ME_EMAIL: {
      return {
        ...state,
        rememberMeEmail: action.payload,
      };
    }

    case SELECT_MENU: {
      return {
        ...state,
        menu: action.payload,
      };
    }

    case SET_SHORTCUTS_ENABLED: {
      return {
        ...state,
        shortcutsEnabled: action.payload,
      };
    }

    case RESET: {
      const {rememberMe, rememberMeEmail} = state;
      if (rememberMe) {
        return {
          ...defaultState,
          rememberMe,
          rememberMeEmail,
        };
      }
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
