import {Integrations} from '@sentry/tracing';

export const FIREBASE_CONFIG_PROD = {
  apiKey: 'AIzaSyBngKXcRxozPhK4GZx3oWcqBUEvRFJYoCA',
  authDomain: 'untitled-app-55769.firebaseapp.com',
  databaseURL: 'https://untitled-app-55769.firebaseio.com',
  projectId: 'untitled-app-55769',
  storageBucket: 'untitled-app-55769.appspot.com',
  messagingSenderId: '752575192307',
  appId: '1:752575192307:web:abe35d770f329854baeb86',
  measurementId: 'G-2ZKQCNP7BE',
};

export const FIREBASE_CONFIG_DEV = {
  apiKey: 'AIzaSyBngKXcRxozPhK4GZx3oWcqBUEvRFJYoCA',
  authDomain: 'untitled-app-55769.firebaseapp.com',
  databaseURL: 'https://untitled-app-55769.firebaseio.com',
  projectId: 'untitled-app-55769',
  storageBucket: 'untitled-app-55769.appspot.com',
  messagingSenderId: '752575192307',
  appId: '1:752575192307:web:abe35d770f329854baeb86',
  measurementId: 'G-2ZKQCNP7BE',
};

// export const FIREBASE_CONFIG = FIREBASE_CONFIG_PROD;
// export const FIREBASE_CONFIG = FIREBASE_CONFIG_DEV;
export const FIREBASE_CONFIG =
  process.env.NODE_ENV === 'development'
    ? FIREBASE_CONFIG_DEV
    : FIREBASE_CONFIG_PROD;

export const FIRESTORE_REST = `https://firestore.googleapis.com/v1/projects/${FIREBASE_CONFIG.projectId}/databases/(default)/documents`;

export const AMPLITUDE_CONFIG = {
  apiKey: '8b533cf8fc9b3cecd57eda7482f7d63b',
  projectId: '337551',
};

export const SENTRY_CONFIG = {
  environment: process.env.NODE_ENV,
  dsn: 'https://704dc654180246a680888151dd60df87@o1007533.ingest.sentry.io/6095772',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
};

// Set this to true, if you want to use it on development
export const ENABLE_REDUX_TOOLS = false;

export const SENTRY_ENABLED = process.env.NODE_ENV === 'production';

export const WAITLIST_URL = 'https://untitledinbrackets.typeform.com/waitlist';
