import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import AccountHeader from '../../molecules/account/header';
import {getSortedTracks, mergeWIPData} from '../../../helpers/helper-untitled';
import Grid from '../../molecules/grid';
import {UPlayerContext} from '../../context/player-context';
import {authorsSelect} from '../../../redux/actions/authors';
import {MenuContext} from '../../context/menu-context';

const UserProfile = ({wips, authors, audios, author, selectAuthor}) => {
  const [common, setCommon] = useState({});
  const {playerState, playSong} = useContext(UPlayerContext);
  const {setSelectedMenu} = useContext(MenuContext);
  const scrollRef = useRef(null);
  const history = useHistory();

  const {initials, name, lastName, email} = author;

  const onSelect = wipUid => {
    history.replace(`/details?wip=${wipUid}`);
    setSelectedMenu('');
  };

  const onAuthor = authorId => {
    selectAuthor({...authors[authorId], id: authorId});
    setSelectedMenu('profile');
  };

  const onPlay = wip => {
    const audiosSorted = getSortedTracks(wip, authors, audios);
    const index = playerState.id === wip.id ? playerState.index : 0;
    playSong(wip.id, audiosSorted, index);
  };

  useEffect(() => {
    const newCommon = {};
    Object.keys(wips).forEach(k => {
      const wip = wips[k];
      const isAuthor = wip.author === author.id;
      const isConfirmed =
        wip.confirmed && wip.confirmed.indexOf(author.id) > -1;
      if (isAuthor || isConfirmed) {
        newCommon[k] = wip;
      }
    });
    setCommon(newCommon);
  }, [author, wips]);
  return (
    <div className="app__group" ref={scrollRef}>
      <div className="app__account">
        <AccountHeader initials={initials} name={`${name} ${lastName}`} />
        <div className="app__account--body">
          <h3>WIPs in Common</h3>
          <Grid
            wips={common}
            authors={authors}
            playerState={playerState}
            onSelect={onSelect}
            onPlay={onPlay}
            onAuthor={onAuthor}
          />
        </div>
      </div>
    </div>
  );
};

UserProfile.defaultProps = {
  author: {},
  wips: {},
  authors: {},
  audios: {},
  selectAuthor: () => {},
};

UserProfile.propTypes = {
  author: PropTypes.objectOf(PropTypes.any),
  wips: PropTypes.objectOf(PropTypes.any),
  authors: PropTypes.objectOf(PropTypes.any),
  audios: PropTypes.objectOf(PropTypes.any),
  selectAuthor: PropTypes.func,
};

const mapStateToProps = ({authors, audios, wips}) => ({
  author: authors.selected,
  wips: mergeWIPData(wips.all, audios.all, authors.users),
  authors: authors.users,
  audios: audios.all,
});

const mapDispatchToProps = {
  selectAuthor: authorsSelect,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
