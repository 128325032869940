import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';

import {
  authLogout,
  authPassword,
  authUpdate,
} from '../../../redux/actions/common/async';

import {toggleTheme} from '../../../redux/actions/common';
import {UPlayerContext} from '../../context/player-context';

import AccountHeader from '../../molecules/account/header';
import Reveal from '../../atoms/reveal';
import Switch from '../../atoms/switch';
import {MenuContext} from '../../context/menu-context';
import {downloadFromJS} from '../../../helpers/helper-download';
import Analytics from '../../../helpers/helper-analytics';
import {isElectron} from '../../../helpers/helper-useragent';

const SHOW_DOWNLOAD_APP = false;

const UserAccount = ({
  user,
  claims,
  authors,
  updateAuth,
  logoutAuth,
  passwordAuth,
  darkTheme,
  onToggleTheme,
}) => {
  const [curUser, setCurUser] = useState({});
  const [password, setPassword] = useState('');
  const {player} = useContext(UPlayerContext);
  const {setSelectedMenu} = useContext(MenuContext);
  const history = useHistory();

  const {name = '', lastName = '', initials, email} = curUser;

  const displayName = `${name} ${lastName}`;

  const onChange = e => {
    setCurUser({
      ...curUser,
      [e.target.name]: e.target.value,
    });
  };
  const onPassChange = e => {
    setPassword(e.target.value);
  };
  const onLogout = () => {
    player.destroy();
    logoutAuth().then(() => {
      setSelectedMenu('');
      history.push('/');
    });
  };
  const onPassword = () => {
    passwordAuth(password);
  };

  const keyPress = e => {
    if (e.key === 'Enter') {
      updateAuth({
        main: {
          email: curUser.email,
        },
        user: curUser,
      });
    }
  };

  const onAdmin = () => {
    history.push('/admin');
  };

  useEffect(() => {
    setCurUser({
      ...user,
      ...authors[user.uid],
    });
  }, [user]);

  return (
    <div className="relative">
      <div className="app__account">
        <AccountHeader initials={initials} name={displayName} />
        <div className="app__account--body">
          <div className="app__account--form">
            <div className="block">
              <Reveal
                className={curUser.uid ? 'animated mb-3' : 'mb-3'}
                color="#DBDBDB"
                delay={0}
                duration={0.9}>
                <label>
                  <span>First Name</span>
                  <input
                    name="name"
                    className="app__input px-2"
                    type="text"
                    placeholder="First Name"
                    value={name || ''}
                    onChange={onChange}
                    onKeyPress={keyPress}
                  />
                </label>
              </Reveal>
            </div>
            <div className="block">
              <Reveal
                className={curUser.uid ? 'animated mb-3' : 'mb-3'}
                color="#DBDBDB"
                delay={0}
                duration={0.9}>
                <label>
                  <span>Last Name</span>
                  <input
                    name="lastName"
                    className="app__input px-2"
                    type="text"
                    placeholder="Last Name"
                    value={lastName || ''}
                    onChange={onChange}
                    onKeyPress={keyPress}
                  />
                </label>
              </Reveal>
            </div>
            <div className="block">
              <Reveal
                className={curUser.uid ? 'animated mb-3' : 'mb-3'}
                color="#DBDBDB"
                delay={0}
                duration={0.9}>
                <label>
                  <span>Email</span>
                  <input
                    name="email"
                    className="app__input px-2"
                    type="email"
                    placeholder="Email"
                    value={email || ''}
                    onChange={onChange}
                    onKeyPress={keyPress}
                  />
                </label>
              </Reveal>
            </div>
            <div className="block">
              <Reveal
                className={curUser.uid ? 'animated mb-3' : 'mb-3'}
                color="#DBDBDB"
                delay={0}
                duration={0.9}>
                <label>
                  <span>Password</span>
                  <input
                    name="password"
                    className="app__input px-2"
                    type="password"
                    placeholder="New Password"
                    value={password || ''}
                    onChange={onPassChange}
                  />
                </label>
              </Reveal>
            </div>
            <button
              type="button"
              className="app__link mb-3"
              onClick={onPassword}>
              <small>Change your password</small>
            </button>
            <div className="block mb-4">
              <label htmlFor="darkMode">
                <span>Dark Mode:</span>
                <Switch
                  id="darkMode"
                  value={darkTheme}
                  onChange={onToggleTheme}
                />
              </label>
            </div>
          </div>
          <div>
            {!document.body.classList.contains('isElectron') &&
              SHOW_DOWNLOAD_APP && (
                <button
                  type="button"
                  className="app__info--download-btn app__btn"
                  onClick={() => {
                    downloadFromJS(
                      'https://github.com/untitled-in-brackets/untitled-desktop-releases/releases/latest/download/untitled.dmg',
                    );
                    Analytics.logEventDownloadDesktopApp('mac');
                  }}>
                  Download Mac App
                </button>
              )}
          </div>
          <div className="app__account--form">
            <button
              type="button"
              className="app__btn btn-outline-purple mt-4"
              onClick={onLogout}>
              Logout
            </button>
            {claims.admin && !isElectron && (
              <button type="button" className="app__btn mt-4" onClick={onAdmin}>
                Admin
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UserAccount.defaultProps = {
  user: {},
  claims: {},
  authors: {},
  updateAuth: () => {},
  logoutAuth: () => {},
  passwordAuth: () => {},
  onToggleTheme: () => {},
  darkTheme: false,
};

UserAccount.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  claims: PropTypes.objectOf(PropTypes.any),
  authors: PropTypes.objectOf(PropTypes.any),
  updateAuth: PropTypes.func,
  logoutAuth: PropTypes.func,
  passwordAuth: PropTypes.func,
  onToggleTheme: PropTypes.func,
  darkTheme: PropTypes.bool,
};

const mapStateToProps = ({common, authors}) => ({
  user: common.user,
  claims: common.claims,
  darkTheme: common.darkTheme,
  authors: authors.users,
});

const mapDispatchToProps = {
  logoutAuth: authLogout,
  updateAuth: authUpdate,
  passwordAuth: authPassword,
  onToggleTheme: toggleTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);
