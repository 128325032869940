/* eslint-disable @typescript-eslint/no-empty-function */
import React, {useContext} from 'react';
import {ShareWipPreviewTrack} from '@untitled/app/core/components/molecules/share-wip-preview-track';
import type {WIP} from '@untitled/app/core/components/templates/share';
import {UPlayerContext} from '@untitled/app/core/components/context/player-context';

type Props = {
  wip: WIP;
};

export const InfoBody: React.FC<Props> = ({wip}) => {
  const {player, playerState, setPlayerState} = useContext(UPlayerContext);

  React.useEffect(() => {
    const unsubscribe = player.addListener('state_changed', setPlayerState);

    return unsubscribe;
  }, []);

  const handlePlay = React.useCallback(
    (id: string) => {
      const index = wip.tracks.map(a => a.id).indexOf(id);
      const playlist = wip.tracks.map(track => ({
        id: track.id,
        title: track.title,
        cover: wip.cover,
        authorName: track.author,
        uri: track.url,
      }));

      if (index !== -1) {
        if (!player.created) {
          player.create(wip.id, playlist, index, wip.cover, true);
          player.play(index);
        } else if (player.index === index) {
          if (player.playing) {
            player.pause();
          } else {
            player.play(index);
          }
        } else {
          player.skipTo(index);
        }
      }
    },
    [wip],
  );

  return (
    <div className="app__info--body">
      {[...wip.tracks].map((track, index) => {
        const playing = playerState.playing && playerState.index === index;

        return (
          <ShareWipPreviewTrack
            key={track.id}
            author={track.author}
            created={track.created}
            fromRecording={track.fromRecording}
            id={track.id}
            onPlay={() => handlePlay(track.id)}
            playing={playing}
            title={track.title}
          />
        );
      })}
    </div>
  );
};
