import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/react';

import RootWeb from '@untitled/app/root-web';

import {
  isIE,
  isInStandalone,
  isMobile,
} from '@untitled/app/core/helpers/helper-useragent';

import '@untitled/app/core/assets/imports-web';

import {SENTRY_CONFIG, SENTRY_ENABLED} from '@untitled/config';

if (SENTRY_ENABLED) {
  Sentry.init(SENTRY_CONFIG);
}

if (isMobile) {
  document.body.classList.add('is-mobile');
} else {
  document.body.classList.add('not-mobile');
}

if (isIE) {
  document.body.classList.add('isIE');
}

if (isInStandalone) {
  document.body.classList.add('isStandalone');
  const root = document.getElementsByTagName('html')[0];
  root.classList.add('class', 'isStandalone');
}

const container = document.getElementById('Root') as HTMLElement;
const root = createRoot(container);
root.render(RootWeb());
