/* eslint-disable no-underscore-dangle */

import {toast} from 'react-toastify';
import * as Sentry from '@sentry/react';

import firebase, {
  db,
  auth,
  provider,
  invokeCloudFunction,
} from '../../../helpers/helper-firebase';

import Analytics from '../../../helpers/helper-analytics';

import {
  userLogged,
  setMfaVerified,
  setLoader,
  resetCommon,
  setRememberMeEmail,
  claimsChanged,
} from './index';

import {authorsReset} from '../authors';
import {listenAuhtors} from '../authors/async';
import {wipsReset} from '../wips';
import {
  listenLastListened,
  listenPinWIPS,
  listenWipJoins,
  listenWIPS,
} from '../wips/async';
import {audiosReset} from '../audios';
import {listenAudios} from '../audios/async';
import {artworksReset} from '../artworks';
import {listenActivitys, listenReads} from '../activity/async';
import {activitysReset} from '../activity';
import {initQueueIpcAsync} from '../queue/async';
import {queueReset} from '../queue';
import {logErrorMessage} from '../../../helpers/helper-logs';

let wipUnsubscribe = null;
let pinUnsubscribe = null;
let lastListenedUnsubscribe = null;
let wipJoinsUnsubscribe = null;
let authorsUnsubscribe = null;
let audioUnsubscribe = null;
let activitysUnsubscribe = null;
let readsUnsubscribe = null;

let queueUnsubscribe = null;

const onErrorDisplay = (getState, message, dispatch) => {
  logErrorMessage(message);
  toast.error(message, {hideProgressBar: true});
  const {common} = getState();
  if (common.loading) {
    dispatch(setLoader(false));
  }
  return false;
};

export const onAuthChange = history => (dispatch, getState) =>
  auth.onAuthStateChanged(async user => {
    if (user) {
      const {common} = getState();
      const {uid, displayName, email, photoURL} = user;

      let mfaEnabled = false;
      let phoneNumber = '';

      try {
        const data = (
          await db.collection('developmentAuthor').doc(uid).get()
        ).data();
        mfaEnabled = data.mfaEnabled ?? false;
        phoneNumber = data.phoneNumber ?? '';
      } catch (error) {
        auth.signOut();
        onErrorDisplay(getState, error.message, dispatch);
      }

      const mfaVerified = common.user?.mfaVerified ?? false;

      if (mfaEnabled && !mfaVerified) {
        invokeCloudFunction('generateMFACode')({uid: user.uid}).catch(() => {
          // eslint-disable-next-line no-use-before-define
          authLogout();
          onErrorDisplay(
            getState,
            'There was an error logging in. Please try again in a bit.',
            dispatch,
          );
        });
      }

      const loggedUser = {
        uid,
        displayName,
        email,
        photoURL,
        mfaEnabled,
        mfaVerified,
        phoneNumber,
      };

      dispatch(userLogged(loggedUser));

      wipUnsubscribe = listenWIPS(uid, history)(dispatch, getState);
      pinUnsubscribe = listenPinWIPS(uid)(dispatch);
      lastListenedUnsubscribe = listenLastListened(uid)(dispatch);
      wipJoinsUnsubscribe = listenWipJoins(uid)(dispatch);
      authorsUnsubscribe = listenAuhtors()(dispatch);
      audioUnsubscribe = listenAudios(uid)(dispatch);
      readsUnsubscribe = listenReads(uid)(dispatch);
      activitysUnsubscribe = listenActivitys(uid)(dispatch, getState);
      queueUnsubscribe = initQueueIpcAsync(uid)(dispatch);

      Analytics.setUserId(user);
      Analytics.logEventOpenApp();

      Sentry.setUser({id: user.uid, email: user.email});

      auth.currentUser
        .getIdTokenResult()
        .then(idTokenResult => {
          const {admin} = idTokenResult.claims;
          dispatch(claimsChanged({admin}));
        })
        .catch(error => {
          console.log(error);
          claimsChanged({});
        });
    } else {
      dispatch(userLogged());
      dispatch(claimsChanged({}));
      dispatch(wipsReset());
      dispatch(artworksReset());
      dispatch(audiosReset());
      dispatch(authorsReset());
      dispatch(activitysReset());

      Sentry.setUser(null);

      if (wipUnsubscribe) {
        wipUnsubscribe();
      }
      if (pinUnsubscribe) {
        pinUnsubscribe();
      }
      if (lastListenedUnsubscribe) {
        lastListenedUnsubscribe();
      }
      if (wipJoinsUnsubscribe) {
        wipJoinsUnsubscribe();
      }
      if (authorsUnsubscribe) {
        authorsUnsubscribe();
      }
      if (audioUnsubscribe) {
        audioUnsubscribe();
      }
      if (activitysUnsubscribe) {
        activitysUnsubscribe();
      }
      if (readsUnsubscribe) {
        readsUnsubscribe();
      }
      if (queueUnsubscribe) {
        queueUnsubscribe();
      }
    }
  });

export const userLogin =
  (email, password, rememberMe) => (dispatch, getState) => {
    const state = getState();
    const {loading} = state.common;
    if (!loading) {
      dispatch(setLoader(true));
    }
    if (rememberMe) {
      dispatch(setRememberMeEmail(email));
    }
    return auth
      .signInWithEmailAndPassword(email, password)
      .then(({user}) => {
        Analytics.setUserId(user);
        Analytics.logEventLogin();
      })
      .catch(error => onErrorDisplay(getState, error.message, dispatch));
  };

export const verifyMFACode = mfaCode => (dispatch, getState) => {
  const state = getState();
  const {loading, user} = state.common;

  if (!loading) {
    dispatch(setLoader(true));
  }

  invokeCloudFunction('verifyMFACode')({uid: user.uid, token: mfaCode})
    .then(({data}) => {
      const {verified} = data;
      dispatch(setLoader(false));
      if (!verified) {
        onErrorDisplay(getState, 'Incorrect code, try again!', dispatch);
      }
      dispatch(setMfaVerified(verified));
    })
    .catch(error => {
      console.warn(error);
      dispatch(setLoader(false));
      dispatch(setMfaVerified(false));
    });
};

export const userRegister = (email, password, data) => (dispatch, getState) => {
  const user = data;
  delete user.password;
  delete user.passwordConfirm;
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser =>
      db
        .collection('developmentAuthor')
        .doc(authUser.user?.uid)
        .set({
          ...user,
          initials: (
            user.name.substr(0, 1) + user.lastName.substr(0, 1)
          ).toLocaleUpperCase(),
          email,
          created: firebase.firestore.Timestamp.now(),
          lastEdited: firebase.firestore.Timestamp.now(),
        }),
    )
    .catch(error => onErrorDisplay(getState, error.message, dispatch));
};

export const authLogin = () => (dispatch, getState) => {
  const state = getState();
  const {loading} = state.common;
  if (!loading) {
    dispatch(setLoader(true));
  }
  return auth
    .signInWithPopup(provider)
    .then(user => {
      console.log('Logged', user);
      if (loading) {
        dispatch(setLoader(false));
      }
    })
    .catch(error => {
      logErrorMessage(error.message);
      if (loading) {
        dispatch(setLoader(false));
      }
    });
};

export const authAnonymous = () =>
  auth
    .signInAnonymously()
    .then(data => {
      console.log(data);
    })
    .catch(error => {
      console.error(error.message);
    });

export const authLogout = () => (dispatch, getState) => {
  const state = getState();
  const {loading} = state.common;
  if (!loading) {
    dispatch(setLoader(true));
  }
  return auth
    .signOut()
    .then(() => {
      dispatch(userLogged());
      dispatch(resetCommon());
      dispatch(queueReset());
      dispatch(setLoader(false));
      return true;
    })
    .catch(logErrorMessage);
};

export const authUpdate =
  ({main, user}) =>
  (dispatch, getState) => {
    const state = getState();
    const {loading} = state.common;
    if (!loading) {
      dispatch(setLoader(true));
    }
    const mainData = JSON.parse(JSON.stringify(main));
    const uUpdates = JSON.parse(JSON.stringify(user));
    Object.keys(uUpdates).forEach(
      key => uUpdates[key] == null && delete uUpdates[key],
    );
    const {name, lastName, email, created} = uUpdates;
    const initials = (
      name.substr(0, 1) + lastName.substr(0, 1)
    ).toLocaleUpperCase();
    return auth.currentUser
      .updateProfile(mainData)
      .then(() =>
        db
          .collection('developmentAuthor')
          .doc(auth.currentUser.uid)
          .set({
            ...uUpdates,
            initials,
            email,
            created: firebase.firestore.Timestamp.fromDate(new Date(created)),
            lastEdited: firebase.firestore.Timestamp.now(),
          }),
      )
      .then(() => {
        toast('Author updated', {hideProgressBar: true});
        dispatch(setLoader(false));
        return true;
      })
      .catch(error => onErrorDisplay(getState, error.message, dispatch));
  };

export const authPassword = newPassword => (dispatch, getState) => {
  const state = getState();
  const {loading} = state.common;
  if (!loading) {
    dispatch(setLoader(true));
  }
  return auth.currentUser
    .updatePassword(newPassword)
    .then(() => {
      toast('Password updated', {hideProgressBar: true});
      dispatch(setLoader(false));
      return true;
    })
    .catch(error => onErrorDisplay(getState, error.message, dispatch));
};

export const authResetPass = email => () =>
  auth
    .sendPasswordResetEmail(email)
    .then(() => {
      // Email sent.
      toast.info('Check your inbox', {autoClose: false});
    })
    .catch(error => {
      toast.error(error.message, {autoClose: 5000});
    });
