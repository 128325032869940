import JSZip from 'jszip';

export const downloadFromURL = (url, name) => {
  const throwawayLink = document.createElement('a');
  throwawayLink.setAttribute('href', url);
  throwawayLink.setAttribute('download', name);
  throwawayLink.setAttribute('style', 'visibility: hidden; display:none');

  document.body.appendChild(throwawayLink);
  throwawayLink.click();
  setTimeout(() => {
    throwawayLink.remove();
  }, 3e3);
};

export const downloadFromJS = url => {
  downloadFromURL(url, 'untitled.dmg');
};

export const downloadUrls = (audios, title) => {
  const zip = new JSZip();
  const folder = zip.folder(title);

  const getUrl = async audio => {
    const audioBlob = await fetch(audio.uri).then(f => f.blob());
    folder.file(`${audio.title}-${Date.now().toString(16)}.mp3`, audioBlob);
    return audioBlob;
  };

  Promise.all(audios.map(audio => getUrl(audio))).then(() => {
    folder
      .generateAsync({
        type: 'blob',
      })
      .then(content => {
        const blobURL = window.URL.createObjectURL(content);
        downloadFromURL(blobURL, title);
      });
  });
};

export default JSZip;
