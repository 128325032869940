import React from 'react';
import {IconPause, IconPlay} from '@untitled/app/core/components/atoms/icons';
import TimeAgo from '@untitled/app/core/components/atoms/timeago';
import styled from 'styled-components';

const StyledRoot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid var(--border);
  flex: 1;
  margin-bottom: 16px;
  align-items: center;
`;

const StyledButtonPlay = styled.button`
  width: 36px;
  height: 36px;
  margin-right: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  > svg {
    fill: #000000;
    fill: var(--fg);
  }
`;

const StyledContainerTrackInfo = styled.div`
  flex: 1;
  max-width: calc(100% - 140px);

  .metadata-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .author-container {
    margin-right: 6px;
  }

  h5,
  span,
  div,
  small {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledContainerTrackMetadata = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

interface IShareWipPreviewTrackProps {
  id: string;
  title: string;
  author: string;
  created: string;
  playing: boolean;
  onPlay: () => void;
  fromRecording: boolean;
}
export const ShareWipPreviewTrack: React.FC<IShareWipPreviewTrackProps> = ({
  id,
  title,
  author,
  created,
  playing,
  onPlay,
  fromRecording,
}) => (
  <StyledRoot data-id={id}>
    <StyledCard>
      <StyledButtonPlay
        type="button"
        className="app__preview--play"
        onClick={onPlay}
        disabled={false}>
        {playing ? <IconPause size={48} /> : <IconPlay size={48} />}
      </StyledButtonPlay>
      <StyledContainerTrackInfo>
        <h4 className="truncate">{title}</h4>
        <StyledContainerTrackMetadata>
          <div style={{marginRight: 6}}>
            <small style={{fontWeight: 500}}>{author}</small>
            <small> - </small>
            <small>
              {/** @ts-expect-error */}
              <TimeAgo datetime={created} />
            </small>
          </div>
          {fromRecording && (
            <div>
              <small style={{color: 'var(--primary)'}}>
                recorded on [untitled]
              </small>
            </div>
          )}
        </StyledContainerTrackMetadata>
      </StyledContainerTrackInfo>
    </StyledCard>
  </StyledRoot>
);
