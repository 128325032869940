import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setShortcutsEnabled} from '@untitled/app/core/redux/actions/common';
import Menu from '..';
import MenuHeader from '../menu-header';
import Notes from '../../notes';

interface MenuNotesProps {
  menuOpened: boolean;
  onCloseMenu: () => void;
  renderHeaderActions: () => JSX.Element;
  user: any;
  selectedWip: any;
}

export const MenuNotes: React.FC<MenuNotesProps> = ({
  menuOpened,
  onCloseMenu,
  renderHeaderActions,
  user,
  selectedWip,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (menuOpened) {
      dispatch(setShortcutsEnabled(false));
    } else {
      dispatch(setShortcutsEnabled(true));
    }
  }, [menuOpened]);

  return (
    /** @ts-expect-error <Menu> component needs types */
    <Menu menuOpened={menuOpened} onClose={onCloseMenu}>
      <div className="app__container">
        <MenuHeader
          title="Notes"
          onClose={onCloseMenu}
          titleActions={renderHeaderActions}
        />
        <Notes user={user} wip={selectedWip} />
      </div>
    </Menu>
  );
};
