import React from 'react';
import PropTypes from 'prop-types';

const MenuHeader = ({title, onClose, titleActions}) => (
  <div className="app__menu--header">
    <div className="app__menu--header-title">
      {titleActions && titleActions()}
      <span className="truncate" style={{marginLeft: titleActions ? 8 : 22}}>
        {title}
      </span>
    </div>
    <button className="app__menu--header-close" type="button" onClick={onClose}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.405544 0.405544C0.94627 -0.135182 1.82296 -0.135182 2.36369 0.405544L13.4406 11.4825C13.9813 12.0232 13.9813 12.8999 13.4406 13.4406C12.8999 13.9813 12.0232 13.9813 11.4825 13.4406L0.405544 2.36369C-0.135182 1.82296 -0.135182 0.94627 0.405544 0.405544Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4406 0.405544C13.9813 0.94627 13.9813 1.82296 13.4406 2.36369L2.36369 13.4406C1.82296 13.9813 0.94627 13.9813 0.405544 13.4406C-0.135182 12.8999 -0.135182 12.0232 0.405544 11.4825L11.4825 0.405544C12.0232 -0.135182 12.8999 -0.135182 13.4406 0.405544Z"
        />
      </svg>
    </button>
  </div>
);

MenuHeader.defaultProps = {
  title: '',
  onClose: () => {},
  titleActions: false,
};

MenuHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  titleActions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default MenuHeader;
