import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

import Clickable from '../../atoms/clickable';

import styles from './style.scss';

const TRANS_SEC = parseFloat(styles.menuMillis || 0.5) * 1000;

const Menu = ({
  children,
  menuOpened,
  onClose,
  tootlip,
  unmountOnExit,
  renderFooter,
}) => {
  const tooltipRef = useRef(null);
  const mouseMove = e => {
    if (!tootlip || (!menuOpened && !tooltipRef.current)) {
      return;
    }
    // console.log(tooltipRef.current)
    const x = `${e.clientX}px`;
    const y = `${e.clientY - 20}px`;
    tooltipRef.current.style.top = y;
    tooltipRef.current.style.left = x;
  };
  useEffect(() => {
    if (menuOpened) {
      window.addEventListener('mousemove', mouseMove);
    }
    return () => {
      window.removeEventListener('mousemove', mouseMove);
    };
  }, [menuOpened]);
  const classHolder = menuOpened ? 'app__menu--holder-opened' : '';
  const classOverlay = menuOpened ? 'app__menu--overlay-opened' : '';
  return (
    <>
      <CSSTransition
        in={menuOpened}
        timeout={TRANS_SEC}
        classNames="transition-menu"
        unmountOnExit={unmountOnExit}>
        <Clickable
          className={`app__menu--overlay ${classOverlay}`}
          onClick={onClose}
          ariaLabel="Close">
          {tootlip ? (
            <div
              className="app__menu--overlay-tooltip color-bg"
              ref={tooltipRef}>
              Close
            </div>
          ) : (
            <div />
          )}
        </Clickable>
      </CSSTransition>
      <div className={`app__menu--holder ${classHolder}`}>
        <div className="app__menu--wrap scrollable">
          {menuOpened && children}
        </div>
        {renderFooter && renderFooter()}
      </div>
    </>
  );
};

Menu.defaultProps = {
  menuOpened: false,
  tootlip: false,
  unmountOnExit: true,
  onClose: () => {},
  renderFooter: undefined,
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  menuOpened: PropTypes.bool,
  tootlip: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  onClose: PropTypes.func,
  renderFooter: PropTypes.func,
};

export default withRouter(Menu);
