import {updateIsPublic} from '@untitled/app/core/redux/actions/wips/async';
import React, {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import type {ToastOptions} from 'react-toastify';
import styled from 'styled-components';
import idgen from 'crypto-js/aes';
import Analytics from '@untitled/app/core/helpers/helper-analytics';

interface Props {
  wipId: string;
  isPublic: boolean;
  isOwner: boolean;
}

const TOAST_DURATION = 3000;
const TOAST_CONF: ToastOptions = {
  autoClose: TOAST_DURATION,
  hideProgressBar: true,
};

const StyledContainer = styled.div`
  width: 100%;
  border: none;
`;

const StyledLeftSide = styled.div`
  display: block;
  width: 80%;
  text-align: left;
  float: left;
`;

interface StyledMakeLinkPublicProps {
  disabled: boolean;
}

const StyledMakeLinkPublic = styled.div<StyledMakeLinkPublicProps>`
  width: 80%;
  font-size: 14px;
  font-weight: 400, Regular;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
`;

const StyledAnyoneWithThisLink = styled.div`
  width: 100%;
  font-size: 10px;
  font-weight: 400, Regular;
  color: #818181;
  opacity: 0.4;
`;

interface LeftSideProps {
  disabled: boolean;
}

const LeftSide: React.FC<LeftSideProps> = ({disabled}) => (
  <StyledLeftSide>
    <StyledMakeLinkPublic disabled={disabled}>
      Make Link Shareable to Anyone
    </StyledMakeLinkPublic>
    <StyledAnyoneWithThisLink>
      anyone with this link can view
    </StyledAnyoneWithThisLink>
  </StyledLeftSide>
);

const StyledRightSide = styled.div`
  display: block;
  width: 20%;
  text-align: right;
  float: left;
  font-size: 20px;
`;

interface StyledToggleProps {
  disabled: boolean;
}

const StyledToggle = styled.div<StyledToggleProps>`
  > label {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 21px;

    > input[type='checkbox'] {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + span {
        background-color: ${props => (props.disabled ? '#f9d9da' : '#ff9ca0')};

        &:before {
          transform: translateX(21px);
        }
      }
    }

    > span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ede2e3;
      transition: 0.3s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: '';
        height: 19px;
        width: 19px;
        left: 1px;
        bottom: 1px;
        background-color: #fff;
        transition: 0.3s;
        border-radius: 50%;
      }
    }
  }
`;

interface ToggleProps {
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
}

const Toggle: React.FC<ToggleProps> = ({checked, onChange, disabled}) => {
  const htmlId = idgen
    .encrypt(Date.now().toString(), 'ToggleComponent')
    .toString();

  return (
    <StyledToggle disabled={disabled}>
      <label htmlFor={htmlId}>
        <input
          id={htmlId}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <span />
      </label>
    </StyledToggle>
  );
};

const RightSide: React.FC<Props> = ({wipId, isPublic, isOwner}) => {
  const [checked, setChecked] = useState(isPublic || false);
  const [disableToggle, setDisableToggle] = useState(false);
  const publicOrPrivate = checked ? 'public' : 'private';

  const onToggleCallback = useCallback(() => {
    if (!isOwner) {
      toast.warning(
        `Only WIP owners can make a WIP ${publicOrPrivate}.`,
        TOAST_CONF,
      );
      return;
    }
    if (!disableToggle) {
      setChecked(val => !val);
    }
  }, [disableToggle, isOwner, publicOrPrivate]);

  useEffect(() => {
    if (isPublic !== undefined) {
      setChecked(isPublic);
    }
  }, [isPublic]);

  useEffect(() => {
    (async () => {
      try {
        await updateIsPublic(wipId, checked);
        Analytics.logEventMakeWipPublic(wipId, checked);
      } catch (reason) {
        setChecked(val => !val);

        toast.warning(
          `There was an issue when making your WIP ${publicOrPrivate}.`,
          TOAST_CONF,
        );
      } finally {
        setDisableToggle(false);
      }
    })();
  }, [checked, publicOrPrivate]);

  return (
    <StyledRightSide>
      <Toggle
        checked={checked}
        onChange={onToggleCallback}
        disabled={!isOwner}
      />
    </StyledRightSide>
  );
};

export const MakeLinkPublic: React.FC<Props> = ({
  wipId,
  isPublic = false,
  isOwner,
}) => (
  <StyledContainer>
    <LeftSide disabled={!isOwner} />
    <RightSide wipId={wipId} isPublic={isPublic} isOwner={isOwner} />
  </StyledContainer>
);
