import React from 'react';
import PropTypes from 'prop-types';
import IconComments from 'react-feather/dist/icons/message-square';
import {IconPause, IconPlay} from '@untitled/app/core/components/atoms/icons';
import TimeAgo from '@untitled/app/core/components/atoms/timeago';
import './style.scss';
import ProgressCircular from '@untitled/app/core/components/atoms/progress-circular';
import {DetailsPopoverMenuTrackActions} from '@untitled/app/core/components/molecules/details-popover-menu-track-actions';
import {useSelector} from 'react-redux';

const Preview = ({
  id,
  wipId,
  title,
  author,
  datetime,
  onPlay,
  onDelete,
  onDownload,
  onComments,
  onClickShareTrackLink,
  playing,
  isOwner,
  isUploader,
  canComment,
  canDownload,
  fromRecording,
  uploading,
}) => {
  const {audio, wipInState, wipInLanding, user} = useSelector(state => ({
    // @ts-expect-error need to add types to redux store
    wipInState: state.wips.all[wipId],
    // @ts-expect-error need to add types to redux store
    audio: id ? state.audios.all[id] || {} : {},
    // @ts-expect-error need to add types to redux store
    user: state.common.user,
    // @ts-expect-error need to add types to redux store
    wipInLanding: state.landing.featured[wipId],
  }));
  const wip = wipInState || wipInLanding;
  const isAuthorOfWip = !!user?.uid && !!wip?.author && user.uid === wip.author;
  const isAuthorOfTrack =
    !!user?.uid && audio?.author && user.uid === audio.author;
  const isProducerOfWip =
    (wip?.confirmed?.indexOf?.(user?.uid) ?? -1) !== -1 &&
    (wip?.writers?.indexOf?.(user?.uid) ?? -1) !== -1;
  const showMenuTrackActions =
    (isAuthorOfTrack || isAuthorOfWip || isProducerOfWip) && !uploading;

  return (
    <div className="app__preview" data-id={id}>
      <div className="app__preview--card">
        {uploading ? (
          <div className="app__preview--loader">
            <ProgressCircular progress={uploading} stroke={2.5} />
          </div>
        ) : (
          <button
            type="button"
            className="app__preview--play"
            onClick={onPlay}
            disabled={!!uploading}>
            {playing ? <IconPause size={48} /> : <IconPlay size={48} />}
          </button>
        )}
        <div className="app__preview--info truncate">
          <h4 className="truncate">{title}</h4>
          <div className="metadata-container">
            <div className="author-container">
              <small className="app__preview--author">{author}</small>
              <small> - </small>
              <small>
                <TimeAgo datetime={datetime} />
              </small>
            </div>
            {fromRecording && (
              <div>
                <small className="app__preview--isrecording">
                  recorded on [untitled]
                </small>
              </div>
            )}
          </div>
        </div>
        <div className="app__preview--actions">
          {showMenuTrackActions && (
            <DetailsPopoverMenuTrackActions
              audioId={id}
              wipId={wipId}
              onDelete={onDelete}
              onDownload={onDownload}
              onClickShareTrackLink={onClickShareTrackLink}
              isOwner={isOwner}
              isUploader={isUploader}
              uploading={uploading}
              canDownload={canDownload}
              isProducerOfWip={isProducerOfWip}
              isAuthorOfTrack={isAuthorOfTrack}
              isAuthorOfWip={isAuthorOfWip}
            />
          )}
          {!!uploading && (
            <div className="app__preview--progress">
              <div
                style={{
                  width: `${uploading + 1}%`,
                }}
              />
            </div>
          )}
          {!uploading && canComment && (
            <button type="button" onClick={onComments}>
              <IconComments size={24} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Preview.defaultProps = {
  id: '',
  wipId: '',
  title: '',
  author: '',
  datetime: '',
  onPlay: () => {},
  onDelete: () => {},
  onDownload: () => {},
  onComments: () => {},
  onClickShareTrackLink: () => {},
  playing: false,
  isOwner: false,
  isUploader: false,
  canComment: true,
  canDownload: true,
  fromRecording: false,
  uploading: undefined,
};

Preview.propTypes = {
  id: PropTypes.string,
  wipId: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
  datetime: PropTypes.string,
  onPlay: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onComments: PropTypes.func,
  onClickShareTrackLink: PropTypes.func,
  playing: PropTypes.bool,
  isOwner: PropTypes.bool,
  isUploader: PropTypes.bool,
  canComment: PropTypes.bool,
  canDownload: PropTypes.bool,
  fromRecording: PropTypes.bool,
  uploading: PropTypes.number,
};

export default Preview;
