/**
 * Constants queue
 */

/** @constant {string} SET_QUEUE_INIT */
export const SET_QUEUE_INIT = 'core/redux/queue/SET_QUEUE_INIT';

/** @constant {string} SET_TEMPURL */
export const SET_TEMPURL = 'core/redux/queue/SET_TEMPURL';

/** @constant {string} SET_MUSIC_URL */
export const SET_MUSIC_URL = 'core/redux/queue/SET_MUSIC_URL';

/** @constant {string} SET_USER_DATA */
export const SET_USER_DATA = 'core/redux/queue/SET_USER_DATA';

/** @constant {string} SET_MACHINE_DATA */
export const SET_MACHINE_DATA = 'core/redux/queue/SET_MACHINE_DATA';

/** @constant {string} TOGGLE_FOLDER */
export const TOGGLE_FOLDER = 'core/redux/queue/TOGGLE_FOLDER';

/** @constant {string} SET_SELECTED_WIP */
export const SET_SELECTED_WIP = 'core/redux/queue/SET_SELECTED_WIP';

/** @constant {string} SET_SELECTED_QUEUE */
export const SET_SELECTED_QUEUE = 'core/redux/queue/SET_SELECTED_QUEUE';

/** @constant {string} QUEUE_RESET */
export const QUEUE_RESET = 'core/redux/queue/QUEUE_RESET';
