import firebase, {db, storage} from './helper-firebase';
import {
  stageString,
  UntitledInstanceObjects,
  WIPMediaKinds,
} from './helper-types';
import {getCroppedImg} from './helper-upload';
import {generateId} from './helper-util';

export const processCover = async (coverFile, croppedAreaPixels, featId) => {
  const uriImage = await getCroppedImg(coverFile.uri, croppedAreaPixels, 0);
  const coverArtFileExtension = coverFile.extension;
  const coverArtStorageRef = storage.ref(
    `__featured__/${featId}/coverArt.${coverArtFileExtension}`,
  );
  const coverArtBlob = await fetch(uriImage).then(f => f.blob());

  return {
    uriImage,
    coverArtBlob,
    coverArtStorageRef,
  };
};

export const processAudio = (soundFile, audioTitles = [], featId) => {
  const now = firebase.firestore.Timestamp.now();
  const audioId = generateId();
  const extension = soundFile.name.split('.').pop();
  const uriSound = URL.createObjectURL(soundFile);
  const parentRef = db
    .collection(stageString(UntitledInstanceObjects.Featured))
    .doc(featId);
  const audioFileExtension = extension;
  const audioStorageRef = storage.ref(
    `__featured__/${featId}/${audioId}.${audioFileExtension}`,
  );
  const index = audioTitles.map(f => f.id).indexOf(soundFile.name);
  const title =
    index !== -1
      ? audioTitles[index].name
      : soundFile.name.split('.').slice(0, -1).join('.');

  return {
    track: {
      id: audioId,
      title: title || soundFile.name.split('.').slice(0, -1).join('.'),
      kind: 'audio',
      storagePath: audioStorageRef.fullPath,
      lastEdited: now,
      peaks: [1, 1, 1],
      parentRef,
      source: 'file',
    },
    data: {
      audioStorageRef,
      // soundFile,
      uriSound,
    },
  };
};

export const preprocessCover = async (
  coverFile,
  croppedAreaPixels,
  authorRef,
  wipId,
) => {
  const now = firebase.firestore.Timestamp.now();
  const uriImage = await getCroppedImg(coverFile.uri, croppedAreaPixels, 0);
  const coverArtRef = db.collection(stageString(WIPMediaKinds.image)).doc();
  const coverArtFileExtension = coverFile.extension;
  const coverArtStorageRef = storage.ref(
    `__some_dir__/${authorRef.id}/${wipId}/coverArt.${coverArtFileExtension}`,
  );
  const coverArtBlob = await fetch(uriImage).then(f => f.blob());
  const coverArtData = {
    author: authorRef,
    created: now,
    kind: 'image',
    storageRef: coverArtStorageRef.fullPath,
    lastEdited: now,
  };

  return {
    uriImage,
    // coverFile,
    coverArtBlob,
    coverArtRef,
    coverArtStorageRef,
    coverArtData,
  };
};

export const preprocessAudio = (
  soundFile,
  audioTitles = [],
  authorRef,
  wipId,
) => {
  const now = firebase.firestore.Timestamp.now();
  const extension = soundFile.name.split('.').pop();
  const uriSound = URL.createObjectURL(soundFile);
  const audioRef = db.collection(stageString(WIPMediaKinds.audio)).doc();
  const wipRef = db
    .collection(stageString(UntitledInstanceObjects.WIP))
    .doc(wipId);
  const audioFileExtension = extension;
  const audioStorageRef = storage.ref(
    `__some_dir__/${authorRef.id}/${wipId}/${audioRef.id}.${audioFileExtension}`,
  );
  // const audioBlob = await fetch(uriSound).then((f) => f.blob());
  const index = audioTitles.map(f => f.id).indexOf(soundFile.name);
  const title =
    index !== -1
      ? audioTitles[index].name
      : soundFile.name.split('.').slice(0, -1).join('.');

  return {
    track: {
      author: authorRef,
      title: title || soundFile.name.split('.').slice(0, -1).join('.'),
      created: now,
      kind: 'audio',
      storageRef: audioStorageRef.fullPath,
      lastEdited: now,
      peaks: [1, 1, 1],
      wipRef,
      source: 'file',
    },
    data: {
      audioRef,
      audioStorageRef,
      soundFile,
      uriSound,
      // audioBlob,
    },
  };
};
