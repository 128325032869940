/* eslint-disable no-param-reassign */
import {sep, join} from 'path';

// const pathSeparator = path.sep;

export const EXTRACT_TYPE_FILES = 'extract-files';
export const EXTRACT_TYPE_FOLDERS = 'extract-folders';

export const getTreeFromPathsArr = pathsArr => {
  const result = [];
  const level = {result};
  pathsArr.forEach(p => {
    p.reduce((r, name) => {
      if (!r[name]) {
        r[name] = {result: []};
        r.result.push({
          name,
          items: r[name].result,
          key: p.join(sep),
          url: name,
        });
      }

      return r[name];
    }, level);
  });
  return result;
};

export const getQueueTree = filesArr => {
  const result = [];
  const level = {result};
  filesArr.forEach(p => {
    const {created} = p;
    p.path.reduce((r, name, i) => {
      const isFile = i === p.path.length - 1;
      if (!r[name]) {
        r[name] = {result: []};
        if (isFile) {
          r.result.push({
            ...p,
            name,
            items: [],
          });
        } else {
          const url = p.path.slice(0, i + 1).join(sep);
          const parent = p.watchPath.split(sep).slice(0, -1).join(sep);

          r.result.push({
            name,
            url,
            key: join(parent, url),
            route: url,
            items: r[name].result,
            created,
          });
        }
      }

      if (r[name].result.length > 0) {
        r[name].total =
          r[name].result.reduce((a, b) => a + (b.progress || 0), 0) /
          r[name].result.length;
      }

      return r[name];
    }, level);
  });

  return result;
};
