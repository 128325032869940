import React, {cloneElement} from 'react';
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  FloatingFocusManager,
  FloatingPortal,
} from '@floating-ui/react-dom-interactions';

interface IPopoverMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  /** Refers to the function that renders the menu that this popover will wrap. */
  render: () => React.ReactNode;
  placement?: Placement;
  children: JSX.Element;
  /** Refers to the optional label that will be used for aria compliance. */
  labelId?: string;
  /** Refers to the optional description that will be used for aria compliance. */
  descriptionId?: string;
}

export const PopoverMenu = ({
  children,
  render,
  placement,
  open,
  setOpen,
  labelId,
  descriptionId,
}: IPopoverMenuProps) => {
  const {x, y, reference, floating, strategy, context} = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift()],
    placement,
    whileElementsMounted: autoUpdate,
  });

  const {getReferenceProps, getFloatingProps} = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ]);

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ref: reference, ...children.props}),
      )}
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  background: 'white',
                  border: '2px solid #1d2124',
                  boxShadow: '0 5px 10px -5px rgba(115,115,115,0.75)',
                  zIndex: 1000,
                },
                'aria-labelledby': labelId,
                'aria-describedby': descriptionId,
              })}>
              {/** @ts-expect-error */}
              {render()}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};
