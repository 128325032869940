import {toast} from 'react-toastify';
import {useEffect, useState} from 'react';
import {featuredChanged, footerChanged} from '.';

import firebase, {db} from '../../../helpers/helper-firebase';
import {
  compareDates,
  parseFirebaseFields,
} from '../../../helpers/helper-untitled';
import {
  stageString,
  UntitledInstanceObjects,
} from '../../../helpers/helper-types';
import {getLandingData} from '../../../helpers/helper-cloud';
import {generateId} from '../../../helpers/helper-util';

export const listenFeatured = history => dispatch => {
  const wips = {};

  const onSubsWips = querySnapshot => {
    querySnapshot.docChanges().forEach(change => {
      const {doc, type} = change;
      if (type === 'removed') {
        console.log('feat removed', doc.id);
        // uploadManager.removeUpload(doc.id);
        delete wips[doc.id];
        const {location = {search: ''}} = history;
        if (location.search.indexOf(doc.id) !== -1) {
          // toast.dark('WIP has been deleted');
          history.push({
            pathname: '/admin',
            state: {tab: 0},
          });
        }
      } else {
        const data = doc.data();
        const parsed = parseFirebaseFields({
          ...data,
          id: doc.id,
        });
        const songs = Object.keys(parsed.songs).map(s => ({
          ...parseFirebaseFields(parsed.songs[s]),
          authorName: parsed.authorName,
          coverURL: parsed.coverURL,
        }));
        wips[doc.id] = parsed;
        wips[doc.id].songs = songs.sort(compareDates);
      }
    });
    dispatch(featuredChanged({...wips}));
  };

  const sub = db
    .collection(stageString(UntitledInstanceObjects.Featured))
    .onSnapshot(onSubsWips);

  return sub;
};

export const useFeaturedData = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [err, setErr] = useState();

  useEffect(() => {
    getLandingData()
      .then(landingData => setData(landingData))
      .catch(reason => setErr(reason))
      .finally(() => setLoading(false));
  }, []);

  return {loading, data, err};
};

export const getFeaturedData = () => async dispatch => {
  const {data, error} = await getLandingData();
  console.log(data);
  if (error) {
    return false;
  }
  const {featured, footer = []} = data;
  dispatch(featuredChanged(featured));
  dispatch(footerChanged(footer));
  return true;
};

export const listenLandingFooter = () => dispatch => {
  const footerRef = db
    .collection(stageString(UntitledInstanceObjects.Landing))
    .doc('footer');

  const onSubsLandingFooter = doc => {
    const data = doc.data();
    const footerLinks = Object.keys(data).map(k => ({
      ...parseFirebaseFields(data[k]),
      id: k,
    }));
    dispatch(footerChanged(footerLinks.sort(compareDates)));
  };

  const sub = footerRef.onSnapshot(onSubsLandingFooter);

  return sub;
};

export const saveFooterLink = (link, user) => async () => {
  const footerRef = db
    .collection(stageString(UntitledInstanceObjects.Landing))
    .doc('footer');
  const linkId = generateId();
  await footerRef.set(
    {
      [linkId]: {
        ...link,
        createdBy: user.uid,
        created: firebase.firestore.Timestamp.now(),
      },
    },
    {merge: true},
  );
  toast('Link saved');
};

export const removeFooterLink = linkId => async () => {
  const footerRef = db
    .collection(stageString(UntitledInstanceObjects.Landing))
    .doc('footer');
  await footerRef.set(
    {
      [linkId]: firebase.firestore.FieldValue.delete(),
    },
    {merge: true},
  );
  toast('Link removed');
};
