import React, {useCallback} from 'react';
import Menu from '@untitled/app/core/components/organisms/menu';
import MenuHeader from '@untitled/app/core/components/organisms/menu/menu-header';
import styled from 'styled-components';
import Switch from '@untitled/app/core/components/atoms/switch';
import {db} from '@untitled/app/core/helpers/helper-firebase';
import {logErrorMessage} from '@untitled/app/core/helpers/helper-logs';
import {useDispatch, useSelector} from 'react-redux';
import {audioChanged} from '@untitled/app/core/redux/actions/audios';
import {toast, ToastOptions} from 'react-toastify';

interface IDetailsMenuShareTrackProps {
  open: boolean;
  onClose: () => void;
  audioId: string;
  wipId: string;
}

const StyledContainerEnableShareLink = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.75rem;
`;

const StyledContainerShareLink = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  appearance: none;
  flex-grow: 1;
  border: 2px solid #1d2124;
  border-right: 1px solid #1d2124;
  padding: 10px;
  background: var(--input-bg);
  font-family: 'Untitled';
`;

const StyledButton = styled.button`
  padding: 10px;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #fff;
  color: #1d2124;
  border: 2px solid #1d2124;
  border-left: 1px solid #1d2124;
  line-height: normal;
`;

const TOAST_DURATION = 3000;
const TOAST_CONF: ToastOptions = {
  autoClose: TOAST_DURATION,
  hideProgressBar: true,
};

export const DetailsMenuShareTrack: React.FC<IDetailsMenuShareTrackProps> = ({
  open,
  onClose,
  audioId,
  wipId,
}) => {
  const dispatch = useDispatch();
  const {audio, wip, user} = useSelector(state => ({
    // @ts-expect-error redux state needs types
    wip: state.wips.all[wipId],
    // @ts-expect-error redux state needs types
    audio: audioId ? state.audios.all[audioId] : {},
    // @ts-expect-error redux state needs types
    user: state.common.user,
  }));
  const isAuthorOfTrack = user.uid === audio.author;
  const isAuthorOfWip = user.uid === wip.author;
  const canEnableShareLink = isAuthorOfTrack || isAuthorOfWip;
  const shareTrackUrl = `${window.location.origin}/share/track/${audioId}`;

  const handleChange = useCallback(
    ({value}: {value: boolean}) => {
      /** Note that 'audios.all' maps audioIds => audio doc with matching id. */
      const newAudio = {[audioId]: {...audio, isPublic: value}};
      dispatch(audioChanged({...newAudio}));

      db.collection('developmentaudio')
        .doc(audioId)
        .update({
          isPublic: value,
        })
        .then(_ => {
          /** @todo Add Amplitude analytics event for toggling 'isPublic' and log it here. */
        })
        .catch(e => {
          logErrorMessage(e);
        });
    },
    [audioId, audio],
  );

  const handleClickCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareTrackUrl);
      /** @todo Add Amplitude analytics event for sharing track and log it here. */
      toast.success('Link copied to clipboard', TOAST_CONF);
    } catch (reason) {
      toast.error('Error copying link to clipboard', TOAST_CONF);
    }
  };

  return (
    <>
      {/** @ts-expect-error menu doesn't have typed props */}
      <Menu menuOpened={open} onClose={onClose}>
        <MenuHeader title="Share Link" onClose={onClose} />
        <div style={{padding: '2.75rem'}}>
          {canEnableShareLink && (
            <StyledContainerEnableShareLink>
              <div style={{marginRight: 'auto'}}>
                <div style={{fontSize: '1.75rem'}}>Make track shareable</div>
                <div style={{fontSize: '1rem'}}>
                  anyone with this link can view
                </div>
              </div>
              <Switch
                id="details-menu-share-track-switch"
                value={!!audio.isPublic}
                onChange={handleChange}
              />
            </StyledContainerEnableShareLink>
          )}
          {!!audio.isPublic && (
            <StyledContainerShareLink>
              <StyledInput
                disabled
                value={shareTrackUrl}
                name="details-menu-share-track-link-input"
              />
              <StyledButton onClick={handleClickCopy}>Copy</StyledButton>
            </StyledContainerShareLink>
          )}
        </div>
      </Menu>
    </>
  );
};
