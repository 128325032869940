import {
  FEATURED_CHANGED,
  FEATURED_PLAYING,
  FEATURED_UPLOADING,
  FEATURED_RESET,
  FEATURED_UPLOADING_PROGRESS,
  FEATUREDS_NOTIFICATION,
  FOOTER_CHANGED,
} from './constants';

export const featuredChanged = payload => ({
  type: FEATURED_CHANGED,
  payload,
});

export const featuredPlaying = payload => ({
  type: FEATURED_PLAYING,
  payload,
});

export const featuredUploading = payload => ({
  type: FEATURED_UPLOADING,
  payload,
});

export const featuredUploadingProgress = payload => ({
  type: FEATURED_UPLOADING_PROGRESS,
  payload,
});

export const featuredNotifications = payload => ({
  type: FEATUREDS_NOTIFICATION,
  payload,
});

export const featuredReset = payload => ({
  type: FEATURED_RESET,
  payload,
});

export const footerChanged = payload => ({
  type: FOOTER_CHANGED,
  payload,
});
