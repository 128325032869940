import amplitude from 'amplitude-js';
import {sha256} from 'js-sha256';
import {AMPLITUDE_CONFIG} from '../../../config';
import {db} from './helper-firebase';

const amplitudeClient = amplitude.getInstance();
amplitudeClient.init(AMPLITUDE_CONFIG.apiKey);

class Analytics {
  static analytics = amplitudeClient;

  static appOpened = false;

  static anonymous = false;

  // Uniquely, but anonymously, identifies a user
  static setUserId(user) {
    this.analytics.setUserId(sha256(user.email));
    this.setUserProperties(user);
  }

  static setUserProperties(user) {
    db.collection('developmentAuthor')
      .doc(user.uid)
      .get()
      .then(result => {
        const internal = result.get('internal');
        this.analytics.setUserProperties({internal: internal ?? false});
        if (!this.anonymous) {
          this.analytics.setUserProperties({
            email: user.email,
            name: result.get('name').concat(' ', result.get('lastName')),
          });
        }
      });
  }

  // When the user logs in
  static logEventLogin() {
    this.analytics.logEvent('login');
  }

  // When the user creates a new WIP
  static logEventCreateWIP(source) {
    this.analytics.logEvent('create_wip', {
      source,
    });
  }

  // When the user uploads a new audio file, whether in creating a new WIP or adding to existing
  // Note: On mobile, also called when new audio is recorded
  static logEventCreateAudio(duration, source) {
    this.analytics.logEvent('create_audio', {
      audio_duration: duration,
      source,
    });
  }

  // When the user shares a WIP with another user, upon creation or after the fact
  static logEventShareWip(authorId, wipId, isProducer) {
    this.analytics.logEvent('share_wip', {
      wip_id: wipId,
      recipient_id: authorId,
      role: isProducer ? 'producer' : 'consumer',
    });
  }

  static logEventMakeWipPublic(wipId, isPublic) {
    this.analytics.logEvent('make_wip_public', {
      wip_id: wipId,
      is_public: isPublic,
    });
  }

  static logEventSharePublicWip(wipId) {
    this.analytics.logEvent('share_public_wip', {wip_id: wipId});
  }

  static logEventVisitPublicWip(wipId) {
    this.analytics.logEvent('visit_public_wip', {wip_id: wipId});
  }

  static logEventDeleteWip() {
    this.analytics.logEvent('delete_wip');
  }

  static logEventDeleteAudio() {
    this.analytics.logEvent('delete_audio');
  }

  // When a new audio file plays, whether user clicked a button or player skipped to next track
  static logEventPlay() {
    this.analytics.logEvent('play');
  }

  // Called once, signifying that app has been started but called once a user is logged in
  static logEventOpenApp() {
    if (!this.appOpened) {
      this.analytics.logEvent('open_app');
      this.appOpened = true;
    }
  }

  static logEventPlayButton() {
    this.analytics.logEvent('play_button');
  }

  static logEventPauseButton() {
    this.analytics.logEvent('pause_button');
  }

  static logEventViewWIP() {
    this.analytics.logEvent('view_wip');
  }

  static logEventViewAudio() {
    this.analytics.logEvent('view_audio');
  }

  static logEventViewNotifications() {
    this.analytics.logEvent('view_notifications');
  }

  static logEventViewDesktopTab() {
    this.analytics.logEvent('view_desktop_tab');
  }

  static logEventViewFolder() {
    this.analytics.logEvent('view_folder');
  }

  static logEventComment() {
    this.analytics.logEvent('comment');
  }

  static logEventRecord() {
    this.analytics.logEvent('record');
  }

  static logEventViewAccount() {
    this.analytics.logEvent('view_account');
  }

  static logEventViewProfile() {
    this.analytics.logEvent('view_profile');
  }

  static logEventViewNotes(wipId) {
    this.analytics.logEvent('view_notes', {wip_id: wipId});
  }

  // Whenever the user taps a notification card
  static logEventTapActivity() {
    this.analytics.logEvent('tap_activity');
  }

  static logEventDownloadDesktopApp(platform) {
    this.analytics.logEvent('download_desktop_app', {
      platform,
    });
  }

  static listening = false;

  static startTime = -1;

  // Called when user starts listening to new audio
  static startListeningAudio(isPublic = false) {
    // If user did not pause, but another song autoplays,
    // log the duration of the previous listening and continue listening
    if (this.listening) {
      this.logEventListen(isPublic);
    }
    this.startTime = new Date().getTime();
    this.listening = true;
  }

  // Called when user stops listening to audio, then logs how many minutes of audio
  // were played
  static stopListeningAudio(isPublic = false) {
    // Only log if it wasn't already paused
    if (this.listening) {
      this.logEventListen(isPublic);
    }
    this.listening = false;
  }

  static logEventListen(isPublic = false) {
    const endTime = new Date().getTime();
    const duration = ((endTime - this.startTime) / 1000.0 / 60.0).toFixed(2);
    this.analytics.logEvent('listen', {
      listen_duration: duration,
      is_public: isPublic,
    });
  }
}

export default Analytics;
