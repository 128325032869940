import {
  FEATURED_CHANGED,
  FEATURED_PLAYING,
  FEATURED_RESET,
  FEATURED_UPLOADING,
  FEATURED_UPLOADING_PROGRESS,
  FEATUREDS_NOTIFICATION,
  FOOTER_CHANGED,
} from '../actions/landing/constants';

const defaultState = {
  featured: {},
  notifications: {},
  footer: [],
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    // case FEATURED_CHANGED: {
    //   return {
    //     ...state,
    //     all: {
    //       ...state.all,
    //       ...action.payload,
    //     },
    //   };
    // }

    case FEATURED_CHANGED: {
      const featured = action.payload || {};

      return {
        ...state,
        featured,
      };
    }

    case FEATURED_PLAYING: {
      const playing = action.payload || {};
      return {
        ...state,
        playing,
      };
    }

    case FEATUREDS_NOTIFICATION: {
      const notifications = action.payload || {};
      return {
        ...state,
        notifications,
      };
    }

    case FEATURED_UPLOADING: {
      const {wipId, data} = action.payload;
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [wipId]: data,
        },
      };
    }

    case FEATURED_UPLOADING_PROGRESS: {
      const {wipId, fileId, progress} = action.payload;
      const {uploading = {}} = state;
      if (!uploading[wipId]) {
        uploading[wipId] = {uploading: {}};
      }
      if (progress === 1) {
        delete uploading[wipId].uploading[fileId];
      } else {
        uploading[wipId].uploading[fileId] = progress;
      }
      if (Object.keys(uploading[wipId].uploading).length === 0) {
        delete uploading[wipId];
      }
      return {
        ...state,
        uploading,
      };
    }

    case FOOTER_CHANGED: {
      const footer = action.payload || {};

      return {
        ...state,
        footer,
      };
    }

    case FEATURED_RESET: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
