/**
 * Constants Activity
 */

/** @constant {string} ACTIVITY_CHANGED */
export const ACTIVITY_CHANGED = 'core/redux/Activity/ACTIVITY_CHANGED';

/** @constant {string} ACTIVITYS_CHANGED */
export const ACTIVITYS_CHANGED = 'core/redux/Activity/ACTIVITYS_CHANGED';

/** @constant {string} READS_CHANGED */
export const READS_CHANGED = 'core/redux/Activity/READS_CHANGED';

/** @constant {string} ACTIVITY_RESET */
export const ACTIVITY_RESET = 'core/redux/Activity/ACTIVITY_RESET';

/** @constant {string} ACTIVITY_OPEN */
export const ACTIVITY_OPEN = 'core/redux/Activity/ACTIVITY_OPEN';
