import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import PlayPause from '../../atoms/playpause';

const TrackItem = ({
  title,
  author,
  album,
  cover,
  id,
  onSelect,
  onPlay,
  playing,
  selected,
  disabled,
  selectDisabled,
}) => {
  const onClick = () => {
    if (!disabled) {
      onPlay(id);
    }
  };
  const selecedClass = selected ? 'item-selected' : '';
  return (
    <div className={`app__track--item ${selecedClass}`}>
      <button
        type="button"
        onClick={() => {
          onSelect(id);
        }}
        disabled={selectDisabled}
        className="app__track--item-thumb"
        style={{
          cursor: selectDisabled ? 'default' : 'pointer',
        }}>
        <img src={cover} alt="" width={60} />
      </button>
      <button
        className="app__track--item-info"
        style={{
          cursor: selectDisabled ? 'default' : 'pointer',
        }}
        type="button"
        onClick={() => {
          onSelect(id);
        }}
        disabled={selectDisabled}>
        <h4 className="app__track--item-title truncate">{title}</h4>
        <p className="app__track--item-author truncate">
          {album.length > 0 ? (
            <small>{`${author} - ${album}`}</small>
          ) : (
            <small>{author}</small>
          )}
        </p>
      </button>
      <PlayPause
        className="app__track--item-control"
        playing={playing}
        onClick={onClick}
      />
    </div>
  );
};

TrackItem.defaultProps = {
  id: '',
  author: '',
  album: '',
  cover: '',
  title: '',
  onSelect: () => {},
  onPlay: () => {},
  playing: false,
  selected: false,
  disabled: false,
  selectDisabled: false,
};

TrackItem.propTypes = {
  id: PropTypes.string,
  author: PropTypes.string,
  album: PropTypes.string,
  cover: PropTypes.string,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onPlay: PropTypes.func,
  playing: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  selectDisabled: PropTypes.bool,
};

export default TrackItem;
