import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {CSSTransition} from 'react-transition-group';

import {onAuthChange} from './redux/actions/common/async';

import Loader from './components/atoms/loader';
import Playbar from './components/organisms/playbar';
import {TRANS_SLOW} from './constants';
import {getFeaturedData} from './redux/actions/landing/async';

const Layout = ({authChange, loading, children, history, darkTheme}) => {
  useEffect(() => {
    const root = document.getElementsByTagName('html')[0];
    if (darkTheme) {
      root.setAttribute('color-scheme', 'dark');
      // root.classList.add('color-scheme-dark');
      document.body.classList.add('color-scheme-dark');
    } else {
      root.setAttribute('color-scheme', 'light');
      // root.classList.remove('color-scheme-dark');
      document.body.classList.remove('color-scheme-dark');
    }
  }, [darkTheme]);

  useEffect(() => {
    const root = document.getElementsByTagName('html')[0];
    if (darkTheme) {
      root.setAttribute('color-scheme', 'dark');
      // root.classList.add('color-scheme-dark');
      document.body.classList.add('color-scheme-dark');
    } else {
      root.setAttribute('color-scheme', 'light');
      // root.classList.remove('color-scheme-dark');
      document.body.classList.remove('color-scheme-dark');
    }
  }, [darkTheme]);

  useEffect(() => {
    authChange(history);
  }, []);

  return (
    <div className="app__layout">
      {children}
      <CSSTransition
        in={!!loading}
        timeout={TRANS_SLOW}
        classNames="transition-slow"
        unmountOnExit>
        <Loader className="" />
      </CSSTransition>

      <Playbar />
    </div>
  );
};

Layout.defaultProps = {
  loading: false,
  authChange: () => {},
  darkTheme: false,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.any),
  ]).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  authChange: PropTypes.func,
  darkTheme: PropTypes.bool,
};

const mapStateToProps = state => ({
  darkTheme: state.common.darkTheme,
  loading: state.common.loading,
});

const mapDispatchToProps = {
  authChange: onAuthChange,
  onFeatured: getFeaturedData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
