import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {setShortcutsEnabled} from '@untitled/app/core/redux/actions/common';
import Menu from '..';
import MenuHeader from '../menu-header';
import Comments from '../../comments';
import CommentInput from '../../../atoms/commentinput';

const MenuComments = ({
  menuOpened,
  selectedAudio,
  onCloseMenu,
  thoughts,
  setThoughts,
  onComment,
  suggestions,
  title,
  renderHeaderActions,
  onAuthor,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (menuOpened) {
      dispatch(setShortcutsEnabled(false));
    } else {
      dispatch(setShortcutsEnabled(true));
    }
  }, [menuOpened]);

  const renderSuggestions = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused,
  ) => {
    console.log(entry);
    console.log(search, index, focused);
    return (
      <div className="app__miniauthor">
        <div className="app__miniauthor--initials">
          <span>{entry.initials}</span>
        </div>
        <div className="app__miniauthor--name">{entry.name}</div>
      </div>
    );
  };
  return (
    <Menu
      menuOpened={menuOpened}
      onClose={onCloseMenu}
      renderFooter={() => (
        <CommentInput
          thoughts={thoughts}
          onChange={({value}) => setThoughts(value)}
          onSubmit={onComment}
          suggestions={suggestions}
          renderUserSuggestion={renderSuggestions}
        />
      )}>
      <MenuHeader
        title={title}
        onClose={onCloseMenu}
        titleActions={renderHeaderActions}
      />
      <Comments audio={selectedAudio} onAuthor={onAuthor} />
    </Menu>
  );
};

MenuComments.defaultProps = {
  menuOpened: false,
  selectedAudio: {},
  onCloseMenu: () => {},
  thoughts: '',
  setThoughts: () => {},
  onComment: () => {},
  suggestions: [],
  title: '',
  renderHeaderActions: () => {},
  onAuthor: () => {},
};
MenuComments.propTypes = {
  menuOpened: PropTypes.bool,
  selectedAudio: PropTypes.objectOf(PropTypes.any),
  onCloseMenu: PropTypes.func,
  thoughts: PropTypes.string,
  setThoughts: PropTypes.func,
  onComment: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
  renderHeaderActions: PropTypes.func,
  onAuthor: PropTypes.func,
};

export default MenuComments;
