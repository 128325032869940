import React, {useEffect, useRef, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import path from 'path';
import {CSSTransition} from 'react-transition-group';

import {throttle} from '../../../helpers/helper-util';

import './style.scss';
import ExplorerFile from './fileexplorer-file';
import ExplorerFolder from './fileexplorer-folder';
import ExplorerAction from './fileexplorer-action';
import ExplorerHead from './fileexplorer-head';
import {UPlayerContext} from '../../context/player-context';

const FileExplorerSlide = ({
  items,
  node,
  selectedNode,
  level,
  selectedLevel,
  parent,
  setSelected,
  onEnter,
  onFileClick,
  onAdd,
  timeout,
  renderActions,
  search,
}) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  const [direction, setDirection] = useState('none');
  const [tooltip, setTooltip] = useState('');
  const slideRef = useRef();
  const {playerState} = useContext(UPlayerContext);

  const {query} = search;

  const sendHeight = el => {
    const {height} = el.getBoundingClientRect();
    onEnter(height);
  };
  const sendHeightThrottled = throttle(sendHeight, timeout);

  const isVisible = (q, arr) => {
    const showQuery =
      q.length === 0 || arr.some(ar => new RegExp(q, 'gi').test(ar));
    return showQuery;
  };

  const renderItem = item => {
    if (item.type === 'action') {
      return <ExplorerAction onClick={item.action} item={item} />;
    }
    if (item.hasItems) {
      return (
        <ExplorerFolder
          setSelected={setSelected}
          item={item}
          renderActions={renderActions}
          tooltip={tooltip}
          setTooltip={setTooltip}
          level={level}
          total={item.total}
        />
      );
    }
    return (
      <ExplorerFile
        onClick={onFileClick}
        onAdd={onAdd}
        item={item}
        playerState={playerState}
      />
    );
  };

  useEffect(() => {
    const newDirection = level <= selectedLevel ? 'left' : 'right';
    const selected = selectedNode === node;
    setShow(selected);
    setActive(selected && node !== active && node);
    if (selectedNode !== node) {
      setDirection(newDirection);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (slideRef.current) {
      sendHeightThrottled(slideRef.current);
    }
  }, [items, query, level]);

  return (
    <CSSTransition
      in={show}
      timeout={timeout}
      classNames={`app__fileexplorer--menu-${direction}`}
      unmountOnExit
      onEnter={sendHeightThrottled}>
      <ul
        className="app__fileexplorer--panel"
        // style={{ position: 'absolute' }}
        ref={slideRef}>
        {parent !== '' && (
          <li>
            <ExplorerHead
              onClick={() => {
                setSelected(parent);
              }}
              label={parent === '<root>' ? node : path.join(parent, node)}
            />
          </li>
        )}
        {items.map(item => {
          const showQuery =
            item.type === 'action' || isVisible(query, [item.name]);
          return (
            showQuery && (
              <li
                key={`menu-item-${item.key}`}
                data-key={item.key}
                className={item.hasItems ? 'app__fileexplorer--group' : ''}>
                {renderItem(item)}
              </li>
            )
          );
        })}
      </ul>
    </CSSTransition>
  );
};

FileExplorerSlide.defaultProps = {
  items: [],
  node: '',
  selectedNode: '',
  parent: '',
  timeout: 300,
  onEnter: () => {},
  onFileClick: () => {},
  onAdd: () => {},
  setSelected: () => {},
  renderActions: undefined,
  level: 0,
  selectedLevel: 0,
  search: {
    query: '',
  },
};

FileExplorerSlide.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  node: PropTypes.string,
  selectedNode: PropTypes.string,
  parent: PropTypes.string,
  timeout: PropTypes.number,
  onEnter: PropTypes.func,
  onFileClick: PropTypes.func,
  onAdd: PropTypes.func,
  setSelected: PropTypes.func,
  renderActions: PropTypes.func,
  level: PropTypes.number,
  selectedLevel: PropTypes.number,
  search: PropTypes.objectOf(PropTypes.any),
};

export default FileExplorerSlide;
