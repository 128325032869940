import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const AccountHeader = ({initials, name}) => (
  <div className="app__account--header">
    <div className="app__account--thumb">
      <div className="app__account--initials">{initials}</div>
    </div>
    <div className="app__account--info truncate">
      <div className="app__account--name truncate">{name}</div>
    </div>
  </div>
);

AccountHeader.defaultProps = {
  initials: '',
  name: '',
};

AccountHeader.propTypes = {
  initials: PropTypes.string,
  name: PropTypes.string,
};

export default AccountHeader;
