import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import Ripple from '../ripple';
import './style.scss';

const Button = ({
  className,
  tabIndex,
  disabled,
  children,
  onTap,
  value,
  name,
  ripple,
  ...rest
}) => {
  const buttonRef = useRef(null);
  const handleClick = e => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onTap({value, name}, e);
  };
  return (
    <button
      className={className}
      ref={buttonRef}
      tabIndex={tabIndex}
      disabled={disabled}
      onClick={handleClick}
      type="button"
      {...rest}>
      {ripple && <Ripple parent={buttonRef.current} />}
      {children}
    </button>
  );
};

Button.defaultProps = {
  onTap: () => {},
  tabIndex: '-100',
  className: '',
  value: '',
  name: '',
  disabled: false,
  ripple: false,
};

Button.propTypes = {
  onTap: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  tabIndex: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  ripple: PropTypes.bool,
};

export default Button;
