import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';

import {useDispatch, useStore} from 'react-redux';
import {userLogin} from '@untitled/app/core/redux/actions/common/async';
import Input from '@untitled/app/core/components/atoms/input';
import Check from '@untitled/app/core/components/atoms/check';
import {UPlayerContext} from '@untitled/app/core/components/context/player-context';

const StepsLogin = ({
  onBack,
  rememberMe,
  rememberMeEmail,
  onRememberMe,
  onPassword,
}) => {
  const [user, setUser] = useState({email: rememberMeEmail, password: ''});
  const {stopPlaying} = useContext(UPlayerContext);
  const dispatch = useDispatch();
  const {getState} = useStore();

  const onChange = e => {
    setUser({
      ...user,
      [e.name]: e.value,
    });
  };

  const onLogin = () => {
    stopPlaying();
    userLogin(user.email, user.password, rememberMe)(dispatch, getState);
  };

  const {email, password} = user;
  const toggleRememberMe = ({value}) => {
    onRememberMe(value);
  };
  return (
    <div className="app__slide app__steps--card steps-center">
      <div className="app__steps--form">
        <Input
          className="app__input mb-3"
          name="email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={onChange}
          onEnter={onLogin}
        />
        <Input
          name="password"
          className="app__input mb-3"
          type="password"
          placeholder="Password"
          value={password}
          onChange={onChange}
          onEnter={onLogin}
        />
        <div className="flex space-between">
          <Check
            name="rememberMe"
            className="app__check"
            onChange={toggleRememberMe}
            title="Remember Me"
            value={rememberMe}
          />
          <button
            type="button"
            className="app__check checked"
            onClick={onPassword}>
            Forgot Password
          </button>
        </div>
        <button type="button" className="app__btn" onClick={onLogin}>
          Login
        </button>
        <button type="button" className="app__link" onClick={onBack}>
          Back
        </button>
      </div>
    </div>
  );
};

StepsLogin.defaultProps = {
  rememberMeEmail: '',
  rememberMe: false,
  onBack: () => {},
  onRememberMe: () => {},
  onPassword: () => {},
};

StepsLogin.propTypes = {
  rememberMeEmail: PropTypes.string,
  rememberMe: PropTypes.bool,
  onBack: PropTypes.func,
  onRememberMe: PropTypes.func,
  onPassword: PropTypes.func,
};

export default StepsLogin;
