import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';

import Slides from '../../molecules/slides';
import Invite from '../invite';
import {compareDates, mergeWIPData} from '../../../helpers/helper-untitled';

import styles from './style.scss';
import Uploads from './uploads';
import {
  addQueueToNewWIP,
  addQueueToWIP,
  toastId,
  toastToWipId,
} from '../../../redux/actions/queue/async';
import {generateWipData} from '../../../helpers/helper-upload';
import UploadsManager from '../../../classes/uploads-manager';
import useUploadCover from './hooks/useUploadCover';

const thumbSize = parseFloat(styles.thumbSize);

const getFileTitle = name =>
  name ? name.split('.').slice(0, -1).join('.') : '';

const UploadQueue = ({
  user,
  selectedQueueWip,
  selectedQueue,
  onDone,
  wips,
  authors,
  onAddQueueToNewWIP,
  onAddQueueToWIP,
}) => {
  const currentAuthor = authors[user.uid];
  const uploadManager = useRef(UploadsManager.getInstance()).current;
  const [curSlide, setCurSlide] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [wipTitle, setWipTitle] = useState('');
  const [audioTitle, setAudioTitle] = useState(
    getFileTitle(selectedQueue.name),
  );
  const [permissions, setPermissions] = useState({});
  const [wipList, setSorted] = useState([]);
  const goToSlide = useRef(null);
  const submittingRef = useRef(false);

  const {coverFile, onArtworkChanged} = useUploadCover();

  const history = useHistory();

  const curAuthors = Object.keys(permissions).filter(
    k => permissions[k] !== '',
  );

  const onNext = () => {
    if (goToSlide.current) {
      goToSlide.current(1);
      setCurSlide(1);
    }
  };
  const onPrev = () => {
    if (goToSlide.current) {
      goToSlide.current(0);
      setCurSlide(0);
    }
  };

  const onAddAuthors = () => {
    if (goToSlide.current) {
      goToSlide.current(2);
      setCurSlide(2);
    }
  };

  const onWriter = uid => {
    console.log('onWriter', uid);
    const perm = permissions[uid];
    setPermissions({
      ...permissions,
      [uid]: perm === 'writer' ? '' : 'writer',
    });
  };

  const onReader = uid => {
    console.log('onReader', uid);
    const perm = permissions[uid];
    setPermissions({
      ...permissions,
      [uid]: perm === 'reader' ? '' : 'reader',
    });
  };

  const onRemove = uid => {
    console.log('onRemove', uid);
    setPermissions({
      ...permissions,
      [uid]: undefined,
    });
  };

  const uploadToNewWip = async () => {
    if (submittingRef.current) {
      return;
    }
    const newWip = {
      wipTitle,
      audioTitle,
      queue: selectedQueue,
      coverFile,
      croppedAreaPixels,
      writers: Object.keys(permissions).filter(
        k => permissions[k] === 'writer',
      ),
      readers: Object.keys(permissions).filter(
        k => permissions[k] === 'reader',
      ),
    };

    toast.dark('Preparing...', {
      autoClose: false,
      hideProgressBar: true,
      toastId,
    });

    const {wipData, uploadData} = await generateWipData(user, newWip);
    console.log({wipData, uploadData});
    submittingRef.current = true;
    uploadManager.addUpload(currentAuthor, wipData, uploadData, true);
    onAddQueueToNewWIP(wipData, uploadData);
    history.push({
      pathname: '/home',
      state: {tab: 0},
    });
    onDone();
    submittingRef.current = false;
  };

  const uploadToWip = async wipId => {
    if (submittingRef.current) {
      return;
    }
    const newWip = {
      queue: selectedQueue,
      wipId,
      wipTitle,
      audioTitle,
    };

    toast.dark('Preparing...', {
      autoClose: false,
      hideProgressBar: true,
      toastId: toastToWipId,
    });

    const {wipData, uploadData} = await generateWipData(user, newWip);
    console.log({wipData, uploadData});
    submittingRef.current = true;
    uploadManager.addUpload(currentAuthor, wipData, uploadData, false);
    onAddQueueToWIP(wipData, uploadData);
    history.push({
      pathname: '/home',
      state: {tab: 0},
    });
    onDone();
    // // This method returns a wip id from cloud functions
    // const response = await onAddQueueToWIP(wipData, uploadData);
    // if (response) {
    //   const {
    //     wipId: wipUrl,
    //   } = response;
    //   history.push(`/details?wip=${wipUrl}`);
    //   onDone();
    // }
    submittingRef.current = false;
  };

  useEffect(() => {
    const keys = Object.keys(wips);
    const wipsArray = keys
      .map(k => wips[k])
      .filter(w => w.author === user.uid || w.writers.indexOf(user.uid) !== -1);

    setSorted(wipsArray.sort(compareDates).reverse());
  }, [wips]);
  return (
    <div className="app__upload">
      <div className="app__upload--tabs p-3">
        <button
          className={`app__upload--tabs-btn ${
            curSlide === 0 ? 'selected' : ''
          }`}
          type="button"
          onClick={onPrev}>
          New WIP
        </button>
        <button
          className={`app__upload--tabs-btn ${
            curSlide === 1 ? 'selected' : ''
          }`}
          type="button"
          onClick={onNext}>
          Other WIP
        </button>
      </div>
      <Slides
        className="app__upload--steps center"
        startAt={curSlide ? 1 : 0}
        setRef={({goTo}) => {
          goToSlide.current = goTo;
        }}
        autoHeight={false}
        unmountOnExit>
        <div className="app__slide">
          <Uploads
            coverArt={coverFile}
            setCroppedAreaPixels={setCroppedAreaPixels}
            onArtworkChanged={onArtworkChanged}
            wipTitle={wipTitle}
            audioTitle={audioTitle}
            setWipTitle={setWipTitle}
            authors={authors}
            setAudioTitle={setAudioTitle}
            curAuthors={curAuthors}
            onUpload={uploadToNewWip}
            onAddAuthors={onAddAuthors}
            thumbSize={thumbSize}
          />
        </div>

        <div className="app__slide">
          <div className="app__upload">
            <div className="app__upload--inputs">
              <input
                name="title"
                className="app__input"
                type="text"
                placeholder="Audio name"
                value={audioTitle}
                onChange={e => {
                  setAudioTitle(e.target.value);
                }}
              />
            </div>
            <div className="app__upload--wips app__rows p-3">
              {selectedQueueWip?.id && (
                <button
                  key={`wip-${selectedQueueWip.id}`}
                  data-uid={selectedQueueWip.id}
                  type="button"
                  className={`app__rows--item ${
                    selectedQueueWip?.id === selectedQueueWip.id
                      ? 'current'
                      : ''
                  }`}
                  onClick={() => {
                    uploadToWip(selectedQueueWip.id);
                  }}>
                  <div className="app__rows--item-info">
                    <span className="app__rows--info-title">
                      {selectedQueueWip.title?.text}
                    </span>
                    <small className="app__rows--info-author">
                      {selectedQueueWip.authorName}
                    </small>
                  </div>
                  <ChevronRightIcon
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="square"
                  />
                </button>
              )}
              {wipList.map(
                wip =>
                  !wip.disabled &&
                  selectedQueueWip?.id !== wip.id && (
                    <button
                      key={`wip-${wip.id}`}
                      data-uid={wip.id}
                      type="button"
                      className={`app__rows--item ${
                        selectedQueueWip?.id === wip.id ? 'current' : ''
                      }`}
                      onClick={() => {
                        uploadToWip(wip.id);
                      }}>
                      <div className="app__rows--item-info">
                        <span className="app__rows--info-title">
                          {wip.title.text}
                        </span>
                        <small className="app__rows--info-author">
                          {wip.authorName}
                        </small>
                      </div>
                      <ChevronRightIcon
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeLinejoin="square"
                      />
                    </button>
                  ),
              )}
            </div>
          </div>
        </div>

        <div className="app__slide">
          <Invite
            selectedWip={{
              ...selectedQueue,
              author: user.uid,
            }}
            onProducer={onWriter}
            onConsumer={onReader}
            onRemove={onRemove}
            permissions={permissions}
          />
          <div className="app__upload--footer p-3">
            <button
              type="button"
              onClick={onPrev}
              className="btn btn-light app__upload--footer-action">
              <span>Done</span>
            </button>
          </div>
        </div>
      </Slides>
    </div>
  );
};

UploadQueue.defaultProps = {
  user: {},
  authors: {},
  wips: {},
  selectedQueueWip: undefined,
  selectedQueue: {},
  onDone: () => {},
  onAddQueueToNewWIP: () => {},
  onAddQueueToWIP: () => {},
};

UploadQueue.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  authors: PropTypes.objectOf(PropTypes.any),
  wips: PropTypes.objectOf(PropTypes.any),
  selectedQueueWip: PropTypes.objectOf(PropTypes.any),
  selectedQueue: PropTypes.objectOf(PropTypes.any),
  onDone: PropTypes.func,
  onAddQueueToNewWIP: PropTypes.func,
  onAddQueueToWIP: PropTypes.func,
};

const mapStateToProps = ({common, authors, wips, audios, queue}) => ({
  user: common.user,
  wips: mergeWIPData(wips.all, audios.all, authors.users),
  authors: authors.users,
  selectedQueueWip: queue.wip,
  selectedQueue: queue.selected,
});

const mapDispatchToProps = {
  onAddQueueToNewWIP: addQueueToNewWIP,
  onAddQueueToWIP: addQueueToWIP,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadQueue);
