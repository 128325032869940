import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({footer, style}) => {
  const renderFooterLink = f => (
    <p>
      [{f.light}{' '}
      <a
        href={f.link}
        target="_blank"
        rel="noreferrer noopener"
        style={{
          // textDecoration: 'none',
          color: '#000000',
          fontWeight: 600,
        }}>
        {f.bold}
      </a>
      ]
    </p>
  );

  const renderFooterRow = f => {
    switch (f.align) {
      case 'left': {
        return (
          <div key={f.id} className="app__footer--linkrow">
            <div className="app__footer--linkrow-link">
              {renderFooterLink(f)}
            </div>
            <div />
            <div />
          </div>
        );
      }
      case 'center': {
        return (
          <div key={f.id} className="app__footer--linkrow">
            <div />
            <div className="app__footer--linkrow-link">
              {renderFooterLink(f)}
            </div>
            <div />
          </div>
        );
      }
      case 'right': {
        return (
          <div key={f.id} className="app__footer--linkrow">
            <div />
            <div />
            <div className="app__footer--linkrow-link">
              {renderFooterLink(f)}
            </div>
          </div>
        );
      }
      default: {
        return (
          <div key={f.id} className="app__footer--linkrow">
            <div className="app__footer--linkrow-link">
              {renderFooterLink(f)}
            </div>
            <div />
            <div />
          </div>
        );
      }
    }
  };

  return (
    <div className="app__steps--footer" style={style}>
      <div className="app__footer--linkrow">
        <div />
        <div className="app__footer--linkrow-link">
          <p>
            [made with{' '}
            <a
              href="https://drive.google.com/file/d/13ZCkeJNG6uZvuFaBafMFI4YaDXAz8PNC/view?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
              style={{
                // textDecoration: 'none',
                color: '#000000',
                fontWeight: 600,
              }}>
              indifference
            </a>
            ]
          </p>
        </div>
        <div />
      </div>
      {footer.map(renderFooterRow)}
    </div>
  );
};

Footer.defaultProps = {
  footer: [],
  style: {},
};

Footer.propTypes = {
  footer: PropTypes.arrayOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
};

export default Footer;
