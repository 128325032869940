/* eslint-disable no-param-reassign */
import {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Analytics from '../../helpers/helper-analytics';

import {throttle} from '../../helpers/helper-util';
import {authorsSelect} from '../../redux/actions/authors';
import {MenuContext} from '../context/menu-context';

const useMenuWrapper = () => {
  const {selectedMenu, setSelectedMenu} = useContext(MenuContext);
  const authors = useSelector(state => state.authors.users);
  const dispatch = useDispatch();

  const onAccount = () => {
    Analytics.logEventViewAccount();
    setSelectedMenu('account');
  };

  const onAuthor = authorId => {
    Analytics.logEventViewProfile();
    dispatch(authorsSelect({...authors[authorId], id: authorId}));
    setSelectedMenu('profile');
  };

  const onAddAuthor = () => {
    setSelectedMenu('collaborators');
  };

  const onBubble = () => {
    Analytics.logEventViewNotifications();
    setSelectedMenu('activity');
  };

  const tOnAccount = throttle(onAccount, 500);
  const tOnAuthor = throttle(onAuthor, 500);
  const tOnAddAuthor = throttle(onAddAuthor, 500);
  const tOnBubble = throttle(onBubble, 500);

  return {
    selectedMenu,
    setSelectedMenu,
    onAccount: tOnAccount,
    onAuthor: tOnAuthor,
    onAddAuthor: tOnAddAuthor,
    onBubble: tOnBubble,
  };
};

export default useMenuWrapper;
