import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';

const ThumbCrop = ({path, setCroppedAreaPixels, thumbSize}) => {
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  });
  const container = useRef(null);
  const [size, setSize] = useState(thumbSize - 2);
  const [zoom, setZoom] = useState(1);
  const [minZoom, setMinZoom] = useState(1);
  const [loaded, setLoaded] = useState(false);

  const unloadingRef = useRef(false);

  const onCropComplete = useCallback((croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  useEffect(() => {
    setLoaded(false);
    const img = new Image();
    img.onload = () => {
      if (unloadingRef.current === true) {
        return;
      }
      const newZoom =
        Math.max(img.width, img.height) / Math.min(img.width, img.height);
      setZoom(newZoom);
      setMinZoom(newZoom);
      setLoaded(true);
    };
    img.src = path;
    return () => {};
  }, [path]);

  useEffect(() => {
    const updateSize = () => {
      if (container.current) {
        if (unloadingRef.current === true) {
          return;
        }
        setSize(container.current.getBoundingClientRect().width);
      }
    };
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [container]);

  useEffect(
    () => () => {
      unloadingRef.current = true;
    },
    [],
  );

  return (
    <div
      ref={container}
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        left: 0,
      }}>
      {loaded ? (
        <Cropper
          image={path}
          crop={crop}
          zoom={zoom}
          zoomWithScroll={false}
          // style={{ cropAreaStyle: { borderRadius: 10 } }}
          minZoom={minZoom}
          aspect={1}
          cropSize={{height: size, width: size}}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
        />
      ) : (
        <div className="loading">Loading</div>
      )}
    </div>
  );
};

ThumbCrop.defaultProps = {
  setCroppedAreaPixels: () => {},
  thumbSize: 322,
};

ThumbCrop.propTypes = {
  path: PropTypes.string.isRequired,
  setCroppedAreaPixels: PropTypes.func,
  thumbSize: PropTypes.number,
};

export default ThumbCrop;
