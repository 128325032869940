import Analytics from '@untitled/app/core/helpers/helper-analytics';
import React, {useCallback} from 'react';
import {toast, ToastOptions} from 'react-toastify';
import styled from 'styled-components';

const StyledCopyWipLinkButton = styled.button`
  width: 100%;
  border: 1px solid #1d2124;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400, Regular;
  margin-bottom: 10px;
`;

interface Props {
  wipId: string;
}

const TOAST_DURATION = 3000;
const TOAST_CONF: ToastOptions = {
  autoClose: TOAST_DURATION,
  hideProgressBar: true,
};

export const CopyWipLinkButton: React.FC<Props> = ({wipId}) => {
  const copyLinkToClipboardCallback = useCallback(() => {
    (async () => {
      try {
        const linkUrl = `${window.location.origin}/wip/${wipId}`;
        await navigator.clipboard.writeText(linkUrl);
        Analytics.logEventSharePublicWip(wipId);
        toast.success('Link copied to clipboard', TOAST_CONF);
      } catch (reason) {
        toast.error('Error copying link to clipboard', TOAST_CONF);
      }
    })();
  }, [wipId]);

  return (
    <StyledCopyWipLinkButton onClick={copyLinkToClipboardCallback}>
      Share WIP Link
    </StyledCopyWipLinkButton>
  );
};
