/* eslint-disable quote-props */

import navbarDark from './images/navbar-dark.png';
import navbarDarkSmall from './images/navbar-small.png';
import iconDark from './images/ui/icon-dark.png';
import iconLight from './images/ui/icon-light.png';
import thumb from './images/ui/thumb.png';

import g01 from './images/ui/gradient-01.jpg';
import g02 from './images/ui/gradient-02.jpg';
import g03 from './images/ui/gradient-03.jpg';
import g04 from './images/ui/gradient-04.jpg';
import g05 from './images/ui/gradient-05.jpg';
import g06 from './images/ui/gradient-06.jpg';

import artwork01 from './featured/track01/artwork.jpg';

import audio01 from './featured/track01/alan lili - soft plans (teaser) V1.mp3';
import atrack01 from './featured/track01/soft plans (teaser) V3.mp3';
import atrack02 from './featured/track01/soft plans (teaser) V5.mp3';
import atrack03 from './featured/track01/soft plans (teaser)V9 w master.mp3';

export default {
  navbarDark,
  navbarDarkSmall,
  iconDark,
  iconLight,
  thumb,

  featured: {
    artwork01,
    audio01,
    audios01: [atrack01, atrack02, atrack03],
  },
  gradients: [g01, g02, g03, g04, g05, g06],
};
