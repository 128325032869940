import React from 'react';
import {
  setLoader,
  setRememberMe,
} from '@untitled/app/core/redux/actions/common';
import {useDispatch, useSelector, useStore} from 'react-redux';
import Slides from '@untitled/app/core/components/molecules/slides';
import {UPlayerContext} from '@untitled/app/core/components/context/player-context';
import GridItem from '@untitled/app/core/components/molecules/grid/grid-item';
import {useHistory} from 'react-router-dom';
import Footer from './footer';
import StepsLogin from './login';
import StepsPassword from './password';
import StepsMFA from './mfa';
import {
  authResetPass,
  authLogout,
} from '@untitled/app/core/redux/actions/common/async';
import landingInfo from '@untitled/app/core/components/organisms/steps/steps-auth/landing.info.json';
import {
  featuredChanged,
  footerChanged,
} from '@untitled/app/core/redux/actions/landing';
import {WAITLIST_URL} from '@untitled/config';

type Props = {
  initialStep: number;
};

enum AuthSlides {
  INITIAL = 0,
  LOGIN = 1,
  MFA = 2,
  FORGOT_PASSWORD = 3,
}

export const StepsAuth: React.FC<Props> = ({initialStep = 0}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const {getState} = store;

  const rememberMe = useSelector(
    (state: any) => state?.common?.rememberMe ?? false,
  );
  const rememberMeEmail = useSelector(
    (state: any) => state?.common?.rememberMeEmail ?? '',
  );
  const mfaEnabled = useSelector(
    (state: any) => state?.common?.user?.mfaEnabled ?? false,
  );
  const mfaVerified = useSelector(
    (state: any) => state?.common?.user?.mfaVerified ?? false,
  );
  const goToSlide = React.useRef();
  const [slide, setSlide] = React.useState<AuthSlides>(AuthSlides.INITIAL);
  const {playerState, playSong} = React.useContext(UPlayerContext);
  const history = useHistory();
  const onSelect = (e: any[]) => {
    history.push(`/preview?wip=${e}`);
  };
  const slideAction = (index: AuthSlides) => {
    const curr = goToSlide.current as any;
    if (curr instanceof Function) {
      curr(index);
    }
  };

  React.useLayoutEffect(() => {
    slideAction(initialStep);
    dispatch(setLoader(false));
    dispatch(featuredChanged(landingInfo.featured));
    dispatch(footerChanged(landingInfo.footer));
  }, [dispatch]);

  React.useEffect(() => {
    if (mfaEnabled && !mfaVerified) {
      slideAction(AuthSlides.MFA);
    }
  }, [mfaEnabled, mfaVerified]);

  return (
    <>
      <div className="app__info">
        <div className="app__info--join">
          <a
            href={WAITLIST_URL}
            target="_blank"
            rel="noreferrer"
            className="app__info--join-btn">
            <div className="">
              join waitlist for{' '}
              <span className="app__info--join-btn-untitled">[untitled]</span>
            </div>
          </a>
        </div>
      </div>
      <Slides
        className="app__steps"
        setRef={({goTo}) => {
          goToSlide.current = goTo;
        }}
        onChange={setSlide}>
        <div className="app__slide app__steps--card card-full">
          <div className="app__steps--grid">
            <h1>work-in-progress</h1>
            <div className="app__grid">
              {Object.values(landingInfo.featured).map(feat => (
                <GridItem
                  key={feat.id}
                  id={feat.id}
                  title={feat.albumName}
                  author={feat.authorName}
                  cover={feat.coverURL}
                  time={feat.lastEdited}
                  onSelect={onSelect}
                  onPlay={() => playSong(feat.id, feat.songs, 0, feat.coverURL)}
                  playing={playerState.id === feat.id && playerState.playing}
                />
              ))}
            </div>
            <div className="app__steps--auth">
              <button
                type="button"
                className="app__btn"
                onClick={() => slideAction(AuthSlides.LOGIN)}>
                Login
              </button>
            </div>
          </div>
          <Footer
            footer={landingInfo.footer}
            style={{opacity: slide === AuthSlides.INITIAL ? 1 : 0}}
          />
        </div>
        <StepsLogin
          onBack={() => slideAction(AuthSlides.INITIAL)}
          rememberMe={rememberMe}
          rememberMeEmail={rememberMeEmail}
          onRememberMe={setRememberMe}
          onPassword={() => slideAction(AuthSlides.FORGOT_PASSWORD)}
        />
        <StepsMFA
          onBack={() => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Are you sure?')) {
              authLogout()(dispatch, getState).then(() =>
                slideAction(AuthSlides.INITIAL),
              );
            }
          }}
        />
        <StepsPassword
          onPasswordReset={authResetPass}
          rememberMeEmail={rememberMeEmail}
          onBack={() => slideAction(AuthSlides.LOGIN)}
        />
      </Slides>
    </>
  );
};
