import React, {useState} from 'react';
import PropTypes from 'prop-types';

import useBubbles from '../../hooks/useBubbles';
import Menu from '../menu';
import MenuHeader from '../menu/menu-header';
import UserAccount from '../user/user-account-sm';
import Activity from '../activity';
// import { Logo } from '../../atoms/logo';

import './style.scss';

const Header = ({slide, onTab, search, onSearch, preSelectedOption}) => {
  const [selectedOption, setSelectedOption] = useState(preSelectedOption);
  const tabWipsClass = slide !== 1 ? 'active' : '';
  const tabQueueClass = slide === 1 ? 'active' : '';
  const bubbles = useBubbles();

  const onCloseMenu = () => {
    setSelectedOption('');
  };

  const onBubble = () => {
    setSelectedOption('activity');
  };

  const onAccount = () => {
    setSelectedOption('account');
  };

  return (
    <>
      <div className="app__header">
        <div className="app__header-tabs">
          {/* <button
            type="button"
            className="app__header-logo"
            onClick={() => {
              onTab(0);
            }}
          >
            <Logo width={42} />
          </button> */}
          <button
            className={`app__header-tab ${tabWipsClass} `}
            type="button"
            onClick={() => {
              if (slide !== 0) {
                onTab(0);
              }
            }}>
            WIPs
          </button>
          <button
            className={`app__header-tab ${tabQueueClass}`}
            type="button"
            onClick={() => {
              onTab(1);
            }}>
            Desktop
          </button>
        </div>
        <div className="app__header-actions">
          <div className="app__header-search">
            <input
              className="app__header-search_input"
              onChange={onSearch}
              value={search.query}
              type="text"
              placeholder="Search by name"
            />
            {search.query && search.query.length > 0 && (
              <button
                type="button"
                className="app__header-search_clear"
                onClick={() => {
                  onSearch({target: {value: ''}});
                }}>
                x
              </button>
            )}
          </div>
          <button
            type="button"
            className={`app__header-bubble ${bubbles > 0 ? 'active' : ''}`}
            onClick={onBubble}>
            {bubbles}
          </button>
          <button
            type="button"
            className="app__header-control"
            onClick={onAccount}>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M20.2677 0.801589C9.47056 0.655756 0.656 9.47031 0.801833 20.2675C0.945819 30.6225 9.37734 39.054 19.7324 39.198C30.5313 39.3457 39.3441 30.5311 39.1964 19.734C39.0542 9.37709 30.6227 0.945575 20.2677 0.801589ZM31.9361 31.0064C31.8994 31.0462 31.8543 31.0774 31.8041 31.0978C31.754 31.1182 31.6999 31.1273 31.6459 31.1245C31.5918 31.1217 31.539 31.107 31.4912 31.0815C31.4434 31.056 31.4019 31.0203 31.3694 30.9769C30.5439 29.8968 29.5328 28.972 28.3835 28.2458C26.0336 26.7376 23.0561 25.9069 20 25.9069C16.944 25.9069 13.9664 26.7376 11.6165 28.2458C10.4673 28.9717 9.45624 29.8961 8.63064 30.976C8.5982 31.0193 8.55662 31.055 8.50885 31.0806C8.46108 31.1061 8.40828 31.1207 8.3542 31.1236C8.30012 31.1264 8.24608 31.1173 8.19591 31.0969C8.14575 31.0765 8.10069 31.0453 8.06392 31.0055C5.35557 28.0818 3.82139 24.2615 3.7554 20.2767C3.60495 11.2951 10.975 3.77731 19.9603 3.75516C28.9457 3.73301 36.2447 11.0292 36.2447 19.9998C36.2478 24.0807 34.7088 28.0121 31.9361 31.0064Z" />
              <path d="M20.0004 9.66211C18.1803 9.66211 16.5346 10.3442 15.3651 11.5838C14.1957 12.8234 13.6115 14.5373 13.7434 16.3769C14.0111 19.9996 16.8179 22.9532 20.0004 22.9532C23.1829 22.9532 25.9841 19.9996 26.2573 16.3778C26.3939 14.5558 25.8143 12.8575 24.6255 11.5949C23.4514 10.3488 21.8085 9.66211 20.0004 9.66211Z" />
            </svg>
          </button>
        </div>
      </div>
      <Menu menuOpened={selectedOption === 'account'} onClose={onCloseMenu}>
        <MenuHeader title="Account" onClose={onCloseMenu} />
        <UserAccount />
      </Menu>
      <Menu menuOpened={selectedOption === 'activity'} onClose={onCloseMenu}>
        <Activity onCloseMenu={onCloseMenu} />
      </Menu>
    </>
  );
};

Header.defaultProps = {
  slide: 0,
  onTab: () => {},
  search: {},
  onSearch: () => {},
  preSelectedOption: '',
};

Header.propTypes = {
  slide: PropTypes.number,
  onTab: PropTypes.func,
  search: PropTypes.objectOf(PropTypes.any),
  onSearch: PropTypes.func,
  preSelectedOption: PropTypes.string,
};

export default Header;
