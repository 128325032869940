import firebase, {db, storage} from './helper-firebase';
import {
  stageString,
  UntitledInstanceObjects,
  WIPMediaKinds,
} from './helper-types';

export const allSettled = promises => {
  const wrappedPromises = promises.map(p =>
    Promise.resolve(p).then(
      val => ({status: 'fulfilled', value: val}),
      err => ({status: 'rejected', reason: err}),
    ),
  );
  return Promise.all(wrappedPromises);
};

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

export async function getRotatedImage(imageSrc, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const orientationChanged =
    rotation === 90 ||
    rotation === -90 ||
    rotation === 270 ||
    rotation === -270;

  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

export const generateWipData = async (author, newWip) => {
  console.log('generateWipData', author, newWip);
  const {uid: authorId} = author;
  const {
    wipId,
    wipTitle,
    audioTitle,
    readers = [],
    writers = [],

    queue,
    coverFile,
    croppedAreaPixels,
  } = newWip;
  const wipRef = db
    .collection(stageString(UntitledInstanceObjects.WIP))
    .doc(wipId);
  const authorRef = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(authorId);

  const now = firebase.firestore.Timestamp.now();

  const wipData = {
    wipRef,
    author: authorRef,
    created: now,
    lastEdited: now,
    title: {
      text: wipTitle || audioTitle,
      created: now,
    },
    peaks: [1, 1, 1],
  };

  const uploadData = {
    readers,
    writers,
  };

  const readersArray = readers.map(authId =>
    db.collection(stageString(UntitledInstanceObjects.Author)).doc(authId),
  );

  const writersArray = writers.map(authId =>
    db.collection(stageString(UntitledInstanceObjects.Author)).doc(authId),
  );

  wipData.readers = readersArray;
  wipData.writers = writersArray;

  if (queue) {
    const {
      name,
      ext,
      duration,
      fileSize,
      // uri,
      storageRef,
    } = queue;
    const audioName =
      audioTitle !== '' ? audioTitle : name.split('.').slice(0, -1).join('.');
    const audioRef = db.collection(stageString(WIPMediaKinds.audio)).doc();
    const audioStorageRef = storage.ref(
      `__some_dir__/${authorId}/${wipRef.id}/${audioRef.id}${ext}`,
    );
    // const audioBlob = await fetch(uri).then((f) => f.blob());
    wipData.audio = audioRef;
    uploadData.audioData = {
      author: authorRef,
      title: audioName,
      created: now,
      kind: 'audio',
      storageRef: audioStorageRef.fullPath,
      lastEdited: now,
      peaks: [1, 1, 1],
      duration,
      fileSize,
      wipRef,
      source: 'queue',
    };
    uploadData.queueStorageRef = storage.ref(storageRef);
    uploadData.audioStorageRef = audioStorageRef;
    // uploadData.audioBlob = audioBlob;
    // uploadData.soundFile = soundFile;
  }

  if (coverFile) {
    const uriImage = await getCroppedImg(coverFile.uri, croppedAreaPixels, 0);
    const coverArtRef = db.collection(stageString(WIPMediaKinds.image)).doc();
    const coverArtFileExtension = coverFile.extension;
    const coverArtStorageRef = storage.ref(
      `__some_dir__/${authorId}/${wipRef.id}/coverArt.${coverArtFileExtension}`,
    );
    const coverArtBlob = await fetch(uriImage).then(f => f.blob());
    wipData.coverArt = coverArtRef;
    uploadData.coverArtData = {
      author: authorRef,
      created: now,
      kind: 'image',
      storageRef: coverArtStorageRef.fullPath,
      lastEdited: now,
    };
    uploadData.coverArtStorageRef = coverArtStorageRef;
    uploadData.coverArtBlob = coverArtBlob;
    uploadData.uriImage = uriImage;
    uploadData.coverFile = coverFile;
  }

  return {
    wipData,
    uploadData,
  };
};

export const calcProgress = uploadProgress =>
  Object.keys(uploadProgress)
    .map(k => uploadProgress[k])
    .reduce((p = 0, c = 0) => p + c);
