import {format} from 'date-fns';
import assets from '@untitled/app/core/assets';

const parseWipDate = d => (d.toDate ? d.toDate().toISOString() : d);

export const parseWIPData = (
  id,
  {
    album,
    audio,
    author,
    coverArt,
    created,
    lastEdited,
    title,
    ready,
    audios,
    readers,
    writers,
    denied,
    confirmed,
    linkEnabled = false,
    allowGuestDownload = false,
    status,
    isPublic = false,
  },
) => {
  // todo: check that there are no missing required props
  const readersArr = readers?.map(r => r.id) ?? [];
  const writersArr = writers?.map(w => w.id) ?? [];
  const deniedArr = denied?.map(d => d.id) ?? [];
  const confirmedArr = confirmed?.map(d => d.id) ?? [];

  const data = {
    id,
    album,
    audio: audio?.id,
    author: author?.id,
    coverArt: coverArt?.id,
    created: parseWipDate(created),
    lastEdited: parseWipDate(lastEdited),
    ready,
    title: {
      created: parseWipDate(title?.created || created),
      text: title?.text ?? 'no title',
    },
    audios: audios?.map(a => a.id).reverse(),
    readers: readersArr,
    writers: writersArr,
    denied: deniedArr,
    confirmed: confirmedArr,
    linkEnabled,
    allowGuestDownload,
    status,
    cover: album?.cover,
    isPublic,
  };

  return data;
};

export const parseFirebaseFields = docData => {
  const fields = {...docData};
  const keys = Object.keys(fields);
  keys.forEach(key => {
    if (fields[key]) {
      if (fields[key].toDate) {
        fields[key] = fields[key].toDate().toISOString();
      } else if (fields[key].id) {
        fields[key] = fields[key].id;
      } else if (Array.isArray(fields[key])) {
        if (
          typeof fields[key][0] === 'object' &&
          !Array.isArray(fields[key][0]) &&
          fields[key][0] !== null
        ) {
          fields[key] = fields[key].map(f => f.id);
        }
      }
    }
  });
  return fields;
};

export const parseFirebaseData = data => {
  const parsed = {...data};
  Object.keys(parsed).forEach(k => {
    parsed[k] = parseFirebaseFields(parsed[k]);
  });
  return parsed;
};

export const mergeWIPData = (wips, audios, authors) => {
  if (!wips || !audios || !authors) {
    return {};
  }

  return Object.entries(wips).reduce((acc, [wipId, wip]) => {
    if (!wip) {
      return acc;
    }
    const audios = wip?.audios ?? [];
    const audio = wip?.audio;
    const tracks = [audio, ...audios].reverse();
    const authorId = wip?.author ?? '';
    const authorName = authors?.[authorId]?.name ?? '';

    const processed = {
      ...wip,
      coverURL: wip?.album.cover,
      audioURL: audios?.[wip.audio]?.uri,
      tracks,
      authorName,
    };

    return {
      ...acc,
      [wipId]: processed,
    };
  }, {});
};

export const filterUploadingWips = (wips, uploading) => {
  const upKeys = Object.keys(uploading);
  const filteredKeys = upKeys.filter(k => !wips[k]);
  const result = filteredKeys.reduce((acc, cur) => {
    acc[cur] = uploading[cur];
    return acc;
  }, {});
  return result;
};

export const onPlayHelper = ({
  wipUid,
  audioId,
  track,
  audio,
  wips,
  audios,
  playWip,
  audioState,
}) => {
  const curWip = wips[wipUid];
  const curAudio = audios[audioId];
  const playing = {
    id: wipUid,
    authorUid: curWip.author,
    authorName: curWip.authorName,
    coverURL: curWip.coverURL,
    title: curWip.title,
    tracks: curWip.tracks,
    track,
    audioId,
    audioTitle: curAudio.title,
    audioURL: curAudio.uri,
  };
  if (audioState.id !== audioId) {
    playWip(playing);
    audio.loadSong(curAudio.uri, audioId);
    return;
  }
  if (audioState.loaded) {
    if (audioState.playing) {
      audio.pause();
    } else {
      audio.play();
    }
  }
};

export const compareDates = (a, b) => {
  const ad = new Date(a.lastEdited || a.created || 0);
  const bd = new Date(b.lastEdited || b.created || 0);
  if (ad < bd) {
    return -1;
  }
  if (ad > bd) {
    return 1;
  }
  return 0;
};

export const getSortedTracks = (wip, authors, audios) => {
  const wipAudios = wip.tracks || [];
  const orderedAudioIds = (wip.album?.tracks ? wip.album.tracks : wipAudios)
    .slice()
    .map(track => track.id)
    .reverse();

  const audiosMap = wipAudios.reduce((acc, id) => {
    const processAudio = audios[id];

    if (!processAudio) {
      return acc;
    }

    const authorId = processAudio.author;
    const {name = '', lastName = ''} = authors[authorId] || {};

    return {
      ...acc,
      [id]: {
        ...processAudio,
        id,
        authorName: `${name} ${lastName}`,
        coverURL: wip.coverURL,
        playlistId: wip.id,
      },
    };
  }, {});

  const audiosArray = orderedAudioIds
    .filter(id => !!id)
    .map(id => audiosMap[id]);
  return audiosArray;
};

export const getRandomGradientImg = () => {
  const {gradients} = assets;
  return gradients[Math.floor(Math.random() * gradients.length)];
};

export const generateGradientUri = () => {
  let canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const angle = 45 * (Math.PI / 180);
  const x2 = 300 * Math.cos(angle);
  const y2 = 300 * Math.sin(angle);
  const gr = ctx.createLinearGradient(0, 0, x2, y2);

  gr.addColorStop(0, 'black');
  gr.addColorStop(1, 'blue');

  ctx.fillStyle = gr;
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  const uri = ctx.canvas.toDataURL();
  canvas = null;
  // console.log(uri);
  return uri;
};

export const defaultDisplayDate = () => format(new Date(), 'MMM d, hh:mm bbb');
