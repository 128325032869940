import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import UploadsManager from '@untitled/app/core/classes/uploads-manager';

const defaultUpload = {};

export const UploadContext = React.createContext(defaultUpload);

export const UploadProvider = ({children}) => {
  const uploadManager = useRef(UploadsManager.getInstance()).current;
  const [uploadManagerState, setUploadManagerState] = useState(
    uploadManager.status,
  );

  const {
    uploads,
    audiosProgress,
    imagesProgress,
    audiosNames,
    removeUploadedAudio,
  } = uploadManagerState;

  useEffect(() => {
    uploadManager.addListener('state_changed', e => {
      setUploadManagerState(e);
    });
  }, []);

  const memoized = useMemo(
    () => ({
      uploads,
      audiosProgress,
      imagesProgress,
      audiosNames,
      removeUploadedAudio,
    }),
    [uploads, audiosProgress, imagesProgress, audiosNames, removeUploadedAudio],
  );

  return (
    <UploadContext.Provider value={memoized}>{children}</UploadContext.Provider>
  );
};

UploadProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
