export const stageString = d => `development${d}`;

export const ActivityType = {
  newComment: 0,
  newAudio: 1,
  newImage: 2,
  newTag: 3,
  acceptedInvite: 4,
  deletedWip: 5,
  updatedTitle: 6,
  newInvite: 7,
  deniedWip: 8,
  removedPermissionsFromWip: 9,
  newListen: 10,
  updatedAudioTitle: 11,
  arrangedWip: 12,
};

export const ActivityTypeMigrator = {
  [ActivityType.newComment]: 'newComment',
  [ActivityType.newAudio]: 'newAudio',
  [ActivityType.newImage]: 'newImage',
  [ActivityType.newTag]: 'newTag',
  [ActivityType.acceptedInvite]: 'acceptedInvite',
  [ActivityType.deletedWip]: 'deletedWip',
  [ActivityType.updatedTitle]: 'updatedTitle',
  [ActivityType.newInvite]: 'newInvite',
  [ActivityType.deniedWip]: 'deniedWip',
  [ActivityType.removedPermissionsFromWip]: 'removedPermissionsFromWip',
  [ActivityType.newListen]: 'newListen',
  [ActivityType.updatedAudioTitle]: 'updatedAudioTitle',
  [ActivityType.archived]: 'arrangedWip',
};

export const UntitledInstanceObjects = {
  WIP: 'WIP',
  Request: 'Request',
  WIPMedia: 'WIPMedia',
  Author: 'Author',
  AuthorMeta: 'AuthorMeta',
  Notification: 'Notification',
  Activity: 'activity',
  Queue: 'Queue',
  Machine: 'Machine',
  Sharing: 'Sharing',
  Featured: 'Featured',
  Landing: 'Landing',
};

export const WIPMediaKinds = {
  audio: 'audio',
  image: 'image',
  text: 'text',
  queue: 'queue',
};

export const AuthorMetaKinds = {
  pins: 'pins',
  reads: 'reads',
  machines: 'machines',
  queue: 'queue',
  listens: 'listens',
  wipJoins: 'wip_joins',
};

export const WIPstatus = {
  archived: 'archived',
};
