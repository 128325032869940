/**
 * Constants landing
 */

/** @constant {string} FEATURED_CHANGED */
export const FEATURED_CHANGED = 'core/redux/landing/FEATURED_CHANGED';

/** @constant {string} FEATUREDS_CHANGED */
export const FEATUREDS_CHANGED = 'core/redux/landing/FEATUREDS_CHANGED';

/** @constant {string} FEATURED_PLAYING */
export const FEATURED_PLAYING = 'core/redux/landing/FEATURED_PLAYING';

/** @constant {string} FEATURED_UPLOADING */
export const FEATURED_UPLOADING = 'core/redux/landing/FEATURED_UPLOADING';

/** @constant {string} FEATURED_UPLOADING_PROGRESS */
export const FEATURED_UPLOADING_PROGRESS =
  'core/redux/landing/FEATURED_UPLOADING_PROGRESS';

/** @constant {string} FEATUREDS_NOTIFICATION */
export const FEATUREDS_NOTIFICATION =
  'core/redux/landing/FEATUREDS_NOTIFICATION';

/** @constant {string} FEATURED_RESET */
export const FEATURED_RESET = 'core/redux/landing/FEATURED_RESET';

/** @constant {string} FOOTER_CHANGED */
export const FOOTER_CHANGED = 'core/redux/landing/FOOTERCHANGED';
