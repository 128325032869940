import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';

const ExplorerHead = ({onClick, label}) => (
  <button className="app__fileexplorer--head" type="button" onClick={onClick}>
    <div className="app__fileexplorer--head-icon">
      <ChevronLeft size={18} color="#FFFFFF" />
    </div>
    <div className="app__fileexplorer--head-info">
      <div className="app__fileexplorer--head-name">{label}</div>
    </div>
  </button>
);

ExplorerHead.defaultProps = {
  onClick: () => {},
  label: '',
};

ExplorerHead.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

export default ExplorerHead;
