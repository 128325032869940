/* eslint-disable no-param-reassign */
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {throttle} from '../../helpers/helper-util';
import {
  removePermissions,
  setAsReader,
  setAsWriter,
} from '../../redux/actions/wips/async';
import {MenuContext} from '../context/menu-context';

const usePermissions = ({selectedWip, permissions = {}}) => {
  const user = useSelector(state => state.common.user);
  const authors = useSelector(state => state.authors.users);
  const {setSelectedMenu} = useContext(MenuContext);
  const history = useHistory();

  const getSubs = (inviteId, authorId) => {
    const subscribers = [inviteId, selectedWip.author].filter(
      a => a !== authorId,
    );

    const users = subscribers.map(k => authors[k]);

    console.log(subscribers);

    return {
      subscribers,
      users,
      author: authors[user.uid],
    };
  };

  const onConsumer = authorId => {
    if (!authors[authorId]) {
      return;
    }
    if (permissions[authorId] === 'reader') {
      return;
    }
    setAsReader({
      wip: selectedWip,
      invited: authors[authorId],
      notify:
        [...selectedWip.writers, ...selectedWip.readers].indexOf(authorId) ===
        -1,
      ...getSubs(authorId, user.uid),
    });
    if (authorId === user.uid) {
      setSelectedMenu('');
    }
  };
  const onProducer = authorId => {
    if (!authors[authorId]) {
      return;
    }
    if (permissions[authorId] === 'writer') {
      return;
    }
    setAsWriter({
      wip: selectedWip,
      invited: authors[authorId],
      notify:
        [...selectedWip.writers, ...selectedWip.readers].indexOf(authorId) ===
        -1,
      ...getSubs(authorId, user.uid),
    });
  };
  const onRemove = authorId => {
    removePermissions({
      wip: selectedWip,
      invited: authors[authorId],
      authorId,
    });
    if (authorId === user.uid) {
      setSelectedMenu('');
      history.push({
        pathname: '/home',
        state: {tab: 0},
      });
    }
  };

  const tOnProducer = throttle(onProducer, 1000);
  const tOnConsumer = throttle(onConsumer, 1000);
  const tOnRemove = throttle(onRemove, 1000);

  return {
    onProducer: tOnProducer,
    onConsumer: tOnConsumer,
    onRemove: tOnRemove,
  };
};

export default usePermissions;
