import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Ripple = ({parent}) => {
  const rippleEl = useRef(null);
  const onClick = e => {
    // Get Cursor Position
    const top = e.pageY;
    const left = e.pageX;
    const cursorPos = {
      top,
      left,
    };

    // Get the element
    const ripple = rippleEl.current;
    ripple.classList.remove('animate');

    const parentRect = parent.getBoundingClientRect();

    const parentWidth = parentRect.width;
    const parentHeight = parentRect.height;

    // Make a Square Ripple
    const rippleWidthShouldBe = Math.max(parentHeight, parentWidth);

    // Make Ripple Position to be center
    const centerize = rippleWidthShouldBe / 2;

    ripple.style.height = `${rippleWidthShouldBe}px`;
    ripple.style.width = `${rippleWidthShouldBe}px`;
    ripple.style.top = `${cursorPos.top - parentRect.top - centerize}px`;
    ripple.style.left = `${cursorPos.left - parentRect.left - centerize}px`;

    parent.focus();
    ripple.classList.add('animate');
  };
  useEffect(() => {
    if (parent !== undefined && parent !== null) {
      parent.addEventListener('click', onClick);
    }
  }, [parent]);
  return <div ref={rippleEl} className="ripple dark" />;
};

Ripple.defaultProps = {
  parent: undefined,
};

Ripple.propTypes = {
  parent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.objectOf(PropTypes.any),
  ]),
};

export default Ripple;
