import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
//@ts-ignore
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';

import Slides from '@untitled/app/core/components/molecules/slides';
import Invite from '@untitled/app/core/components/organisms/invite';
import {
  compareDates,
  mergeWIPData,
} from '@untitled/app/core/helpers/helper-untitled';

//@ts-ignore
import styles from '@untitled/app/core/components/organisms/upload/style.scss';
import Uploads from '@untitled/app/core/components/organisms/upload/uploads';
import Input from '@untitled/app/core/components/atoms/input';
import {
  bulkToNewWip,
  bulkUploadAudios,
} from '@untitled/app/core/redux/actions/wips/async-bulk';
import useUploadCover from '@untitled/app/core/components/organisms/upload/hooks/useUploadCover';

const thumbSize = parseFloat(styles.thumbSize);

const getFileTitle = (name: string) =>
  name ? name.split('.').slice(0, -1).join('.') : '';
const getFilesTitles = (soundFiles: any[]) =>
  soundFiles.map(soundFile => ({
    id: soundFile.name,
    name: getFileTitle(soundFile.name),
  }));

enum SlidesEnum {
  NEW_WIP_UPLOAD = 0,
  EXISTING_WIP_UPLOAD = 1,
  AUDIO_NAME_IN_EXISTING_WIP_UPLOAD = 2,
  ADD_COLLABORATORS = 3,
}

interface Props {
  user: any;
  authors: any;
  wips: any;
  selectedWip: any;
  soundFiles: any[];
  onDone: () => void;
  onBulkAudios: (author: any, wip: any) => void;
  onBulkNewWip: (author: any, wip: any) => void;
}

const UploadWip = ({
  user,
  selectedWip,
  soundFiles,
  onDone,
  wips,
  authors,
  onBulkAudios,
  onBulkNewWip,
}: Props) => {
  const currentAuthor = authors[user.uid];
  const [curSlide, setCurSlide] = useState(selectedWip?.id ? 1 : 0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [wipTitle, setWipTitle] = useState('');
  const [audioTitles, setAudioTitles] = useState(getFilesTitles(soundFiles));
  const [permissions, setPermissions] = useState<any>({});
  const [wipList, setSorted] = useState<any[]>([]);
  const [selected, setSelected] = useState(selectedWip);
  const goToSlide = useRef<any | null>(null);
  const submittingRef = useRef(false);

  const {coverFile, onArtworkChanged} = useUploadCover();

  const curAuthors = Object.keys(permissions).filter(
    k => permissions[k] !== '',
  );

  //#region Move slides
  // The functions in this region share the very same
  // logic just changing the number of the slide they
  // are moving to. That's why we have a  `basicGoTo`
  // function, and four functions that depend on
  // `basicGoTo`.
  const moveToSlide = (slide: number) => {
    if (goToSlide.current) {
      goToSlide.current(slide);
      setCurSlide(slide);
      const scrollElem = document?.querySelector('.app__upload')?.parentElement;
      if (scrollElem) {
        scrollElem.scrollTop = 0;
      }
    }
  };

  const onPrev = () => moveToSlide(SlidesEnum.NEW_WIP_UPLOAD);
  const onNext = () => moveToSlide(SlidesEnum.EXISTING_WIP_UPLOAD);
  const onSelectWip = (wip: any) => {
    setSelected(wip);
    moveToSlide(SlidesEnum.AUDIO_NAME_IN_EXISTING_WIP_UPLOAD);
  };
  const onAddAuthors = () => moveToSlide(SlidesEnum.ADD_COLLABORATORS);
  //#endregion

  const onWriter = (uid: string) => {
    console.log('onWriter', uid);
    const perm = permissions[uid];
    setPermissions({
      ...permissions,
      [uid]: perm === 'writer' ? '' : 'writer',
    });
  };

  const onReader = (uid: string) => {
    console.log('onReader', uid);
    const perm = permissions[uid];
    setPermissions({
      ...permissions,
      [uid]: perm === 'reader' ? '' : 'reader',
    });
  };

  const onRemove = (uid: string) => {
    console.log('onRemove', uid);
    setPermissions({
      ...permissions,
      [uid]: undefined,
    });
  };

  const uploadToNewWip = async () => {
    if (submittingRef.current) {
      return;
    }
    const newWip = {
      wipTitle,
      audioTitles,
      soundFiles,
      coverFile,
      croppedAreaPixels,
      writers: Object.keys(permissions).filter(
        k => permissions[k] === 'writer',
      ),
      readers: Object.keys(permissions).filter(
        k => permissions[k] === 'reader',
      ),
    };
    console.log(newWip, currentAuthor);
    submittingRef.current = true;
    onDone();
    await onBulkNewWip(currentAuthor, newWip);
    submittingRef.current = false;
  };

  const uploadToWip = async (wipId: string) => {
    if (submittingRef.current) {
      return;
    }
    const newWip = {
      wipId,
      audioTitles,
      soundFiles,
    };
    console.log(newWip, currentAuthor);
    submittingRef.current = true;
    onDone();
    await onBulkAudios(currentAuthor, newWip);
    submittingRef.current = false;
  };

  const onTitleChanged = (val: string, id: string) => {
    const newTitles = [...audioTitles];
    const index = audioTitles.map(f => f.id).indexOf(id);
    newTitles[index].name = val;
    setAudioTitles(newTitles);
  };

  useEffect(() => {
    const keys = Object.keys(wips);
    const wipsArray: any = keys
      .map(k => wips[k])
      .filter(w => w.author === user.uid || w.writers.indexOf(user.uid) !== -1);
    setSorted(wipsArray.sort(compareDates).reverse());
  }, [wips]);

  useEffect(() => {
    setAudioTitles(getFilesTitles(soundFiles));
  }, [soundFiles]);

  return (
    <div className="app__upload">
      <div className="app__upload--tabs p-3">
        <button
          className={`app__upload--tabs-btn ${
            curSlide === 0 ? 'selected' : ''
          }`}
          type="button"
          onClick={onPrev}>
          New WIP
        </button>
        <button
          className={`app__upload--tabs-btn ${
            curSlide === 1 || curSlide === 2 ? 'selected' : ''
          }`}
          type="button"
          onClick={onNext}>
          Other WIP
        </button>
      </div>
      <Slides
        className="app__upload--steps center"
        startAt={curSlide ? 1 : 0}
        setRef={({goTo}) => {
          goToSlide.current = goTo;
        }}
        autoHeight={false}
        unmountOnExit>
        <div className="app__slide">
          <Uploads
            coverArt={coverFile}
            setCroppedAreaPixels={setCroppedAreaPixels}
            onArtworkChanged={onArtworkChanged}
            wipTitle={wipTitle}
            setWipTitle={setWipTitle}
            authors={authors}
            audioTitles={audioTitles}
            setAudioTitles={onTitleChanged}
            curAuthors={curAuthors}
            onUpload={uploadToNewWip}
            onAddAuthors={onAddAuthors}
            thumbSize={thumbSize}
          />
        </div>

        <div className="app__slide">
          <div className="app__upload">
            <div className="app__upload--wips app__rows p-3">
              {selectedWip?.id && (
                <button
                  key={`wip-${selectedWip.id}`}
                  data-uid={selectedWip.id}
                  type="button"
                  className={`app__rows--item ${
                    selectedWip?.id === selectedWip.id ? 'current' : ''
                  }`}
                  onClick={() => {
                    onSelectWip(selectedWip);
                  }}>
                  <div className="app__rows--item-info">
                    <span className="app__rows--info-title">
                      {selectedWip.title?.text}
                    </span>
                    <small className="app__rows--info-author">
                      {selectedWip.authorName}
                    </small>
                  </div>
                  <ChevronRightIcon
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="square"
                  />
                </button>
              )}
              {wipList.map(
                wip =>
                  !wip.disabled &&
                  selectedWip?.id !== wip.id && (
                    <button
                      key={`wip-${wip.id}`}
                      data-uid={wip.id}
                      type="button"
                      className={`app__rows--item ${
                        selectedWip?.id === wip.id ? 'current' : ''
                      }`}
                      onClick={() => {
                        onSelectWip(wip);
                      }}>
                      <div className="app__rows--item-info">
                        <span className="app__rows--info-title">
                          {wip.title.text}
                        </span>
                        <small className="app__rows--info-author">
                          {wip.authorName}
                        </small>
                      </div>
                      <ChevronRightIcon
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeLinejoin="square"
                      />
                    </button>
                  ),
              )}
            </div>
          </div>
        </div>

        <div className="app__slide">
          <div className="app__upload--inputs">
            {selected && (
              <div className="app__upload--head">
                <small>Uploading to: </small>
                <br />
                <span>{selected.title.text}</span>
              </div>
            )}
            {audioTitles.map(({name, id}) => (
              <Input
                key={id}
                name="title"
                className="app__input"
                placeholder="Add Audio Title"
                value={name}
                onChange={e => {
                  onTitleChanged(e.value, id);
                }}
              />
            ))}
          </div>
          <div className="app__upload--footer p-3">
            <button
              type="button"
              onClick={() => {
                uploadToWip(selected.id);
              }}
              className="btn btn-light app__upload--footer-action">
              <span>Done</span>
            </button>
          </div>
        </div>

        <div className="app__slide">
          <Invite
            selectedWip={{
              ...selectedWip,
              author: user.uid,
            }}
            onProducer={onWriter}
            onConsumer={onReader}
            onRemove={onRemove}
            permissions={permissions}
          />
          <div className="app__upload--footer p-3">
            <button
              type="button"
              onClick={onPrev}
              className="btn btn-light app__upload--footer-action">
              <span>Done</span>
            </button>
          </div>
        </div>
      </Slides>
    </div>
  );
};

const mapStateToProps = ({common, authors, wips, audios}: any) => ({
  user: common.user,
  wips: mergeWIPData(wips.all, audios.all, authors.users),
  authors: authors.users,
});

const mapDispatchToProps = {
  onBulkNewWip: bulkToNewWip,
  onBulkAudios: bulkUploadAudios,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadWip);
