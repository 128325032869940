import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Tabs = ({children, active}) => {
  const childRefs = useRef({});
  const [bg, setBg] = useState({
    width: 0,
    left: 0,
  });
  useEffect(() => {
    if (childRefs.current[active]) {
      const activeTab = childRefs.current[active];
      const {width} = activeTab.getBoundingClientRect();
      setBg({
        width,
        left: activeTab.offsetLeft,
      });
    } else {
      setBg({
        width: 0,
        left: 0,
      });
    }
  }, [active, childRefs]);
  return (
    <div className="app__tabs">
      <div
        className="app__tabs--bg"
        style={{
          width: bg.width,
          left: bg.left,
        }}
      />
      {React.Children.map(children, (child, i) => (
        <div
          className="app__tabs--tab"
          data-tab={i}
          data-active={active === i}
          ref={el => {
            childRefs.current[i] = el;
          }}>
          {child}
        </div>
      ))}
    </div>
  );
};

Tabs.defaultProps = {
  active: -1,
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  active: PropTypes.number,
};

export default Tabs;
