import React from 'react';
import PropTypes from 'prop-types';

import Menu from '..';
import MenuHeader from '../menu-header';
import Invite from '../../invite';
import {getWipPermissions} from '../../../../helpers/helper-util';
import usePermissions from '../../../hooks/usePermissions';

const MenuInvite = ({selectedWip, menuOpened, onCloseMenu}) => {
  const permissions = getWipPermissions(selectedWip);

  const {onProducer, onConsumer, onRemove} = usePermissions({
    selectedWip,
    permissions,
  });

  return (
    <Menu menuOpened={menuOpened} onClose={onCloseMenu}>
      <div className="app__container">
        <MenuHeader title="Collaborators" onClose={onCloseMenu} />
        <Invite
          selectedWip={selectedWip}
          onProducer={onProducer}
          onConsumer={onConsumer}
          onRemove={onRemove}
          permissions={permissions}
        />
      </div>
    </Menu>
  );
};

MenuInvite.defaultProps = {
  selectedWip: {},
  menuOpened: false,
  onCloseMenu: () => {},
};

MenuInvite.propTypes = {
  selectedWip: PropTypes.objectOf(PropTypes.any),
  menuOpened: PropTypes.bool,
  onCloseMenu: PropTypes.func,
};

export default MenuInvite;
