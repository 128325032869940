import React from 'react';
import PropTypes from 'prop-types';
import Plus from 'react-feather/dist/icons/plus';

const ExplorerAction = ({onClick, item}) => {
  const {name} = item;
  return (
    <button
      className="app__fileexplorer--action"
      type="button"
      onClick={() => {
        onClick(item);
      }}>
      <div className="app__fileexplorer--action-icon">
        {item.renderLabel ? item.renderLabel() : <Plus size={18} />}
      </div>
      <div className="app__fileexplorer--action-info">
        <div className="app__fileexplorer--action-name">{name}</div>
      </div>
    </button>
  );
};

ExplorerAction.defaultProps = {
  onClick: () => {},
  item: {},
};

ExplorerAction.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
};

export default ExplorerAction;
