import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Input = ({
  id,
  className,
  placeholder,
  setRef,
  type,
  tabIndex,
  disabled,
  value,
  name,
  required,
  onChange,
  onKeyPress,
  onEnter,
  error,
  autoComplete,
  style,
}) => {
  const keyPress = e => {
    if (e.key === 'Enter') {
      onEnter();
    } else {
      onKeyPress(e);
    }
  };
  const onUpdate = e => {
    onChange({
      name: name === '' ? id : name,
      value: e.target.value,
    });
  };
  return (
    <input
      id={id}
      className={`${className} ${error ? 'has-error' : ''}`}
      placeholder={placeholder}
      ref={setRef}
      type={type}
      onFocus={e => {
        e.target.click();
      }}
      // onBlur={onBlur}
      onChange={onUpdate}
      onKeyPress={keyPress}
      value={value}
      tabIndex={tabIndex}
      disabled={disabled}
      required={required}
      autoComplete={autoComplete}
      style={style}
    />
  );
};

Input.defaultProps = {
  id: undefined,
  onChange: v => {
    console.log(v);
  },
  setRef: () => {},
  onEnter: () => {},
  onKeyPress: () => {},
  className: '',
  placeholder: '',
  type: 'text',
  name: '',
  autoComplete: '',
  disabled: false,
  error: false,
  required: false,
  value: '',
  tabIndex: '0',
  style: {},
};

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  autoComplete: PropTypes.string,
  onEnter: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  tabIndex: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};

export default Input;
