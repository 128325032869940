/* eslint-disable no-param-reassign */
import {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getSortedTracks} from '../../helpers/helper-untitled';
import {UPlayerContext} from '../context/player-context';

// const areEqual = (prev, next) => JSON.stringify(prev) === JSON.stringify(next);

const useWipPlaylist = ({selectedWip}) => {
  const {player, playerState} = useContext(UPlayerContext);
  const [wipAudios, setWipAudios] = useState([]);
  const wip = useSelector(state => state.wips.all[playerState.id]);
  const audios = useSelector(state => state.audios.all);
  const authors = useSelector(state => state.authors.users);

  useEffect(() => {
    const audiosSorted = getSortedTracks(selectedWip, authors, audios);
    setWipAudios(audiosSorted);
    if (wip) {
      const songs = getSortedTracks(wip, authors, audios);
      player.update(songs);
    }
  }, [selectedWip, wip, authors, audios]);

  return wipAudios;
};

export default useWipPlaylist;
