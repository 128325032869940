/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */

import {toast} from 'react-toastify';

import {
  wipsChanged,
  pinsChanged,
  wipChanged,
  wipsNotifications,
  notesChanged,
  listensChanged,
  wipJoinsChanged,
} from '@untitled/app/core/redux/actions/wips';
import {audioRemove} from '@untitled/app/core/redux/actions/audios';

import firebase, {
  auth,
  db,
  storage,
} from '@untitled/app/core/helpers/helper-firebase';
import {
  parseFirebaseFields,
  parseWIPData,
} from '@untitled/app/core/helpers/helper-untitled';
import {
  ActivityType,
  AuthorMetaKinds,
  stageString,
  UntitledInstanceObjects,
  WIPMediaKinds,
} from '@untitled/app/core/helpers/helper-types';
import {
  generateActivityFeed,
  generateNotifications,
  processActivities,
  sendPushNotification,
} from '@untitled/app/core/helpers/helper-notifications';
import {allSettled} from '@untitled/app/core/helpers/helper-upload';
import Analytics from '@untitled/app/core/helpers/helper-analytics';
import UploadsManager from '@untitled/app/core/classes/uploads-manager';
import {logErrorMessage} from '@untitled/app/core/helpers/helper-logs';
import {WIPstatus} from '@untitled/app/core/constants';
import {setLoader} from '@untitled/app/core/redux/actions/common';

const uploadManager = UploadsManager.getInstance();

const displayError = (err: {message: string}) => {
  // Handle Errors here.
  logErrorMessage(err.message);
  toast.error(err.message, {
    type: 'error',
    autoClose: 5000,
  });
  return false;
};

type DocumentData = firebase.firestore.DocumentData;
type QuerySnapshot = firebase.firestore.QuerySnapshot<DocumentData>;
interface DispatchTypeParams {
  type: string;
  payload: any;
}
type DispatchType = (params: DispatchTypeParams) => void;
type GetStateType = () => any;
interface NotificationSnapshot extends Record<string, any> {}
interface WipSnapshot extends Record<string, any> {}
interface SnapshotParsed {
  notifications: NotificationSnapshot;
  wips: WipSnapshot;
  shouldGoHome: Boolean;
}

const goHome = (history: any) => {
  toast.dark('WIP has been deleted');
  history.push({
    pathname: '/home',
    state: {tab: 0},
  });
};

const transformWip = (parsed: any, state: any) => {
  const {author, audios = []} = parsed;

  const audioList = state?.audios?.all ?? [];
  const authorsList = state?.authors?.users ?? [];
  const authorName = authorsList?.[author]?.name ?? '';
  const audio = parsed?.audio;
  const tracks = [audio, ...audios].reverse();

  return {
    ...parsed,
    tracks,
    authorName,
    audioURL: audioList?.[audio]?.audio?.uri,
  };
};

const onWipSnapshot =
  (
    history: any,
    uid: string,
    dispatch: (act: {type: string; payload: any}) => void,
    getState: () => any,
  ) =>
  (querySnapshot: QuerySnapshot) => {
    const {
      activity,
      authors: {users},
    } = getState();
    const ignoredAuthorIds = users[uid]?.ignoredAuthorIds ?? [];
    const wipInvites: object[] = activity.all.filter(
      ({kind}: {kind: number}) => kind === ActivityType.newInvite,
    );

    const parsedSnapshot = querySnapshot.docChanges().reduce(
      (acc: SnapshotParsed, change: any) => {
        const {doc, type} = change;
        const {location} = history;

        if (type === 'removed') {
          uploadManager.removeUpload(doc.id);
          acc.notifications[doc.id] = undefined;
          acc.wips[doc.id] = undefined;
          acc.shouldGoHome =
            acc.shouldGoHome || location.search.indexOf(doc.id) !== -1;
        } else {
          const data = doc.data() as any;
          const parsed = parseWIPData(doc.id, {
            ...data,
          });
          const {author, confirmed, denied, writers, readers, id} = parsed;
          const isConfirmed = confirmed.indexOf(uid) > -1;
          const isDenied = denied.indexOf(uid) > -1;

          const isIgnored =
            wipInvites.find(
              ({author, wipId}) =>
                wipId === doc.id && ignoredAuthorIds?.includes(author),
            ) !== undefined;

          if (author === uid || (isConfirmed && !isDenied)) {
            if (parsed.status !== WIPstatus.archived) {
              acc.wips[id] = transformWip(parsed, getState());
            }
            if (author !== uid && isConfirmed) {
              acc.notifications[id] = undefined;
            }
          } else if (
            !isDenied &&
            !isIgnored &&
            (writers.indexOf(uid) > -1 || readers.indexOf(uid) > -1)
          ) {
            acc.notifications[id] = parsed;
          } else if ((isDenied || isIgnored) && author !== uid) {
            acc.notifications[id] = undefined;
          }
        }
        return acc;
      },
      {
        notifications: {},
        wips: {},
        shouldGoHome: false,
      },
    );
    dispatch(wipsChanged(parsedSnapshot.wips));
    dispatch(wipsNotifications(parsedSnapshot.notifications));
    setTimeout(() => dispatch(setLoader(false)), 500);

    if (parsedSnapshot.shouldGoHome) {
      goHome(history);
    }
  };

export const listenWIPS =
  (uid: string, history: any) =>
  (dispatch: DispatchType, getState: GetStateType) => {
    const authorRef = db.collection('developmentAuthor').doc(uid);
    const [authorWipsSub, invitedWipSub] = [
      db
        .collection(stageString(UntitledInstanceObjects.WIP))
        .where('author', '==', authorRef)
        .orderBy('lastEdited', 'desc')
        .onSnapshot(onWipSnapshot(history, uid, dispatch, getState)),
      db
        .collection(stageString(UntitledInstanceObjects.WIP))
        .where('invited', 'array-contains', authorRef)
        .orderBy('lastEdited', 'desc')
        .onSnapshot(onWipSnapshot(history, uid, dispatch, getState)),
    ];

    return () => {
      authorWipsSub();
      invitedWipSub();
    };
  };

export const listenPinWIPS = (uid: string) => (dispatch: DispatchType) => {
  const thisAuthorPinsRef = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(uid)
    .collection(stageString(UntitledInstanceObjects.AuthorMeta))
    .doc('pins');

  return thisAuthorPinsRef.onSnapshot((doc: any) => {
    const data = doc.data() || {};
    const pins = Object.keys(data)
      .map(key => ({key, pinned: data[key].toDate().toISOString()}))
      .sort((a, b) => b.pinned.localeCompare(a.pinned))
      .map(pin => pin.key);

    dispatch(pinsChanged(pins));
  });
};

export const listenLastListened =
  (uid: string) => (dispatch: (action: any) => void) => {
    return db
      .collection(stageString(UntitledInstanceObjects.Author))
      .doc(uid)
      .collection(stageString(UntitledInstanceObjects.AuthorMeta))
      .doc(AuthorMetaKinds.listens)
      .onSnapshot((doc: any) => {
        const data = doc.data() || {};
        const listens = {} as any;

        Object.entries(data).forEach(([key, value]) => {
          listens[key] = (value as any).toDate();
        });

        dispatch(listensChanged(listens));
      });
  };

export const listenWipJoins =
  (uid: string) => (dispatch: (action: any) => void) => {
    return db
      .collection(stageString(UntitledInstanceObjects.Author))
      .doc(uid)
      .collection(stageString(UntitledInstanceObjects.AuthorMeta))
      .doc(AuthorMetaKinds.wipJoins)
      .onSnapshot((doc: any) => {
        const data = doc.data() || {};
        const wipJoins = {} as any;

        Object.entries(data).forEach(([key, value]) => {
          wipJoins[key] = (value as any).toDate();
        });

        dispatch(wipJoinsChanged(wipJoins));
      });
  };

export const getWIP = (uid: string) => (dispatch: DispatchType) =>
  db
    .collection('developmentWIP')
    .doc(uid)
    .get()
    .then((doc: any) => {
      const data = doc.data() as any;
      const parsed = parseWIPData(doc.id, data);
      dispatch(wipChanged(parsed));
    })
    .catch(displayError);

const toastId = 'upload-toast';
const toastToWipId = 'toast-to-wip';
const toastNewImageId = 'toast-new-image';

export const addWIP =
  (wipData: any, uploadData: any) =>
  async (_: DispatchType, getState: GetStateType) => {
    console.log('addWIP', wipData, uploadData);

    const {
      wipRef,
      author: authorRef,
      audio: audioRef,
      coverArt: coverArtRef,
    } = wipData;

    const {
      audioStorageRef,
      audioBlob,
      uriSound,
      coverArtStorageRef,
      coverArtBlob,
      uriImage,
      audioData,
      coverArtData,
      readers = [],
      writers = [],
    } = uploadData;

    toast('Uploading files', {
      progress: 0,
      autoClose: 1000,
      toastId,
    });

    const [audioDownloadURL, coverArtDownloadURL] = await Promise.all([
      new Promise(resolve => {
        audioStorageRef
          .put(audioBlob, {
            customMetadata: {
              author: authorRef.path,
              wip: wipRef.path,
              storageRef: audioStorageRef.fullPath,
              firestoreAudioPath: audioRef.path,
            },
          })
          .on(
            'state_changed',
            (a: any) => {
              uploadManager.updateUpload({
                wipId: wipRef.id,
                uploadProgress: a.bytesTransferred / a.totalBytes || 0,
                fileId: audioRef.id,
                type: 'audio',
              });
            },
            (e: any) => {
              console.log('e', e);
            },
            () => audioStorageRef.getDownloadURL().then(resolve),
          );
      }),
      new Promise(resolve => {
        coverArtStorageRef
          .put(coverArtBlob, {
            customMetadata: {
              author: authorRef.path,
              wip: wipRef.path,
              dbRef: coverArtRef.path,
              kind: WIPMediaKinds.image,
              storageRef: coverArtStorageRef.fullPath,
              imageRef: coverArtRef.id,
            },
          })
          .on(
            'state_changed',
            (a: any) => {
              uploadManager.updateUpload({
                wipId: wipRef.id,
                uploadProgress: a.bytesTransferred / a.totalBytes || 0,
                fileId: coverArtRef.id,
                type: 'image',
              });
            },
            (e: any) => {
              console.log('e', e);
            },
            () => coverArtStorageRef.getDownloadURL().then(resolve),
          );
      }),
    ]);

    audioData.uri = audioDownloadURL;
    coverArtData.uri = coverArtDownloadURL;

    const invited = [...readers, ...writers].map(id =>
      db.collection(stageString(UntitledInstanceObjects.Author)).doc(id),
    );

    const wipUpdates = {
      ...wipData,
      invited,
    };

    const wipId = wipRef.id;

    delete wipUpdates.wipRef;
    await Promise.allSettled([
      audioRef.set(audioData),
      coverArtRef.set(coverArtData),
    ]);
    await wipRef.set({
      ...wipUpdates,
      album: {
        cover: coverArtDownloadURL,
      },
      ready: true,
      uploading: null,
    });
    URL.revokeObjectURL(uriImage);
    URL.revokeObjectURL(uriSound);
    toast.dark('WIP uploaded successfully!', {
      autoClose: 3000,
      hideProgressBar: true,
    });
    // TODO: properly determine the length of the uploaded audio
    const audioDuration = 24;
    Analytics.logEventCreateWIP('device_upload');
    Analytics.logEventCreateAudio(audioDuration, 'device_upload');

    for (let i = 0; i < readers.length; i += 1) {
      Analytics.logEventShareWip(readers[i], wipRef.id, false);
    }
    for (let i = 0; i < writers.length; i += 1) {
      Analytics.logEventShareWip(writers[i], wipRef.id, true);
    }

    const meta = {
      kind: ActivityType.newInvite,
    };

    const {author, users} = await processActivities(
      wipRef,
      authorRef,
      getState(),
      [...readers, ...writers],
      meta,
    );

    if (!(wipRef.id in ['YWbDeHlMNmIZzPgLCu4Q', 'PBkL1L6kUXOOUjLWAIbc'])) {
      const notifications = generateNotifications({
        type: ActivityType.newInvite,
        users,
        audioId: audioRef.id,
        author,
        wip: {
          id: wipId,
          ...uploadData,
          readers,
          writers,
          title: wipData.title,
        },
        audioTitle: audioData.title,
        wipTitle: uploadData.wipTitle,
        targetAll: true,
      });

      return sendPushNotification(notifications);
    }

    return null;
  };

const {arrayRemove, arrayUnion} = firebase.firestore.FieldValue;

export const addAudioToWIP =
  (wipData: any, uploadData: any) =>
  async (_: DispatchType, getState: GetStateType) => {
    console.log('addAudioToWIP', wipData, uploadData);
    const {wips} = getState();

    const {wipRef, author: authorRef, audio: audioRef} = wipData;

    const {audioStorageRef, audioBlob, audioData} = uploadData;

    const wipList = {...wips.all, ...wips.notifications};
    const wip = wipList[wipRef.id];

    const now = firebase.firestore.Timestamp.now();

    toast('Uploading audio file', {
      progress: 0,
      autoClose: 1000,
      toastId: toastToWipId,
    });

    const [audioDownloadURL] = await Promise.all([
      new Promise(resolve => {
        audioStorageRef
          .put(audioBlob, {
            customMetadata: {
              author: authorRef.path,
              wip: wipRef.path,
              storageRef: audioStorageRef.fullPath,
              firestoreAudioPath: audioRef.path,
            },
          })
          .on(
            'state_changed',
            (a: any) => {
              uploadManager.updateUpload({
                wipId: wipRef.id,
                uploadProgress: a.bytesTransferred / a.totalBytes || 0,
                fileId: audioRef.id,
                type: 'audio',
              });
            },
            () => {},
            () => audioStorageRef.getDownloadURL().then(resolve),
          );
      }),
    ]);

    audioData.uri = audioDownloadURL;

    const updates = {
      lastEdited: now,
      audios: arrayUnion(audioRef),
    };

    await allSettled([audioRef.set(audioData), wipRef.update(updates)]).then(
      () => {
        toast.dark('WIP audio uploaded successfully!', {
          autoClose: 5000,
          hideProgressBar: true,
        });

        // TODO: properly determine the length of the uploaded audio
        const audioDuration = 47;
        Analytics.logEventCreateAudio(audioDuration, 'device_upload');
      },
    );

    const confirmed = wip.confirmed || [];

    const meta = {
      kind: ActivityType.newAudio,
      audioId: audioRef.id,
    };

    const {author, users} = await processActivities(
      wipRef,
      authorRef,
      getState(),
      [wip.author, ...confirmed],
      meta,
    );

    if (!(wipRef.id in ['YWbDeHlMNmIZzPgLCu4Q', 'PBkL1L6kUXOOUjLWAIbc'])) {
      const notifications = generateNotifications({
        type: ActivityType.newAudio,
        users,
        audioId: audioRef.id,
        author,
        wip,
        audioTitle: audioData.title,
        wipTitle: uploadData.wipTitle,
      });

      return sendPushNotification(notifications);
    }

    return null;
  };

export const deleteWipAudio =
  (wip: any, audio: any) => async (dispatch: DispatchType) => {
    const {id: wipId, audio: mainAudio} = wip;
    const {id: audioId, storageRef} = audio;

    const wipRef = db
      .collection(stageString(UntitledInstanceObjects.WIP))
      .doc(wipId);

    if (mainAudio === audioId) {
      const {audios = []} = wip;

      if (audios.length === 0) {
        // No other audio, delete the wip and its activity
        await wipRef
          .collection(stageString(UntitledInstanceObjects.Activity))
          .get()
          .then((result: any) => {
            result.forEach((doc: any) => {
              doc.ref.delete();
            });
          });
        await wipRef.delete();
        Analytics.logEventDeleteWip();

        const audioRef = db
          .collection(stageString(WIPMediaKinds.audio))
          .doc(audioId);
        await audioRef.delete();
        await storage.ref(storageRef).delete();

        Analytics.logEventDeleteAudio();

        // toast.dark('WIP Deleted successfully!', {
        //   autoClose: 5000,
        //   hideProgressBar: true,
        // });
        return true;
      }

      // delete only the audio, replace "audio" field with the oldest in "audios"
      const newMainRef = db
        .collection(stageString(WIPMediaKinds.audio))
        .doc([...audios].shift());

      const oldMainRef = db
        .collection(stageString(WIPMediaKinds.audio))
        .doc(wip.audio);

      wipRef
        .update({
          audio: newMainRef,
          audios: firebase.firestore.FieldValue.arrayRemove(newMainRef),
        })
        .then(() => {
          oldMainRef.delete();
        });

      return false;
    }
    // just delete the audio from "audios"
    const audioRef = db
      .collection(stageString(WIPMediaKinds.audio))
      .doc(audioId);

    await wipRef.update({
      audios: arrayRemove(audioRef),
    });

    await audioRef.delete();

    Analytics.logEventDeleteAudio();

    // const queueRef = db.collection(stageString(WIPMediaKinds.queue))
    //   .doc(audioId);

    // const queueDoc = await queueRef.get();

    // if (!queueDoc.exists) {
    //   console.log('No queue connected, deleting file');
    //   await storage.ref(storageRef).delete();
    // } else {
    //   console.log('Queue connected, leaving file');
    // }

    await storage.ref(storageRef).delete();

    toast('Audio deleted', {autoClose: 3000});
    dispatch(audioRemove(audioId));
    return false;
  };

export const setWipCoverArt =
  (wip: any, image: any) => async (_: DispatchType, getState: GetStateType) => {
    console.log('setWipCoverArt', wip, image);
    const {id: wipId, author: wipAuthorId, coverArt} = wip;
    const state = getState();
    const authorId = state?.common?.user?.uid ?? wipAuthorId;

    const deleteRef = db
      .collection(stageString(WIPMediaKinds.image))
      .doc(coverArt);
    const imageRef = db.collection(stageString(WIPMediaKinds.image)).doc();
    const wipRef = db
      .collection(stageString(UntitledInstanceObjects.WIP))
      .doc(wipId);
    const authorRef = db
      .collection(stageString(UntitledInstanceObjects.Author))
      .doc(authorId);
    const imageStorageRef = storage.ref(
      `__some_dir__/${authorId}/${wipId}/coverArt.${image.extension}`,
    );

    const imageCustomMetadata = {
      author: authorRef.path,
      wip: wipRef.path,
      dbRef: imageRef.path,
      kind: WIPMediaKinds.image,
      storageRef: imageStorageRef.fullPath,
      imageRef: imageRef.id,
    };

    toast('Updating cover art', {
      progress: 0,
      autoClose: 1000,
      toastId: toastNewImageId,
    });

    const imageBlob = await fetch(image.uri).then(f => f.blob());

    const imageDownloadURI = await new Promise(resolve => {
      imageStorageRef
        .put(imageBlob, {
          customMetadata: imageCustomMetadata,
        })
        .on(
          'state_changed',
          (a: any) => {
            uploadManager.updateUpload({
              wipId: wipRef.id,
              uploadProgress: a.bytesTransferred / a.totalBytes || 0,
              fileId: imageRef.id,
              type: 'image',
            });
          },
          () => {},
          () => {
            imageStorageRef.getDownloadURL().then((d: any) => resolve(d));
          },
        );
    });

    const imageData = {
      created: firebase.firestore.Timestamp.now(),
      lastEdited: firebase.firestore.Timestamp.now(),
      uri: imageDownloadURI,
      author: authorRef,
    };

    await allSettled([
      imageRef.set(imageData),
      wipRef.update({
        coverArt: imageRef,
        lastEdited: firebase.firestore.Timestamp.now(),
      }),
      deleteRef.delete(),
    ]);

    const confirmed = wip.confirmed || [];

    const meta = {
      kind: ActivityType.newImage,
      coverArtId: imageRef.id,
    };

    const {author, users} = await processActivities(
      wipRef,
      authorRef,
      state,
      [wip.author, ...confirmed],
      meta,
    );

    if (!(wipRef.id in ['YWbDeHlMNmIZzPgLCu4Q', 'PBkL1L6kUXOOUjLWAIbc'])) {
      const notifications = generateNotifications({
        type: ActivityType.newImage,
        users,
        author,
        wip,
      });

      return sendPushNotification(notifications);
    }

    return null;
  };

const basePermissions = (authorRef: any) => ({
  readers: arrayRemove(authorRef),
  writers: arrayRemove(authorRef),
  denied: arrayRemove(authorRef),
  invited: arrayUnion(authorRef),
});

const notifyAction = async (
  wip: any,
  author: any,
  subscribers: any,
  wipRef: any,
  users: any,
  invited: any,
) => {
  console.log('notifyAction', wip);
  const activityUpdateData = generateActivityFeed({
    wipId: wip.id,
    authorId: author.id,
    kind: ActivityType.newInvite,
    subscribers,
  });

  await wipRef
    .collection(stageString(UntitledInstanceObjects.Activity))
    .doc()
    .set(activityUpdateData);

  const notifications = generateNotifications({
    type: ActivityType.newInvite,
    users,
    author,
    wip,
    invite: invited,
  });

  return sendPushNotification(notifications);
};

export const setAsReader = async (args: any) => {
  const {invited, notify, subscribers, users, wip, author} = args;
  console.log(args);
  const wipRef = db
    .collection(stageString(UntitledInstanceObjects.WIP))
    .doc(wip.id);
  const authorRef = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(invited.id);

  // Check to see whether the invited collaborator
  // is new or having permissions edited, before logging
  let newCollab = true;
  await wipRef
    .get()
    .then((doc: any) => {
      newCollab = !doc
        .get('writers')
        .some((element: any) => element.id === invited.id);
    })
    .then(() => {
      wipRef
        .update({
          ...basePermissions(authorRef),
          readers: arrayUnion(authorRef),
        })
        .then(() => {
          if (newCollab) {
            Analytics.logEventShareWip(invited.id, wip.id, false);
          }
        });
    });

  if (!notify) {
    return false;
  }

  return notifyAction(wip, author, subscribers, wipRef, users, invited);
};

export const setAsWriter = async (args: any) => {
  const {invited, notify, subscribers, users, wip, author} = args;
  console.log(args);
  const wipRef = db
    .collection(stageString(UntitledInstanceObjects.WIP))
    .doc(wip.id);
  const authorRef = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(invited.id);

  // Check to see whether the invited collaborator
  // is new or having permissions edited, before logging
  let newCollab = true;
  await wipRef
    .get()
    .then((doc: any) => {
      newCollab = !doc
        .get('readers')
        .some((element: any) => element.id === invited.id);
    })
    .then(() => {
      wipRef
        .update({
          ...basePermissions(authorRef),
          writers: arrayUnion(authorRef),
        })
        .then(() => {
          if (newCollab) {
            Analytics.logEventShareWip(invited.id, wip.id, true);
          }
        });
    });

  if (!notify) {
    return false;
  }

  return notifyAction(wip, author, subscribers, wipRef, users, invited);
};

export const removePermissions = async (args: any) => {
  const {wip, authorId} = args;
  const wipRef = db
    .collection(stageString(UntitledInstanceObjects.WIP))
    .doc(wip.id);
  const authorRef = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(authorId);

  await wipRef
    .collection(stageString(UntitledInstanceObjects.Activity))
    .get()
    .then((result: any) => {
      result.forEach((doc: any) => {
        doc.ref.update({
          subscribers: firebase.firestore.FieldValue.arrayRemove(authorId),
        });
      });
    });

  return wipRef.update({
    ...basePermissions(authorRef),
    denied: arrayUnion(authorRef),
    confirmed: arrayRemove(authorRef),
    invited: arrayRemove(authorRef),
  });
};

export const acceptInvite =
  (args: any) => async (_: DispatchType, getState: GetStateType) => {
    const {wips} = getState();
    const {wipId, authorId} = args;
    const wipList = {...wips.all, ...wips.notifications};
    const wip = wipList[wipId];

    const wipRef = db.collection('developmentWIP').doc(wipId);
    const authorRef = db
      .collection(stageString(UntitledInstanceObjects.Author))
      .doc(authorId);

    await wipRef.update({
      denied: arrayRemove(authorRef),
      confirmed: arrayUnion(authorRef),
    });

    // Notifications side
    const subs = [wip.author];

    const meta = {
      kind: ActivityType.acceptedInvite,
    };

    const {author, users} = await processActivities(
      wipRef,
      authorRef,
      getState(),
      subs,
      meta,
    );

    if (!(wipId in ['YWbDeHlMNmIZzPgLCu4Q', 'PBkL1L6kUXOOUjLWAIbc'])) {
      const notifications = generateNotifications({
        type: ActivityType.acceptedInvite,
        users,
        author,
        wip,
      });

      sendPushNotification(notifications);
    }

    return {
      users,
      author,
      wip,
      kind: ActivityType.acceptedInvite,
    };
  };

export const denyInvite =
  (args: any) => async (_: DispatchType, getState: GetStateType) => {
    const {wips} = getState();
    const {wipId, authorId} = args;
    const wipList = {...wips.all, ...wips.notifications};
    const wip = wipList[wipId];

    const wipRef = db.collection('developmentWIP').doc(wipId);
    const authorRef = db
      .collection(stageString(UntitledInstanceObjects.Author))
      .doc(authorId);

    await wipRef.update({
      denied: arrayUnion(authorRef),
      confirmed: arrayRemove(authorRef),
    });
  };

export const updateWIP =
  (author: any, newEdit: any) =>
  async (dispatch: DispatchType, getState: GetStateType) => {
    const {wip, coverArt, wipTitle} = newEdit;
    const now = firebase.firestore.Timestamp.now();

    const wipRef = db
      .collection(stageString(UntitledInstanceObjects.WIP))
      .doc(wip.id);

    if (coverArt) {
      await setWipCoverArt(wip, coverArt)(dispatch, getState);
      toast.dismiss(toastNewImageId);
    }

    const wipData = {
      lastEdited: now,
      title: {
        text: wipTitle,
        created: now,
      },
    };

    const wipDoc = await wipRef.get();
    const remoteData = wipDoc.data() as any;
    await wipRef.update(wipData);

    if (remoteData?.title?.text !== wipTitle) {
      const authorRef = db
        .collection(stageString(UntitledInstanceObjects.Author))
        .doc(author.uid);
      const meta = {
        kind: ActivityType.updatedTitle,
        type: ActivityType.updatedTitle,
        title: wipTitle,
        oldTitle: remoteData?.title?.text,
      };
      const subscribers = [wip.author, ...wip.confirmed].filter(
        sub => sub !== author.uid,
      );

      const {users, author: authorNotif} = await processActivities(
        wipRef,
        authorRef,
        getState(),
        subscribers,
        meta,
      );

      if (!(wip.id in ['YWbDeHlMNmIZzPgLCu4Q', 'PBkL1L6kUXOOUjLWAIbc'])) {
        const notifications = generateNotifications({
          type: ActivityType.updatedTitle,
          users,
          author: authorNotif,
          wip,
          wipTitle,
        });

        sendPushNotification(notifications);
      }
    }
  };

export const updateIsPublic = async (wipId: string, isPublic: boolean) => {
  await db
    .collection(stageString(UntitledInstanceObjects.WIP))
    .doc(wipId)
    .update({isPublic});
};

export const onSetPin = (wipId: string, pinned: boolean) => {
  const user = auth.currentUser;
  const authorMeta = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(user?.uid)
    .collection(stageString(UntitledInstanceObjects.AuthorMeta));
  authorMeta.doc('pins').set(
    {
      [wipId]: pinned
        ? firebase.firestore.FieldValue.delete()
        : firebase.firestore.Timestamp.now(),
    },
    {merge: true},
  );
};

export const onWipJoin = (wipId: string, joined: Boolean) => {
  const user = auth.currentUser;
  db.collection(stageString(UntitledInstanceObjects.Author))
    .doc(user?.uid)
    .collection(stageString(UntitledInstanceObjects.AuthorMeta))
    .doc(AuthorMetaKinds.wipJoins)
    .set(
      {
        [wipId]: joined
          ? firebase.firestore.Timestamp.now()
          : firebase.firestore.FieldValue.delete(),
      },
      {merge: true},
    )
    .catch((error: any) =>
      console.warn(`While updating wip_joins with wipId=${wipId}`, error),
    );
};

export const getNotes = (authorId: string) => (dispatch: DispatchType) =>
  db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(authorId)
    .collection(stageString(UntitledInstanceObjects.AuthorMeta))
    .doc('notes')
    .onSnapshot(
      (doc: any) => {
        const data = doc.data() || {};
        const notes = Object.keys(data).reduce((acc, key) => {
          acc[key] = parseFirebaseFields(data[key]);
          return acc;
        }, {} as Record<string, any>);
        dispatch(notesChanged(notes));
      },
      (error: any) => {
        console.warn('Notes onSnapshot error', error);
      },
    );

interface SaveNoteParams {
  wipId: string;
  authorId: string;
  text: string;
  title: string;
}

export const saveNote = ({wipId, authorId, text, title}: SaveNoteParams) => {
  const authorMeta = db
    .collection(stageString(UntitledInstanceObjects.Author))
    .doc(authorId)
    .collection(stageString(UntitledInstanceObjects.AuthorMeta));

  return authorMeta.doc('notes').set(
    {
      [wipId]: {
        lastUpdated: firebase.firestore.Timestamp.now(),
        text,
        title,
      },
    },
    {merge: true},
  );
};
