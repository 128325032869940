import React, {useEffect} from 'react';
import {useQuery, gql} from '@apollo/client';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {setLoader} from '@untitled/app/core/redux/actions/common';
import HocQueue from '@untitled/app/core/components/hoc/hoc-queue';
import Slides from '@untitled/app/core/components/molecules/slides';
import Scroller from '@untitled/app/core/components/atoms/scroller';
import {logErrorMessage} from '@untitled/app/core/helpers/helper-logs';

import {InfoHeader, InfoBody} from './components';
import Analytics from '@untitled/app/core/helpers/helper-analytics';
import {WAITLIST_URL} from '@untitled/config';

const SHARED_WIP = gql`
  query PublicWip($id: ID!) {
    publicWip(id: $id) {
      id
      authorName
      cover
      title
      tracks {
        id
        author
        created
        fromRecording
        title
        url
      }
    }
  }
`;

type Track = {
  id: string;
  author: string;
  created: string;
  fromRecording: boolean;
  title: string;
  url: string;
};

export type WIP = {
  authorName: string;
  id: string;
  cover: string;
  disabled: boolean;
  title: string;
  tracks: Track[];
};

const Share = () => {
  const {location} = useHistory();
  const dispatch = useDispatch();

  const wipId = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
  );

  const {data, loading, error} = useQuery(SHARED_WIP, {
    variables: {
      id: wipId,
    },
  });

  useEffect(() => {
    Analytics.logEventVisitPublicWip(wipId);
  }, [wipId]);

  useEffect(() => {
    if (!loading) {
      dispatch(setLoader(false));
    }
  }, [loading]);

  if (error) {
    logErrorMessage(error);
    window.location.hash = '';
    window.location.replace('/');
  }

  if (loading || data === undefined) {
    return null;
  }

  return (
    <div id="DetailsPage" className="app__page app__content">
      <div className="app__home">
        <Slides className="app__homebody" autoHeight={false}>
          <Scroller className="app__homebody--tab scrollable">
            <div className="app__info">
              <div className="app__info--join">
                <a
                  href={WAITLIST_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="app__info--join-btn">
                  <div className="">
                    join waitlist for{' '}
                    <span className="app__info--join-btn-untitled">
                      [untitled]
                    </span>
                  </div>
                </a>
              </div>

              <InfoHeader wip={data.publicWip} />
              <InfoBody wip={data.publicWip} />
            </div>
          </Scroller>
        </Slides>
      </div>
    </div>
  );
};

export default HocQueue(Share);
