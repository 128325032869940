import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const PlayPause = ({onClick, className, size, playing, disabled}) => (
  <button
    type="button"
    onClick={onClick}
    className={`app__playpause ${className}`}
    disabled={disabled}>
    <div className="app__playpause--play">
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        {playing ? (
          <>
            <path d="M11 7H8V17H11V7Z" />
            <path d="M13 17H16V7H13V17Z" />
          </>
        ) : (
          <path d="M8 17L16 12L8 7V17Z" />
        )}
      </svg>
    </div>
  </button>
);

PlayPause.defaultProps = {
  className: '',
  onClick: () => {},
  size: 32,
  playing: false,
  disabled: false,
};

PlayPause.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
  playing: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PlayPause;
