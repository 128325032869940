import {
  SET_QUEUE_INIT,
  SET_TEMPURL,
  SET_MUSIC_URL,
  SET_MACHINE_DATA,
  TOGGLE_FOLDER,
  SET_USER_DATA,
  SET_SELECTED_WIP,
  SET_SELECTED_QUEUE,
  QUEUE_RESET,
} from '../actions/queue/constants';

const defaultState = {
  tempUrl: '/',
  musicUrl: '/',
  folder: false,
  machineData: {},
  userData: {},
  machineId: null,
  queueUpdate: Date.now(),
  platform: '',
  initialized: false,
  selected: undefined,
  desktopUrl: undefined,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_QUEUE_INIT: {
      const {musicUrl, desktopUrl, tempUrl, machineId, platform, userData} =
        action.payload;
      return {
        ...state,
        musicUrl,
        desktopUrl,
        tempUrl,
        machineId,
        platform,
        userData,
      };
    }

    case SET_TEMPURL: {
      return {
        ...state,
        tempUrl: action.payload,
      };
    }

    case SET_MUSIC_URL: {
      return {
        ...state,
        musicUrl: action.payload,
      };
    }

    case SET_USER_DATA: {
      const userData = action.payload || {};
      return {
        ...state,
        userData,
      };
    }

    case SET_MACHINE_DATA: {
      const machineData = action.payload || {};
      return {
        ...state,
        machineData,
      };
    }

    case TOGGLE_FOLDER: {
      return {
        ...state,
        folder: !state.folder,
      };
    }

    case SET_SELECTED_WIP: {
      return {
        ...state,
        wip: action.payload,
      };
    }

    case SET_SELECTED_QUEUE: {
      console.log(SET_SELECTED_QUEUE, action.payload);
      return {
        ...state,
        selected: action.payload,
      };
    }

    case QUEUE_RESET: {
      return {
        ...state,
        folder: false,
        machineData: {},
        userData: {},
        queueUpdate: Date.now(),
        initialized: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
