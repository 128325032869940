import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const RangeSlider = ({
  min,
  max,
  step,
  value,
  onChange,
  onMouseDown,
  onTouchStart,
  onTouchEnd,
  onMouseUp,
  disabled,
}) => {
  const val = 100 / (max / value);
  const background = `linear-gradient(to right, var(--fg) 0%, var(--fg) ${val}%, #919191 ${val}%, #919191 100%)`;
  return (
    <div className="app__range">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onMouseUp={onMouseUp}
        style={{
          background,
        }}
        disabled={disabled}
      />
    </div>
  );
};

RangeSlider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  value: 0,
  onChange: () => {},
  onMouseDown: undefined,
  onTouchStart: undefined,
  onTouchEnd: undefined,
  onMouseUp: undefined,
  disabled: undefined,
};

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onMouseDown: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onMouseUp: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RangeSlider;
