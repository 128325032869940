import {useRef, useState} from 'react';
import {throttle} from '../../helpers/helper-util';

const useThrottledSearch = () => {
  const [query, setQuery] = useState('');

  const onSearch = text => {
    setQuery(text);
  };

  const throttledSearch = useRef(throttle(onSearch, 1000));

  return {
    searchQuery: query,
    setSearchQuery: throttledSearch.current,
  };
};

export default useThrottledSearch;
