import {
  AUDIO_CHANGED,
  AUDIOS_CHANGED,
  AUDIO_REMOVE,
  AUDIO_RESET,
  AUDIO_SELECT,
  COMMENTS_CHANGED,
} from './constants';

export const audioChanged = payload => ({
  type: AUDIO_CHANGED,
  payload,
});

export const audiosChanged = payload => ({
  type: AUDIOS_CHANGED,
  payload,
});

export const audioRemove = payload => ({
  type: AUDIO_REMOVE,
  payload,
});

export const audiosReset = payload => ({
  type: AUDIO_RESET,
  payload,
});

export const audiosSelect = payload => ({
  type: AUDIO_SELECT,
  payload,
});

export const commentsChanged = payload => ({
  type: COMMENTS_CHANGED,
  payload,
});
