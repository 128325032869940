/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {format, render, cancel, register} from 'timeago.js';

const localeFunc = (number, index, totalSec) =>
  // number: the timeago / timein number;
  // index: the index of array below;
  // totalSec: total seconds between date to be formatted and today's date;
  [
    ['just now', 'right now'],
    ['now', 'now'],
    // ['%s seconds ago', 'in %s seconds'],
    ['1min ago', 'in 1min'],
    ['%smin ago', 'in %smins'],
    ['1h ago', 'in 1h'],
    ['%sh ago', 'in %sh'],
    ['1d ago', 'in 1d'],
    ['%sd ago', 'in %sd'],
    ['1w ago', 'in 1w'],
    ['%sw ago', 'in %sw'],
    ['1mo ago', 'in 1mo'],
    ['%smo ago', 'in %smo'],
    ['1y ago', 'in 1y'],
    ['%sy ago', 'in %sy'],
  ][index];
// register your locale with timeago
register('my-locale', localeFunc);

const TimeAgo = ({className, style, live, datetime, locale, ...rest}) => {
  const elemRef = useRef(null);
  const renderTimeAgo = () => {
    // cancel all the interval
    cancel(elemRef.current);
    // if is live
    if (live !== false) {
      // live render
      const dateString =
        datetime instanceof Date ? datetime.getTime() : datetime;
      elemRef.current.setAttribute('datetime', dateString);

      render(elemRef.current, locale);
    }
  };
  useEffect(() => {
    renderTimeAgo();
  });
  useEffect(
    () => () => {
      cancel(elemRef.current);
    },
    [],
  );
  return (
    <time
      ref={elemRef}
      className={className || ''}
      style={style}
      title={new Date(datetime).toLocaleString()}
      {...rest}>
      {format(datetime, locale)}
    </time>
  );
};

TimeAgo.propTypes = {
  datetime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]).isRequired, // date to be formated
  live: PropTypes.bool, // real time render.
  locale: PropTypes.string, // locale lang
  className: PropTypes.string, // class name
  style: PropTypes.objectOf(PropTypes.any), // style object
};

TimeAgo.defaultProps = {
  live: true,
  locale: 'my-locale',
  className: '',
  style: {},
};

export default TimeAgo;
