import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Reveal = ({delay, duration, className, children, color}) => (
  <div className={`${className} block-reveal`}>
    <div
      className="block-reveal__element"
      style={{
        animationDelay: `${delay + duration / 2}s`,
      }}>
      {children}
    </div>
    <span
      className="block-reveal__block"
      style={{
        animationDelay: `${delay}s`,
        animationDuration: `${duration}s`,
        backgroundColor: color,
      }}
    />
  </div>
);

Reveal.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  delay: PropTypes.number,
  duration: PropTypes.number,
};

Reveal.defaultProps = {
  className: '',
  delay: 0.2,
  duration: 1,
  color: '#000',
};

export default Reveal;
