export const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const throttle = (callback, del) => {
  let isThrottled = false;
  let arg;
  let context;

  function wrapper(...args) {
    if (isThrottled) {
      arg = args;
      context = this;
      return;
    }

    isThrottled = true;
    callback.apply(this, args);

    setTimeout(() => {
      isThrottled = false;
      if (arg) {
        wrapper.apply(context, arg);
        arg = null;
        context = null;
      }
    }, del);
  }

  return wrapper;
};

export const generateId = () =>
  Date.now().toString(36) + Math.random().toString(36).substr(2, 4);

export const pad = function pad(n) {
  return n < 10 ? `0${n}` : n;
};

export const secondsToMinutes = secs => {
  const sign = Math.sign(secs);
  const durationMinutes = Math.floor((secs * sign) / 60);
  const durationSeconds = parseInt(secs * sign - durationMinutes * 60, 10);
  return `${sign < 0 ? '-' : ''}${pad(durationMinutes)}:${pad(
    durationSeconds,
  )}`;
};

// export const getSafeRegexString = (s) => s.replace(/[\\*()+[\]]/gi, '');
export const getSafeRegexString = s => s.replace(/[^\w\s]/gi, '');

export const windowsPathToUnix = path => {
  if (
    typeof path === 'string' &&
    path.match(/(\w+?:)(\\([A-z0-9 \-$#@!])+)(\.?)((\w+)?)/)
  ) {
    return path.replaceAll('\\', '/');
  }
  return path;
};

/**
 * RegEx grouped results. Example - "@[Full Name](123abc)"
 * We have 4 groups here:
 * - The whole original string - "@[Full Name](123abc)"
 * - Mention trigger - "@"
 * - Name - "Full Name"
 * - Id - "123abc"
 */
const mentionRegEx = /((.)\[([^[]*)]\(([^(^)]*)\))/gi;

/**
 * Replace all mention values in value to some specified format
 *
 * @param value - value that is generated by MentionInput component
 * @param replacer - function that takes mention object as parameter and returns string
 */
export const replaceMentionValues = (value, replacer) =>
  value.replace(mentionRegEx, (fullMatch, original, trigger, name, id) =>
    replacer({
      original,
      trigger,
      name,
      id,
    }),
  );

export const isMusic = filename =>
  new RegExp(
    /([a-zA-Z0-9\s_\\.\-():])+(.mp3|.wav|.flac|.aac|.m4a|.aiff|.mp4)$/i,
  ).test(filename);

export const isEmail = email => new RegExp(/\S+@\S+\.\S+/).test(email);

export const getWipPermissions = wip => {
  const permissions = {};
  if (wip && wip.writers) {
    wip.writers.forEach(k => {
      permissions[k] = 'writer';
    });
  }
  if (wip && wip.readers) {
    wip.readers.forEach(k => {
      permissions[k] = 'reader';
    });
  }
  return permissions;
};
