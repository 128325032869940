import Analytics from './helper-analytics';
import firebase, {db} from './helper-firebase';
import {logErrorException} from './helper-logs';
import {
  ActivityType,
  ActivityTypeMigrator,
  stageString,
  UntitledInstanceObjects,
} from './helper-types';

// Can use this function below, OR use Expo's Push Notification Tool-> https://expo.io/notifications
export const sendPushNotification = async messages => {
  try {
    const result = await Promise.all(
      messages.map(async message => {
        const res = await fetch('https://onesignal.com/api/v1/notifications', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            Authorization:
              'Basic ZjFjNjAyYzYtNDdhZi00OGU4LWIwYzEtN2U4ZThiMjVkYmVl',
          },
          body: JSON.stringify({
            app_id: '540fed58-3dcf-4b49-bda9-80ce69cfdeca',
            contents: {en: message.body},
            headings: {en: message.title},
            include_external_user_ids: message.to,
            data: message.data,
          }),
        });
        return res.json();
      }),
    );
    console.log('result', result);
    return result;
  } catch (error) {
    logErrorException(error);
    return null;
  }
};

export const generateActivityFeed = ({
  wipId,
  audioId = '',
  authorId,
  kind,
  subscribers,
  coverArt = '',
  comment = undefined,
  title = '',
  oldTitle = '',
  taggedAuthors = [],
  removedAuthorId = '',
}) => {
  const activityData = {
    kind,
    type: ActivityTypeMigrator[kind],
    created: firebase.firestore.Timestamp.now(),
    author:
      authorId &&
      db.collection(stageString(UntitledInstanceObjects.Author)).doc(authorId),
    wipId,
  };
  const optionalData = Object.fromEntries(
    Object.entries({
      subscribers,
      coverArt,
      comment,
      title,
      oldTitle,
      taggedAuthors,
      audioId,
      removedAuthorId,
    }).filter(([, v]) => !!v),
  );

  return {
    ...activityData,
    ...optionalData,
  };
};

const messagesTypes = {
  [ActivityType.newComment]: {
    owner: 'commented on your wip',
    collaborator: 'commented on',
  },
  [ActivityType.acceptedInvite]: {
    owner: 'accepted your invite for',
    collaborator: 'accepted an invite for',
  },
  [ActivityType.deniedWip]: {
    owner: 'denied your invite for',
    collaborator: 'denied an invite for',
  },
  [ActivityType.newInvite]: {
    owner: 'has been invited to',
    collaborator: 'has been invited to',
  },
  [ActivityType.removedPermissionsFromWip]: {
    owner: 'has been removed from',
    collaborator: 'has been removed from',
  },
  [ActivityType.newImage]: {
    owner: 'updated the cover art for',
    collaborator: 'updated the cover art for',
  },
  [ActivityType.updatedTitle]: {
    owner: 'updated the title for',
    collaborator: 'updated the title for',
  },
  [ActivityType.newAudio]: {
    owner: 'added an audio on',
    collaborator: 'added an audio on',
  },
  [ActivityType.newListen]: {
    owner: 'is listening your wip',
    collaborator: 'is listening',
  },
  [ActivityType.updatedAudioTitle]: {
    owner: 'updated the title for',
    collaborator: 'updated the title for',
  },
  [ActivityType.arrangedWip]: {
    owner: 'changed the order of',
    collaborator: 'changed the order of',
  },
};

export const generateNotification = (type, data) => {
  const {
    author,
    wip,
    wipTitle,
    audioTitle,
    comment,
    audioId,
    userId,
    invite = {},
    targetAll = false,
    taggedAuthors = [],
    guest,
  } = data;
  const {author: authorId, title, id: wipId} = wip;
  const isOwner = authorId === userId;
  const {owner, collaborator} = messagesTypes[type];
  const message = isOwner ? owner : collaborator;
  switch (type) {
    case ActivityType.newComment: {
      const isTagged = taggedAuthors.indexOf(userId) !== -1;
      const msg = isTagged
        ? `${author.fullName} mentioned you on ${title.text}`
        : `${author.fullName} ${message} ${title.text}`;
      return {
        title: msg,
        body: comment,
        to: [userId],
        data: {
          wipId,
          audioId,
        },
      };
    }
    case ActivityType.acceptedInvite: {
      return {
        title: `${author.fullName} ${message} ${title.text}`,
        body: `${author.fullName} can now listen to ${title.text}`,
        to: [userId],
        data: {
          wipId,
        },
      };
    }
    case ActivityType.deniedWip: {
      return {
        title: `${author.fullName} ${message} ${title.text}`,
        body: 'Tap to go to WIP',
        to: [userId],
        data: {
          wipId,
        },
      };
    }
    case ActivityType.newInvite: {
      const isTarget = invite.id === userId;
      const msg =
        isTarget || targetAll
          ? `${author.fullName} invited you to "${title.text}"`
          : `${author.fullName} invited ${invite.fullName} to "${title.text}"`;
      return {
        title: msg,
        body: 'Tap to go to WIP',
        to: [userId],
        data: {
          wipId: isTarget || targetAll ? undefined : wipId,
        },
      };
    }
    case ActivityType.removedPermissionsFromWip: {
      return {
        title:
          author.id === userId
            ? `${author.fullName} was removed from "${title.text}"`
            : `${author.fullName} removed ${invite.fullName} from "${title.text}"`,
        body: 'Tap to go to WIP',
        to: [userId],
        data: {
          wipId,
        },
      };
    }
    case ActivityType.newImage: {
      return {
        title: `${author.fullName} updated the cover art for "${title.text}"`,
        body: 'Tap to go to WIP',
        to: [userId],
        data: {
          wipId,
        },
      };
    }
    case ActivityType.updatedTitle: {
      return {
        title: `${author.fullName} updated the title for ${title.text}`,
        body: `New title is ${wipTitle}`,
        to: [userId],
        data: {
          wipId,
        },
      };
    }
    case ActivityType.newAudio: {
      return {
        title: `${author.fullName} added ${audioTitle} on "${title.text}"`,
        body: 'Tap to open notifications',
        to: [userId],
        data: {
          wipId,
          audioId,
        },
      };
    }
    case ActivityType.newListen: {
      return {
        title: 'You have a new view',
        body: `${guest.fullName} ${message} ${title.text}`,
        to: [userId],
        data: {
          wipId,
        },
      };
    }
    case ActivityType.deletedWip:
    default:
      return false;
  }
};

export const generateNotifications = ({
  type,
  users = [],
  author = {},
  wip = {},
  comment = '',
  audioId = '',
  invite = {},
  wipTitle = '',
  audioTitle = '',
  targetAll = false,
  taggedAuthors = [],
}) => {
  const notifications = [];

  users.forEach(user => {
    const notification = generateNotification(type, {
      author,
      wip,
      comment,
      audioId,
      userId: user.id,
      invite,
      wipTitle,
      audioTitle,
      targetAll,
      taggedAuthors,
    });
    if (notification) {
      notifications.push(notification);
    }
  });

  return notifications;
};

export const processActivities = async (wipRef, userRef, state, subs, meta) => {
  const {wips, authors} = state;

  const wipList = {...wips.all, ...wips.notifications};
  const wip = wipList[wipRef.id];
  const author = authors.users[userRef.id];

  const subscribers = subs.filter(a => a !== userRef.id);

  const users = subscribers.map(id => ({id, ...authors.users[id]}));

  const activityUpdateData = generateActivityFeed({
    wipId: wipRef.id,
    authorId: userRef.id,
    subscribers,
    ...meta,
  });

  console.log('Activity', activityUpdateData, meta);

  await wipRef
    .collection(stageString(UntitledInstanceObjects.Activity))
    .doc()
    .set(activityUpdateData)
    .then(() => {
      Analytics.logEventComment();
    });

  if (
    meta.kind !== ActivityType.acceptedInvite &&
    meta.kind !== ActivityType.newInvite &&
    meta.kind !== ActivityType.deletedWip &&
    meta.kind !== ActivityType.deniedWip
  ) {
    wipRef.update({lastEdited: firebase.firestore.Timestamp.now()});
  }

  return {
    wip,
    users,
    author,
  };
};
