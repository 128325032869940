import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';

const MenuSlide = ({
  children,
  level,
  selectedLevel,
  onEnter,
  timeout,
  unmountOnExit,
}) => {
  const [show, setShow] = useState(false);
  const [direction, setDirection] = useState('none');
  useEffect(() => {
    const newDirection = level <= selectedLevel ? 'left' : 'right';
    const selected = selectedLevel === level;
    setShow(selected);
    if (selectedLevel !== level) {
      setDirection(newDirection);
    }
  }, [selectedLevel]);
  return (
    <CSSTransition
      in={show}
      timeout={timeout}
      classNames={`app__sliding--menu-${direction}`}
      unmountOnExit={unmountOnExit}
      mountOnEnter
      onEnter={el => {
        setTimeout(() => {
          const {height} = el.getBoundingClientRect();
          onEnter(height);
        }, 200);
      }}>
      <div className="app__sliding--panel" style={{position: 'absolute'}}>
        {children}
      </div>
    </CSSTransition>
  );
};

MenuSlide.defaultProps = {
  timeout: 3000,
  onEnter: () => {},
  level: 0,
  selectedLevel: 0,
  unmountOnExit: false,
};

MenuSlide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  timeout: PropTypes.number,
  onEnter: PropTypes.func,
  level: PropTypes.number,
  selectedLevel: PropTypes.number,
  unmountOnExit: PropTypes.bool,
};

export default MenuSlide;
