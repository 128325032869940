import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import {COLORS} from '../../../constants';

const Loader = ({className, style, width}) => (
  <div className={`app__loader ${className}`} style={style}>
    <div className="app__loader--inner">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        viewBox="0 0 24 30"
        xmlSpace="preserve">
        <rect x="0" y="0" width="4" height="20" fill={COLORS.primary}>
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="1; .2; 1"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="7" y="0" width="4" height="20" fill={COLORS.primary}>
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="1; .2; 1"
            begin="0.2s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="14" y="0" width="4" height="20" fill={COLORS.primary}>
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="1; .2; 1"
            begin="0.4s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  </div>
);

Loader.defaultProps = {
  className: '',
  style: {},
  width: 64,
};

Loader.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  style: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
};

export default Loader;
