import React from 'react';
import PropTypes from 'prop-types';
import PlusIcon from 'react-feather/dist/icons/plus';

import Files from '../../atoms/files';
import ThumbCrop from '../../molecules/thumb-crop';
import Input from '../../atoms/input';

const Uploads = ({
  coverArt,
  setCroppedAreaPixels,
  onArtworkChanged,
  wipTitle,
  authorName,
  setWipTitle,
  authors,
  audioTitles,
  setAudioTitles,
  curAuthors,
  onUpload,
  onAddAuthors,
  onAuthorName,
  thumbSize,
}) => (
  <div className="app__slide app__upload">
    <div className="app__upload--thumb mb-3">
      <img src={coverArt.uri} alt="artwork" />
      {coverArt.uri && (
        <ThumbCrop
          path={coverArt.uri}
          setCroppedAreaPixels={setCroppedAreaPixels}
          thumbSize={thumbSize}
        />
      )}
      <Files onChange={onArtworkChanged}>Edit Cover</Files>
    </div>
    <div className="app__upload--form">
      <div className="app__upload--inputs">
        <Input
          name="wipTitle"
          className="app__input"
          placeholder="Add WIP Title"
          value={wipTitle}
          onChange={e => {
            setWipTitle(e.value);
          }}
        />

        {onAuthorName && (
          <Input
            name="authorName"
            className="app__input"
            placeholder="Add Author Name"
            value={authorName}
            onChange={e => {
              onAuthorName(e.value);
            }}
          />
        )}
        <div className="app__upload--sep">
          <p>Tracks:</p>
        </div>

        {audioTitles.map(({name, id}) => (
          <Input
            key={id}
            name="title"
            className="app__input"
            placeholder="Add Audio Title"
            value={name}
            onChange={e => {
              setAudioTitles(e.value, id);
            }}
          />
        ))}
      </div>
    </div>
    {onAddAuthors && (
      <div className="app__upload--authors p-3">
        <div className="app__upload--authors-heading">
          <button type="button" onClick={onAddAuthors}>
            Add collaborators:
            <div className="app__miniauthor--add outline">
              <PlusIcon size={14} strokeWidth="2" />
            </div>
          </button>
        </div>
        {curAuthors.map(k => (
          <div key={k} className="app__miniauthor">
            <div className="app__miniauthor--initials">
              <span>{authors[k].initials}</span>
            </div>
            <div className="app__miniauthor--name">{`${authors[k].name}`}</div>
          </div>
        ))}
      </div>
    )}

    <div className="app__upload--footer p-3">
      <button
        type="button"
        onClick={onUpload}
        disabled={!wipTitle || (onAuthorName && !authorName)}
        className="btn btn-outline app__upload--footer-action">
        <span>Done</span>
      </button>
    </div>
  </div>
);

Uploads.defaultProps = {
  coverArt: {},
  setCroppedAreaPixels: () => {},
  onArtworkChanged: () => {},
  wipTitle: '',
  authorName: '',
  audioTitles: [],
  setWipTitle: () => {},
  authors: {},
  setAudioTitles: () => {},
  curAuthors: [],
  onUpload: () => {},
  onAuthorName: undefined,
  onAddAuthors: undefined,
  thumbSize: 322,
};

Uploads.propTypes = {
  coverArt: PropTypes.objectOf(PropTypes.any),
  setCroppedAreaPixels: PropTypes.func,
  onArtworkChanged: PropTypes.func,
  wipTitle: PropTypes.string,
  authorName: PropTypes.string,
  audioTitles: PropTypes.arrayOf(PropTypes.any),
  setWipTitle: PropTypes.func,
  authors: PropTypes.objectOf(PropTypes.any),
  setAudioTitles: PropTypes.func,
  curAuthors: PropTypes.arrayOf(PropTypes.any),
  onUpload: PropTypes.func,
  onAuthorName: PropTypes.func,
  onAddAuthors: PropTypes.func,
  thumbSize: PropTypes.number,
};

export default Uploads;
