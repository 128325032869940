import React from 'react';
import PropTypes from 'prop-types';

import TimeAgo from '@untitled/app/core/components/atoms/timeago';
import {IconPin} from '@untitled/app/core/components/atoms/icons';
import {ProgressCircularGridItem} from '@untitled/app/core/components/atoms/progress-circular';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import assets from '@untitled/app/core/assets';

const calcProgress = (uploadProgress = {}) => {
  const uploads = Object.values(uploadProgress).filter(v => v < 100);

  if (!uploads.length) {
    return 100;
  }

  const sum = uploads.reduce((acc, curr) => acc + curr, 0);
  return sum / uploads.length;
};

const THUMB_SIZE = 140;

const GridItem = ({
  id,
  title,
  author,
  cover,
  onPlay,
  onSelect,
  onAuthor,
  playing,
  selected,
  time,
  disabled,
  uploading,
  pinned,
  uploadProgress,
  onError,
}) => {
  const onClick = () => {
    if (!disabled) {
      onPlay();
    }
  };
  const selecedClass = selected ? 'item-selected' : '';
  const progress = uploadProgress ? calcProgress(uploadProgress) : 100;

  return (
    <div className={`app__grid--item ${selecedClass}`} data-id={id}>
      <div className="app__grid--item-thumb">
        <button
          className="app__grid--item-img"
          type="button"
          onClick={() => {
            if (!disabled) {
              onSelect(id);
            }
          }}>
          <div className="app__grid--item-bg">
            <LazyLoadImage
              src={cover ?? assets.thumb}
              alt={''}
              height={THUMB_SIZE}
              width={THUMB_SIZE}
              loading="lazy"
              onError={() => {
                onError(`Error loading image: ${cover}`);
              }}
            />
          </div>
        </button>

        {progress < 100 && (!cover || !!uploadProgress) && (
          <ProgressCircularGridItem
            stroke={2.5}
            progress={progress}
            color="rgba(0,0, 0, 1)"
            bg="rgba(0, 0, 0, 0.4)"
          />
        )}

        {!uploading && (
          <button
            className="app__thumb--icon"
            type="button"
            onClick={onClick}
            disabled={uploading || disabled}>
            <svg
              width="48"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              {playing ? (
                <>
                  <path d="M6 3 10 3 10 21 6 21z" fill="white" />
                  <path d="M14 3 18 3 18 21 14 21z" fill="white" />
                </>
              ) : (
                <path d="M5 2 5 22 21 12z" fill="white" />
              )}
            </svg>
          </button>
        )}
      </div>
      <div className="app__grid--item-info">
        <button
          className="app__grid--item-title truncate"
          type="button"
          onClick={() => {
            if (!disabled) {
              onSelect(id);
            }
          }}
          title={title}>
          <p className="truncate">{title}</p>
          {pinned && (
            <div className="title-pin">
              <div className="title-badge">
                <IconPin size={8} color="white" />
              </div>
            </div>
          )}
        </button>
        <button
          className="app__grid--item-author"
          type="button"
          onClick={onAuthor}
          title={author}>
          <p className="truncate">
            <span>{author}</span>
            {time && (
              <>
                <span className="separator">-</span>
                <TimeAgo datetime={time} />
              </>
            )}
          </p>
        </button>
      </div>
    </div>
  );
};

GridItem.defaultProps = {
  author: '',
  cover: '',
  id: '',
  title: '',
  onPlay: () => {},
  onSelect: () => {},
  onAuthor: () => {},
  onError: () => {},
  playing: false,
  selected: false,
  disabled: false,
  time: undefined,
  uploading: false,
  pinned: false,
  uploadProgress: undefined,
};

GridItem.propTypes = {
  author: PropTypes.string,
  cover: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  onPlay: PropTypes.func,
  onSelect: PropTypes.func,
  onAuthor: PropTypes.func,
  onError: PropTypes.func,
  playing: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  time: PropTypes.string,
  uploading: PropTypes.bool,
  pinned: PropTypes.bool,
  uploadProgress: PropTypes.objectOf(PropTypes.any),
};

export default GridItem;
